import { useMutation } from "@apollo/client";
import { Modal, notification } from "antd";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ADD_JOURNAL,
  ADD_NOTES_IMAGES,
  WORKSHOP_NOTES
} from "../../../client/graphql";
import { VoiceDualIcon } from "../../../themes/dualImages";
import { getS3UploadUrl, uploadFiles } from "../../../utils";
import Audio from "../Audio";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import Loader from "../Loader";
const WaveContainer = styled.div`
  .bg-liteViolet {
    background: #5432ba;
  }
  .bg-skyBlue {
    background: rgb(var(--blue));
  }
  .bg-liteYellow {
    background: #ffcf28;
  }
`;
let timeInterval;
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const Wave = (props) => {
  const [waveCount, setWaveCount] = React.useState(null);
  useEffect(() => {
    if (props.isAnimating) {
      timeInterval = setInterval(() => {
        setWaveCount((curr) => {
          const newCounter = (curr + 1) % 3;
          // setCurrentText(texts[newCounter % texts.length]);
          return newCounter;
        });
        // console.log(((waveCount || 0) + 1) % 3);
        // setWaveCount(((waveCount || 0) + 1) % 3);
      }, 500);
    } else {
      setWaveCount(null);
      clearInterval(timeInterval);
    }
    return () => {
      setWaveCount(null);
      clearInterval(timeInterval);
    };
  }, [props.isAnimating]);
  return (
    <WaveContainer className="flex items-center justify-center h-16">
      <div
        className={` w-3 ${waveCount === 0 ? "h-10" : "h-7"
          } duration-700 transition-all rounded-4xl mr-2 bg-liteYellow`}
      ></div>
      <div
        className={`w-3 ${waveCount === 1 ? "h-16" : "h-14"
          } duration-500 transition-all rounded-4xl mr-2 bg-liteViolet`}
      ></div>
      <div
        className={`w-3 ${waveCount === 2 ? "h-12" : "h-10"
          } duration-500 transition-all rounded-4xl bg-skyBlue `}
      ></div>
    </WaveContainer>
  );
};
export default function NotesAudio(props) {
  const id = useParams();
  const [showAudioUpload, setShowAudioUpload] = React.useState(false);
  const [showRecorded, setShowRecorded] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const [isBlocked, setisBlocked] = React.useState(false);
  const [seconds, setSeconds] = React.useState(0);
  const [audioURL, setaudioURL] = React.useState("");
  const [audioFile, setAudioFile] = React.useState("");
  // console.log(id);
  React.useEffect(() => {
    let intervalId;
    if (seconds === 60 && isRecording) {
      clearInterval(intervalId);
      stopRecording(false);
      // return;
    } else if (isRecording) {
      intervalId = setInterval(() => {
        setSeconds(seconds + 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      clearInterval(intervalId);
    }
  }, [seconds, isRecording]);
  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    // checkPermissionForAudio();
  });
  useEffect(() => {
    return () => {
      if (audioURL) {
        window.URL.revokeObjectURL(audioURL);
      }
    };
  }, []);

  const [addAudio, { loading: addingAudio }] = useMutation(ADD_NOTES_IMAGES, {
    onCompleted: (data) => {
      if (data.ipadAddNotesImagesV2) {
        props.onSuccess && props.onSuccess();

        notification.success({ message: "Audio uploaded" });
        setShowAudioUpload(false);
        setShowRecorded(false);
        let elementId = document.getElementById("notesSection");
        if (elementId) {
          elementId.scrollIntoView();
        }
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addJournal, { loading: addingJournal }] = useMutation(ADD_JOURNAL, {
    onCompleted: (data) => {
      if (data.AddJournalNoteImageV2?.is_added) {
        props.onSuccess && props.onSuccess();
        notification.success({ message: "Audio uploaded" });
        setShowAudioUpload(false);
        setShowRecorded(false);
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addWorkshopNotes, { loading: addingNotes }] = useMutation(
    WORKSHOP_NOTES,
    {
      onCompleted: (data) => {
        if (data.ipadAddNotesImagesV2?.is_added) {
          props.onSuccess && props.onSuccess();
          notification.success({ message: "Audio uploaded" });
          setShowAudioUpload(false);
          setShowRecorded(false);
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  const startRecording = () => {
    if (isBlocked) {
      notification.error({
        message: "Something went wrong",
        description: `Please give permission for the microphone for recording audio.`,
      });
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = (param) => {
    setIsRecording(false);

    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (!param) {
          setaudioURL(window.URL.createObjectURL(blob));
          setAudioFile(file);
          setShowAudioUpload(false);
          setShowRecorded(true);
          setIsRecording(false);
        }
      })
      .catch((e) => console.log(e));
  };
  const checkPermissionForAudio = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia =
          // navigator.getUserMedia ||
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setisBlocked(false);
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong",
          description: `Please give permission for the microphone for recording audio.`,
        });
        setisBlocked(true);
        console.log(err.name + ": " + err.message);
      });
  };
  return (
    <div>
      <Modal
        width={600}
        title={null}
        visible={showRecorded}
        footer={null}
        closable={false}
        maskClosable={true}
        destroyOnClose={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowRecorded(false);
          setSeconds(0);
        }}
      >
        <Loader loading={addingAudio || addingNotes || addingJournal} />

        <div className="nunito-bold-xl mt-2 text-center">Record Audio</div>
        <div className=" mt-16">{audioURL && <Audio url={audioURL} />}</div>
        <div className=" mt-14">
          <Textarea notes={notes} setNotes={setNotes} />
        </div>
        <div className="mt-8 flex items-center justify-center">
          <div className=" w-40 mr-4">
            <Button
              className="font-Nunito text-darkGray border border-darkGray pl-3 pr-3  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                setShowRecorded(false);

                // AddToWishlist();
              }}
            >
              Cancel
            </Button>
          </div>
          <div className=" w-36 mr-4">
            <Button
              secondaryGhost
              className="font-Nunito text-base px-3  py-2 text-center flex font-bold justify-center"
              onClick={() => {
                setaudioURL("");
                setAudioFile("");
                setSeconds(0);
                setShowAudioUpload(true);
                setShowRecorded(false);
              }}
            >
              {`Change audio`}
            </Button>
          </div>
          <div className=" w-40">
            <Button
              secondary
              className="font-Nunito text-base text-white px-6  py-2 flex font-bold text-center justify-center"
              onClick={async () => {
                console.log(audioFile);
                let signedURL = await getS3UploadUrl(
                  audioFile.name,
                  "audio/mpeg"
                );
                if (signedURL) {
                  let urls = await uploadFiles([signedURL], [audioFile]);
                  //https://altius-testing-images.s3.ap-south-1.amazonaws.com/9R1qFZk4yxme-at-thevoice.mp3
                  if (urls && urls[0]) {
                    if (props?.title === "Journal Entry") {
                      addJournal({
                        variables: {
                          lcId: parseInt(localStorage.getItem("lc_id")) || 64,
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: urls[0],
                          type: "audio",
                        },
                      });
                    } else if (props?.title === "Workshop notes") {
                      await addWorkshopNotes({
                        variables: {
                          workshop_id: parseInt(id?.id),
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: urls,
                          type: "audio",
                        },
                      });
                    } else {
                      await addAudio({
                        variables: {
                          activityId: parseInt(id?.activityId),
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: urls,
                          type: "audio",
                        },
                      });
                    }
                  }
                }
              }}
            >
              {`Save`}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        width={600}
        title={null}
        visible={showAudioUpload}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowAudioUpload(false);
          if (isRecording) {
            setSeconds(0);

            stopRecording(true);
          }
        }}
      >
        <div className="nunito-bold-xl mt-2 text-center">Record Audio</div>
        <div className="mt-3 text-center text-lightGray h9">
          {`You can record up to 1 minute`}
        </div>
        <div className="flex mt-14 mb-16 items-center justify-center">
          <Wave isAnimating={isRecording} />
          <div className=" ml-9 h1">{`${("00" + seconds).slice(-2)} sec`}</div>
        </div>
        <div className="mt-8 flex items-center justify-center">
          <div className=" w-36 mr-4">
            <Button
              className="font-Nunito text-darkGray border border-darkGray pl-3 pr-3  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                setShowAudioUpload(false);
                // AddToWishlist();
              }}
            >
              Cancel
            </Button>
          </div>
          {isRecording && (
            <div className=" w-36">
              <Button
                danger
                className="font-Nunito text-base text-white px-6 py-2 flex font-bold text-center justify-center"
                onClick={() => {
                  stopRecording(false);
                }}
              >
                {`Stop`}
              </Button>
            </div>
          )}
          {!isRecording && (
            <div className=" w-36">
              <Button
                secondary
                className="font-Nunito text-base text-white px-6 py-2 flex font-bold text-center justify-center"
                onClick={() => {
                  startRecording();
                }}
              >
                {`Record`}
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <div
        className="flex items-center cursor-pointer mb-5"
        onClick={() => {
          checkPermissionForAudio();
          setShowAudioUpload(true);
        }}
      >
        {/* <img src={Images.VoiceIcon} alt="Voice img" /> */}
        <VoiceDualIcon />
        <div className="ml-3">Record audio</div>
      </div>
    </div>
  );
}
