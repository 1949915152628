import { Layout } from "antd";
import React from "react";
import { useHistory } from "react-router";
import Images from "../../themes/Images";
const { Content } = Layout;

export default function GenericNotFound() {
  const history = useHistory();

  return (
    <Layout>
      <Content className="min-h-screen text-center flex justify-center items-center flex-col">
        <img src={Images.FourOFour} className="mx-auto max-w-2xl" alt="404" />
        <h5 className="mt-5 font-Nunito font-semibold text-base">
          The page you are looking for doest not exist.
        </h5>
        <h5 className="font-Nunito font-semibold text-base mb-10">
          Continue your
          <span
            onClick={() => {
              history.push("/dashboard");
            }}
            style={{
              color: "#5432ba",
            }}
            className=" cursor-pointer font-Nunito font-bold mt-2 ml-1"
          >
            exploration
          </span>
        </h5>
      </Content>
    </Layout>
  );
}
