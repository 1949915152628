import React, { useContext } from "react";
import ChildDetail from "./details";
import ParentDetail from "./parents/index";
import NavContext from "../../context/NavContext";

const WorkshopDetailIndex = () => {
  const userNavContext = useContext(NavContext);
  return (
    <>
      {userNavContext?.userInfo?.mode === "LIGHT_MODE" ? (
        <ChildDetail />
      ) : (
        <ParentDetail />
      )}
    </>
  );
};

export default WorkshopDetailIndex;
