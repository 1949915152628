import { useQuery } from "@apollo/client";
import { Typography } from "antd";
import moment from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { WORKSHOP_LIST } from "../../client/graphql";
import WorkshopCard from "../../components/Cards/WorkshopCard";
import { ExploreContainer } from "../../components/common/ExploreHeading";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";
import AdultPaymentModal from "../../components/common/AdultPaymentModal";

const { Paragraph } = Typography;

const FeaturedWorkshopContainer = styled.div`
  width: 461px;
  height: 232px;
  cursor: pointer;
  color: #fff;
  .authorImg {
    min-width: 44px;
  }
  .authorTitle {
    width: calc(461px - 76px);
  }
  & .bg {
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 44.18%,
      rgba(0, 0, 0, 0.42) 74.63%,
      rgba(0, 0, 0, 0.72) 99.17%
    );
  }
`;

const CarouselContainer = ({ data, id, maxCount }) => {
  return (
    (data || []).length > maxCount && (
      <>
        <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
          <img
            src={Images.CarouselLeft}
            alt="imageRight"
            className={`rotate-180 transform shadow-3xl rounded-full w-11 h-11 cursor-pointer z-10`}
            onClick={() => {
              document.getElementById(id).scrollTo({
                left: document.getElementById(id).scrollLeft - 250,
                behavior: "smooth",
              });
            }}
          />
        </div>
        <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
          <img
            src={Images.CarouselLeft}
            alt="imageRight"
            className={`transform shadow-3xl rounded-full w-11 h-11 cursor-pointer z-10`}
            onClick={() => {
              document.getElementById(id).scrollTo({
                left: document.getElementById(id).scrollLeft + 250,
                behavior: "smooth",
              });
            }}
          />
        </div>
      </>
    )
  );
};

const FeaturedWorkshop = ({ authorImg, workshopImg, title, date }) => {
  return (
    <FeaturedWorkshopContainer className="relative rounded overflow-hidden">
      <img
        src={workshopImg}
        alt="Work Featured img"
        className="w-full h-full object-cover rounded"
      />
      <div className="absolute inset-0 bg"></div>
      <div className="absolute left-0 right-0 bottom-0 p-3">
        <div className="flex">
          <div className=" w-11 h-11 rounded-full authorImg">
            <img
              src={authorImg}
              alt="auth img"
              className="h-full w-full object-cover rounded-full"
            />
          </div>
          <div className="ml-2  authorTitle">
            <Paragraph
              ellipsis={{
                rows: 1,
                expandable: false,
              }}
              className=" h4  mb-0 w-full"
            >
              {title}
            </Paragraph>
            <div className="nunito-semibold-xs">{date}</div>
          </div>
        </div>
      </div>
    </FeaturedWorkshopContainer>
  );
};

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
    ? 48
    : 64;

export default function Workshops() {
  const [featuredWorkshop, setFeaturedWorkshop] = React.useState([]);
  const [upcomingWorkshop, setUpcomingWorkshop] = React.useState([]);
  const [pastWorkshop, setPastWorkshop] = React.useState([]);
  const history = useHistory();
  const { data, loading } = useQuery(WORKSHOP_LIST, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(localStorage.getItem("lc_id")) || lc_id,
    },
  });

  React.useEffect(() => {
    if (data) {
      setFeaturedWorkshop(data?.ipadWorkshopList?.featured_workshops);
      setPastWorkshop(data?.ipadWorkshopList?.past_workshop);
      setUpcomingWorkshop(data?.ipadWorkshopList?.workshop_details);
    }
  }, [data]);

  const getDateString = (workshop, type) => {
    let dateString = "";
    const workshopDuration = workshop?.Dates[0]?.duration;
    const startDate =
      workshop?.Dates?.length > 0
        ? moment
            .unix(workshop?.Dates[0]?.workshop_Date / 1000)
            .format("DD MMM 'YY")
        : moment().format("DD MMM 'YY");
    const startDay =
      workshop?.Dates?.length > 0
        ? moment.unix(workshop?.Dates[0]?.workshop_Date / 1000).format("ddd")
        : moment().format("ddd");
    const endDate =
      workshop?.Dates?.length > 0
        ? moment
            .unix(
              workshop?.Dates[workshop?.Dates?.length - 1]?.workshop_Date / 1000
            )
            .format("DD MMM 'YY")
        : moment().format("DD MMM 'YY");
    const endDay =
      workshop?.Dates?.length > 0
        ? moment
            .unix(
              workshop?.Dates[workshop?.Dates?.length - 1]?.workshop_Date / 1000
            )
            .format("ddd")
        : moment().format("ddd");
    if (workshop?.Dates?.length === 1) {
      dateString = `${startDay} | ${startDate} - ${workshopDuration}`;
    } else {
      if (workshop?.repeatDays) {
        if (type === "featured") {
          dateString = `${workshop?.repeatDays} | ${startDate} - ${endDate}`;
        } else {
          dateString = `${workshop?.repeatDays} | ${startDate} - ${endDate} - ${workshopDuration}`;
        }
      } else {
        if (type === "featured") {
          dateString = `${startDay} - ${endDay} | ${startDate} - ${endDate}`;
        } else {
          dateString = `${startDay} - ${endDay} | ${startDate} - ${endDate} - ${workshopDuration}`;
        }
      }
    }
    return dateString;
  };

  return (
    <div>
      <Loader loading={loading} />
      {/* <AdultPaymentModal showTrialOver={true} /> */}
      <ExploreContainer className="flex justify-between items-center py-4 px-12 relative bg-white">
        <div className="h7 ">
          We have successfully conducted{" "}
          <span className=" font-semibold">
            {pastWorkshop?.length} workshops.
          </span>{" "}
          Be a part of our next one!
        </div>
      </ExploreContainer>
      <div
        className="container mx-auto p-6 bg-lightWhite"
        style={{
          boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
          borderRadius: "3px",
        }}
      >
        {featuredWorkshop?.length > 0 && (
          <>
            <div className="nunito-bold-xl text-textDark">
              {featuredWorkshop?.length > 0
                ? featuredWorkshop[0]?.workshop_title
                : null}
            </div>
            <div className="relative mt-6 ">
              <CarouselContainer
                id={"featuredWorkshop"}
                data={featuredWorkshop}
                maxCount={3}
              />
              <div
                id="featuredWorkshop"
                className=" flex items-center overflow-auto "
              >
                {featuredWorkshop?.map((workshop, workshopIndex) => {
                  return (
                    workshopIndex < 5 && (
                      <div
                        key={`Workshop-${workshopIndex}`}
                        className="mr-5"
                        onClick={() => {
                          history.push(`/workshops/${workshop?.id}`);
                        }}
                      >
                        <FeaturedWorkshop
                          date={getDateString(workshop, "featured")}
                          title={workshop?.workshop_name}
                          authorImg={workshop?.content_creator?.profile_image}
                          workshopImg={workshop?.featured_image[0]?.image_url}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </>
        )}
        {upcomingWorkshop && upcomingWorkshop?.length > 0 && (
          <>
            <div className="flex justify-between my-6">
              <div className="nunito-bold-xl text-textDark">
                Upcoming Events
              </div>
              {upcomingWorkshop?.length > 5 && (
                <Link to="/workshops/upcoming">
                  <div className="h7 cursor-pointer text-textDark">
                    View all
                  </div>
                </Link>
              )}
            </div>
            <div className="relative mt-6">
              <CarouselContainer
                id={"upcomingWorkshop"}
                data={upcomingWorkshop}
                maxCount={3}
              />
              <div
                id="upcomingWorkshop"
                className=" flex items-center overflow-auto "
              >
                {upcomingWorkshop.map((workshop, workshopIndex) => {
                  return (
                    workshopIndex < 5 && (
                      <div
                        key={`UpcomingWorkshop-${workshopIndex}`}
                        className="mr-5"
                        onClick={() => {
                          history.push(`/workshops/${workshop?.id}`);
                        }}
                      >
                        <WorkshopCard
                          thumbnail={workshop?.featured_image[0]?.image_url}
                          author={workshop?.content_creator?.profile_image}
                          time={getDateString(workshop, "upcoming")}
                          title={workshop?.workshop_name}
                          tags={workshop?.interest_details
                            ?.slice(0, 1)
                            ?.map((interest) => interest?.name)}
                          loading={loading}
                          list={"upcoming"}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className="flex justify-between my-6">
          <div className="nunito-bold-xl text-textDark">
            Past Popular Events
          </div>
          {pastWorkshop?.length > 5 && (
            <Link to="/workshops/past">
              <div className="h7 cursor-pointer text-textDark">View all</div>
            </Link>
          )}
        </div>
        <div className="relative mt-6">
          <CarouselContainer
            id={"pastWorkshop"}
            data={pastWorkshop}
            maxCount={3}
          />
          <div id="pastWorkshop" className=" flex items-center overflow-auto ">
            {pastWorkshop.map((workshop, workshopIndex) => {
              return (
                workshopIndex < 5 && (
                  <div
                    key={`pastWorkshop-${workshopIndex}`}
                    className="mr-5"
                    onClick={() => {
                      history.push(`/workshops/${workshop?.id}`);
                    }}
                  >
                    <WorkshopCard
                      thumbnail={workshop?.featured_image[0]?.image_url}
                      author={workshop?.content_creator?.profile_image}
                      time={getDateString(workshop, "past")}
                      title={workshop?.workshop_name}
                      tags={workshop?.interest_details
                        ?.slice(0, 1)
                        ?.map((interest) => interest?.name)}
                      loading={loading}
                      list={"past"}
                    />
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
