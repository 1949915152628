import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import CardListingLayout from "../../components/Cards/CardListingLayout";
import ExpertCard from "../../components/Cards/ExpertCard";
import Button from "../../components/common/Form/Button";

const Constants = {
  EXPERT_ROLE: 4,
  CURATOR_ROLE: 5,
};
export default function ExploreCardSearch({
  collections = [],
  isCollection = false,
  isPlaylist = false,
  onWishlistAdded,
  expertCards = [],
}) {
  const history = useHistory();

  const [showAllCards, setshowAllCards] = React.useState(false);

  const onCardOpen = useCallback(
    (collectionId, cardId) => {
      if (isCollection) {
        history.push(`/collections/${collectionId}/${cardId}`);
      } else if (isPlaylist) {
        history.push(`/playlists/${collectionId}/${cardId}`);
      }
    },
    [isCollection, isPlaylist]
  );

  const onCollectionOpen = useCallback(
    (collectionId, userRole) => {
      let basePath = isCollection ? `collections` : `playlists`;
      if (
        userRole === Constants.EXPERT_ROLE ||
        userRole === Constants.CURATOR_ROLE
      ) {
        history.push(`/${basePath}/expert/${collectionId}`);
      } else {
        history.push(`/${basePath}/${collectionId}`);
      }
    },
    [isCollection, isPlaylist]
  );
  const onPlayListOpen = useCallback((playlistId, userRole) => {
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/playlists/expert/${playlistId}`);
    } else {
      history.push(`/playlists/${playlistId}`);
    }
  }, []);
  return (
    <div>
      <div className=" inline-block w-full  px-16 ">
        {collections.map((collection, collectionIndex) => {
          if (collectionIndex === 0 || showAllCards)
            return (
              <div className="mb-10">
                <CardListingLayout
                  data={collection || {}}
                  onCardOpen={(cardId) => onCardOpen(collection.id, cardId)}
                  isPlaylist={isPlaylist}
                  onOpen={onCollectionOpen}
                  onWishlistAdded={onWishlistAdded}
                  showAll
                  onWishlistAdded={onWishlistAdded}
                />
              </div>
            );
        })}
        {collections && collections.length > 1 && !showAllCards && (
          <div className="mt-5 pb-10">
            <div className=" w-32 mx-auto  cursor-pointer">
              <Button
                secondaryGhost
                onClick={() => {
                  setshowAllCards(true);
                }}
              >
                {`+ ${collections.length - 1} more`}
              </Button>
            </div>
          </div>
        )}
      </div>
      {expertCards.length !== 0 && (
        <div className={`w-full  px-16 ${collections.length ? "mt-10" : ""} `}>
          <div className="font-Nunito font-bold text-xl">
            {`Experts and Curators ${
              isPlaylist ? "Learning path" : "Collections"
            }`}
          </div>
          <div className=" mt-5 grid grid-cols-3 gap-5 pb-10">
            {expertCards.map((playlist, playlistIndex) => {
              return (
                <div key={`Playlist-${playlistIndex}`} className="px-2">
                  <ExpertCard
                    data={playlist}
                    isPlaylist={isPlaylist}
                    onClick={() => {
                      onPlayListOpen(playlist.id, playlist?.user?.role_id);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
