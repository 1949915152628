import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
const { Paragraph } = Typography;

const WorkshopCardContainer = styled.div`
  min-width: 330px;
  width:330px;
  box-shadow: 0px 4px 16px 0px rgba(var(--shadowWhite),0.1);
  background: rgb(var(--eerieBlack));

  & .image {
    height: 144px;
  }
`;

export default function WorkshopCard({
  thumbnail,
  time,
  title,
  tags,
  author,
  loading = false,
  list,
}) {
  return (
    <WorkshopCardContainer className=" rounded-xl cursor-pointer ">
      {loading && (
        <div className="loader w-full object-cover image rounded-tl-xl rounded-tr-xl"></div>
      )}
      {!loading && (
        <img
          src={thumbnail}
          alt="Workshop imag"
          className="w-full object-cover image rounded-tl-xl rounded-tr-xl"
          style={
            list === "past" ? { filter: "grayscale(1)" } : { filter: "none" }
          }
        />
      )}
      <div className="relative">
        <div className=" w-10 h-10 rounded-full absolute right-3 -top-5 ">
          {loading && (
            <div className="loader w-full h-full object-cover rounded-full shadow-7xl"></div>
          )}
          {!loading && (
            <img
              src={author}
              alt="Workshop imag"
              className=" w-full h-full object-cover rounded-full shadow-7xl"
              //style={
              //  list === "past"
              //    ? { filter: "grayscale(1)" }
              //    : { filter: "none" }
              //}
            />
          )}
        </div>
      </div>
      <div className=" px-3 py-2 mb-0.5">
        <div className="h9 text-lightGray w-5/6">{time}</div>
        <div className=" mt-3">
          <Paragraph
            ellipsis={{
              rows: 1,
              expandable: false,
            }}
            className={`nunito-bold-xl mb-0 ${
              loading ? "loader rounded w-2/3 h-4" : "w-full"
            }`}
          >
            {!loading ? title : ""}
          </Paragraph>
        </div>
        <div className=" mt-10 flex overflow-auto h-6">
          {(loading ? [{}, {}] : tags || []).map((tag, tagIndex) => {
            return (
              <div
                key={`Tag-${title || ""}-${tagIndex}`}
                className={`nunito-semibold-xs bg-flashWhite px-3 py-1 text-pureBlack rounded-full whitespace-nowrap mr-3 ${
                  loading ? "loader rounded w-full h-4" : ""
                }`}
              >
                {!loading ? tag : ""}
              </div>
            );
          })}
        </div>
      </div>
    </WorkshopCardContainer>
  );
}
