import React, { useState } from "react";
import Images from "../../themes/Images";
import Button from "./Form/Button";

export default function ErrorBoundary(props) {
  const [hasError, setHasError] = useState(false);

  return (
    <div>
      {hasError ? (
        <div className="h-screen w-full flex items-center justify-center pt-5 pb-5 flex-col">
          <img
            src={Images.SomethingWentWrong}
            alt="Something Went Wrong"
            className="mx-auto"
          />
          <div className="msg-title mt-5">
            It looks like something went wrong
          </div>
          <Button
            secondaryGhost
            className="mt-2 font-Nunito text-base px-8  py-2 text-center flex font-normal justify-center items-center"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </div>
      ) : (
        props.children
      )}
    </div>
  );
}
