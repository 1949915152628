import { useMutation, useQuery } from "@apollo/client";
import { notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { GET_STUDENT_IMAGES, PICK_AVATAR } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";

const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`;
export default function AvatarPicker(props) {
  const history = useHistory();
  const location = useLocation();
  const [selectedImg, setSelectedImg] = useState(null);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const [avatarList, setAvatarList] = useState([]);

  useEffect(() => {
    if (!user_details || user_details.isProfileImage) {
      history.push("/");
    }
  }, []);

  const Avatar = (props) => {
    return (
      <div
        className={`text-center cursor-pointer`}
        onClick={() => {
          if (props.onSelect) props.onSelect();
        }}
      >
        <div
          className={`md:h-24 md:w-24 w-20 h-20 rounded-full relative m-auto flex items-center justify-center`}
          style={{ backgroundColor: props.bgColor }}
        >
          <img
            src={props.url}
            className={` ${props.bgColor ? "w-16 h-16" : ""} `}
            alt="avatar"
          />
          {props.isSelected && (
            <img
              src={Images.CheckedIcon}
              alt="CheckedIcon"
              className="absolute right-0 bottom-0"
            />
          )}
        </div>
      </div>
    );
  };

  const { data, loading: loadingAvatarList } = useQuery(GET_STUDENT_IMAGES, {
    onCompleted: () => {
      if (data.getStudentImages) {
        let avartarImgList = [];
        let userLetter = user_details.username.charAt(0).toUpperCase();
        data.getStudentImages.Images.map((avatarImages) => {
          let avatar = avatarImages.student_images;
          if (avatar.url_type === "letter" && avatar.character === userLetter) {
            avartarImgList.push(avatarImages);
          } else if (avatar.url_type === "emoji") {
            avartarImgList.push(avatarImages);
          }
        });
        setAvatarList(avartarImgList);
      }
    },
  });

    return (
      <div>
        <Loader loading={loadingAvatarList} />
        <div className="flex justify-between h-9 items-center cursor-pointer">
          <div className="absolute left-0 right-0 text-center pointer-events-none">
            <div className="authHeading">Select Avatar</div>
          </div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4 mt-10 md:mt-12">
          {avatarList.map((avatarImages, avatarIndex) => {
            let avatar = avatarImages.student_images;
            return (
              <React.Fragment key={`Avatar-${avatarIndex}`}>
                <Avatar
                  url={avatar.image_url}
                  bgColor={avatar.bg_colour}
                  isSelected={
                    selectedImg && selectedImg.url === avatar.image_url
                  }
                  onSelect={() => {
                    setSelectedImg({
                      url: avatar.image_url,
                      bgColor: avatar.bg_colour,
                    });
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div
          className={`block text-center mt-16 w-56 mx-auto ${
            selectedImg?.url ? `` : `opacity-50 pointer-events-none`
          } `}
        >
          <Button
            onClick={() => {
              if (selectedImg) {
                if (props.onChange) props.onChange(selectedImg);
              } else {
                notification.info({
                  message: "Please select one image to proceed",
                });
              }
            }}
            primary
          >
            Select
          </Button>
        </div>
      </div>
    );
}
