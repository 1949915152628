import { RightOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { SEND_REQUEST } from "../../client/graphql";
import NavContext from "../../context/NavContext";
import Images from "../../themes/Images";

const NotificationContainer = styled.div`
  padding: 27px;
  padding-top: 17px;
  border-radius: 10px;
  background: ${(props) => (props.isColored ? "#5432BA" : "#EC1A58")};
  z-index: 999;
  width: 350px;
`;

export default function NotificationAlert({ isColored = false, title, desc }) {
  const userNavContext = useContext(NavContext);
  const [sendRequestToParent] = useMutation(SEND_REQUEST, {
    onCompleted: (data) => {
      if (data?.sendRequest?.status === "Success") {
        setShowNotification(false);
        notification.success({ message: "Request send to parent!!" });
      }
    },
  });
  const [showNotification, setShowNotification] = React.useState(true);
  let history = useHistory();
  return (
    <>
      {showNotification ? (
        <>
          {localStorage.showNotification === "true" ? (
            <></>
          ) : (
            <NotificationContainer
              isColored={isColored}
              className="fixed bottom-5 right-5"
            >
              <img
                src={Images.WhiteCloseIcon}
                alt="close Icon"
                className="absolute right-4 top-4 cursor-pointer"
                onClick={() => {
                  setShowNotification(false);
                  localStorage.setItem("showNotification", "true");
                }}
              />
              <div className=" font-Nunito text-base text-pureWhite font-extrabold">
                {title ? title : userNavContext?.userInfo?.subs?.heading || ""}{" "}
                Alert
              </div>
              {userNavContext?.userInfo?.mode === "DARK_MODE" ? (
                desc ? (
                  desc
                ) : (
                  <div className=" font-Nunito text-base text-pureWhite font-extrabold mt-2">
                    Your subscription will expire on{" "}
                    {userNavContext?.userInfo?.expiry_date?.format(
                      "DD MMM YYYY"
                    )}{" "}
                    •{" "}
                    <Link to="/payment/proceed">
                      {" "}
                      <a
                        className="underline cursor-pointer"
                        onClick={() => {
                          setShowNotification(false);
                          history.push("/payment/proceed");
                          localStorage.setItem("showNotification", "true");
                        }}
                      >
                        Renew now{" "}
                      </a>
                    </Link>{" "}
                    <RightOutlined
                      style={{
                        color: "#fff",
                        fontSize: 14,
                      }}
                    />
                  </div>
                )
              ) : (
                <div className=" font-Nunito text-base text-pureWhite font-extrabold mt-2">
                  Your free trial will end on{" "}
                  {userNavContext?.userInfo?.expiry_date?.format("DD MMM YYYY")}
                  <div>To continue your learning journey,</div>
                  <div>
                    <div
                      className="underline cursor-pointer"
                      onClick={() => {
                        if (userNavContext?.userInfo?.is_parent) {
                          sendRequestToParent();
                        } else {
                          userNavContext.setUserInfo({
                            ...userNavContext.userInfo,
                            ...{
                              showParentSetup: true,
                            },
                          });
                        }
                      }}
                    >
                      send a request to your parent
                    </div>
                  </div>
                </div>
              )}
            </NotificationContainer>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
