import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../../components/common/Form/Button";
import Images from "../../../../themes/Images";

export default function PrerequisiteCard({
  status,
  imgURL,
  title,
  isViewCard = false,
  hideStatus = true,
  isCompleted = false,
  isSchedule,
  onSchedule,
  id,
  setShowPrerequisite,
}) {
  const location = useLocation();

  const history = useHistory();

  return (
    <div className=" mb-4">
      {hideStatus && <div className="h6">{`Status - ${status}`}</div>}
      <div className=" mt-2 flex gap-4 ">
        <div
          className={`p-2 border border-blue flex gap-4 ${isViewCard ? "w-7/12" : "w-full"
            } w-7/12 rounded items-center`}
        >
          <div className=" w-12 h-7 flex-shrink-0 ">
            <img src={imgURL} alt="Card URL" className=" w-full h-full" />
          </div>
          <div className="h8">
            {title}
            {!hideStatus && (
              <div className=" text-xs font-semibold">
                {`Status -`}{" "}
                <span
                  className={`${status === "In - Progress" ||
                    status === "Not Start" ||
                    status === "Scheduled"
                    ? "text-red"
                    : ""
                    }`}
                >
                  {status === "In - Progress" ||
                    status === "Not Start" ||
                    status === "Scheduled"
                    ? "Pending"
                    : status}
                </span>
              </div>
            )}
          </div>
        </div>
        {isViewCard && (
          <div className="w-5/12 flex gap-4">
            <div className=" w-full ">
              <Button
                secondaryGhost
                className={
                  "font-Nunito text-base px-6 py-2 font-bold text-center"
                }
                onClick={() => {
                  if (!location.pathname.includes("/playlists")) {
                    history.push(`/collections/cards/${id}`);
                  } else {
                    history.push(`/playlists/cards/${id}`);
                  }
                  setShowPrerequisite(false);
                }}
              >
                View
              </Button>
            </div>

            <div className=" w-56 flex-shrink-0 ">
              {status === "Scheduled" && (
                <Button
                  className={
                    "font-Nunito text-base px-6  py-2 font-bold"
                  }
                  secondary
                  onClick={() => {
                    if (!location.pathname.includes("/playlists")) {
                      history.push(`/collections/cards/${id}`);
                    } else {
                      history.push(`/playlists/cards/${id}`);
                    }
                    setShowPrerequisite(false);
                  }}
                >
                  Learn
                </Button>
              )}
              {status === "Not Start" && (
                <Button
                  primary
                  className="font-Nunito text-base px-6  py-2  font-bold flex items-center justify-center text-pureWhite"
                  onClick={() => {
                    onSchedule({
                      activityId: !location.pathname.includes("/playlists")
                        ? id
                        : null,
                      playlistId: location.pathname.includes("/playlists")
                        ? id
                        : null,
                    });
                  }}
                >
                  <img
                    src={Images.LearningPathAddIcon}
                    alt="Add Icon"
                    className="mr-3"
                  />
                  {` Add to schedule`}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
