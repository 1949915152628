import React from 'react';


const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1
    } : null;
}

const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;

const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

const points = points => {
    return points
        .map(point => point[0].toFixed(4) + ',' + point[1].toFixed(4))
        .join(' ');
};

const axis = options => (col, i) => (
    <polyline
        key={`poly-axis-${i}`}
        points={points([
            [0, 0],
            [
                polarToX(col.angle, options.chartSize / 2),
                polarToY(col.angle, options.chartSize / 2)
            ]
        ])}
        {...options.axisProps(col)}
    />
);

const dot = (columns, options, chat) => (chartData, colorIndex) => {
    const data = chartData.data;
    const meta = chartData.meta || {};
    let colors = options.colors || [];
    if (colors.length !== chat.length) {
        colors = chat.map((data, i) => {
            if (colors[i] === undefined)
                return "#00FF00"
            else
                return colors[i]
        })
    }

    const extraProps = options.dotProps(meta);
    let mouseEnter = () => { };
    let mouseLeave = () => { };
    if (extraProps.mouseEnter) {
        mouseEnter = extraProps.mouseEnter;
    }
    if (extraProps.mouseLeave) {
        mouseLeave = extraProps.mouseLeave;
    }
    return columns.map((col, i) => {
        const val = isNaN(data[col.key]) ? 0 : data[col.key]
        if ('number' !== typeof val) {
            throw new Error(`Data set ${i} is invalid.`);
        }
        const fillRGBA = hexToRgb(colors[colorIndex]);
        let fillColor = `rgba(${fillRGBA.r},${fillRGBA.g},${fillRGBA.b},${fillRGBA.a})`;
        return (
            <circle
                key={`dot-${col.key}-${val}`}
                cx={polarToX(col.angle, (val * options.chartSize) / 2)}
                cy={polarToY(col.angle, (val * options.chartSize) / 2)}
                fill={fillColor}
                stroke={fillColor}
                opacity="0.7"
                className={[extraProps.className, meta.class].join(' ')}
                onMouseEnter={() => mouseEnter({ key: col.key, value: val, idx: i })}
                onMouseLeave={() => mouseLeave({})}
            />
        );
    });
};

const shape = (columns, options) => (chartData, DataIndex, chat) => {
    const data = chartData.data;
    const meta = chartData.meta || {};
    const extraProps = options.shapeProps(meta);
    let colors = options.colors || [];

    if (colors.length !== chat.length) {
        colors = chat.map((data, i) => {
            if (colors[i] === undefined)
                return "#00FF00"
            else
                return colors[i]
        })
    }
    const fillRGBA = hexToRgb(colors[DataIndex]);
    let strokeColor = `rgba(${fillRGBA.r},${fillRGBA.g},${fillRGBA.b},0.1)`;
    let fillColor = `rgba(${fillRGBA.r},${fillRGBA.g},${fillRGBA.b},${fillRGBA.a})`;
    return (
        <path
            key={`shape-${DataIndex}`}
            d={options.smoothing(
                columns.map(col => {
                    const val = isNaN(data[col.key]) ? 0 : data[col.key]
                    if ('number' !== typeof val) {
                        throw new Error(`Data set ${DataIndex} is invalid.`);
                    }

                    return [
                        polarToX(col.angle, (val * options.chartSize) / 2),
                        polarToY(col.angle, (val * options.chartSize) / 2)
                    ];
                })
            )}
            {...extraProps}
            stroke={strokeColor}
            fill={fillColor}
            className={[extraProps.className, meta.class].join(' ')}
        />
    );
};

const scale = (options, value) => (
    <circle
        key={`circle-${value}`}
        cx={0}
        cy={0}
        r={(value * options.chartSize) / 2}
        {...options.scaleProps(value)}
    />
);

const caption = options => col => (
    <text
        key={`caption-of-${col.key}`}
        x={polarToX(col.angle, (options.size / 2) * 0.95).toFixed(4)}
        y={polarToY(col.angle, (options.size / 2) * 0.95).toFixed(4)}
        dy={(options.captionProps(col).fontSize || 10) / 2}
        {...options.captionProps(col)}
    >
        {/* {col.caption} */}
        {col.caption.match(/.{1,14}\b/g).map((data, index) => {
            const dx = polarToX(col.angle, (options.size / 2) * 0.95).toFixed(4) >= 0 ? 5 : -5;
            return <tspan y={polarToY(col.angle, (options.size / 2) * 0.95).toFixed(4)} x={polarToX(col.angle, (options.size / 2) * 0.95).toFixed(4)} dy={`${1.5 * index}em`} dx={dx}>{data}</tspan>

        })}
    </text>
);

const render = (captions, chartData, options = {}) => {
    if ('object' !== typeof captions || Array.isArray(captions)) {
        throw new Error('caption must be an object');
    }
    if (!Array.isArray(chartData)) {
        throw new Error('data must be an array');
    }
    options.chartSize = options.size / options.zoomDistance;

    const columns = Object.keys(captions).map((key, i, all) => {
        return {
            key,
            caption: captions[key],
            angle: (Math.PI * 2 * i) / all.length
        };
    });
    const groups = [
        <g key={`g-groups}`}>{chartData.map(shape(columns, options, chartData))}</g>
    ];
    if (options.captions) {
        groups.push(<g key={`poly-captions`}>{columns.map(caption(options))}</g>);
    }
    if (options.dots) {
        groups.push(<g key={`g-dots`}>{chartData.map(dot(columns, options, chartData))}</g>);
    }
    if (options.axes) {
        groups.unshift(<g key={`group-axes`}>{columns.map(axis(options))}</g>);
    }
    if (options.scales > 0) {
        const scales = [];
        for (let i = options.scales; i > 0; i--) {
            scales.push(scale(options, i / options.scales));
        }
        groups.unshift(<g key={`poly-scales`}>{scales}</g>);
    }
    const delta = (options.size / 2).toFixed(4);
    return <g transform={`translate(${delta},${delta})`}>{groups}</g>;
};

export default render;
