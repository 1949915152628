import { useLazyQuery } from "@apollo/client";
import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ADD_FEEDBACK } from "../../../client/graphql";
import Button from "../../../components/common/Form/Button";
import StickyBottom from "../../../components/common/StickyBottom";
import Interest from "../../Interest";
const TitleBlock = ({ children }) => {
  return <div className=" text-textDark h3 text-left">{children}</div>;
};
const InterestContainer = styled.div`
  width: 180px;
  height: 108px;
  borderradius: 5;
  .bg {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 35.36%,
      rgba(0, 0, 0, 0.64) 100%
    );
    border-radius: 5px;
  }
`;
const TagContainer = styled.div`
  .clipArea {
    width: 20px;
    height: 36px;
    clip-path: polygon(100% 100%, 100% 0, 0 50%);
  }
  .textContainer {
    position: relative;
    left: -1px;
    /* border-top-left-radius: 2px; */
  }
`;
const Tag = ({ title, isActive, onClick }) => {
  return (
    <TagContainer
      className={`mr-5 flex mb-6 ${isActive ? `cursor-pointer` : ``}`}
      onClick={() => {
        if (isActive) {
          onClick();
        }
      }}
    >
      <div
        className={`clipArea transition-all duration-500 ${isActive ? "bg-blue" : "bg-flashWhite"
          }`}
      ></div>
      <div
        className={`textContainer transition-all duration-500 pl-0.5 pr-4 ${isActive ? "bg-blue text-pureWhite" : "bg-flashWhite text-pureBlack"
          } flex items-center justify-center`}
      >
        {title}
      </div>
    </TagContainer>
  );
};
const InterestImg = ({ url, title, onClick }) => {
  return (
    <InterestContainer
      className="relative rounded-md overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      <img
        src={url}
        alt="Interest Img"
        className="w-full h-full object-cover rounded-md"
      />
      <div className=" absolute inset-0 bg rounded-md"></div>
      <div className="absolute bottom-0 left-0 right-0 h9 text-pureWhite rounded-md pl-3 pb-2">
        {title}
      </div>
    </InterestContainer>
  );
};

const Like = {
  YES: 1,
  NO: 0,
  DEFAULT: 2,
};

const Difficulty = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
  DEFAULT: 0,
};

export default function Feedback({
  data,
  path,
  activityId,
  cardType,
  ...props
}) {
  const history = useHistory();
  const [showInterests, setShowInterests] = useState(false);
  const [liked, setLiked] = useState(undefined);
  const [difficulty, setDifficulty] = useState(undefined);
  const [interestId, setInterestId] = useState(undefined);
  useEffect(() => {
    if (interestId) {
      window?.scrollTo(0, 0);
    }
  }, [interestId]);
  useEffect(() => {
    let input = {
      activityId: activityId,
      like: liked ? liked : Like.DEFAULT,
      difficulty: difficulty ? difficulty : Difficulty.DEFAULT,
    };
    if (liked || difficulty) {
      addFeedback({
        variables: input,
      });
    }
  }, [liked, difficulty]);

  const [addFeedback, { loading: updatingFeedback }] = useLazyQuery(
    ADD_FEEDBACK,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.studentFeedbackV2) {
          //notification.success({ message: "Feedback updated" });
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  return (
    <div>
      {!interestId && (
        <>
          <div
            className="container mx-auto mt-10 p-6 bg-white"
            style={{
              boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
              borderRadius: "3px",
            }}
          >
            <div className="flex ">
              <img
                src={props.cover?.image_url}
                alt="activity img"
                className=" rounded "
                width={200}
                height={200}
              />
              <div className="ml-6 text-lightGray h7">
                <div>
                  You have successfully completed{" "}
                  <span className="h5">{props.title}</span>
                </div>
                <div className="font-Nunito text-spanishGray mt-3">
                  I estimated to spend{" "}
                  {props.estimate > 60
                    ? Math.floor(props.estimate / 60) +
                    "h " +
                    (props.estimate % 60) +
                    "m"
                    : props.estimate + `m`}{" "}
                  •{" "}
                  {props.estimate > props.actual ? (
                    <span className="text-green">
                      I took only{" "}
                      {props.actual > 60
                        ? Math.floor(props.actual / 60) +
                        "h " +
                        (props.actual % 60) +
                        "m"
                        : props.actual + `m`}{" "}
                      to complete this
                    </span>
                  ) : (
                    <span className="text-orchid">
                      I took{" "}
                      {props.actual > 60
                        ? Math.floor(props.actual / 60) +
                        "h " +
                        (props.actual % 60) +
                        "m"
                        : props.actual + `m`}{" "}
                      to complete this
                    </span>
                  )}
                </div>
              </div>
            </div>
            {data?.recommended && data?.recommended.length !== 0 && (
              <>
                <div className=" mt-6 mb-6 border-b border-gray4 "></div>
                <TitleBlock>Useful links to browse</TitleBlock>
                <div className="mt-4 flex">
                  {data.recommended.map((recommended) => (
                    <div
                      className=" py-2 px-6 border-blue text-blue border rounded-3xl cursor-pointer mr-5"
                      onClick={() => {
                        if (recommended.link) {
                          window.open(recommended.link);
                        }
                      }}
                    >
                      {recommended.title}
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className=" mt-6 mb-6 border-b border-gray4 "></div>
            <TitleBlock>Feedback</TitleBlock>
            <div className="grid grid-cols-4 gap-4 mt-4">
              <div className="text-normal">
                {cardType === "do"
                  ? `Did you like doing this learning content?`
                  : `Did you like watching this learning content?`}
              </div>
              <div className="col-span-3 ml-20 flex">
                <div
                  className={`nunito-semibold-xl px-14 py-1 rounded-4xl ${liked === Like.YES ? `bg-green text-pureWhite` : `text-green`
                    } border border-green cursor-pointer`}
                  onClick={() => {
                    setLiked(Like.YES);
                    setShowInterests(true);
                  }}
                >
                  Yes
                </div>
                <div
                  className={`nunito-semibold-xl px-14 py-1 rounded-4xl ml-8 border border-red cursor-pointer ${liked === Like.NO ? `bg-red text-pureWhite` : `text-red`
                    }`}
                  onClick={() => {
                    setLiked(Like.NO);
                    setShowInterests(true);
                  }}
                >
                  No
                </div>
              </div>
              {cardType !== "watch" && (
                <div>How did you find this learning content?</div>
              )}
              {cardType !== "watch" && (
                <div className="col-span-3 ml-20 flex">
                  <div
                    className={`nunito-semibold-xl px-14 py-1 rounded-4xl border border-green cursor-pointer ${difficulty === Difficulty.EASY
                      ? `bg-green text-pureWhite`
                      : `text-green`
                      }`}
                    onClick={() => {
                      setDifficulty(Difficulty.EASY);
                      setShowInterests(true);
                    }}
                  >
                    Easy
                  </div>
                  <div
                    className={`nunito-semibold-xl px-14 py-1 rounded-4xl ml-8 border border-orange cursor-pointer ${difficulty === Difficulty.MEDIUM
                      ? `bg-orange text-pureWhite`
                      : `text-orange`
                      }`}
                    onClick={() => {
                      setDifficulty(Difficulty.MEDIUM);
                      setShowInterests(true);
                    }}
                  >
                    Medium
                  </div>
                  <div
                    className={`nunito-semibold-xl px-14 py-1 rounded-4xl ml-8 border border-red cursor-pointer ${difficulty === Difficulty.HARD
                      ? `bg-red text-pureWhite`
                      : `text-red`
                      }`}
                    onClick={() => {
                      setDifficulty(Difficulty.HARD);
                      setShowInterests(true);
                    }}
                  >
                    Hard
                  </div>
                </div>
              )}
            </div>
            <div className=" mt-6 mb-6 border-b border-gray4 "></div>
            <div
              className={
                showInterests ? "opacity-100" : "opacity-30 pointer-events-none"
              }
            >
              <TitleBlock>Want to learn more? </TitleBlock>
              <div className="h4">
                Here are some ways you can look for more fun stuff! Tap on these
                to discover similar cards
              </div>
              <div className=" text-lightGray h4 mt-6">Interest and Skills</div>
              <div className=" mt-6 flex gap-5 flex-wrap">
                {data?.interestData &&
                  data?.interestData.length !== 0 &&
                  data.interestData.map((interest) => (
                    <InterestImg
                      url={interest.profile_image}
                      title={interest.name}
                      onClick={() => {
                        //history.push(`/interest/${interest.id}`);
                        setInterestId({
                          id: interest.id,
                          type: interest.type ? interest.type : "interest",
                        });
                      }}
                    ></InterestImg>
                  ))}
              </div>
              <div className=" text-lightGray h4 mt-6">Blooms Taxonomy</div>
              <div className="mt-6 flex flex-wrap">
                {data?.bloomstax?.data_points?.map((bloom, bloomIndex) => {
                  return (
                    <div key={`BloomsTaxonomy-${bloomIndex}`}>
                      <Tag
                        title={bloom.name}
                        isActive={bloom.isSelected}
                        onClick={() => {
                          if (bloom.isSelected)
                            setInterestId({ id: bloom.id, type: "tag" });
                          //history.push(`/tags/${bloom.id}`);
                        }}
                      ></Tag>
                    </div>
                  );
                })}
              </div>
              <div className=" text-lightGray h4 mt-6">
                Multiple Intelligences
              </div>
              <div className="mt-6 flex flex-wrap">
                {data?.multipleIntel?.data_points?.map((intel, intelIndex) => {
                  return (
                    <div
                      key={`Multiple-Intelligences-${intelIndex}
`}
                    >
                      <Tag
                        title={intel.name}
                        isActive={intel.isSelected}
                        onClick={() => {
                          setInterestId({ id: intel.id, type: "tag" });
                          //history.push(`/tags/${intel.id}`);
                        }}
                      ></Tag>
                    </div>
                  );
                })}
              </div>
              <div className=" text-lightGray h4 mt-6">
                21st Century Learning and Innovation Skills
              </div>
              <div className="mt-6 flex flex-wrap">
                {data?.innovationSkills?.data_points.map(
                  (innov, innvoIndex) => {
                    return (
                      <div key={`Century-Learning-${innvoIndex}`}>
                        <Tag
                          title={innov.name}
                          isActive={innov.isSelected}
                          onClick={() => {
                            setInterestId({ id: innov.id, type: "tag" });
                            //history.push(`/tags/${innov.id}`);
                          }}
                        ></Tag>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <StickyBottom
            className={
              ""
              // showInterests ? "opacity-100" : "opacity-30 pointer-events-none"
            }
          >
            <div className="flex justify-end items-center  px-12 bg-white  py-3">
              <div className=" w-40">
                <Button
                  disabled={!showInterests}
                  secondary
                  className="font-Nunito px-4  py-3 flex font-bold justify-center"
                  onClick={() => {
                    if (props.onDone) props.onDone();
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </StickyBottom>
        </>
      )}
      {interestId && (
        <div className="bg-white h-screen overflow-scroll">
          <Interest
            path={path}
            interestId={interestId.type === "interest" && interestId.id}
            tagId={interestId.type === "tag" && interestId.id}
            goBack={() => {
              setInterestId(undefined);
            }}
          />
        </div>
      )}
      {/* <Modal
        width={430}
        title={null}
        visible={false}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowFeedback(false);
        }}
      >
        <div className="nunito-bold-xl mt-2 text-center">Add feedback</div>

        <div className=" mt-2  text-center text-lightGray h9">
          {`Please share your feedback for this learning content to proceed`}
        </div>
        <div className="mt-6 flex items-center justify-center">
          <div className=" w-40 ">
            <Button
              secondary
              className="font-Nunito text-base text-white px-6  py-2 flex font-bold text-center justify-center"
              onClick={() => {
                setShowFeedback(false);
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}
