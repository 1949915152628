import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../components/common/Loader";
import Images from "../../../themes/Images";
const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  /* min-height: 650px; */
  /* height: 100vh; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export default function OnboardingLayout(props) {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.token) {
      history.push("/dashboard");
    }
  }, [])

  return (
    <div>
      <div className=" min-h-screen  min-w-full relative flex justify-center items-center">
        <div className="absolute top-0 left-0 right-0 bottom-0 -z-1">
          <img
            src={Images.AuthLayout}
            className="w-full h-full object-cover hidden xl:block "
            alt="Layout"
          />
          <img
            src={Images.AuthPasscodeLayout}
            className="w-full h-full object-cover xl:hidden"
            alt="Layout"
          />
        </div>
        <div className=" flex md:h-screen justify-end p-4 xl:p-14 md:h- md:max-w-2xl xl:max-w-none  w-full " style={{ minHeight: 720 }}>
          <div className=" w-full xl:w-1/2 xl:max-w-xl h-full">
            <Loader loading={props.validating} />
            <CardContainer className="rounded-xl bg-white px-4 lg:px-6 py-8 relative h-full ">
              {props.children}
            </CardContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
