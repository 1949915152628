import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Avatar, Modal, notification, Popover, Spin } from "antd";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GET_NOTIFICATION } from "../../../client/graphql";
import NavContext from "../../../context/NavContext";
import { NotificationDualIcon } from "../../../themes/dualImages";
import Images, { DefaultAvatar } from "../../../themes/Images";
import Button from "../Form/Button";
import LCSelectModal from "../LCSelectModal";
import SubscriptionEndModal from "../SubscriptionExpiredModal";
import TrialEndModal from "../TrialExpiredModal";
import TrialStartModal from "../TrialStartModal";
import {
  ExploreIcon,
  JournalIcon,
  ScheduleIcon,
  WorkshopIcon
} from "./NavIcon";
import SearchInput from "./SearchInput";

const NavContainer = styled.div`
  .navItem:hover .fill svg path {
    fill: white;
  }
  .navItem:hover .stroke svg path {
    stroke: white;
  }
`;

const MenuItem = ({ Image, title, link, baseRoute, route, imageClass }) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <NavContainer>
      <NavLink
        to={`/${link}`}
        isActive={(match, location) => {
          if (baseRoute) {
            if (baseRoute === route) {
              setIsActive(true);
              return true;
            }
          }
          if (!match) {
            setIsActive(false);
            return false;
          }
          if (location.pathname === `/${link}`) {
            setIsActive(true);
            return true;
          }
        }}
      >
        <div
          className={` navItem  cursor-pointer flex justify-center items-center py-2 mx-2 rounded-3xl hover:text-white hover:bg-blue px-3 ${isActive ? "bg-blue text-white px-3" : ""
            }`}
        // onClick={() => {
        //   history.push(`/${link}`);
        // }}
        >
          <span className={`mr-2 ${imageClass || ""}`}>
            <Image isActive={isActive}></Image>
          </span>
          <span className="font-Nunito text-sm font-semibold">{title}</span>
        </div>
      </NavLink>
    </NavContainer>
  );
};

export default function Nav(props) {
  const location = useLocation();
  const user_details = useMemo(() => JSON.parse(localStorage.getItem("user_details")), []);
  const [showLCList, setshowLCList] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [currentLc, setCurrentLc] = useState("Home");
  const isRemindProfileSetup = localStorage.getItem("remindProfileSetup");
  const [showParentSetup, setShowParentSetup] = useState(false);
  const [showTrial, setShowTrial] = useState(false);
  const [showTrialEnd, setShowTrialEnd] = useState(false);
  const [showSubscripitonExpired, setShowSubscriptionExpired] = useState(false);
  const [showPopups, setShopPopups] = useState(false);
  const userNavContext = useContext(NavContext);

  React.useEffect(() => {
    if (!isRemindProfileSetup) {
      if (!user_details?.isParentAdded && user_details?.Onboarded_days <= 1) {
        setShowParentSetup(true);
      } else {
        setShopPopups(false);
      }
    }
  }, [user_details, isRemindProfileSetup]);

  React.useEffect(() => {
    setShowTrial(
      localStorage.getItem("showTrialStarts") === "true" ||
      userNavContext?.userInfo?.showTrial
    );
    setShowTrialEnd(
      localStorage.getItem("showTrialEnd") === "true" ||
      userNavContext?.userInfo?.expired
    );
    // setShowSubscriptionExpired(
    //   localStorage.getItem("showSubscriptionEnd") === "false" || localStorage.getItem("showSubscriptionEnd") === "null") ? false : true
    // );
    let expType = userNavContext?.userInfo?.expiry_type;
    if (expType && expType === "Paid" && userNavContext?.userInfo?.expired) {
      setShowSubscriptionExpired(true);
    }
  }, []);

  React.useEffect(() => {
    if (
      location.pathname.includes("journal") ||
      location.pathname.includes("workshop") ||
      location.pathname.includes("profile") ||
      location.pathname.includes("parent") || location.pathname.includes("payment/proceed")

    ) {
      setShopPopups(true);
    } else {
      setShopPopups(false);
    }
  }, [location.pathname]);

  const { loading: loadingNotification } = useQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
    pollInterval: 20000,
    onCompleted: (response) => {
      if (response.iPadNotificationV2) {
        let notificationArr = [];
        if (response.iPadNotificationV2.workshops) {
          notificationArr.push(...response.iPadNotificationV2.workshops);
        }
        if (response.iPadNotificationV2.completed_workshops) {
          notificationArr.push(
            ...response.iPadNotificationV2.completed_workshops
          );
        }
        notificationArr = notificationArr.map((noti) => {
          return {
            workshop_name: noti.workshop_name,
            comment: noti.comment,
            createdAt: noti.createdAt,
            workshop_id: noti.workshop_id ? noti.workshop_id : noti.id,
            reference_link: noti.reference_link || "",
            pdf_name: noti.pdf_name || "",
          };
        });
        if (response.iPadNotificationV2?.access?.length) {
          let subs = {
            expired: response.iPadNotificationV2?.access?.[0]?.expired,
            access_end_date: response.iPadNotificationV2?.access?.[0]?.access_end_date,

            type: response.iPadNotificationV2?.access?.[0]?.expiry_type,
            heading:
              response.iPadNotificationV2?.access?.[0]?.access_status === "Free"
                ? "Free trial"
                : "Subscription",
          };
          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            ...{
              subs: subs,
              expiry_date: response.iPadNotificationV2?.access?.[0]?.access_end_date ? moment(
                response.iPadNotificationV2?.access?.[0]?.access_end_date / 1).utc()
                : userNavContext?.userInfo?.expiry_date,
            },
          });
        }
        setNotifications(notificationArr);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  // console.log(useHistory())

  // console.log(
  //   showTrial,
  //   showTrialEnd,
  //   showSubscripitonExpired,
  //   location.pathname
  // );
  return (
    <>
      <div className="sticky left-0 right-0 top-0 flex justify-between items-center px-12 bg-white py-4 z-20">
        <div className="flex-shrink-0 flex items-center justify-center">
          <div className=" flex-shrink-0">
            <Link to={"/dashboard"}>
              <img src={Images.Logo} alt="logo" className="mx-auto " />
            </Link>
          </div>
          {/* <div className=" lg:ml-5 xl:ml-12 text-center  mx-2 lg:w-20 xl:w-40">
            <div
              className="ant-dropdown-link text-blue whitespace-nowrap cursor-pointer font-Nunito font-semibold	"
              onClick={(e) => {
                e.preventDefault();
                setshowLCList(true);
              }}
            >
              {currentLc} <DownOutlined />
            </div>
          </div> */}
        </div>
        {/* <div className="flex justify-center items-center flex-shrink-0"> */}
        <div className="flex justify-center items-center flex-shrink-0">
          <MenuItem
            Image={ExploreIcon}
            title={"Explore"}
            link={"dashboard"}
            route="explore"
            baseRoute={props.baseRoute}
            imageClass={"stroke"}
          />
          <MenuItem
            Image={ScheduleIcon}
            title={"Schedule"}
            link={`schedule`}
            route="schedule"
            baseRoute={props.baseRoute}
            imageClass={"stroke"}
          />
          <MenuItem
            Image={JournalIcon}
            title={"Journal"}
            link={"journal"}
            route="journal"
            baseRoute={props.baseRoute}
            imageClass={"stroke"}
          />
          <MenuItem
            Image={WorkshopIcon}
            title={"Workshops"}
            route="workshops"
            link="workshops"
            baseRoute={props.baseRoute}
            imageClass={"fill"}
          />
        </div>
        {/* </div> */}
        {/* <div className=" flex-shrink-0"> */}
        <div className="w-4/12">
          <SearchInput></SearchInput>
        </div>
        <div className="flex justify-center items-center flex-shrink-0">
          <div className="ml-5 mr-5 w-5 flex-shrink-0">
            <Popover
              placement="bottomRight"
              title={null}
              overlayInnerStyle={{
                borderRadius: 10,
              }}
              visible={visible}
              onVisibleChange={handleVisibleChange}
              content={
                <div
                  className={` pt-1 pb-2 w-96 ${
                    (notifications || []).length === 0 ? " h-16" : "h-52"
                  } overflow-scroll`}
                >
                  {loadingNotification && (
                    <div className="text-center h-full">
                      <Spin spinning={loadingNotification}></Spin>
                    </div>
                  )}
                  {!loadingNotification && (notifications || []).length === 0 && (
                    <>
                      <div className="flex justify-center items-center h4 h-full">
                        No new notifications available
                      </div>
                    </>
                  )}
                  {!loadingNotification && (notifications || []).length !== 0 && (
                    <>
                      <div className=" h9 ">
                        Notifications ({notifications.length})
                      </div>
                      {notifications.map((notification, notificationIndex) => {
                        let createdAt = moment(
                          notification.createdAt,
                          "x"
                        ).format("DD-MMM-YYYY hh:mm:ss");
                        return (
                          <div
                            className="flex justify-start items-center mt-3 cursor-pointer"
                            key={`Notification-${notificationIndex}`}
                            onClick={() => {
                              history.push(
                                `/workshops/${
                                  notification.workshop_id
                                }?notification=true${
                                  notification.reference_link
                                    ? "&reference_image=true"
                                    : ""
                                }`
                              );
                              setVisible(false);
                            }}
                          >
                            <div className="flex items-start pt-4 border-t border-gray4 w-full ">
                              {!notification.reference_link &&
                                !notification.pdf_name && (
                                  <img
                                    src={Images.NotificationCheckIcon}
                                    alt="Checked Icon"
                                    className="mr-2"
                                  />
                                )}
                              {notification.reference_link && (
                                <img
                                  src={notification.reference_link}
                                  alt="Reference Link"
                                  className=" w-16 h-10 object-cover mr-2 rounded "
                                />
                              )}
                              {notification.pdf_name && (
                                <img
                                  src={Images.PdfRefIcon}
                                  alt="Reference Link"
                                  className=" w-6 h-8 object-cover mr-2 rounded "
                                />
                              )}
                              <div>
                                <div className="h9 mr-3 text-lightGray ">
                                  {notification.comment}
                                </div>
                                <div className=" text-lightGray h11 mt-2">
                                  {moment(createdAt).fromNow()}
                                </div>
                              </div>
                            </div>
                            <div className=" flex-shrink-0">
                              <img
                                src={Images.NotificationArrowIcon}
                                alt="Notification Arrow"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              }
              trigger="click"
            >
              <div className="relative">
                {(notifications || []).length !== 0 && (
                  <div className="absolute top-0 -right-1 w-1.5 h-1.5 rounded-full bg-red"></div>
                )}
                {/* <img
                  src={Images.NotificationIcon}
                  alt="Notifications"
                  className=" cursor-pointer"
                /> */}
                <NotificationDualIcon className="cursor-pointer" />
              </div>
            </Popover>
          </div>

          <Popover
            placement="bottomRight"
            className="menuPopover"
            overlayClassName="rounded-lg pb-0 mb-3 shadow-5xl overflow-hidden w-182 z-10"
            content={
              <div className="h9 text-charcoal">
                <div
                  className="my-3 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push("/profile");
                  }}
                >
                  My Profile
                </div>
                <div
                  className="mb-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setshowLCList(true);
                  }}
                >
                  Switch Learning Centre
                </div>
              </div>
            }
            trigger="click"
          >
            <div className="flex justify-center items-center cursor-pointer">
              <Avatar
                icon={<UserOutlined />}
                src={userNavContext?.userInfo?.profile_image || DefaultAvatar}
              />
              <div className="flex-shrink-0 ml-2">
                <DownOutlined />
              </div>
            </div>
          </Popover>
        </div>
        {/* </div> */}
      </div>
      <LCSelectModal
        visible={showLCList}
        onClose={() => {
          setshowLCList(false);
        }}
        onOpen={() => {
          setshowLCList(true);
        }}
        selectedLc={(name) => {
          setCurrentLc(name);
        }}
      />
      <Modal
        visible={showParentSetup || userNavContext?.userInfo?.showParentSetup}
        onCancel={() => {
          // setShowLoginBlock(false);
        }}
        closable={false}
        closeIcon={false}
        width={480}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

        // title="Choose where you want to learn today"
      >
        <div className=" px-5">
          <img
            src={Images.LoginEmptySearch}
            alt="Login Empty State"
            className="mx-auto"
          />
          <div className="h4 text-center mt-8">
            To ensure your safety while using the app, your parent has to
            complete their profile set up.
          </div>
          <div className=" mt-10 flex items-center justify-center">
            <div className=" w-40 mx-auto">
              <Button
                secondaryGhost
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  localStorage.setItem("remindProfileSetup", true);
                  setShowParentSetup(false);
                  userNavContext.setUserInfo({
                    ...userNavContext.userInfo,
                    ...{
                      showParentSetup: false,
                    },
                  });
                }}
              >
                Remind me later
              </Button>
            </div>
            <div className=" w-32 mx-auto">
              <Button
                secondary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  setShowParentSetup(false);
                  window.open(
                    `${process.env.REACT_APP_WEB_APP}accountSetup`,
                    "_blank"
                  );
                }}
              >
                Set up
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {showTrial && !showPopups && (
        <TrialStartModal showTrialStarted={showTrial} />
      )}
      {showTrialEnd &&
        !showSubscripitonExpired &&
        !showPopups &&
        !showParentSetup && (
          <TrialEndModal showTrialExpired={showTrialEnd && !showParentSetup} />
        )}
      {showSubscripitonExpired && !showPopups && !showParentSetup && (
        <SubscriptionEndModal
          showSubscripitonExpired={showSubscripitonExpired && !showParentSetup}
        />
      )}
    </>
  );
}
