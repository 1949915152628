import { useQuery } from "@apollo/client";
import { notification, Tabs } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { GET_CARD_DETAILS } from "../../client/graphql";
import ExpertCard from "../../components/Cards/ExpertCard";
import PlaylistCard from "../../components/Cards/PlaylistCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";

const { TabPane } = Tabs;

const TabContainer = styled.div`
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: rgb(var(--blue));
    }
    .ant-tabs-tab-btn {
      font-family: Nunito;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #828282;
    }
  }
  .ant-tabs-ink-bar {
    background: transparent;
    min-height: 4px;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      content: "";
      width: 70%;
      height: 4px;
      background: rgb(var(--blue));
      border-radius: 2px;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important
    ;
  }
`;

export default function Playlist() {
  const [playlist, setPlaylist] = useState([]);

  const { loading: loadingExpertCardDetails } = useQuery(GET_CARD_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      card: false,
      expert_curator: false,
      playlist: true,
    },
    onCompleted: (response) => {
      if (response.iPadgetCardsDetails) {
        if (response.iPadgetCardsDetails.PlayList) {
          setPlaylist(response.iPadgetCardsDetails.PlayList);
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return (
    <div>
      <Loader loading={loadingExpertCardDetails} />
      <div>
        <ExploreHeading
          isBack={true}
          title={"I’ve completed these Learning paths"}
        ></ExploreHeading>
        {playlist.length === 0 && !loadingExpertCardDetails && (
          <div>
            <div className="text-center mt-10">
              <img
                src={Images.WishlistEmptyState}
                alt="Wishlist Empty State"
                className="mx-auto"
              />
              <div className=" mt-10 nunito-normal-lg">
                {"Your completed learning path will appear here"}
              </div>
            </div>
          </div>
        )}
        {playlist.length !== 0 && !loadingExpertCardDetails && (
          <div
            className="container mx-auto p-8 bg-white"
            style={{
              boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
              borderRadius: "3px",
            }}
          >
            <TabContainer className=" mt-5">
              <Tabs
                defaultActiveKey="1"
                tabBarGutter={60}
                tabBarExtraContent={{
                  right: (
                    <div
                      className="px-6 cursor-pointer"
                      onClick={() => {
                        let transformStyle = document.querySelector(
                          `#lctabList .ant-tabs-nav-list`
                        ).style.transform;
                        const SplittedTranslate = transformStyle.split(",");
                        const translateX = SplittedTranslate[0].replace(
                          /[^\d.]/g,
                          ""
                        );
                        let computedTranslate = parseFloat(translateX)
                          ? parseFloat(translateX) + 250
                          : -250;
                        console.log(
                          Math.abs(computedTranslate),
                          document.querySelector(
                            `#lctabList .ant-tabs-nav-list`
                          ).offsetWidth
                        );
                        if (
                          Math.abs(computedTranslate) + 1000 <
                          document.querySelector(
                            `#lctabList .ant-tabs-nav-list`
                          ).offsetWidth
                        ) {
                          SplittedTranslate[0] = SplittedTranslate[0].replace(
                            translateX,
                            computedTranslate
                          );
                        }
                        document.querySelector(
                          `#lctabList .ant-tabs-nav-list`
                        ).style.transform = SplittedTranslate.join(",");
                        // .scrollTo({
                        //   left:
                        //     document.querySelector(
                        //       `#lctabList .ant-tabs-nav-list`
                        //     ).scrollLeft + 250,
                        //   behavior: "smooth",
                        // });
                      }}
                    >
                      {playlist.length > 5 && (
                        <img
                          src={Images.CarouselLeft}
                          alt="Carousel Icon"
                          style={{ minWidth: 44 }}
                          className=" shadow-3xl rounded-full h-11"
                        />
                      )}
                    </div>
                  ),
                }}
                moreIcon={
                  <img
                    src={Images.CarouselLeft}
                    alt="Carousel Icon"
                    style={{ minWidth: 44 }}
                    className=" shadow-3xl rounded-full h-11"
                  />
                }
                destroyInactiveTabPane
                onChange={() => { }}
                id="lctabList"
              >
                {playlist.map((tab, tabIndex) => {
                  return (
                    <TabPane tab={tab.user.name} key={tabIndex + 1}>
                      <div className=" mt-4 grid grid-cols-5 gap-4">
                        {tab.playlist_detail.map((LCcard, cardIndex) => {
                          return (
                            <PlaylistCard
                              loading={false}
                              isPlaylist={true}
                              data={LCcard}
                              customSize={{
                                width: "214px",
                                height: "252px",
                              }}
                            ></PlaylistCard>
                          );
                        })}
                      </div>
                      {tab.expertCurator_detail && (
                        <div>
                          <div className="nunito-bold-2xl my-6">
                            Expert and Curator Learning paths
                          </div>
                          <div>
                            <div className="grid grid-cols-3 gap-4">
                              {tab.expertCurator_detail.map(
                                (expertCurator, cardIndex) => {
                                  return (
                                    <ExpertCard
                                      data={expertCurator}
                                      onClick={() => { }}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </TabPane>
                  );
                })}
              </Tabs>
            </TabContainer>
          </div>
        )}
      </div>
    </div>
  );
}
