import { useLazyQuery, useMutation } from "@apollo/client";
import { Button as AntdButton, notification, Tabs } from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router";
import { ADD_SUBSCRIPTION, GET_EXPLORE_COLLECTION, GET_EXPLORE_PLAYLIST } from "../client/graphql";
import CardListingLayout from "../components/Cards/CardListingLayout";
import ExpertCard from "../components/Cards/ExpertCard";
import PaymentEmptyState from "../components/Cards/PaymentEmptyState";
import Carousel from "../components/common/Carousel";
import Button from "../components/common/Form/Button";
import { TabContainer } from "../components/Style/TabContainer";
import NavContext from "../context/NavContext";
import Images from "../themes/Images";

const { TabPane } = Tabs;

const Constants = {
  EXPERT_ROLE: 4,
  CURATOR_ROLE: 5,
};

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Dashboard() {
  const history = useHistory();
  const location = useLocation();
  const [collections, setCollections] = useState([]);
  const [expertCollections, setExpertCollections] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [expertPlaylist, setExpertPlaylist] = useState([]);
  //const [page, setPage] = useState(1);
  const [collectionPage, setCollectionPage] = useState(1);
  const [playlistPage, setPlaylistPage] = useState(1);
  const [hasMoreCollectionItems, setHasMoreCollectionItems] = useState(false);
  const [hasMorePlaylistItems, setHasMorePlaylistItems] = useState(false);
  const [selectedTab, setselectedTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const userNavContext = useContext(NavContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("ref") === "logo") {
      //setPage(0);
      setCollections([]);
      setExpertCollections([]);
      setPlaylist([]);
      setExpertPlaylist([]);
      setCollectionPage(1);
      setPlaylistPage(1);
      history.replace({
        search: ``,
      });
      // refetchData({
      //   variables: {
      //     isTag: true,
      //     lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      //   },
      // });
    }
  }, [location.search]);
  useEffect(() => {
    if (userNavContext?.userInfo?.lc_id) {
      //setPage(0);
      setCollections([]);
      setExpertCollections([]);
      setPlaylist([]);
      setExpertPlaylist([]);
      setCollectionPage(1);
      setPlaylistPage(1);
    }
  }, [userNavContext?.userInfo?.lc_id]);

  /*const {
    data,
    loading,
    refetch: refetchData,
  } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      isTag: true,
      lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      page: page,
      // search: "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.IpadSearchV3) {
        setCollections([...collections, ...data.IpadSearchV3.collection]);
        setExpertCollections(data.IpadSearchV3.expert_collection.slice(0, 5));
        setPlaylist([...playlist, ...data.IpadSearchV3.playlist]);
        setExpertPlaylist(data.IpadSearchV3.expert_playlist);
        setHasMoreItems(
          data.IpadSearchV3.collection.length ||
          data.IpadSearchV3.playlist.length
        );
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });*/

  const [getExploreCollection, { loading: loadingCollection }] = useLazyQuery(GET_EXPLORE_COLLECTION, {
    variables: {
      isTag: true,
      lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      page: collectionPage,
      // search: "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getExploreCollection) {
        setCollections([...collections, ...data.getExploreCollection.collection]);
        setExpertCollections(data.getExploreCollection.expert_collection.slice(0, 5));
        if (data.getExploreCollection.collection.length > 0)
          setHasMoreCollectionItems(true);
        else setHasMoreCollectionItems(false);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const [getExplorePlaylist, { loading: loadingPlaylist }] = useLazyQuery(GET_EXPLORE_PLAYLIST, {
    variables: {
      isTag: true,
      lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      page: playlistPage,
      // search: "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getExplorePlaylist) {
        setPlaylist([...playlist, ...data.getExplorePlaylist.playlist]);
        setExpertPlaylist(data.getExplorePlaylist.expert_playlist);
        if (data.getExplorePlaylist.playlist.length > 0)
          setHasMorePlaylistItems(true);
        else setHasMorePlaylistItems(false);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  })


  useEffect(() => {
    //refetchData();
    setLoading(true);
    if (selectedTab === "1") {
      setHasMoreCollectionItems(false);
      setCollections([]);
      setExpertCollections([]);
      setCollectionPage(1);
      setTimeout(() => {
        getExploreCollection();
      }, 200);
    } else if (selectedTab === "2") {
      setHasMorePlaylistItems(false);
      setPlaylist([]);
      setExpertPlaylist([]);
      setPlaylistPage(1);
      setTimeout(() => {
        getExplorePlaylist();
      }, 200);
    }
    setLoading(false);
  }, [selectedTab]);

  const onCollectionOpen = useCallback((collectionId, userRole) => {
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/collections/expert/${collectionId}`);
    } else {
      history.push(`/collections/${collectionId}`);
    }
  }, []);

  const onCardOpen = useCallback((collectionId, cardId) => {
    history.push(`/collections/${collectionId}/${cardId}`);
  }, []);

  const onPlaylistCardOpen = useCallback((playlistId, cardId) => {
    history.push(`/playlists/${playlistId}/${cardId}`);
  }, []);

  const onPlayListOpen = useCallback((playlistId, userRole) => {
    console.log(playlistId, userRole);
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/playlists/expert/${playlistId}`);
    } else {
      history.push(`/playlists/${playlistId}`);
    }
  }, []);

  const [addSubscription] = useMutation(ADD_SUBSCRIPTION, {
    onCompleted: (data) => {
      if (data.addSubscription) {
        window.location.reload()
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  return (
    <div>
      {!userNavContext.userInfo.isLearner ? (
        <div
          className="flex items-center justify-center"
          style={{ minHeight: `80vh` }}
        >
          {!(userNavContext.userInfo.childCount >= 4) && (
            <div className="mr-8">
              <PaymentEmptyState
                title={"Add a new profile"}
                subTitle={
                  "Add child's profile and enable them to start their learning journey"
                }
                buttonContainer={
                  <Button
                    secondary
                    className={""}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_WEB_APP}parent/workshops?is_child=true`,
                        "_blank"
                      );
                    }}
                  >
                    {"Add new child profile"}
                  </Button>
                }
                image={Images.EnrollLearnerEmptyState}
              />
            </div>
          )}
          {userNavContext?.userInfo?.mode !== "LIGHT_MODE" && (
            <PaymentEmptyState
              title={"Enroll yourself as a learner"}
              subTitle={
                "Wouldn’t it be fantastic if we enabled you with skills to learn absolutely anything?"
              }
              buttonText="Try it for free"
              buttonContainer={
                <Button
                  primary
                  className={""}
                  onClick={() => {
                    addSubscription({
                      variables: {
                        startDate: moment().format("YYYY-MM-DD HH:mm:ss"), //String(moment().unix()),
                        subscriptionType: "Parent",
                      },
                    });
                  }}
                >
                  {"Try it for free"}
                </Button>
              }
              image={Images.LearnerEmptyState}
            />
          )}
        </div>
      ) : (
        <TabContainer>
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => {
              setselectedTab(key);
            }}
            className=" -ml-5 px-20"
          >
            <TabPane tab="Explore On the Go" key="1"></TabPane>
            <TabPane tab="Explore One by One" key="2"></TabPane>
          </Tabs>
          {(loading || loadingCollection || loadingPlaylist) &&
            ((selectedTab === "1" &&
              collections.length === 0 &&
              !hasMoreCollectionItems) ||
              (selectedTab === "2" &&
                playlist.length === 0 &&
                !hasMorePlaylistItems)) && (
              <div className="w-full px-16 mt-5">
                <CardListingLayout
                  data={{
                    cards: [{}, {}, {}],
                  }}
                  onOpen={onCollectionOpen}
                  loading
                />
              </div>
            )}
          {/* <div className="bg-silverSand bg-opacity-10  px-16 mt-5">
                <div className="flex justify-between pt-6">
                  <div className="font-Nunito font-bold text-xl">
                    Learn from our experts and curators
                  </div>
                  <div className=""></div>
                </div>
                <div className="-ml-2 mt-2 pb-5">
                  <Carousel LeftArrowClassName="-left-3">
                    {[{}, {}, {}].map((collection, collectionIndex) => (
                      <div
                        key={`ExpertCollection-${collectionIndex}`}
                        className="px-2"
                      >
                        <ExpertCard
                          data={collection}
                          onClick={() => {}}
                          loading
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div> */}

          {selectedTab === "1" && (
            <div className=" mt-5 w-full">
              {collections.length === 0 &&
                expertCollections.length === 0 &&
                !loadingCollection && (
                  <div className="flex flex-col items-center justify-center bg-white pt-28 pb-20">
                    <img
                      src={Images.CollectionEmpty}
                      alt="Collection Empty"
                      className="ml-2"
                    />
                    <div className="nunito-normal-lg mt-10">
                      Learning content available as cards in collections would
                      appear here.
                    </div>
                  </div>
                )}
              {collections.length !== 0 && (
                <div className=" inline-block w-full  px-16 ">
                  <CardListingLayout
                    data={collections[0]}
                    onCardOpen={(cardId) =>
                      onCardOpen(collections[0].id, cardId)
                    }
                    onOpen={onCollectionOpen}
                    showAll={collections[0]?.cards?.length <= 5}
                    onWishlistAdded={(data) => {
                      let tmpData = collections.map((wishlistCollection) => {
                        let tmpWishlistCollection = { ...wishlistCollection };
                        if (wishlistCollection.id === collections[0].id) {
                          tmpWishlistCollection.cards = data;
                        }
                        return tmpWishlistCollection;
                      });
                      setCollections(tmpData);
                      //refetchData();
                    }}
                  />
                </div>
              )}
              {expertCollections.length !== 0 && (
                <div className="bg-silverSand bg-opacity-10  px-16 mt-5">
                  {/* <div className="absolute left-0 right-0 top-0 bottom-0 bg-silverSand bg-opacity-10"></div> */}
                  <div className="flex justify-between pt-6">
                    <div className="font-Nunito font-bold text-xl">
                      Learn from our experts and curators
                    </div>
                    <div className="">
                      <AntdButton
                        type="link"
                        onClick={() => {
                          history.push(`/collections/expert/contents`);
                        }}
                        className="font-Nunito font-semibold"
                      >
                        View all expert's content
                      </AntdButton>
                    </div>
                  </div>
                  <div className="-ml-2 mt-2 pb-5">
                    <Carousel LeftArrowClassName="-left-3">
                      {expertCollections.map((collection, collectionIndex) => (
                        <div
                          key={`ExpertCollection-${collectionIndex}`}
                          className="px-2"
                        >
                          <ExpertCard
                            data={collection}
                            onClick={() => {
                              onCollectionOpen(
                                collection.id,
                                collection?.user?.role_id
                              );
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}
              <InfiniteScroll
                pageStart={collectionPage}
                threshold={1000}
                loadMore={(loadPage) => {
                  if (!loadingCollection) {
                    setCollectionPage(collectionPage + 1);
                  }
                }}
                hasMore={hasMoreCollectionItems}
                loader={
                  <div className="w-full px-16 mt-5">
                    <CardListingLayout
                      data={{
                        cards: [{}, {}, {}],
                      }}
                      onOpen={onCollectionOpen}
                      loading
                    />
                  </div>
                }
              >
                {collections.length > 1 &&
                  collections
                    .slice(1, collections.length)
                    .map((collection, collectionIndex) => {
                      return (
                        <div
                          className="mt-5  inline-block w-full px-16 "
                          key={`Collection-${collectionIndex}`}
                        >
                          <CardListingLayout
                            data={collection}
                            onCardOpen={(cardId) =>
                              onCardOpen(collection.id, cardId)
                            }
                            onOpen={onCollectionOpen}
                            showAll={collection?.cards?.length <= 5}
                            onWishlistAdded={(data) => {
                              let tmpData = collections.map(
                                (wishlistCollection) => {
                                  let tmpWishlistCollection = {
                                    ...wishlistCollection,
                                  };
                                  if (wishlistCollection.id === collection.id) {
                                    tmpWishlistCollection.cards = data;
                                  }
                                  return tmpWishlistCollection;
                                }
                              );
                              setCollections(tmpData);
                              //refetchData();
                            }}
                          />
                        </div>
                      );
                    })}
              </InfiniteScroll>
            </div>
          )}
          {/* </TabPane> */}
          {/* <TabPane tab="Explore One by One" key="2"> */}
          {selectedTab === "2" && (
            <div className="">
              {playlist.length === 0 &&
                expertPlaylist.length === 0 &&
                !loadingPlaylist && (
                  <div className="flex flex-col items-center justify-center bg-white pt-28 pb-20">
                    <img
                      src={Images.CollectionEmpty}
                      alt="Collection Empty"
                      className="ml-2"
                    />
                    <div className="nunito-normal-lg mt-10">
                      Learning content available as cards in learning paths
                      would appear here.
                    </div>
                  </div>
                )}

              {playlist.length !== 0 && (
                <div className=" inline-block w-full  mt-5 px-16">
                  <CardListingLayout
                    data={playlist[0]}
                    onOpen={onPlayListOpen}
                    onCardOpen={(cardId) =>
                      onPlaylistCardOpen(playlist[0].id, cardId)
                    }
                    isPlaylist
                    showAll={playlist[0]?.cards?.length <= 5}
                  />
                </div>
              )}
              {expertPlaylist.length !== 0 && (
                <div className="bg-silverSand bg-opacity-10 px-16 mt-5">
                  <div className="flex justify-between pt-6">
                    <div className="font-Nunito font-bold text-xl">
                      Learn from our experts and curators
                    </div>
                    <div className="">
                      <AntdButton
                        type="link"
                        className="font-Nunito"
                        onClick={() => {
                          history.push(`/playlists/expert/contents`);
                        }}
                      >
                        View all expert's content
                      </AntdButton>
                    </div>
                  </div>
                  <div className="-ml-2 mt-2 pb-5">
                    <Carousel LeftArrowClassName="-left-3">
                      {expertPlaylist.map((playlist, playlistIndex) => (
                        <div key={`Playlist-${playlistIndex}`} className="px-2">
                          <ExpertCard
                            data={playlist}
                            isPlaylist
                            onClick={() => {
                              onPlayListOpen(
                                playlist.id,
                                playlist?.user?.role_id
                              );
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}
              <InfiniteScroll
                pageStart={playlistPage}
                threshold={1000}
                loadMore={(loadPage) => {
                  if (!loadingPlaylist) {
                    setPlaylistPage(playlistPage + 1);
                  }
                }}
                hasMore={hasMorePlaylistItems}
                loader={
                  <div className="w-full px-16 mt-5">
                    <CardListingLayout
                      data={{
                        cards: [{}, {}, {}],
                      }}
                      onOpen={onCollectionOpen}
                      loading
                    />
                  </div>
                }
              >
                {playlist.length > 1 &&
                  playlist
                    .slice(1, playlist.length)
                    .map((playlist, playlistIndex) => {
                      return (
                        <div
                          className=" inline-block w-full  mt-5 px-16 "
                          key={`PlaylistExpert-${playlistIndex}`}
                        >
                          <CardListingLayout
                            data={playlist}
                            onOpen={onPlayListOpen}
                            onCardOpen={(cardId) =>
                              onPlaylistCardOpen(playlist.id, cardId)
                            }
                            isPlaylist
                            showAll={playlist?.cards?.length <= 5}
                          />
                        </div>
                      );
                    })}
              </InfiniteScroll>
            </div>
          )}
          {/* </TabPane> */}
        </TabContainer>
      )}
    </div>
  );
}
