import { SearchOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Input, notification } from "antd";
import React, { useRef, useState } from "react";
import { Detector } from "react-detect-offline";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { GET_FREQUENT_TAGS, GET_INTEREST_CATEGORY } from "../../../client/graphql";
import Images from "../../../themes/Images";
import InterestCard from "../../Cards/InterestCard";

const SearchContainer = styled.div`
  & > div {
    height: 46px;
    min-width: 180px;
    &.small{
      min-width: 44px;

    }
  }
  .small .ant-input-affix-wrapper{
    border-color:transparent;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
  }
  .w-65vw {
    width: 65vw;
  }
  .h-60vh {
    overflow: scroll;
    max-height: 60vh;
  }
  .searchContainer {
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 14px 14px;
  }
`;
const TagContainer = styled.div`
  background: rgb(var(--white));

  border: 1px solid rgb(var(--blue));
  border-radius: 40px;
`;
let interestLoading = true;
var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function SearchInput({ isSmall }) {
  const history = useHistory();
  const inputRef = useRef();
  const { name: searchTerm } = useParams();
  const [search, setSearch] = React.useState("");
  const [isFocus, setIsFocus] = React.useState(false);
  const [allInterest, setAllInterest] = React.useState([]);
  const [showSearch, setShowSearch] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [frequentTags, setFrequentTags] = useState([]);
  const location = useLocation();
  React.useEffect(() => {
    if (isFocus) {
      setTimeout(() => {
        setShowSearch(true);
      }, 700);
    } else {
      setShowSearch(false);
    }
  }, [isFocus]);
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (location.pathname.includes("/search")) {
      setIsSearching(true);
      setTags([params.get("name") || searchTerm]);
    } else {
      setTags([]);
      setIsSearching(false);
    }
  }, [location.pathname, location.search]);
  const [fetchFrequentTags, { loading: fetchingData }] = useLazyQuery(GET_FREQUENT_TAGS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
    },
    onCompleted: (data) => {
      console.log("completed:" + lc_id);
      if (data.getFrequentTags) {
        setFrequentTags([...data.getFrequentTags.tags].slice(0, 5));
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const [getInterestCategory, { loading: loadingInterestCategory }] = useLazyQuery(GET_INTEREST_CATEGORY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.iPadgetInterestCategory) {
        setAllInterest(data.iPadgetInterestCategory.intrest_cat);
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });
  React.useEffect(() => {
    setTimeout(() => {
      fetchFrequentTags()
      getInterestCategory();
    }, 4000);
  }, [])

  return (
    <Detector
      render={({ online }) => (
        <SearchContainer className=" ">
          <div className={`relative ${isSmall ? "small" : ""}`}>
            <div
              className={`absolute right-0 bg-white duration-700 transition-all   ${isFocus || isSearching ? "w-65vw" : "w-full"
                } `}
            >
              <Input
                ref={inputRef}
                onChange={(e) => {
                  if (online || (typeof navigator !== "undefined" && /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(navigator.userAgent))) {
                    setSearch(e.target.value);
                  }
                  // console.log("value", value);
                }}
                placeholder={
                  (tags || []).length
                    ? ""
                    : "Search by activities, things you love exploring or tags"
                }
                prefix={
                  <SearchOutlined
                    style={{
                      color: isFocus || isSearching ? 'rgb(var(--blue))' : isSmall ? "#fff" : "#374151",
                    }}
                  />
                }
                value={search}
                size="large"
                onFocus={() => {
                  if (online || (typeof navigator !== "undefined" && /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(navigator.userAgent))) {
                    setIsFocus(true);
                  }
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setIsFocus(false);
                  }, 300);
                }}
                onPressEnter={() => {
                  if (search) {
                    history.push(`/search?type=search&name=${search}`);
                    setSearch("");
                    inputRef.current.blur();
                    setIsFocus(false);
                  }
                }}
                className={`py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg ${isFocus || isSearching ? "border-blue" : ""
                  }`}
              />
              {tags.length !== 0 && (
                <>
                  <div
                    className={`absolute left-9 top-0 bottom-0 right-10 py-2 flex items-center h-11  overflow-x-auto overflow-y-hidden pointer-events-none  z-20 ${search && `opacity-10`
                      }`}
                  >
                    {(tags || []).map((tag, tagIndex) => {
                      return (
                        <TagContainer
                          className="h9 mr-3 px-4 text-black py-1 whitespace-nowrap "
                          key={`Search-UsedTag-${tagIndex}`}
                        >
                          {tag}
                        </TagContainer>
                      );
                    })}
                  </div>
                  <div
                    className=" absolute right-3 h-11 top-0 flex items-center justify-center z-20 cursor-pointer"
                    onClick={() => {
                      setTags([]);
                      setIsFocus(true);
                      setIsSearching(true);
                      inputRef.current.focus();
                    }}
                  >
                    <img src={Images.SearchCloseIcon} alt="Close Icon" />
                  </div>
                </>
              )}
              <div
                className={
                  !isFocus
                    ? `hidden`
                    : `relative w-full ${showSearch ? "h-60vh" : "h-0 overflow-hidden invisible"
                    } bg-white  pt-7 px-8 pb-9 searchContainer duration-700 transition-all`
                }
              >
                {!fetchingData && (
                  <>
                    <div className=" h5">Most used tags</div>
                    <div className="flex flex-wrap mt-4">
                      {frequentTags.map((tag, tagIndex) => {
                        return (
                          <TagContainer
                            className="h8 mr-4 px-4 py-3 mb-4 cursor-pointer z-10 text-black"
                            key={`UsedTag-${tagIndex}`}
                            onClick={() => {
                              console.log("clicked");
                              history.push(`/search?type=tag&name=${tag.name}`);
                              setSearch("");
                              setIsSearching(false);
                              setIsFocus(false);
                            }}
                          >
                            <div>{tag.name}</div>
                          </TagContainer>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className=" h5">Search by your interests</div>
                <div className=" mt-4 text-center grid grid-cols-4 gap-5">
                  {loadingInterestCategory && (
                    <>
                      {[{}, {}, {}, {}].map((interest, interestIndex) => {
                        return (
                          <InterestCard
                            key={`Interest-loading-${interestIndex}`}
                            loading={true}
                            interestIndex={interestIndex}
                            isMargin={false}
                            className="min-w-100"
                          />
                        );
                      })}
                    </>
                  )}
                  {!loadingInterestCategory && (
                    <>
                      {allInterest.map((interest, interestIndex) => {
                        return (
                          <div
                            className=" cursor-pointer"
                            key={`Interest-${interestIndex}`}
                            onClick={() => {
                              history.push(
                                `/search?type=interest&name=${interest.name}`
                              );
                              setSearch("");
                              setIsSearching(false);
                              setIsFocus(false);
                            }}
                          >
                            <InterestCard
                              key={`Interest-${interestIndex}`}
                              image={interest.background_image}
                              title={interest.name}
                              isMargin={false}
                              isSelected={interest.isActive}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SearchContainer>
      )}
    />
  );
}
