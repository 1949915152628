import { useMutation, useQuery } from '@apollo/client';
import { Avatar, DatePicker, Form, Input, notification, Upload } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GET_LEARNING_CENTRE, SAVE_PROFILE } from "../../../client/graphql";
import Button from '../../../components/common/Form/Button';
import NavContext from '../../../context/NavContext';
import { UploadDualPhoto } from '../../../themes/dualImages';
import { DefaultAvatar } from '../../../themes/Images';
import { avatarMap, getS3UploadUrl, uploadFiles } from '../../../utils';
const InputContainer = styled.div`
  .ant-input-affix-wrapper input,
  .ant-input-affix-wrapper {
    background-color: #f4f4f4;
    border-color: transparent;
    /* height:60px; */
    &.border-liteRed {
      border-color: #d1d5db;
    }
  }
  .ant-input-affix-wrapper.bg-red-100 input,
  .ant-input-affix-wrapper.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .ant-input {
    border-radius: 62px;
  }
  .ant-picker {
    border-radius: 62px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
`;

export default function Profile() {
  const userNavContext = useContext(NavContext);
  const [isEdit, setIsEdit] = React.useState(false)
  const [errors, setErrors] = React.useState({});
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dob, setDob] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("");
  const [selectedImg, setSelectedImg] = useState({});
  const [profileImg, setProfileImg] = useState("");
  const history = useHistory();

  const { loading: loadinglearningCentre, refetch: refetchLearnerDetails } = useQuery(GET_LEARNING_CENTRE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (resp) => {
      if (resp.getlearnerDetails && resp.getlearnerDetails.user_details) {
        setFirstName(resp.getlearnerDetails.user_details.name);
        setLastName(resp.getlearnerDetails.user_details.last_name);
        setEmail(resp.getlearnerDetails.user_details.email);
        setDob(resp.getlearnerDetails.user_details.year_of_birth);
        setCountry(resp.getlearnerDetails.user_details.country);
        setCity(resp.getlearnerDetails.user_details.address);
        setMobileNumber(resp.getlearnerDetails.user_details.phone_no);
        setProfileImg(resp.getlearnerDetails.user_details.profile_image);
        setSelectedImg(resp.getlearnerDetails.user_details.profile_image);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const [editLearningCenter, { loading: updatingLearningCenter }] = useMutation(SAVE_PROFILE, {
    variables: {
      name: firstName,
      last_name: lastName,
      year_of_birth: dob,
      country: country,
      address: city,
      phone_no: mobileNumber,
      profileImage: selectedImg,
    },
    onCompleted: (resp) => {
      if (resp.editLearner) {
        notification.success({ message: "Profile Saved" });
        setIsEdit(false);
        refetchLearnerDetails();
        let uInfo = { ...userNavContext.userInfo };
        uInfo.parentInfo.profile_image = selectedImg;
        userNavContext.setUserInfo(uInfo);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });


  return (
    <div className=" mt-2">
      <div className="flex justify-between ">
        <div className="nunito-bold-2xl mb-9">About me</div>
        <div className="flex items-center mb-3">
          <div
            className="cursor-pointer text-blue nunito-bold-lg mr-8"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            {isEdit ? "Cancel" : `Edit Details`}
          </div>
          {isEdit && (
            <Button
              secondary
              className="font-Nunito text-base px-6 py-2 flex font-bold"
              onClick={async () => {
                await editLearningCenter();
              }}
            >
              {`Save Changes`}
            </Button>
          )}
          <Button
            secondary
            className="ml-2 font-Nunito text-base px-8  py-2 text-center flex font-bold justify-center items-center"
            onClick={() => {
              let details = localStorage.getItem("userList");
              localStorage.clear();
              localStorage.removeItem("token");
              localStorage.setItem("userList", details);
              window.open(
                `${process.env.REACT_APP_WEB_APP}parent/redirect`,
                "_blank"
              );
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      {!isEdit && (
        <div className=" flex">
          <div>
            <div
              className="h-36 w-36 rounded-full m-auto"
            // style={{ backgroundColor: bg_color || "whitesmoke" }}
            >
              <Avatar
                src={profileImg || DefaultAvatar}
                style={{
                  backgroundColor: "#FFF6D6",
                  color: "#4C39B3",
                  width: "100%",
                  height: "100%",
                  fontSize: 32,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {avatarMap(
                  (firstName || "") +
                  " " +
                  (lastName || ""),
                  3
                )}
              </Avatar>
            </div>
          </div>
          <div className=" ml-10 nunito-bold-lg text-pureWhite grid grid-cols-2 gap-8 w-full">
            <div className="">
              <div className="  mr-16 ">First Name</div>
              <div className="font-normal">{firstName}</div>
            </div>
            <div className=" ">
              <div className="  mr-16 ">Last name</div>
              <div className="font-normal">{lastName}</div>
            </div>
            <div className=" ">
              <div className="  mr-16 ">Email</div>
              <div className="font-normal">{email}</div>
            </div>
            <div className=" ">
              <div className="  mr-16 ">Year of Birth</div>
              <div className="font-normal">{dob}</div>
            </div>
            <div className=" ">
              <div className="  mr-16 ">Country</div>
              <div className="font-normal">{country}</div>
            </div>
            <div className=" ">
              <div className="  mr-16 ">City</div>
              <div className="font-normal">{city}</div>
            </div>
            <div className="">
              <div className="  mr-16 ">Mobile Number</div>
              <div className="font-normal">{mobileNumber}</div>
            </div>
          </div>
        </div>
      )}
      {isEdit && (
        <Form name="editProfile" onFinish={() => { }}>
          <div className="flex">
            <div className="">
              <div
                className="mb-2 h-182 w-182 rounded-full mr-3"
                // style={{ backgroundColor: bg_color || "whitesmoke" }}
                onClick={async () => { }}
              >
                <Avatar
                  src={selectedImg || DefaultAvatar}
                  style={{
                    backgroundColor: "#FFF6D6",
                    color: "#4C39B3",
                    width: "100%",
                    height: "100%",
                    fontSize: 32,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {avatarMap(
                    (firstName || "") +
                    " " +
                    (lastName || ""),
                    3
                  )}
                </Avatar>
                {/* <img src={selectedImg} alt="avatar" /> */}
              </div>
              <div className="">
                <Upload
                  accept={".png,.jpg,.jpeg"}
                  listType="picture"
                  showUploadList={false}
                  multiple={false}
                  beforeUpload={async (file, fileList) => {
                    let signedURL = await getS3UploadUrl(file.name, "image");
                    if (signedURL) {
                      let imageURLs = await uploadFiles([signedURL], [file]);
                      setSelectedImg(imageURLs[0]);
                    }
                    return false;
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer mb-5"
                    id="photoUpload"
                  >
                    {/* <img src={Images.UploadPhotoIcon} alt="Voice img" /> */}
                    <UploadDualPhoto />
                    <div className="ml-3 text-charcoal">Upload a photo</div>
                  </div>
                </Upload>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-6 gap-y-3 w-8/12">
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  My First Name
                </div>
                <InputContainer>
                  <Form.Item
                    name="fname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                    showCount
                  >
                    {console.log(firstName)}
                    <Input
                      value={firstName}
                      maxLength={32}
                      showCount
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        if (!e.target.value) {
                          setErrors({ fname: "Required" });
                        } else {
                          setErrors({});
                        }
                      }}
                      size="small"
                      onPressEnter={() => { }}
                      className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg ${errors["fname"] ? `border border-liteRed` : ``
                        }`}
                    />
                  </Form.Item>
                </InputContainer>
              </div>
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  My Last name
                </div>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                  showCount
                >
                  <InputContainer>
                    <Input
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      maxLength={32}
                      showCount
                      size="large"
                      onPressEnter={() => { }}
                      className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                    />
                  </InputContainer>
                </Form.Item>
              </div>
              {/*<div className="flex flex-col mt-5 pr-5">
              <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                My Email ID
              </div>
              <InputContainer>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled
                  size="large"
                  onPressEnter={() => { }}
                  className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                />
              </InputContainer>
            </div>*/}
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  Year Of Birth
                </div>
                <Form.Item
                  name="yearofbirth"
                  rules={[
                    {
                      required: true,
                      message: "Please input your year of birth!",
                    },
                  ]}
                >
                  <InputContainer>
                    <DatePicker
                      allowClear={false}
                      value={moment(dob, "YYYY")}
                      placeholder={""}
                      suffixIcon={false}
                      picker="year"
                      format={`YYYY`}
                      onChange={(val) => {
                        setDob(val.format("YYYY"));
                      }}
                      disabledDate={(currentData) =>
                        currentData.isAfter(moment())
                      }
                      size="large"
                      className={`w-full px-4 py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                    />
                  </InputContainer>
                </Form.Item>
              </div>
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  Country
                </div>
                <Form.Item
                  name="country"
                  rules={[
                    { required: true, message: "Please input your country!" },
                  ]}
                >
                  <InputContainer>
                    <Input
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      size="large"
                      onPressEnter={() => { }}
                      className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                    />
                  </InputContainer>
                </Form.Item>
              </div>
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  City
                </div>
                <Form.Item
                  name="city"
                  rules={[
                    { required: true, message: "Please input your city!" },
                  ]}
                >
                  <InputContainer>
                    <Input
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      size="large"
                      onPressEnter={() => { }}
                      className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                    />
                  </InputContainer>
                </Form.Item>
              </div>
              <div className="flex flex-col mt-5 pr-5">
                <div className="mr-16 mb-3 font-semibold text-pureWhite ">
                  Mobile Number
                </div>
                <Form.Item
                  name="mobile_no"
                  rules={[
                    {
                      required: true,
                      message: "Please input your mobile number!",
                    },
                  ]}
                >
                  <InputContainer>
                    <Input
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      size="large"
                      onPressEnter={() => { }}
                      className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                    />
                  </InputContainer>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}
