import React, { useContext } from "react";
import ChildCalendar from "./calendar";
import ParentCalendarComponent from "./parentCalendar";
import NavContext from "../../context/NavContext";

const CalendarIndex = () => {
  const userNavContext = useContext(NavContext);
  return (
    <>
      {userNavContext?.userInfo?.mode === "LIGHT_MODE" ? (
        <ChildCalendar />
      ) : (
        <ParentCalendarComponent />
      )}
    </>
  );
};

export default CalendarIndex;
