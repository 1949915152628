import { useQuery } from "@apollo/client";
import { notification, Tabs } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { GET_CARD_DETAILS } from "../../client/graphql";
import ExploreHeading from "../../components/common/ExploreHeading";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";
const { TabPane } = Tabs;

const TabContainer = styled.div`
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: rgb(var(--blue));
    }
    .ant-tabs-tab-btn {
      font-family: Nunito;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #828282;
    }
  }
  .ant-tabs-ink-bar {
    background: transparent;
    min-height: 4px;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      content: "";
      width: 70%;
      height: 4px;
      background: rgb(var(--blue));
      border-radius: 2px;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important
    ;
  }
`;
const CardContainer = styled.div`
  .image {
    min-width: 1;
  }
`;
const Card = ({ image, title, isExpert, loading, author, role }) => {
  return (
    <CardContainer className=" p-2 bg-lightWhite rounded-md overflow-hidden flex ">
      <div className=" rounded-md overflow-hidden w-40 h-24" style={{minWidth: "40%"}}>
        {loading && <div className="w-full h-full loader"></div>}
        {!loading && (
          <img
            src={image}
            alt="LC Card img"
            className="w-full h-full object-cover"
          />
        )}
      </div>
      <div className="ml-3">
        <div className={`h9 ${loading ? "loader rounded h-4 w-44" : ""}`}>
          {title || ""}
        </div>
        {isExpert && (
          <div
            className={`mt-2 h8 text-lightGray italic flex ${loading ? "loader rounded h-3 mt-2 w-36" : ""
              }`}
          >
            {loading ? (
              ""
            ) : (
              <>
                {`By ${author}`}
                {role === 4 && (
                  <img
                    src={Images.VerifiedIcon}
                    alt="verified Icon"
                    className="ml-2"
                  />
                )}
              </>
            )}{" "}
          </div>
        )}
      </div>
    </CardContainer>
  );
};
export default function CompletedCards(props) {
  console.log(props.location.pathname);
  const path = props.location.pathname;
  const [isExpert, setIsExpert] = React.useState(false);
  const [cards, setCards] = useState([]);
  const [expertCuratorCards, setExpertCuratorCards] = useState([]);
  const location = useLocation();
  React.useEffect(() => {
    if (location?.pathname?.includes("/profile/expert/collections")) {
      setIsExpert(true);
    }
  }, []);

  const { loading: loadingCardDetails } = useQuery(GET_CARD_DETAILS, {
    fetchPolicy: "network-only",
    skip: path !== "/profile/collections",
    variables: {
      card: true,
      expert_curator: false,
      playlist: false,
    },
    onCompleted: (response) => {
      if (response.iPadgetCardsDetails) {
        if (response.iPadgetCardsDetails.cards) {
          setCards(response.iPadgetCardsDetails.cards);
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const { loading: loadingExpertCardDetails } = useQuery(GET_CARD_DETAILS, {
    fetchPolicy: "network-only",
    skip: path !== "/profile/expert/collections",
    variables: {
      card: false,
      expert_curator: true,
      playlist: false,
    },
    onCompleted: (response) => {
      if (response.iPadgetCardsDetails) {
        if (response.iPadgetCardsDetails.expert_curator) {
          setExpertCuratorCards(response.iPadgetCardsDetails.expert_curator);
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return (
    <div>
      <Loader loading={loadingCardDetails || loadingExpertCardDetails} />
      <ExploreHeading
        isBack={true}
        title={
          isExpert
            ? "I’ve completed these Expert’s cards"
            : "I’ve completed these cards"
        }
      ></ExploreHeading>
      {cards.length === 0 &&
        expertCuratorCards.length === 0 &&
        !loadingCardDetails &&
        !loadingExpertCardDetails && (
          <div>
            <div className="text-center mt-10">
              <img
                src={Images.WishlistEmptyState}
                alt="Wishlist Empty State"
                className="mx-auto"
              />
              <div className=" mt-10 nunito-normal-lg">
                {"Your completed cards will appear here"}
              </div>
            </div>
          </div>
        )}
      {(cards.length !== 0 || expertCuratorCards.length !== 0) &&
        !loadingCardDetails &&
        !loadingExpertCardDetails && (
          <div
            className="container mx-auto p-8 bg-white"
            style={{
              boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
              borderRadius: "3px",
            }}
          >
            <TabContainer className=" mt-5">
              <Tabs
                defaultActiveKey="1"
                tabBarGutter={60}
                tabBarExtraContent={{
                  right: (
                    <div
                      className="px-6 cursor-pointer"
                      onClick={() => {
                        let transformStyle = document.querySelector(
                          `#lctabList .ant-tabs-nav-list`
                        ).style.transform;
                        const SplittedTranslate = transformStyle.split(",");
                        const translateX = SplittedTranslate[0].replace(
                          /[^\d.]/g,
                          ""
                        );
                        let computedTranslate = parseFloat(translateX)
                          ? parseFloat(translateX) + 250
                          : -250;
                        console.log(
                          Math.abs(computedTranslate),
                          document.querySelector(
                            `#lctabList .ant-tabs-nav-list`
                          ).offsetWidth
                        );
                        if (
                          Math.abs(computedTranslate) + 1000 <
                          document.querySelector(
                            `#lctabList .ant-tabs-nav-list`
                          ).offsetWidth
                        ) {
                          SplittedTranslate[0] = SplittedTranslate[0].replace(
                            translateX,
                            computedTranslate
                          );
                        }
                        document.querySelector(
                          `#lctabList .ant-tabs-nav-list`
                        ).style.transform = SplittedTranslate.join(",");
                        // .scrollTo({
                        //   left:
                        //     document.querySelector(
                        //       `#lctabList .ant-tabs-nav-list`
                        //     ).scrollLeft + 250,
                        //   behavior: "smooth",
                        // });
                      }}
                    >
                      {(!isExpert
                        ? cards.length > 5
                        : expertCuratorCards.length > 5) && (
                          <img
                            src={Images.CarouselLeft}
                            alt="Carousel Icon"
                            style={{ minWidth: 44 }}
                            className=" shadow-3xl rounded-full h-11"
                          />
                        )}
                    </div>
                  ),
                }}
                moreIcon={
                  <img
                    src={Images.CarouselLeft}
                    alt="Carousel Icon"
                    style={{ minWidth: 44 }}
                    className=" shadow-3xl rounded-full h-11"
                  />
                }
                destroyInactiveTabPane
                onChange={() => { }}
                id="lctabList"
              >
                {cards &&
                  cards.map((tab, tabIndex) => {
                    return (
                      <TabPane tab={tab.user.name} key={tabIndex + 1}>
                        <div className=" mt-4 grid grid-cols-3 gap-4">
                          {tab.cards_detail.map((card, cardIndex) => {
                            return (
                              <Card
                                key={`Card-${cardIndex}`}
                                isExpert={isExpert}
                                loading={false}
                                author={tab.user.name}
                                image={card.thumb_images[0].image_url}
                                title={card.name}
                                role={tab.user.role_id}
                              />
                            );
                          })}
                        </div>
                      </TabPane>
                    );
                  })}

                {expertCuratorCards &&
                  expertCuratorCards.map((tab, tabIndex) => {
                    return (
                      <TabPane tab={tab.user.name} key={tabIndex + 1}>
                        <div className=" mt-4 grid grid-cols-3 gap-4">
                          {tab.cards_detail.map((card, cardIndex) => {
                            return (
                              <Card
                                key={`Card-${cardIndex}`}
                                isExpert={isExpert}
                                loading={false}
                                author={tab.user.name}
                                image={card.thumb_images[0].image_url}
                                title={card.name}
                                role={tab.user.role_id}
                              />
                            );
                          })}
                        </div>
                      </TabPane>
                    );
                  })}
              </Tabs>
            </TabContainer>
          </div>
        )}
    </div>
  );
}
