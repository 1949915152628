import { DatePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

const DateContainer = styled.div``;
export default function WeekPicker({ onChange, start_date, end_date, week, className = "" }) {
  const [showDate, setShowDate] = useState(false);
  const handleChange = (date, dateString) => {
    onChange && onChange(date, dateString);
    setShowDate(false);
  };
  return (
    <DateContainer>
      <div className="-mt-8 "></div>

      <DatePicker.WeekPicker
        open={showDate}
        className=" invisible pointer-events-none"
        dropdownClassName="weekPicker"
        format="YYYY-wo"
        value={moment(start_date)}
        onChange={handleChange}
        disabledDate={(current) => !moment().isAfter(current)}
        renderExtraFooter={() => (
          <div className="text-right">
            <span
              className=" text-blue cursor-pointer"
              onClick={() => setShowDate(false)}
            >
              Close
            </span>
          </div>
        )}
      />

      <div
        onClick={() => setShowDate(!showDate)}
        className={`bg-white rounded-md border flex items-center justify-center py-2 px-3 text-spanishGray nunito-semibold-xs border-spanishGray ${className}`}
        style={{ height: 32 }}
      >
        <span className="">{moment(start_date).format("ll")}</span>
        <span className=" px-2">-</span>
        <span className="">{moment(end_date).format("ll")}</span>
        {/* <span className="mr-3">
          <Icon type="down" style={{ verticalAlign: "middle" }} />
        </span> */}
      </div>
    </DateContainer>
  );
}
