import { useMutation } from "@apollo/client";
import { Modal, notification } from "antd";
import React from "react";
import { FEEDBACK_RESPONSE } from "../../../client/graphql";
import Button from "../../../components/common/Form/Button";
import Textarea from "../../../components/common/Form/Textarea";
import Loader from "../../../components/common/Loader";

export default function Notes(props) {
  const [notes, setNotes] = React.useState("");
  const feedbackId = props?.data;

  const [addFeedBack, { loading: addingFeedback }] = useMutation(
    FEEDBACK_RESPONSE,
    {
      onCompleted: (data) => {
        if (data?.StudentResponse?.status) {
          props?.onSuccess();
          props?.closeModal();
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  return (
    <div>
      <Modal
        width={550}
        title={null}
        visible={props?.isVisible}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          props?.closeModal();
        }}
      >
        <Loader loading={addingFeedback} />
        <div className="nunito-bold-xl mt-2 text-center">Feedback</div>

        <div className=" mt-6">
          <Textarea notes={notes} setNotes={setNotes} />
        </div>
        <div className="mt-8 flex items-center justify-center">
          <div className=" w-40 mr-4">
            <Button
              className="font-Nunito text-lightGray border border-lightGray px-3  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                props?.closeModal();
                // AddToWishlist();
              }}
            >
              Cancel
            </Button>
          </div>

          <div className=" w-40 ">
            <Button
              secondary
              className="font-Nunito text-base text-white px-6  py-2 flex font-bold text-center justify-center"
              onClick={() => {
                addFeedBack({
                  variables: {
                    id: feedbackId,
                    response: window.btoa(unescape(encodeURIComponent(notes))),
                  },
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
