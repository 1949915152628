import { useMutation, useQuery } from "@apollo/client";
import { notification, Result } from "antd";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GET_LEARNING_CENTRE, SUBSCRIPTION_PAYMENT, VIEW_USER } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import NavContext from "../../context/NavContext";
import { BackBlueDualIcon } from "../../themes/dualImages";
import Images from "../../themes/Images";
import { BackButtonHandler } from "../../utils";


function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const PaymentStyle = styled.div`
  .paymentHeading {
    position: absolute;
    left: 55px;
    right: 55px;
    text-align: center;
  }
  .paymentContainer {
    background: rgba(248, 201, 71, 0.1);
    /* Brand colors/Brand yellow */

    border: 1px solid #f8c947;
  }
  .bg-ghostWhite {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

// const VIEW_USER = gql`
//   query {
//     viewUser {
//       user_details {
//         id
//         name
//         # first_name
//         profile_image
//         role_id
//         is_child
//         is_learner
//         child_details {
//           id
//           username
//           fname
//           lname
//           dob
//         }
//       }
//       student_details {
//         id
//         username
//         fname
//         lname
//       }
//     }
//   }
// `;

export default function PaymentProceed() {
  const history = useHistory();
  const userNavContext = useContext(NavContext);
  const [accessDenied, setAccessDenied] = React.useState(false);

  const [subscriptionPayment, { loading: creatingOrder }] = useMutation(
    SUBSCRIPTION_PAYMENT
  );
  const { data: learnerDetails, loading: gettingLearnerInfo } = useQuery(
    GET_LEARNING_CENTRE
  );

  const [userID, setUserID] = React.useState(null);
  const [name, setName] = React.useState("")
  const { data, loading: loadingUser } = useQuery(VIEW_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      if (response?.viewUser?.student_details) {
        setAccessDenied(true)
      } else {
        setAccessDenied(false)
        if (getQueryVariable('child_id')) {
          response?.viewUser?.user_details?.child_details?.map((child) => {
            if (child.id === getQueryVariable('child_id')) {
              setName(child?.username)
            }
          })
        } else {
          setName(data?.viewUser?.user_details?.name)
        }
      }
    }
  });
  React.useEffect(() => {
    const decoded = jwt_decode(localStorage.getItem("token"));
    setUserID(decoded.id);

  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const payWithRazorpay = async (order_id, amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount.toString(),
      currency: "INR",
      name: "Subscription",
      description: `Sub/${learnerDetails?.getlearnerDetails?.user_details?.name}/${moment().format("DD-MM-YYYY")}`,
      order_id: order_id,
      handler: onPaymentCompleted,
      prefill: {
        name: userNavContext?.userInfo?.parentInfo.name,
        email: learnerDetails?.getlearnerDetails?.user_details?.email,
        contact: learnerDetails?.getlearnerDetails?.user_details?.phone_no,
      },
      notes: {
        address: "notes",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleClick = () => {
    let amount = 10000;//in paise
    subscriptionPayment({
      variables: {
        user_id: parseInt(userID),
        subscription_type: getQueryVariable('child_id') ? "Student" : "Parent",
        amount: amount,
        student_id: getQueryVariable('child_id') ? getQueryVariable('child_id') : null,
        purchase_date: moment().format("yyyy-MM-DD HH:mm:ss")//moment().format("DD MMM YYYY"),

      },
    }).then(res => {
      console.log(res)
      if (res?.data?.updatePayment?.error) {
        notification.error({
          message: "Something went wrong",
          description: res?.data?.updatePayment?.msg || "",
        });
      } else {
        let orderId = res.data?.updatePayment?.data?.id;
        payWithRazorpay(orderId, amount);
        //window.location.href = res?.data?.updatePayment?.data?.longurl;
      }
    });
  };

  const onPaymentCompleted = async (response) => {
    window.location.href =
      `/payment/verify/${response.razorpay_order_id}/${response.razorpay_payment_id}`
      ;
  };

  return (
    <div>
      <Loader
        loading={
          gettingLearnerInfo ||
          creatingOrder ||
          loadingUser
        }
      />
      {accessDenied && (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              type="primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Back Home
            </Button>
          }
        />
      )}
      {!accessDenied && (
        <PaymentStyle className=" mt-20 mb-24 max-w-xl mx-auto relative">
          <div className="flex">
            <div
              className="flex items-center justify-center left-0 z-20 cursor-pointer"
              onClick={() => {
                // history.goBack();
                BackButtonHandler(history);

                // console.log(history);
              }}
            >
              <BackBlueDualIcon />

              <span className=" text-base ml-2 hidden lg:block text-blue">
                Back
              </span>
            </div>
            <div className="nunito-bold-lg text-pureWhite paymentHeading whitespace-nowrap">
              Choose payment method for {name}
            </div>
          </div>
          <div className="mt-3 bg-ghostWhite rounded-xl p-8 ">
            <div className="paymentContainer rounded-xl p-5 flex">
              <img
                src={Images.OneTimePaymentArrow}
                alt="Arrow"
                className=" flex-shrink-0 self-start"
              />
              <div className="ml-4 text-pureWhite">
                <div className="nunito-bold-lg">One time payment</div>
                <ul className="h6 pl-5 list-disc">
                  <li>All payment methods supported</li>
                  <li>No auto renewal</li>
                </ul>
              </div>
            </div>
            <div className="mt-8 h6 text-pureWhite">
              You will have access for 12 months till{" "}
              {moment().add(1, "year").format("DD MMM yyyy")}. Post that your
              Subscription will end. You will have to pay again manually to get
              access to your learning content and progress.
              <div className=" text-sm opacity-60 mt-1">All prices inclusive of GST</div>
            </div>
            <div className="mt-8 w-60 mx-auto">
              <Button
                secondary
                className="h6 font-bold bg-violet border-violet px-8  py-3 text-center flex justify-center items-center"
                onClick={() => {
                  handleClick();
                }}
              >
                Proceed to payment
              </Button>
            </div>
          </div>
        </PaymentStyle>
      )}
    </div>
  );
}
