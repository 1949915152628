import React from "react";
import { useHistory } from "react-router-dom";
import Images from "../../themes/Images";

export default function Breadcrumb({ routes }) {
  const history = useHistory();
  return (
    <div className="flex items-center mb-4">
      {routes.map((route, routesIndex) => {
        return (
          <div
            className={`h9 flex ${
              routesIndex + 1 !== routes.length
                ? "text-Gray500"
                : "text-textDark"
            } ${route.url? `cursor-pointer`: ``}`}
            key={`Breadcrumb-${routesIndex}`}
            onClick={() => {
              if (route.url) {
                history.push(route.url);
              }
            }}
          >
            {route.label}
            {routesIndex + 1 !== routes.length && (
              <img
                src={Images.BreadcrumbArrowIcon}
                className="mx-2"
                alt="Breadcrumb Icon"
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
