import React from "react";
import styled from "styled-components";
const WatchContainer = styled.div`
  width: 472px;
  height: 266px;
  & .bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
  iframe.show {
    position: fixed;
    inset: 0;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    height: 100%;
  }
  .fullscreen {
    z-index: 1000000;
  }
`;
// if (document.exitFullscreen) {
//   document.exitFullscreen();
// } else if (document.webkitExitFullscreen) { /* Safari */
//   document.webkitExitFullscreen();
// } else if (document.msExitFullscreen) { /* IE11 */
//   document.msExitFullscreen();
// }
export default function YoutubePlayer({ poster, videoURL }) {
  const [fullScreen, setfullScreen] = React.useState(false);
  // React.useEffect(() => {
  //   document.onfullscreenchange = function (event) {
  //     console.log("FULL SCREEN CHANGE");
  //   };
  // }, []);
  React.useEffect(() => {
    if (fullScreen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [fullScreen]);
  React.useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key == "Escape") {
        setfullScreen(false);
      }
    };

    window.document.addEventListener("keyup", handleKeyUp);

    return () => {
      window.document.removeEventListener("keyup", handleKeyUp);
    };
  });
  return (
    <WatchContainer
      className="relative overflow-hidden cursor-pointer"
      onClick={() => {
        setfullScreen(true);
        // console.log("Checkin");
        // var e = document.getElementById("youtubeFrame");
        // if (e.requestFullscreen) {
        //   e.requestFullscreen();
        // } else if (e.webkitRequestFullscreen) {
        //   e.webkitRequestFullscreen();
        // } else if (e.mozRequestFullScreen) {
        //   e.mozRequestFullScreen();
        // } else if (e.msRequestFullscreen) {
        //   e.msRequestFullscreen();
        // }
      }}
    >
      {/* <img
        src={poster}
        alt="video thumbnail"
        className="w-full h-full object-cover"
      />
      <div className="bg absolute inset-0 "></div>
      <div className="absolute inset-0 flex items-center justify-center z-1">
        <img src={Images.YoutubeIcon} alt="Youtube Icon" />
      </div>
      <div
        className="fixed bottom-0 right-0 w-14 h-14 fullscreen"
        onClick={(e) => {
          e.stopPropagation();
          setfullScreen(false);
        }}
      ></div> */}
      <div>
        {videoURL && (
          <iframe
            title="Youtube Video player"
            id="youtubeFrame"
            className={` w-full h-full absolute inset-0`}
            src={videoURL}
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          />
        )}
      </div>
    </WatchContainer>
  );
}
