import { Modal } from "antd";
import React from "react";
import Confetti from 'react-confetti';
import Button from "../../../../components/common/Form/Button";
import Notesbutton from "../../../../components/common/Notes/Notesbutton";
import Images from "../../../../themes/Images";

export default function NotesFinish(props) {
  const [isFinishedLearning, setIsFinishedLearning] = React.useState(false);
  const [congratsPopup, setCongratsPopup] = React.useState(false);
  return (
    <div>
      <div className="flex">
        <Notesbutton
          title={"Make notes"}
          type={props.type}
          onSuccess={props.onSuccess}
          enableCrop={true}
        />
        <div className="ml-5 w-182">
          <Button
            disabled={props.disabled}
            secondary
            className="font-Nunito text-base px-6  py-2 flex font-bold text-center justify-center"
            onClick={() => {
              setIsFinishedLearning(true);
            }}
          >
            {`Finish`}
          </Button>
        </div>
      </div>

      <Modal
        width={500}
        title={null}
        visible={isFinishedLearning}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="finishModal rounded-lg"
        onCancel={() => {
          setIsFinishedLearning(false);
        }}
      >
        {/* <Confetti
          ref={document.getElementById('root')}
          canvasRef={document.getElementById('root')}
          width={document.body.clientWidth}
          height={document.body.clientHeight}
          colors={
            [
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4CAF50",
              "#8BC34A",
              "#CDDC39",
              "#FFEB3B",
              "#FFC107",
              "#FF9800",
              "#FF5722",
              "#795548"
            ]
          }
          friction={0.99}
          gravity={0.1}
          initialVelocityX={4}
          initialVelocityY={10}
          numberOfPieces={200}
          opacity={1}
          recycle={true}
          run={true}

          tweenDuration={5000}
          wind={0}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 99999
          }}
        /> */}
        <div className="mt-2 text-center nunito-bold-xl text-gray900">
          Finish learning
        </div>
        <div className="mt-4 text-center text-Gray500">
          {`Are you sure you want to finish?`}
        </div>
        <div className=" mt-9 flex items-center justify-center">
          <div className=" w-40">
            <Button
              className="font-Nunito text-darkGray border border-darkGray px-4  py-2 flex  font-bold justify-center"
              onClick={() => {
                setIsFinishedLearning(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="ml-4 w-40">
            <Button
              secondary
              className="font-Nunito text-base px-6  py-2 flex font-bold justify-center"
              onClick={() => {
                setIsFinishedLearning(false);
                setCongratsPopup(true);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        width={575}
        title={null}
        visible={congratsPopup}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="finishModal rounded-lg"
        onCancel={() => {
          setCongratsPopup(false);
        }}
      >
        <Confetti
          width={document.body.clientWidth}
          height={document.body.clientHeight}
          colors={[
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4CAF50",
            "#8BC34A",
            "#CDDC39",
            "#FFEB3B",
            "#FFC107",
            "#FF9800",
            "#FF5722",
            "#795548",
          ]}
          friction={0.99}
          gravity={0.1}
          initialVelocityX={4}
          initialVelocityY={10}
          numberOfPieces={200}
          opacity={1}
          recycle={true}
          run={true}
          tweenDuration={5000}
          wind={0}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99999,
          }}
        />
        <div className=" h-56">
          <img
            src={Images.CongratsStarIcon}
            alt="Congrats Icon"
            className="mx-auto"
            style={{}}
          />
        </div>
        <div className="mt-5 uppercase h3 mb-4" style={{ color: "#5432BA" }}>
          CONGRATULATIONS!
        </div>
        <div className="font-Nunito font-normal text-xl text-center text-lightGray">
          You have successfully completed{" "}
          <span className="font-bold text-textDark">{props.title || ""}</span>
        </div>
        <div className=" w-182 mx-auto mb-2 mt-6">
          <Button
            secondary
            className="font-Nunito text-base px-6  py-2 flex font-bold justify-center"
            onClick={() => {
              setCongratsPopup(false);
              if (props.onFinish) props.onFinish();
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
      <img
        src={Images.CongratsStarIcon}
        alt="Congrats Icon"
        className="mx-auto hidden"
        style={{}}
      />
    </div>
  );
}
