import React from "react";
import Images from "../../themes/Images";
import Button from "./Form/Button";

export default function InternetOffline() {
  return (
    <div className="text-center mt-10">
      <img
        src={Images.InternetState}
        alt="Internet State"
        className=" mx-auto"
      />
      <div className=" mt-20 nunito-normal-lg w-8/12 text-center max-w-xl mx-auto">
        Whoops! Looks like your internet is a tad-bit slow.
        {/* Swipe down to
        refresh and see all the fun stuff in store. */}
      </div>
      <div className=" mt-8 pb-10">
        <div className=" w-32 mx-auto">
          <Button
            secondaryGhost
            className={
              "font-Nunito px-5  py-1  font-bold flex items-center justify-center text-sm"
            }
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </div>
      </div>
    </div>
  );
}
