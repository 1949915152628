import { useQuery } from "@apollo/client";
import { notification } from "antd";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation, useParams } from "react-router";
import { GET_INTEREST_TAG_CARD, GET_SEARCH_DATA } from "../../client/graphql";
import ImageCard from "../../components/Cards/ImageCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import Loader from "../../components/common/Loader";

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function CardSearch() {
  const [cards, setCards] = React.useState([]);
  const [expertCards, setExpertCards] = React.useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const { type, name } = useParams();
  const location = useLocation();
  const history = useHistory();

  console.log(location.pathname);

  const { loading: fetchingData } = useQuery(
    GET_SEARCH_DATA,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      skip: type === 'tag' || type === 'interest',
      variables: {
        search: name,
        lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
        page: page,
      },
      onCompleted: (data) => {
        if (data.getExploreSearch) {
          setCards([...cards, ...data.getExploreSearch.cards]);
          setExpertCards([...expertCards, ...data.getExploreSearch.exports_cards]);
          setHasMoreItems(
            data.getExploreSearch.cards.length ||
            data.getExploreSearch.exports_cards.length
          );
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      }
    }
  );

  const { loading: fetchingInterestTagData } = useQuery(GET_INTEREST_TAG_CARD, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: type === 'search',
    variables: {
      search: name,
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      is_tag: type === "tag",
      is_interest: type === "interest",
      page: page,
    },
    onCompleted: (data) => {
      if (data.getInterestTagCard) {
        setCards([...cards, ...data.getInterestTagCard.cards]);
        setExpertCards([...expertCards, ...data.getInterestTagCard.exports_cards]);
        setHasMoreItems(
          data.getInterestTagCard.cards.length ||
          data.getInterestTagCard.exports_cards.length
        );
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    }
  });

  /*const { loading: loadingSearch } = useQuery(GET_CARD_SEARCH, {
    fetchPolicy: "network-only",
    variables: {
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      is_tag: type === "tag" ? true : false,
      is_interest: type === "interest" ? true : false,
      search: name,
      page: page,
    },
    onCompleted: (response) => {
      if (response.IpadSearchV3) {
        if (response.IpadSearchV3.cards) {
          // [...collections, ...data.getExploreCollection.collection]
          setCards([...cards, ...response.IpadSearchV3.cards]);
        }
        if (response.IpadSearchV3.exports_cards) {
          setExpertCards([...expertCards, ...response.IpadSearchV3.exports_cards]);
        }
        setHasMoreItems(
          response.IpadSearchV3.cards.length ||
          response.IpadSearchV3.exports_cards.length
        );
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });*/
  return (
    <div>
      <div className="bg-white">
        <ExploreHeading
          title={<div className="flex items-center justify-center">Cards </div>}
        ></ExploreHeading>
        <div className="px-12">
          <div className="nunito-semibold-lg mt-5 ">
            Showing cards for <b>{name}</b>{" "}
          </div>
          <Loader loading={fetchingData || fetchingInterestTagData} />
          <div>
            <InfiniteScroll
              threshold={1000}
              pageStart={page}
              loadMore={(loadPage) => {
                if (type === 'search' && !fetchingData) {
                  setPage(page + 1);
                } else if ((type === 'tag' || type === 'interest') && !fetchingInterestTagData) {
                  setPage(page + 1);
                }
              }}
              hasMore={hasMoreItems}
              loader={
                <div className="mt-3 grid grid-cols-3 gap-5">
                  {[{}, {}, {}].map((card) => (
                    <ImageCard loading />
                  ))}
                </div>
              }
            >
              <div className="mt-3 grid grid-cols-3 gap-2">
                {(location.pathname.includes("/search/experts")
                  ? expertCards
                  : cards
                )?.map((card, cardIndex) => {
                  return (
                    <div
                      className={` cursor-pointer px-2`}
                      key={`Cards-${cardIndex}`}
                      onClick={() => {
                        if (location.pathname.includes("/search/experts")) {
                          history.push(`/collections/expert/cards/${card.id}`);
                        } else {
                          history.push(`/collections/cards/${card.id}`);
                        }
                      }}
                    >
                      <ImageCard
                        title={card.name || ``}
                        coverImage={
                          card.thumb_images && card.thumb_images.length
                            ? card.thumb_images[0].image_url
                            : undefined
                        }
                        status={
                          card.activity_status
                            ? card.activity_status
                            : card.card_status
                        }
                        cardType={
                          card.default_activities_id === 2 ||
                            card.default_activities_id === 3
                            ? "watch"
                            : card.default_activities_id === 4
                              ? "do"
                              : "watch"
                        }
                        isWishlist={card.is_wishlist}
                        difficultyLevel={card.difficulty}
                        timeLevel={card.time ? `${card.time} mins` : ""}
                        videoRefIcon={card.video_link || card.url}
                        stepsRefIcon={card.steps}
                        imgRefIcon={card.activity_reference_images}
                        pdfRefIcon={card.pdf_url}
                        loading={false}
                      />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
            {/* {location.pathname.includes("/search/experts") &&
              expertCards?.map((card, cardIndex) => {
                return (
                  <div
                    className={` cursor-pointer px-2`}
                    key={`Cards-${cardIndex}`}
                    onClick={() => {
                      history.push(`/collections/cards/${card.id}`);

                      // onCardOpen(card.id);
                      history.push(`/collections/expert/cards/${card.id}`);
                    }}
                  >
                    <ImageCard
                      title={card.name || ``}
                      coverImage={
                        card.thumb_images && card.thumb_images.length
                          ? card.thumb_images[0].image_url
                          : undefined
                      }
                      status={
                        card.activity_status
                          ? card.activity_status
                          : card.card_status
                      }
                      cardType={
                        card.default_activities_id === 2 ||
                        card.default_activities_id === 3
                          ? "watch"
                          : card.default_activities_id === 4
                          ? "do"
                          : "watch"
                      }
                      isWishlist={card.is_wishlist}
                      difficultyLevel={card.difficulty}
                      timeLevel={card.time ? `${card.time} mins` : ""}
                      videoRefIcon={card.video_link || card.url}
                      stepsRefIcon={card.steps}
                      imgRefIcon={card.activity_reference_images}
                      pdfRefIcon={card.pdf_url}
                      loading={false}
                    />
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>
    </div>
  );
}
