import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Badge, notification, Tabs } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ADD_SUBSCRIPTION,
  GET_BILLING,
  PAYMENT_WORKSHOP_CART_VERIFY
} from "../../../client/graphql";
import PaymentEmptyState from "../../../components/Cards/PaymentEmptyState";
import Loader from "../../../components/common/Loader";
import { TabContainer } from "../../../components/Style/TabContainer";
import NavContext from "../../../context/NavContext";
import Images from "../../../themes/Images";
const { TabPane } = Tabs;


export default function Billings() {
  const [selectedTab, setSelectedTab] = React.useState("1");
  const [activeNav, setActiveNav] = React.useState("1");
  const [billingData, setBillingData] = useState({});
  const UserNavContext = useContext(NavContext);
  const location = useLocation();
  const history = useHistory();

  const [updatePaymentCart, { loading }] = useLazyQuery(
    PAYMENT_WORKSHOP_CART_VERIFY
  );



  const { loading: fetchingBillingData, data: billingUserData } = useQuery(GET_BILLING, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      SetBillingDate()
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const SetBillingDate = () => {
    if (!billingUserData) {
      return
    }
    let childMap = {};
    childMap[billingUserData.getBilling.Billing.name] = {
      is_renew: UserNavContext.userInfo?.is_renew,
      is_paid: UserNavContext.userInfo?.is_paid || false,
      is_parent: true,
    };
    UserNavContext.userInfo?.parentInfo?.child_details?.map((child) => {
      childMap[child.username] = {
        id: child.id,
        is_renew: child.is_renew,
        is_paid: child.is_paid,
        count: 0
      };
    });
    billingUserData.getBilling.Billing.subscription.map((subs) => {
      if (!childMap[subs.username]?.isSubs) {
        childMap[subs.username] = {
          ...subs,
          ...childMap[subs.username],
          isSubs: subs.status === "Paid" ? true : false,
          count: !subs.expiry_date
            ? 0
            : moment.unix(subs.expiry_date / 1000).isAfter(moment())
              ? 0
              : 1,
        };
      }
    });
    billingUserData.getBilling.Billing.workshop.map((wrkshp) => {
      wrkshp.registered_list.map((user) => {
        if (!childMap[user.username]) {
          childMap[user.username] = {};
        }
        if (!childMap[user.username].workshops) {
          childMap[user.username]["workshops"] = [];
        }
        childMap[user.username].workshops.push({
          ...wrkshp,
          ...user,
        });
        if (user.registration_status !== "Registered") {
          childMap[user.username].count += 1;
        }
      });
    });

    setBillingData(childMap);
    setSelectedTab(Object.keys(childMap)[0]);
  }
  React.useEffect(() => {
    SetBillingDate()

  }, [UserNavContext.userInfo?.parentInfo?.child_details])

  React.useEffect(() => {
    let searchString = location.search;
    searchString = searchString.substring(1);
    if (searchString.length > 0) {
      let searchStringArray = searchString.split("&");
      updatePaymentCart({
        variables: {
          payment_request_id: searchStringArray[2]?.split("=")[1],
        },
      });
    }
  }, []);
  const [addSubscription] = useMutation(ADD_SUBSCRIPTION, {
    onCompleted: (data) => {
      if (data.addSubscription) {
        window.location.reload()
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  return (
    <div>
      <Loader loading={fetchingBillingData} />
      {billingData && (
        <>
          <div>
            <TabContainer>
              <Tabs
                defaultActiveKey={Object.keys(billingData)[0]}
                onChange={(key) => {
                  setSelectedTab(key);
                  if (
                    billingData[key]?.is_parent ||
                    billingData[key]?.isSubs
                  ) {
                    setActiveNav("1");
                  } else if (
                    billingData[key]?.is_parent ||
                    (billingData[key]?.workshops &&
                      billingData[key]?.workshops.length !== 0)
                  ) {
                    setActiveNav("2");
                  }
                }}
                className=""
              >
                {Object.keys(billingData).map((child) => (
                  <TabPane
                    tab={
                      <div>
                        {billingData[child].first_name
                          ? billingData[child].first_name +
                          " " +
                          (billingData[child].last_name || "")
                          : child}
                        <Badge
                          className="ml-2"
                          count={billingData[child].count}
                        />
                      </div>
                    }
                    key={child}
                  ></TabPane>
                ))}
              </Tabs>
            </TabContainer>
            <div className="mt-8 rounded-md bg-ghostWhite py-4 px-8 flex">
              <div className=" w-2/12">
                <div className="nunito-bold-lg text-pureWhite">User</div>
                <div className=" mt-4 nunito-normal-lg text-pureWhite whitespace-pre-wrap">
                  {billingData[selectedTab]?.username || selectedTab}
                </div>
              </div>
              <div className=" w-2/12">
                <div className="nunito-bold-lg text-pureWhite">
                  Subscription
                </div>
                <div className=" mt-4 nunito-normal-lg text-pureWhite whitespace-pre-wrap">
                  {billingData[selectedTab]?.isSubs
                    ? billingData[selectedTab]?.subscription || "-"
                    : "-"}
                </div>
              </div>
              <div className=" w-1/4">
                <div className="nunito-bold-lg text-pureWhite">
                  Payment Method
                </div>
                <div className=" mt-4 nunito-normal-lg text-pureWhite whitespace-pre-wrap">
                  {billingData[selectedTab]?.isSubs
                    ? billingData[selectedTab]?.payment_method || "-"
                    : "-"}
                </div>
              </div>
              <div className=" w-1/4">
                <div className="nunito-bold-lg text-pureWhite">
                  Renewal due on
                </div>
                <div className=" mt-4 nunito-normal-lg text-pureWhite whitespace-pre-wrap">
                  {billingData[selectedTab]?.isSubs
                    ? billingData[selectedTab]?.expiry_date
                      ? moment
                        .unix(billingData[selectedTab]?.expiry_date / 1000)
                        .format("DD MMM YYYY")
                      : "-"
                    : "-"}
                </div>
              </div>
              {billingData[selectedTab]?.is_renew && !billingData[selectedTab]?.is_paid && (
                <div className=" w-1/5 flex items-center justify-center">
                  <div
                    className=" nunito-bold-lg text-pureWhite bg-liteGreen px-5 py-1 rounded-full inline-block cursor-pointer"
                    onClick={() => {
                      history.push(
                        `/payment/proceed${billingData[selectedTab]?.id
                          ? `?child_id=${billingData[selectedTab]?.id}`
                          : ""
                        }`
                      );
                    }}
                  >
                    Renew now
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" mt-12">
            <div className="flex ">
              {(billingData[selectedTab]?.is_parent ||
                billingData[selectedTab]?.isSubs) && (
                  <div
                    className={`mr-8 text-pureWhite nunito-bold-lg pb-2 border-b-4 ${activeNav === "1"
                      ? "border-pureWhite"
                      : "border-transparent"
                      }  cursor-pointer`}
                    onClick={() => {
                      setActiveNav("1");
                    }}
                  >
                    Subscriptions
                  </div>
                )}
              {(billingData[selectedTab]?.is_parent ||
                (billingData[selectedTab]?.workshops &&
                  billingData[selectedTab]?.workshops.length !== 0)) && (
                  <div
                    className={`text-pureWhite nunito-bold-lg pb-2 border-b-4 ${activeNav === "2"
                      ? "border-pureWhite"
                      : "border-transparent"
                      } cursor-pointer`}
                    onClick={() => {
                      setActiveNav("2");
                    }}
                  >
                    Workshops
                  </div>
                )}
            </div>
            <div>
              {activeNav === "1" && (
                <div>
                  {" "}
                  {billingData[selectedTab]?.isSubs && (
                    <div className="flex mt-5 justify-between">
                      {/* <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Payment Method</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.payment_method || "-"}
                        </div>
                      </div> */}

                      <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Subscription Type</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.subscription || "-"}
                        </div>
                      </div>
                      <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Purchase date</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.purchase_date
                            ? moment(
                              (billingData[selectedTab]?.purchase_date - 1 + 1) * 1000
                            ).format("DD MMM YYYY")
                            : "-"}
                        </div>
                      </div>
                      {/* <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Beginning Date</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.purchase_date
                            ? moment(
                              (billingData[selectedTab]?.purchase_date - 1 + 1) * 1000
                            ).format("DD MMM YYYY")
                            : "-"}
                        </div>
                      </div> */}
                      <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Renewal Date</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.expiry_date
                            ? moment.unix(
                              (billingData[selectedTab]?.expiry_date) / 1000
                            ).format("DD MMM YYYY")
                            : "-"}
                        </div>
                      </div>

                      {/* <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Expiry date</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.expiry_date
                            ? moment
                              .unix(
                                billingData[selectedTab]?.expiry_date / 1000
                              )
                              .format("DD MMM YYYY")
                            : "-"}
                        </div>
                      </div> */}
                      <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Payment</div>
                        <div className="mt-2 font-normal">
                          {billingData[selectedTab]?.payment_method || "-"}
                        </div>
                      </div>
                      <div className="nunito-bold-lg text-pureWhite mr-9">
                        <div>Amount</div>
                        <div className="mt-2 font-normal">
                          {(billingData[selectedTab]?.total_amount
                            ? `₹ ${parseFloat(
                              billingData[selectedTab]?.total_amount || 0
                            ).toFixed(2)}`
                            : 0) || "-"}
                        </div>
                      </div>
                      {/* <div className="nunito-bold-lg text-pureWhite">
                        <div>Invoice</div>
                        <div className="mt-2 font-normal">{"-"}</div>
                      </div> */}
                    </div>
                  )}
                  {billingData[selectedTab]?.is_parent &&
                    !billingData[selectedTab]?.isSubs && (
                      <div className=" mt-16 flex justify-center items-center ">
                        <PaymentEmptyState
                          title={"Enroll yourself as a learner"}
                          subTitle={
                            "Wouldn’t it be fantastic if we enabled you with skills to learn absolutely anything?"
                          }
                          buttonText="Try it for free"
                          image={Images.LearnerEmptyState}
                          onButtonClick={() => {
                            addSubscription({
                              variables: {
                                startDate: moment().format(
                                  "YYYY-MM-DD HH:mm:ss"
                                ), //String(moment().unix()),
                                subscriptionType: "Parent",
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
              {activeNav === "2" && (
                <div>
                  {" "}
                  <div className=" mt-5 ">
                    {billingData[selectedTab]?.workshops &&
                      billingData[selectedTab]?.workshops.length !== 0 && (
                        <table className="w-full">
                          <tr className="nunito-bold-lg text-pureWhite text-left ">
                            <th>Workshop Details</th>
                            <th>Purchase date</th>
                            <th>Gateway</th>
                            <th>Status</th>
                            <th>Amount</th>
                            {/* <th>Invoice</th> */}
                          </tr>
                          {billingData[selectedTab]?.workshops.map((wrkshp) => (
                            <tr className="nunito-normal-lg text-pureWhite ">
                              <td className="pt-2">
                                {wrkshp.workshop_name || "-"}
                              </td>
                              <td className="pt-2">
                                {wrkshp.purchase_date
                                  ? moment.unix((wrkshp?.purchase_date)).format(
                                    "DD MMM YYYY"
                                  )
                                  : "-"}
                              </td>
                              <td className="pt-2">
                                {wrkshp.payment_gateway || "-"}
                              </td>
                              <td className="pt-2">
                                {wrkshp.registration_status || "-"}
                              </td>
                              <td className="pt-2">
                                {(wrkshp?.total_amount
                                  ? `₹ ${parseFloat(
                                    wrkshp?.total_amount || 0
                                  ).toFixed(2)}`
                                  : 0) || "-"}
                              </td>
                              {/* <td className="pt-2">-</td> */}
                            </tr>
                          ))}
                        </table>
                      )}
                    {billingData[selectedTab]?.is_parent &&
                      (!billingData[selectedTab]?.workshops ||
                        billingData[selectedTab]?.workshops.length === 0) && (
                        <div className=" mt-16 flex justify-center items-center ">
                          <PaymentEmptyState
                            title={"Try workshops"}
                            subTitle={
                              "Learn something new from some of the best in their fields!"
                            }
                            buttonText="Go to Workshops"
                            image={Images.EnrollLearnerEmptyState}
                            onButtonClick={() => {
                              history.push("/workshops");
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
