import { useLazyQuery } from "@apollo/client";
import { notification } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ADD_TO_WISHLIST_ACTIVITY } from "../../client/graphql";
import Carousel from "../common/Carousel";
import Button from "../common/Form/Button";
import ImageCard, { CardContainer } from "./ImageCard";
import PlaylistCard from "./PlaylistCard";

const CarouselContainer = styled.div`
  width: calc(100% - 240px);
`;

export default function CardListingLayout({
  data = {},
  onOpen,
  onCardOpen,
  loading,
  isPlaylist,
  showAll = false,
  onWishlistAdded,
}) {
  const history = useHistory();
  const [addToWishList, { loading: addingWishList }] = useLazyQuery(
    ADD_TO_WISHLIST_ACTIVITY,
    {
      fetchPolicy: "network-only",
      onCompleted: (wishlistData) => {
        if (wishlistData.addToWishListV2 && onWishlistAdded) {
          let wishlistCard = [...data.cards]?.map((card, cardIndex) => {
            let cardList = { ...card };
            if (card.id === wishlistData.addToWishListV2?.id) {
              cardList.is_wishlist = true;
            }
            return cardList;
          });

          onWishlistAdded(wishlistCard);
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  return (
    <div className=" flex">
      <div>
        <PlaylistCard
          loading={loading}
          isPlaylist={isPlaylist}
          data={data}
          onClick={() => {
            if (isPlaylist) {
              history.push(`/playlists/${data.id}`);
            } else {
              history.push(`/collections/${data.id}`);
            }
          }}
        ></PlaylistCard>
      </div>
      <CarouselContainer>
        <Carousel className={" pl-6"}>
          {data.cards?.map((card, cardIndex) => {
            return (
              <div
                className={` cursor-pointer px-2`}
                key={`Cards-${cardIndex}`}
                onClick={() => {
                  onCardOpen(card.id);
                }}
              >
                <ImageCard
                  title={card.name || ``}
                  coverImage={
                    card.thumb_images && card.thumb_images.length
                      ? card.thumb_images[0].image_url
                      : undefined
                  }
                  status={
                    card.activity_status
                      ? card.activity_status
                      : card.card_status
                  }
                  cardType={
                    card.default_activities_id === 2 ||
                      card.default_activities_id === 3
                      ? "watch"
                      : card.default_activities_id === 4
                        ? "do"
                        : "watch"
                  }
                  isWishlist={card.is_wishlist}
                  showWishlist={!card.is_wishlist && !isPlaylist}
                  difficultyLevel={card.difficulty}
                  timeLevel={card.time ? `${card.time} mins` : ""}
                  videoRefIcon={card.video_link || card.url}
                  stepsRefIcon={card.steps}
                  imgRefIcon={card.activity_reference_images}
                  pdfRefIcon={card.pdf_url}
                  loading={loading}
                  onWishlist={() => {
                    if (!isPlaylist) {
                      //for collections
                      addToWishList({
                        variables: {
                          activityId: card.id,
                        },
                      });
                    }
                  }}
                />
              </div>
            );
          })}
          {!loading && !showAll && (
            <div className="px-2">
              <CardContainer>
                <div className="viewAll pt-8 px-9">
                  <div className=" text-darkGray font-Nunito text-center">
                    View all to explore lot of awesome content in this
                    collection
                  </div>
                  <div className="mt-11">
                    <div className=" w-32 mx-auto">
                      <Button
                        secondaryGhost
                        onClick={() => {
                          onOpen(data.id, data.user?.role_id);
                        }}
                      >
                        View all
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContainer>
            </div>
          )}
        </Carousel>
      </CarouselContainer>
    </div>
  );
}
