import { useQuery } from "@apollo/client";
import { notification } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { GET_EXPLORE_COLLECTION} from "../../../client/graphql";
import ExpertCard from "../../../components/Cards/ExpertCard";
import ExploreHeading from "../../../components/common/ExploreHeading";
import Images from "../../../themes/Images";
const Constants = {
  EXPERT_ROLE: 4,
  CURATOR_ROLE: 5,
};
var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
    ? 48
    : 64;
export default function ExpertContent() {
  const history = useHistory();
  const [expertCollections, setExpertCollections] = React.useState([]);
  const { data, loading } = useQuery(GET_EXPLORE_COLLECTION, {
    variables: {
      isTag: true,
      lcId: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      // search: "",
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getExploreCollection) {
        setExpertCollections(data.getExploreCollection.expert_collection);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  const onCollectionOpen = React.useCallback((collectionId, userRole) => {
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/collections/expert/${collectionId}`);
    } else {
      history.push(`/collections/${collectionId}`);
    }
  }, []);
  return (
    <div className="bg-white">
      <ExploreHeading
        title={
          <div className="flex items-center justify-center">
            Expert content{" "}
            {expertCollections.user?.role_id === Constants.EXPERT_ROLE && (
              <img
                src={Images.VerifiedIcon}
                alt="verified Icon"
                className="ml-2"
              />
            )}
          </div>
        }
        onClick={() => {
          history.push("/dashboard");
        }}
      ></ExploreHeading>
      <div className="px-12">
        <div className="grid grid-cols-3 gap-x-2 gap-y-5 mt-10 pb-5 ">
          {loading &&
            [{}, {}, {}].map((collection, collectionIndex) => (
              <div key={`ExpertCollection-${collectionIndex}`} className="px-2">
                <ExpertCard data={collection} onClick={() => {}} loading />
              </div>
            ))}
          {expertCollections.map((collection, collectionIndex) => (
            <div key={`ExpertCollection-${collectionIndex}`} className="px-2">
              <ExpertCard
                data={collection}
                onClick={() => {
                  onCollectionOpen(collection.id, collection?.user?.role_id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
