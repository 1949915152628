import gql from "graphql-tag";

export const VALIDATE_USERNAME = gql`
  query UserNameValidation($name: String) {
    userNameValidation(username: $name) {
      is_available
      new_student
      is_updated
      isProfileImage
      ChangePasswordPage
      bg_color
      profile_url
      student_details {
        id
        profile_imageUrl
        BG_color
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String, $password: String) {
    ipadLoginV2(username: $username, password: $password) {
      id
      profile_url
      bg_color
      student_details {
        token
        status
        id
        isParentAdded
        Day_FirstLogin
        Onboarded_days
        username
        is_userGuide
        userGuideData
        fname
        lname
        profile_imageUrl
        BG_color
        dob
        access_hours
        completed_learning_content
        trial_start_date
        trial_end_date
        access_status
        access_type
        show_trial
        expired
        expiry_type
      }
    }
  }
`;

export const CREATE_PASSCODE = gql`
  mutation createPasscode(
    $username: String
    $passcode: String
    $is_newLogin: Boolean
    $trial_start_date: String
  ) {
    ipadResetPasswordV2(
      username: $username
      password: $passcode
      is_newLogin: $is_newLogin
      trial_start_date: $trial_start_date
    ) {
      student_details {
        token
        username
        BG_color
        profile_imageUrl
        Day_FirstLogin
        Onboarded_days
        is_userGuide
        access_hours
        completed_learning_content
        trial_start_date
        expiry_date
        access_status
        access_type
        show_trial
        expired
      }
    }
  }
`;

export const PICK_AVATAR = gql`
  mutation updateAvatar(
    $username: String
    $profilePic: String
    $bgColor: String
  ) {
    ipadStudentImageUpload(
      username: $username
      profile_image: $profilePic
      BG_color: $bgColor
    ) {
      is_updated
    }
  }
`;

export const FORGOT_PASSCODE_REQUEST = gql`
  query forgotPasscodeRequest($username: String) {
    forgotRequest(username: $username) {
      success_message
      ChangePasswordPage
    }
  }
`;

export const ADD_SUBSCRIPTION = gql`
  mutation addSubscription($startDate: String, $subscriptionType: String) {
    addSubscription(
      trial_start_date: $startDate
      subscription_type: $subscriptionType
    ) {
      user_details {
        access_hours
        completed_learning_content
        trial_end_date
        trial_start_date
        access_status
        access_type
        show_trial
        expiry_type
        access_id
        expired
      }
    }
  }
`;

export const GET_EXPLORE_COLLECTION = gql`
  query getExploreCollection($search: String
    $lcId: Int
    $isTag: Boolean
    $page: Int
  ){
    getExploreCollection(search: $search, lc_id: $lcId, is_tag: $isTag, page: $page){
      coll_count
      ply_count
      collection {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          title
          background_image
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
          card_type
        }
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
      }
      expert_collection {
        completedCount
        id
        count
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        name
        title
        background_image
        color_code
        cards {
          id
          title
          background_image
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
          card_type
        }
      }
      user {
        id
        name
        profile_image
        bio
        role_id
        address
      }
    }
  }
`;

export const GET_EXPLORE_PLAYLIST = gql`
  query getExplorePlaylist( $search: String
    $lcId: Int
    $isTag: Boolean
    $page: Int
  ){
    getExplorePlaylist(search: $search, lc_id: $lcId, is_tag: $isTag, page: $page){
      coll_count
      ply_count
      playlist {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      expert_playlist {
        completedCount
        id
        count
        name
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      user {
        id
        name
        profile_image
        bio
        role_id
        address
      }
    }
  }
`;

export const GET_STUDENT_IMAGES = gql`
  query getStudentImages {
    getStudentImages {
      Images {
        student_images {
          url_type
          character
          image_url
          bg_colour
        }
      }
    }
  }
`;

export const GET_COLLECTION_DETAILS = gql`
  query ipadActivityList(
    $collections_id: ID
    $sort: String
    $search: String
    $filter: [FilterInputType]
  ) {
    ipadActivityList(
      collections_id: $collections_id
      sort: $sort
      search: $search
      filter: $filter
    ) {
      total_count
      collection_details {
        id
        user {
          id
          name
          role_id
        }
        name
        is_active
        total_activity
        description
        completion_msg
      }

      activity_details {
        id
        name
        activity_state
        title
        notes
        breif_desc
        long_desc
        difficulty
        time
        time_periods
        total_time
        paused_time
        image_source_text
        image_source_link
        video_id
        video_link
        video_credit_text
        video_credit_link
        pdf_url
        pdf_name
        pdf_source_url
        pdf_source_text
        status
        is_wishlist
        activity_status
        default_activities_id
        trailer_url
        trailer_credit_url
        trailer_credit_text
        # prerequisite_details {
        #   id
        #   name
        #   activity_status
        #   is_wishlist
        #   thumb_images {
        #     id
        #     image_url
        #   }
        #   image_source_link
        # }
        background_images {
          id
          image_url
          is_active
        }
        thumb_images {
          id
          image_url
          is_active
        }
        isSkipped
        completed_student_count
        total_time
        total_count
        user_activity_type
        url
        steps
        activity_reference_images
      }
    }
  }
`;

export const GET_FILTER_DATA = gql`
  query getFilterData($cardCollectonId: ID, $libraryCollectionId: ID) {
    getFilterData(
      activity_collection_id: $cardCollectonId
      library_collection_id: $libraryCollectionId
    ) {
      interest_details {
        id
        interest_categories_id
        name
        profile_image
        desc
        is_active
        lc_name
        lc_count
        activity_name
        activity_count
        library_name
        library_count
        do
        read
        watch
        listen
        do_count
        read_count
        watch_count
        listen_count
      }
      interest_category_name {
        name
      }
      difficulty_details {
        age_groups {
          id
          age
          is_active
        }
        age_dificulty {
          id
          dificulty
          is_active
        }
      }
      time {
        min
        max
      }
      contentType {
        id
        name
      }
    }
  }
`;

export const GET_PLAYLIST_DETAILS = gql`
  query ipadPlaylistListV2(
    $playlistId: ID
    $sort: String
    $search: String
    $filter: [FilterInputType]
  ) {
    ipadPlaylistListV2(
      playlist_id: $playlistId
      sort: $sort
      search: $search
      filter: $filter
    ) {
      total_playlist
      is_schedule
      playlist_details {
        id
        name
        title
        source_text
        source_link
        background_image
        is_active
        is_wishlist
        playlist_status
        total_no_cards
        color_code
        user {
          id
          name
          role_id
        }
        description
        completion_msg
      }
      all_activities {
        id
        name
        is_wishlist
        activity_status
        activities_thumb_images {
          id
          image_url
        }
        card_id
        difficulty
        time
        url
        steps
        background_images
        activity_reference_images
        default_activities_id
        video_link
        url
        pdf_url
        pdf_name
        position
      }
    }
  }
`;

export const ADD_TO_WISHLIST = gql`
  query addToWishListV2($playlistId: ID) {
    addToWishListV2(playlist_id: $playlistId) {
      is_updated
    }
  }
`;

export const ADD_ALL_TO_SCHEDULE = gql`
  query addAllToSchedule($playlistId: ID) {
    addToSchedulePlaylistLearner(playlist_id: $playlistId) {
      prerequisite_details {
        id
        name
        card_details {
          id
          name
          card_id
          is_wishlist
          library_status
          activity_status
          thumb_images {
            id
            image_url
          }
          default_libraries_id
        }
      }
      is_schedule
    }
  }
`;

export const GET_NEAR_LC = gql`
  query getNearLC($lat: String, $long: String) {
    getNearLC(lat: $lat, long: $long) {
      is_available
      learningCenterDetails {
        id
        name
        displayName
        email
        profile_image
      }
      contentCreatorDetails {
        contentCreator_details {
          id
          role_id
          name
        }
      }
      interest_categories {
        id
        name
      }
    }
  }
`;

export const JOURNALV3 = gql`
  query journalv3($date: String) {
    Journalv3(date: $date) {
      overall_journal_list {
        date
        card_details {
          type
          notes
          image
          feedback_id
          id
          card_id
          default_activities_id
          feedback_desc
          feedback_state
          feedback_voice
          playlist_id
          name
          activity_status
          lc_name
          createdAt
          journal_state
          video_view
          steps_view
          total_time_spent
          user {
            id
            name
            bio
            profile_image
            role_id
          }
          pictures {
            id
            type
            image
            disp_time
            createdAt
            notes
          }
          student_response
          journal_feedback {
            feedback_type
            id
            lc_id
            lc_name
            feedback
            journal_id
            student_response
          }
        }
        workshop_details {
          id
          workshop_id
          workshop_name
          featured_image {
            image_url
          }
          trailer_link
          description
        }
      }
    }
  }
`;

export const ADULT_JOURNAL = gql`
  query JournalLearner($date: String) {
    JournalLearner(date: $date) {
      overall_journal_list {
        date
        card_details {
          type
          notes
          image
          feedback_id
          id
          card_id
          default_activities_id
          feedback_desc
          feedback_state
          feedback_voice
          playlist_id
          name
          activity_status
          lc_name
          createdAt
          journal_state
          video_view
          steps_view
          total_time_spent
          user {
            id
            name
            bio
            profile_image
            role_id
          }
          pictures {
            id
            type
            image
            disp_time
            createdAt
            notes
          }
          student_response
          journal_feedback {
            feedback_type
            id
            lc_id
            lc_name
            feedback
            journal_id
            student_response
          }
        }
        workshop_details {
          id
          workshop_id
          workshop_name
          featured_image {
            image_url
          }
          trailer_link
          description
        }
      }
    }
  }
`;

export const CALENDAR_VIEW = gql`
  query CALENDAR_VIEW($date: String) {
    iPadCalenderView(date: $date) {
      calender_details {
        activity
        date
        journal_image_notes_state
        default_activities_id
        libraries
        playlist
      }
    }
  }
`;

export const GET_COLLECTION_ACTIVITY_DETAILS = gql`
  query getActivityDetail($activityId: ID) {
    ipadActivityDetailV2(activity_id: $activityId) {
      collection_details {
        id
        name
      }
      playlist_details {
        id
        name
      }
      activity_details {
        id
        name
        activity_status
      }
      playlist_prerequisite_details {
        id
        name
        playlist_status
        is_wishlist
        background_image
        card_details {
          id
        }
      }
      prerequisite_details {
        id
        name
        activity_status
        is_wishlist
        activities_id
        thumb_images {
          id
          image_url
        }
        image_source_link
      }
      activity_in_details {
        id
        name
        url
        user_id
        user_activity_type
        is_wishlist
        difficulty
        default_activities_id
        playlist_id
        activity_status
        activity_state
        trailer_url
        trailer_credit_url
        trailer_credit_text

        thumb_images {
          id
          image_url
        }
        background_images {
          id
          image_url
        }
        is_wishlist
        breif_desc
        long_desc
        safety_desc
        video_link
        video_credit_text
        video_credit_link
        image_source_link
        image_source_text
        safety
        activity_nodes_images {
          id
          image
          notes
          type
          createdAt
        }
        steps
        steps_source_text
        steps_source_link
        paused_time
        time
        total_time
        time_periods
        pdf_url
        pdf_name
        pdf_source_url
        pdf_source_text

        feedbacklist {
          id
          student_response
        }
        user {
          id
          name
          role_id
        }
      }
      is_schedule
      steps {
        id
        image
        title
        desc
        position
        is_active
      }
      materials_required {
        id
        name
        image
        is_active
        position
      }
      images {
        id
        image_url
      }
      recommended {
        id
        activities_id
        title
        link
      }
      estimated_time
    }
  }
`;

export const ADD_TO_SCHEDULE_ACTIVITY = gql`
  query addToScheduleV3($activityId: ID, $collectionId: ID, $lc_id: ID) {
    addToScheduleV3(
      activity_id: $activityId
      collection_id: $collectionId
      lc_id: $lc_id
    ) {
      is_schedule
      prerequisites {
        id
        activity_name
      }
    }
  }
`;

export const ADD_TO_WISHLIST_ACTIVITY = gql`
  query addToWishListV2($activityId: ID) {
    addToWishListV2(activity_id: $activityId) {
      id
      is_updated
    }
  }
`;

export const START_PAUSE_ACTIVITY = gql`
  mutation StartAndPauseActivity(
    $activityId: ID
    $start: String
    $pause: String
    $estimated: String
    $lc_id: ID
  ) {
    activityStartAndPauseV2(
      activity_id: $activityId
      start_time: $start
      pause_time: $pause
      estimated_time: $estimated
      lc_id: $lc_id
    ) {
      is_updated
      estimated_time
      is_start
    }
  }
`;

export const ADD_NOTES_IMAGES = gql`
  mutation addImages(
    $activityId: ID
    $library_id: ID
    $notes: String
    $imageURL: [String]
    $type: String
  ) {
    ipadAddNotesImagesV2(
      activity_id: $activityId
      library_id: $library_id
      notes: $notes
      image_url: $imageURL
      type: $type
    ) {
      is_added
    }
  }
`;

export const ADD_JOURNAL = gql`
  mutation addJournal(
    $lcId: ID
    $notes: String
    $imageURL: String
    $type: String
  ) {
    AddJournalNoteImageV2(
      image_url: $imageURL
      notes: $notes
      type: $type
      lc_id: $lcId
    ) {
      is_added
    }
  }
`;

export const FEEDBACK_RESPONSE = gql`
  mutation FEEDBACK_RESPONSE($id: ID, $response: String) {
    StudentResponse(feedback_id: $id, response: $response) {
      status
    }
  }
`;

export const FINISH_ACTIVITY = gql`
  mutation finishActivity(
    $activityId: ID
    $playlistId: ID
    $finish: String
    $lc_id: ID
  ) {
    finishActivityV3(
      activity_id: $activityId
      finish_time: $finish
      playlist_id: $playlistId
      lc_id: $lc_id
    ) {
      finish_details {
        is_finish
        success_message
        time
        name
        recommended {
          id
          activities_id
          title
          link
          is_active
        }
        all_tags {
          interests {
            id
            name
            profile_image
            desc
            data_point
            isSelected
          }
          data_points {
            id
            name
            profile_image
            desc
            data_point
            isSelected
          }
          data_types {
            id
            name
            data_points {
              id
              name
              profile_image
              desc
              data_point
              isSelected
            }
          }
        }
      }
      estimated_time
    }
  }
`;

export const ADD_FEEDBACK = gql`
  query studentFeedbackV2($activityId: ID, $like: ID, $difficulty: ID) {
    studentFeedbackV2(
      activity_id: $activityId
      like: $like
      difficulty: $difficulty
    ) {
      is_updated
    }
  }
`;

export const GET_INTEREST = gql`
  query getInterest($id: ID) {
    getInterest(interest_categories_id: $id) {
      contentType {
        id
        name
      }
      interest_category_name {
        name
      }
      # difficulty_details
      # time
      interest_details {
        id
        interest_categories_id
        name
        profile_image
        desc
        is_active
        lc_name
        lc_count
        activity_name
        activity_count
        library_name
        library_count
        do
        read
        watch
        listen
        do_count
        read_count
        watch_count
        listen_count
      }
    }
  }
`;

export const GET_INTEREST_VIEW = gql`
  query ipadInterestView($id: ID, $lc_id: ID) {
    iPadInterestView(tag_id: $id, lc_id: $lc_id) {
      tags_details {
        tag {
          id
          name
          profile_image
          desc
        }
        card_details {
          default_activities_id
          id
          name
          card_id
          library_status
          pdf_url
          activity_status
          is_wishlist
          activities_thumb_images {
            id
            image_url
          }
          default_libraries_id
          libraries_thumb_images {
            id
            image_url
          }
          difficulty
          time
          suitable_for
          url
          video_link
          steps
          activity_reference_images
        }
      }
    }
  }
`;

export const GET_TAG_VIEW = gql`
  query ipadTagView($id: ID, $lc_id: ID) {
    iPadTagView(tag_id: $id, lc_id: $lc_id) {
      tags_details {
        tag {
          id
          name
          profile_image
          desc
        }
        card_details {
          default_activities_id
          id
          name
          card_id
          library_status
          pdf_url
          activity_status
          is_wishlist
          activities_thumb_images {
            id
            image_url
          }
          default_libraries_id
          libraries_thumb_images {
            id
            image_url
          }
          difficulty
          time
          suitable_for
          url
          video_link
          steps
          activity_reference_images
        }
      }
    }
  }
`;

export const GET_BREADCRUMB_FOR_COLLECTION = gql`
  query ipadActivityList($collections_id: ID) {
    ipadActivityList(collections_id: $collections_id) {
      collection_details {
        id
        name
      }
    }
  }
`;

export const GET_BREADCRUMB_FOR_PLAYLIST = gql`
  query ipadPlaylistListV2($playlistId: ID) {
    ipadPlaylistListV2(playlist_id: $playlistId) {
      playlist_details {
        id
        name
        title
      }
    }
  }
`;
export const GET_KANBAN_LIST = gql`
  query getKanbanListV3($lc_id: ID) {
    getKanbanListV3(lc_id: $lc_id) {
      i_want_to_learn {
        id
        name
        activity_status
        activities_thumb_images {
          id
          image_url
          activities_id
          is_active
        }
        card_id
        default_libraries_id
        default_activities_id
        playlist_status
        paused_time
        difficulty
        is_wishlist
        background_image
        playlist_cards {
          id
          name
          activity_status
          is_wishlist
          card_id
          background_image
        }
        libraries_thumb_images {
          id
          image_url
        }
      }
      totay_learn {
        id
        name
        activity_status
        card_id
        default_libraries_id
        default_activities_id
        activities_thumb_images {
          id
          image_url
          activities_id
          is_active
        }
        libraries_thumb_images {
          id
          image_url
        }
        playlist_status
        paused_time
        difficulty
        is_wishlist
        background_image
        playlist_cards {
          id
          name
          activity_status
          is_wishlist
          card_id
          background_image
        }
      }
      yet_finish {
        id
        name
        activity_status
        card_id
        default_activities_id
        activities_thumb_images {
          id
          image_url
          activities_id
          is_active
        }
        libraries_thumb_images {
          id
          image_url
        }
        playlist_status
        paused_time
        difficulty
        is_wishlist
        background_image
        playlist_cards {
          id
          name
          activity_status
          is_wishlist
          card_id
          background_image
        }
      }
    }
  }
`;
export const REMOVE_TO_SCHEDULE_BY_ACTIVITY = gql`
  query removeToScheduleV2($activity_id: ID, $playlist_id: ID) {
    removeToScheduleV2(activity_id: $activity_id, playlist_id: $playlist_id) {
      is_updated
    }
  }
`;

export const GET_WISHLIST = gql`
  query IpadWishListV2($lc_id: ID) {
    IpadWishListV2(lc_id: $lc_id) {
      cards {
        id
        name
        is_wishlist
        steps
        activity_status
        thumb_images {
          id
          image_url
        }
        difficulty
        time
        activity_reference_images
        card_type
        no_cards
        default_activities_id
        suitable_for_id
        activity_state
        steps
        pdf_url
        default_libraries_id
        no_cards
        user_role
      }
      exports_cards {
        id
        name
        is_wishlist
        steps
        activity_status
        thumb_images {
          id
          image_url
        }
        difficulty
        time
        activity_reference_images
        card_type
        no_cards
        default_activities_id
      }
      playlist {
        id
        completedCount
        name
        title
        background_image
        color_code
        count
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
          time
        }
      }
      expert_playlist {
        id
        name
        title
        background_image
        color_code
        count
        completedCount
        user {
          id
          role_id
          name
        }
      }
      coll_count
      ply_count
      card_count
      expert_count
      coll_count
      expert_card_count
    }
  }
`;

export const SCHEDULE_STATE_CHANGE = gql`
  query scheduleStateChangeV2($activity_id: ID, $playlist_id: ID, $state: ID) {
    scheduleStateChangeV2(
      activity_id: $activity_id
      playlist_id: $playlist_id
      state: $state
    ) {
      is_updated
    }
  }
`;
export const GET_OVERALL_PROGRESS = gql`
  query IpadOverallProgressNew {
    IpadOverallProgressNew {
      student_details {
        fname
        username
        lname
        dob
        parent_name
        parent_email
      }
      InterestCategorys {
        id
        name
        background_image
        total_activites
        activites_completed
      }
      completed_learning_contents {
        cards
        expert_curator
        learning_paths
      }
      total_hours_spent {
        do_cards_hours
        watch_cards_hours
        total_hours
      }
      dificulity_level {
        easy
        medium
        hard
      }
      avg_time
      avg_activity
    }
  }
`;

export const GET_INTEREST_CATEGORY = gql`
  query iPadgetInterestCategory {
    iPadgetInterestCategory {
      intrest_cat {
        id
        name
        is_active
        total_interest
        background_image
        color_code
        template
        is_added
      }
    }
  }
`;
export const WORKSHOP_LIST = gql`
  query ($id: ID, $search: String) {
    ipadWorkshopList(lc_id: $id, search: $search) {
      workshop_details {
        id
        workshop_name
        featured_image {
          image_url
        }
        workshop_date
        workshop_time
        repeats_day
        end_date
        Dates {
          duration
          duration_type
          workshop_Date
        }
        interest_details {
          id
          name
        }
        content_creator {
          id
          name
          profile_image
        }
      }
      featured_workshops {
        id
        workshop_name
        featured_image {
          image_url
        }
        Dates {
          duration
          duration_type
          workshop_Date
        }
        workshop_date
        workshop_time
        workshop_title
        repeats_day
        end_date
        workshopDates
        content_creator {
          id
          name
          profile_image
        }
      }
      past_workshop {
        id
        workshop_name
        featured_image {
          image_url
        }
        workshop_date
        workshop_time
        repeats_day
        end_date
        interest_details {
          id
          name
        }
        repeatDays
        Dates {
          duration
          duration_type
          workshop_Date
        }
        content_creator {
          id
          name
          profile_image
        }
      }
    }
  }
`;

export const VIEW_WORKSHOP = gql`
  query VIEW_WORKSHOP(
    $id: ID
    $isNotification: Boolean
    $isReferenceNotification: Boolean
  ) {
    ipadViewWorkshop(
      workshop_id: $id
      is_notification: $isNotification
      is_reference_notification: $isReferenceNotification
    ) {
      attendedList {
        workshop_id
        workshop_date
        session
      }
      registration_status
      workshop_details {
        available_seats
        workshop_registration_status
        id
        workshop_name
        description
        featured_image {
          image_url
        }
        skills_required
        reference_link
        learning_outcome
        workshop_date
        workshop_time
        repeats_day
        end_date
        workshop_schedule_date
        workshop_zoom {
          id
          password
          join_url
        }
        interest_details {
          id
          name
        }
        materials_details {
          id
          name
          image
        }
        content_creator {
          id
          name
          last_name
          displayName
          profile_image
        }
        status
        workshop_Date
        workshopDates
        repeatDays
        workshop_plan_id
        repeats_type
        workshopDate
        trailer_link
        pdf_name
        is_common_timing
        Dates {
          workshop_Date
          start_time
          duration
          duration_type
          end_time
        }
        workshop_duration
        workshop_duration_type
        workshop_notes {
          image
          notes
          type
          createdAt
        }
      }
    }
  }
`;

export const GET_STUDENT_GRAPH = gql`
  query StudentGraph {
    StudentGraph {
      graph_data {
        name
        data_points
        values_current_month
        values_previous_month
      }
    }
  }
`;

export const GET_STUDENT_WEEKLY_PROGRESS = gql`
  query iPadStudentWeeklyProgess($date: String) {
    iPadStudentWeeklyProgess(date: $date) {
      weekly_details {
        day
        time_spent
        cards_count
      }
      total_time
      total_days
    }
  }
`;

export const GET_DATA_POINTS = gql`
  query getDataPoint($data_grade_types_id: ID) {
    getDataPoint(data_grade_types_id: $data_grade_types_id, is_student: true) {
      data_grade_name {
        name
      }
      data_point_details {
        id
        name
        desc
      }
    }
  }
`;

export const GET_CARD_DETAILS = gql`
  query iPadgetCardsDetails(
    $card: Boolean
    $expert_curator: Boolean
    $playlist: Boolean
  ) {
    iPadgetCardsDetails(
      card: $card
      expert_curator: $expert_curator
      playlist: $playlist
    ) {
      cards {
        user {
          id
          name
          profile_image
          role_id
        }
        cards_detail {
          id
          name
          default_activities_id
          thumb_images {
            id
            image_url
          }
        }
      }
      PlayList {
        user {
          id
          name
          profile_image
          role_id
        }
        playlist_detail {
          id
          title
          background_image
          cards_completed_count
        }
        expertCurator_detail {
          id
          title
          background_image
          cards_completed_count
        }
      }
      expert_curator {
        user {
          id
          name
          profile_image
          role_id
        }
        cards_detail {
          id
          name
          default_activities_id
          thumb_images {
            id
            image_url
          }
        }
      }
    }
  }
`;

export const ADD_PROFILE_INTERESTS = gql`
  mutation addProfileInterest($categories: [ID]) {
    studentAddInterestCategory(interest_categories_id: $categories) {
      is_added
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query iPadNotificationV2 {
    iPadNotificationV2 {
      status
      completed_workshops {
        id
        createdAt
        comment
        pdf_name
        reference_link
        workshop_name
      }
      workshops {
        createdAt
        comment
        workshop_id
        workshop_name
      }
      access {
        expiry_type
        access_status
        access_end_date
        expired
      }
    }
  }
`;

export const GET_FREQUENT_TAGS = gql`
  query getFrequentTags($lcId: ID) {
    getFrequentTags(lc_id: $lcId) {
      tags {
        id
        name
      }
      workshop_tags {
        id
        name
      }
    }
  }
`;

export const GET_SEARCH_DATA = gql`
  query getExploreSearch(
    $search: String
    $lc_id: Int
    $is_tag: Boolean
    $is_interest: Boolean
    $page: Int
  ) {
    getExploreSearch(
      search: $search
      lc_id: $lc_id
      is_tag: $is_tag
      is_interest: $is_interest
      page: $page
    ) {
      card_count
      expert_card_count
      coll_count
      ply_count
      total_card_count
      tot_expert_card_count
      interest_cat {
        id
        background_image
        name
      }
      featured_workshops {
        workshop_title
        workshop_details {
          workshop_title
          workshop_duration
          workshop_duration_type
          Dates {
            workshop_Date
            start_time
            duration
            duration_type
          }
          repeatDays
          workshop_plan_id
          workshopDates
          description
          available_seats
          workshop_time
          learning_outcome
          skills_required
          reference_link
          id
          workshop_name
          featured_image {
            image_url
          }
          interest_details {
            id
            name
          }
          workshop_date
          content_creator {
            id
            profile_image
            name
          }
        }
      }
      workshops {
        workshop_title
        workshop_duration
        workshop_duration_type
        Dates {
          workshop_Date
          start_time
          duration
          duration_type
        }
        repeatDays
        workshop_plan_id
        workshopDates
        description
        available_seats
        workshop_time
        learning_outcome
        skills_required
        reference_link
        id
        workshop_name
        featured_image {
          image_url
        }
        interest_details {
          id
          name
        }
        workshop_date
        content_creator {
          id
          profile_image
          name
        }
      }
      past_workshops {
        workshop_title
        workshop_duration
        workshop_duration_type
        Dates {
          workshop_Date
          start_time
          duration
          duration_type
        }
        repeatDays
        workshop_plan_id
        workshopDates
        description
        available_seats
        workshop_time
        learning_outcome
        skills_required
        reference_link
        id
        workshop_name
        featured_image {
          image_url
        }
        interest_details {
          id
          name
        }
        workshop_date
        content_creator {
          id
          profile_image
          name
        }
      }
      user {
        id
        name
        profile_image
        bio
        role_id
        address
      }
      total_card_count
      tot_expert_card_count
      cards {
        id
        default_activities_id
        name
        pdf_url
        is_wishlist
        activity_status
        url
        activity_reference_images
        steps
        time
        thumb_images {
          id
          image_url
          activities_id
        }
        image_source_link
        suitable_for
        difficulty
      }
      exports_cards {
        id
        default_activities_id
        name
        pdf_url
        is_wishlist
        activity_status
        url
        activity_reference_images
        steps
        time
        thumb_images {
          id
          image_url
        }
        image_source_link
        suitable_for
        difficulty
      }
      collection {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      expert_collection {
        completedCount
        id
        count
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      playlist {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      age_groups {
        id
        age
      }
      expert_playlist {
        completedCount
        id
        count
        name
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
    }
  }
`;

export const GET_INTEREST_TAG_CARD = gql`
  query getInterestTagCard(
    $search: String
    $lc_id: Int
    $is_tag: Boolean
    $is_interest: Boolean
    $page: Int
  ) {
    getInterestTagCard(
      search: $search
      lc_id: $lc_id
      is_tag: $is_tag
      is_interest: $is_interest
      page: $page
    ) {
      card_count
      expert_card_count
      coll_count
      ply_count
      total_card_count
      tot_expert_card_count
      interest_cat {
        id
        background_image
        name
      }
      featured_workshops {
        workshop_title
        workshop_details {
          workshop_title
          workshop_duration
          workshop_duration_type
          Dates {
            workshop_Date
            start_time
            duration
            duration_type
          }
          repeatDays
          workshop_plan_id
          workshopDates
          description
          available_seats
          workshop_time
          learning_outcome
          skills_required
          reference_link
          id
          workshop_name
          featured_image {
            image_url
          }
          interest_details {
            id
            name
          }
          workshop_date
          content_creator {
            id
            profile_image
            name
          }
        }
      }
      workshops {
        workshop_title
        workshop_duration
        workshop_duration_type
        Dates {
          workshop_Date
          start_time
          duration
          duration_type
        }
        repeatDays
        workshop_plan_id
        workshopDates
        description
        available_seats
        workshop_time
        learning_outcome
        skills_required
        reference_link
        id
        workshop_name
        featured_image {
          image_url
        }
        interest_details {
          id
          name
        }
        workshop_date
        content_creator {
          id
          profile_image
          name
        }
      }
      past_workshops {
        workshop_title
        workshop_duration
        workshop_duration_type
        Dates {
          workshop_Date
          start_time
          duration
          duration_type
        }
        repeatDays
        workshop_plan_id
        workshopDates
        description
        available_seats
        workshop_time
        learning_outcome
        skills_required
        reference_link
        id
        workshop_name
        featured_image {
          image_url
        }
        interest_details {
          id
          name
        }
        workshop_date
        content_creator {
          id
          profile_image
          name
        }
      }
      user {
        id
        name
        profile_image
        bio
        role_id
        address
      }
      total_card_count
      tot_expert_card_count
      cards {
        id
        default_activities_id
        name
        pdf_url
        is_wishlist
        activity_status
        url
        activity_reference_images
        steps
        time
        thumb_images {
          id
          image_url
          activities_id
        }
        image_source_link
        suitable_for
        difficulty
      }
      exports_cards {
        id
        default_activities_id
        name
        pdf_url
        is_wishlist
        activity_status
        url
        activity_reference_images
        steps
        time
        thumb_images {
          id
          image_url
        }
        image_source_link
        suitable_for
        difficulty
      }
      collection {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      expert_collection {
        completedCount
        id
        count
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      playlist {
        completedCount
        id
        count
        name
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
      age_groups {
        id
        age
      }
      expert_playlist {
        completedCount
        id
        count
        name
        user {
          id
          name
          profile_image
          bio
          role_id
          address
        }
        title
        background_image
        color_code
        cards {
          id
          default_activities_id
          name
          pdf_url
          is_wishlist
          activity_status
          url
          activity_reference_images
          steps
          time
          thumb_images {
            id
            image_url
          }
          image_source_link
          suitable_for
          difficulty
        }
      }
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editProfile(
    $firstName: String
    $lastName: String
    $dob: String
    $profile_image: String
    $BG_color: String
  ) {
    EditStudentAboutMe(
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      profile_image: $profile_image
      BG_color: $BG_color
    ) {
      is_updated
    }
  }
`;

export const CHANGE_PROFILE_PASSWORD = gql`
  mutation changePassword($oldPassword: String, $password: String) {
    ipadChangePassword(oldpassword: $oldPassword, password: $password) {
      is_password_changed
      status
      message
      success_message
    }
  }
`;
export const WORKSHOP_INTERESTED = gql`
  mutation WORKSHOP_INTERESTED($id: ID) {
    workshopImInterested(workshop_id: $id) {
      is_updated
      workshop_details {
        id
        workshop_name
        description
        featured_image {
          image_url
        }
        workshop_time
        workshop_date
        end_date
        repeats_day
        interest_details {
          id
          name
        }
        materials_details {
          id
          name
        }
      }
    }
  }
`;
export const WORKSHOP_NOTES = gql`
  mutation WORKSHOP_NOTES(
    $workshop_id: ID
    $notes: String
    $imageURL: [String]
    $type: String
  ) {
    ipadAddNotesImagesV2(
      workshop_id: $workshop_id
      notes: $notes
      image_url: $imageURL
      type: $type
    ) {
      is_added
    }
  }
`;
export const WORKSHOP_ATTENDANCE = gql`
  mutation WORKSHOP_ATTENDANCE($id: ID) {
    updateAttendedStatus(workshop_id: $id) {
      is_updated
    }
  }
`;

export const IPADNOTIFICATION = gql`
  query {
    iPadNotificationV2 {
      workshops {
        id
        workshop_name
      }
      completed_workshops {
        comment
        reference_link
        pdf_name
      }
    }
  }
`;

export const PARENT_VIEW_WORKSHOP = gql`
  query PARENT_VIEW_WORKSHOP($id: ID) {
    viewWorkshop(id: $id) {
      Price
      GST
      workshop_details {
        id
        workshop_id
        workshop_name
        featured_image {
          image_url
          position
        }
        trailer_link
        description
        workshop_type
        workshop_amount
        prerequisites
        maximum_participants
        minimum_participants
        skills_required
        learning_outcome
        reference_link
        status
        workshop_duration_type
        workshop_time
        workshop_duration
        workshop_date
        workshop_EndTime
        workshopDate
        workshopDates
        workshop_plan_id
        repeats_type
        end_date
        repeats_day
        repeatDays
        occurance
        no_of_days_workshop
        duration
        workshop_schedule_date
        available_seats
        age_group
        workshop_session
        workshopDate
        InterestedChild {
          id
          username
          is_attended
          registration_type
        }
        RegisteredChild {
          id
          username
          fname
          is_attended
          registration_type
        }
        is_cart
        cart_age_group {
          id
          age_group_id
        }
        is_common_timing
        zoom_meeting
        Dates {
          workshop_Date
          start_time
          end_date
          end_time
          duration
          duration_type
        }
        ListnonConsecutive {
          workshop_Date
          start_time
          end_date
          end_time
          duration
          duration_type
        }
        InProgress
        IsPast
        workshop_registration_status
        user_details {
          id
          name
          last_name
          displayName
          email
          address
          phone_no
          role_id
          profile_image
          bio
        }
      }
      interest_details {
        interest_categories_id
        interest_categories_name
        interests {
          id
          name
        }
      }
      Age_group {
        age
        age_group_id
        age_group_difficulty
        age_group_difficulty_id
      }
      Materials_required {
        id
        name
        image
        position
      }
      Workshop_tags {
        id
        name
      }
      registered_student_count
      is_registered
      cart_count
    }
  }
`;

export const VIEW_CART = gql`
  query VIEW_CART {
    viewCart {
      interest_details {
        interest_categories_id
        interest_categories_name
        interests {
          id
          name
        }
      }
      Age_group {
        id
        workshop_id
        workshop_draft_id
        age_group_id
        age_group_difficulty_id
        age_group_difficulty
        age
        is_active
      }
      Materials_required {
        id
        workshop_id
        workshop_draft_id
        name
        image
        position
      }
      Workshop_tags {
        id
        name
      }
      notifications {
        id
        notification_id
        status
        comments
      }
      registered_student_count
      is_registered
      cart_count
      cart {
        id
        workshop_details {
          id
          role_id
          workshop_draft_id
          workshop_id
          user_details {
            id
            name
            last_name
            displayName
            email
            address
            phone_no
            role_id
            profile_image
            bio
          }
          workshop_name
          featured_image {
            image_url
            position
            type
          }
          trailer_link
          description
          workshop_type
          workshop_amount
          prerequisites
          maximum_participants
          minimum_participants
          skills_required
          learning_outcome
          reference_link
          status
          workshop_duration_type
          is_active
          workshop_time
          workshop_duration
          workshop_date
          workshop_EndTime
          currency
          pdf_name
          workshop_Date
          workshopDates
          workshop_plan_id
          repeats_type
          end_date
          repeats_day
          repeatDays
          occurance
          no_of_days_workshop
          duration
          workshop_schedule_date
          available_seats
          age_group
          workshop_session
          workshopDate
          InterestedChild {
            id
            fname
            lname
            username
            is_attended
            registration_type
          }
          RegisteredChild {
            id
            fname
            lname
            username
            is_attended
            registration_type
          }
          is_cart
          cart_age_group {
            id
            age_group_id
            is_active
          }
          is_common_timing
          zoom_meeting
          Dates {
            workshop_Date
            start_time
            end_date
            end_time
            duration
            duration_type
          }
          ListnonConsecutive {
            workshop_Date
            start_time
            end_date
            end_time
            duration
            duration_type
          }
          InProgress
          primary_host
          IsPast
          workshop_registration_status
        }
        parent_id
        learners {
          id
          fname
          lname
          username
          is_attended
          registration_type
        }
        total_workshop
        total_amount
        payment_resonse
        txn_id
        status
        save_for_later
        is_delete
        registration_type
        is_Edit
      }
      save_for_later {
        id
        workshop_details {
          id
          role_id
          workshop_draft_id
          workshop_id
          user_details {
            id
            name
            last_name
            displayName
            email
            address
            phone_no
            role_id
            profile_image
            bio
          }
          workshop_name
          featured_image {
            image_url
            position
            type
          }
          trailer_link
          description
          workshop_type
          workshop_amount
          prerequisites
          maximum_participants
          minimum_participants
          skills_required
          learning_outcome
          reference_link
          status
          workshop_duration_type
          is_active
          workshop_time
          workshop_duration
          workshop_date
          workshop_EndTime
          currency
          pdf_name
          workshop_Date
          workshopDates
          workshop_plan_id
          repeats_type
          end_date
          repeats_day
          repeatDays
          occurance
          no_of_days_workshop
          duration
          workshop_schedule_date
          available_seats
          age_group
          workshop_session
          workshopDate
          InterestedChild {
            id
            fname
            lname
            username
            is_attended
            registration_type
          }
          RegisteredChild {
            id
            fname
            lname
            username
            is_attended
            registration_type
          }
          is_cart
          cart_age_group {
            id
            age_group_id
            is_active
          }
          is_common_timing
          zoom_meeting
          Dates {
            workshop_Date
            start_time
            end_date
            end_time
            duration
            duration_type
          }
          ListnonConsecutive {
            workshop_Date
            start_time
            end_date
            end_time
            duration
            duration_type
          }
          InProgress
          primary_host
          IsPast
          workshop_registration_status
        }
        parent_id
        learners {
          id
          fname
          lname
          username
          is_attended
          registration_type
        }
        total_workshop
        total_amount
        payment_resonse
        txn_id
        status
        save_for_later
        is_delete
        registration_type
        is_Edit
      }
      save_for_later_count
      GST
      Price
      ParentDetails {
        id
        name
        last_name
        displayName
        email
        address
        phone_no
        role_id
        profile_image
        bio
      }
      adultDetails {
        id
        name
        last_name
        displayName
        email
        address
        phone_no
        role_id
        profile_image
        bio
      }
      AgeStatus
    }
  }
`;

export const PAYMENT_WORKSHOP_CART = gql`
  mutation payWorkshopCart($cart_id: [ID], $amount: Float, $token_id: String) {
    payWorkshopCart(cart_id: $cart_id, amount: $amount, token_id: $token_id) {
      error
      msg
      is_updated
      data {
        id
        longurl
        redirect_url
      }
    }
  }
`;

export const PAY_WORKSHOP_CART = gql`
  mutation paymentWorkshopCart($cart_id: [ID], $amount: Float) {
    paymentWorkshopCart(cart_id: $cart_id, amount: $amount) {
      error
      msg
      is_updated
      data {
        id
        amount
        currency
      }
    }
  }
`;

export const STUDENT_LIST = gql`
  query getDashboard {
    getDashboard(date: "") {
      students {
        id
        student_details {
          fname
          lname
          username
          dob
          age_group
          age_group_id
          attendance
          profile_imageUrl
        }
      }
      user_details {
        id
        role_id
        first_name
        last_name
        email
        bio
        profile_image
        phone_no
        address
        country
        is_active
        status
        comments
        createdAt
        onboardedAt
      }
    }
  }
`;

export const REGISTER_WORKSHOP = gql`
  mutation addToCart(
    $workshop_id: ID
    $registration_details: [RegisterWorkshopType]
  ) {
    addToCart(
      workshop_id: $workshop_id
      registration_details: $registration_details
    ) {
      error
      msg
      is_updated
    }
  }
`;

export const PAYMENT_WORKSHOP_CART_VERIFY = gql`
  query learnerWorkshopCartVerify($payment_request_id: String) {
    learnerWorkshopCartVerify(payment_request_id: $payment_request_id) {
      error
      msg
      is_updated
    }
  }
`;

export const LEARNER_WORKSHOP_CART_VERIFY = gql`
  query learnerWorkshopCartVerify(
    $payment_request_id: String
    $order_id: String
  ) {
    learnerWorkshopCartVerify(payment_request_id: $payment_request_id, order_id: $order_id) {
      error
      msg
      data {
        id
        order_id
        method
        captured
        amount
        currency
        status
      }
    }
  }
`;

export const UPDATE_CART = gql`
  mutation updateCart_learner(
    $cart_id: ID
    $isSaveLater: ID
    $isDelete: Boolean
  ) {
    updateCart_learner(
      cart_id: $cart_id
      isSaveLater: $isSaveLater
      isDelete: $isDelete
    ) {
      error
      msg
      is_updated
    }
  }
`;

export const UPDATE_STUDENT_WORKSHOP_CART = gql`
  mutation updateStudentCart_learner(
    $cart_id: ID
    $registration_details: [RegisterWorkshopType]
    $workshop_id: ID
  ) {
    updateStudentCart_learner(
      cart_id: $cart_id
      registration_details: $registration_details
      workshop_id: $workshop_id
    ) {
      error
      msg
      is_updated
    }
  }
`;

export const SEND_REQUEST = gql`
  mutation {
    sendRequest {
      status
    }
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation changeLearnerPassword($oldpassword: String, $password: String) {
    changeLearnerPassword(oldpassword: $oldpassword, password: $password) {
      user_details {
        password
      }
    }
  }
`;

export const GET_LEARNING_CENTRE = gql`
  query getlearnerDetails {
    getlearnerDetails {
      user_details {
        name
        last_name
        country
        displayName
        email
        profile_image
        address
        phone_no
        bio
        latitude
        longitude
        loginType
        year_of_birth
      }
    }
  }
`;

export const REGISTER_FREE_WORKSHOP = gql`
  mutation registerWorkshopLearner(
    $workshop_id: ID
    $registration_details: [RegisterWorkshopType]
  ) {
    registerWorkshopLearner(
      workshop_id: $workshop_id
      registration_details: $registration_details
    ) {
      error
      msg
      is_updated
    }
  }
`;

export const SAVE_PROFILE = gql`
  mutation editLearner(
    $name: String
    $last_name: String
    $country: String
    $displayName: String
    $bio: String
    $profileImage: String
    $phone_no: String
    $address: String
    $latitude: Float
    $longitude: Float
    $interest_categories_id: [ID]
    $year_of_birth: String
  ) {
    editLearner(
      name: $name
      last_name: $last_name
      country: $country
      displayName: $displayName
      bio: $bio
      profileImage: $profileImage
      phone_no: $phone_no
      address: $address
      latitude: $latitude
      longitude: $longitude
      interest_categories_id: $interest_categories_id
      year_of_birth: $year_of_birth
    ) {
      interest_categories {
        id
      }
      user_details {
        id
        name
        phone_no
        profile_image
      }
    }
  }
`;

export const SUBSCRIPTION_PAYMENT = gql`
  mutation SUBSCRIPTION_PAYMENT(
    $user_id: ID
    $subscription_type: String
    $amount: Float
    $student_id: ID
    $purchase_date: String
  ) {
    updatePayment(
      user_id: $user_id
      subscription_type: $subscription_type
      amount: $amount
      student_id: $student_id
      purchase_date:$purchase_date
    ) {
      error
      msg
      is_updated
      data {
        id
        longurl
        redirect_url
      }
    }
  }
`;

export const VERIFY_SUBSCRIPTION = gql`
  query VERIFY_SUBSCRIPTION(
    $payment_request_id: String
    $purchase_date: String
  ) {
    paymentVerification(
      payment_request_id: $payment_request_id
      purchase_date: $purchase_date
    ) {
      msg
      error
      email
    }
  }
`;

export const VERIFY_PAYMENT = gql`
  query VERIFY_PAYMENT($payment_request_id: String,$order_id: String, $purchase_date: String) {
    paymentVerification(
      payment_request_id: $payment_request_id
      order_id: $order_id
      purchase_date: $purchase_date
    ) {
      msg
      error
      email
    }
  }
`;

export const GET_BILLING = gql`
  query getBillingDetails {
    getBilling {
      Billing {
        id
        name
        subscription {
          user
          subscription_type
          payment_method
          purchase_date
          expiry_date
          gateway
          status
          total_amount
          payment_gateway
          username
          first_name
          last_name
          subscription_status
          subscription
        }
        workshop {
          workshop_id
          workshop_name
          registered_list {
            id
            user_id
            username
            lname
            fname
            registration_type
            registration_status
            payment_gateway
            purchase_date
            total_amount
          }
        }
      }
    }
  }
`;

export const PARENT_NOTIFICATION = gql`
  query PARENT_NOTIFICATION{
    parentNotification {
      notification_details {
        username
        notification_type
      }
      parent_notification{
        username
        message
        notification_type
      }
    }
  }
`;

export const VIEW_USER = gql`
  query{
  viewUser{
    user_details{
      id
      name
      # first_name
      profile_image
      role_id
      is_child
      is_learner
      show_trial
      expiry_date
      expiry_type
      signupType
      child_details{
         id
      username
      fname
      lname
        dob
        is_renew
        is_paid
      }
      completed_learning_content
      card_count
      beta_user
      expired
      is_renew
      is_paid
    }
    student_details{
      id
      username
      fname
      expiry_date
      expiry_type
      lname
      show_trial
      completed_learning_content
      card_count
      beta_user
      expired
      is_parent
    }
  }
}
`;


export const UPDATE_EXPIRE_DATE = gql`
   mutation updateExpiryDate(
    $subscription_type: String
  ) {
    updateExpiryDate(
      subscription_type: $subscription_type
    ) {
      message
    }
  }
`;
