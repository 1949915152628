import { Input, InputNumber } from "antd";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  .ant-input-affix-wrapper input,
  .ant-input-affix-wrapper {
    background-color: #f4f4f4;
    border-color: transparent;
    /* height:60px; */
    &.border-liteRed {
      border-color: #d1d5db;
    }
  }
  .ant-input-affix-wrapper.bg-red-100 input,
  .ant-input-affix-wrapper.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .ant-input {
    border-radius: 62px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
`;

const ReactInput = ({ showError, preventText, small, ...props }) => {
  const { values, setFieldValue, setTouched } = useFormikContext();
  const [field, meta, helpers] = useField(props);

  const [isFocusing, setIsFocusing] = React.useState(false);
  //console.log(meta, helpers);
  return (
    <InputContainer className="text-gray text-left">
      {props.label && (
        <label className="font-normal text-xs font-IBMPlex text-formLabel text-opacity-60 mb-1 block">
          {props.label}
        </label>
      )}

      {!props.isPassword && !props.isNumber && (
        <Input
          {...props}
          onChange={(e) => {
            // console.log("value", value);
            if (!preventText) {
              setFieldValue(props.name, e.target.value);
              if (props.onChange) {
                props.onChange(e);
              }
            }
          }}
          onFocus={() => {
            setTouched({ [props.name]: true }, true);
            setIsFocusing(true);
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          onBlur={() => {
            setIsFocusing(false);
            if (props.onBlur) {
              props.onBlur();
            }
          }}
          value={values[props.name] || props.value}
          size="large"
          className={`py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg ${meta.touched && meta.error ? " border-liteRed" : ""
            } ${props.className ? props.className : ``}`}
        />
      )}
      {props.isPassword && (
        <div>
          <Input.Password
            {...props}
            onChange={(e) => {
              // console.log("value", value);
              if (!preventText) {
                setFieldValue(props.name, e.target.value);
                if (props.onChange) {
                  props.onChange(e);
                }
              }
            }}
            onFocus={() => {
              setTouched({ [props.name]: true }, true);
              setIsFocusing(true);
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            onBlur={() => {
              setIsFocusing(false);
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            value={values[props.name]}
            size="large"
            ispassword={`${props.isPassword}`}
            className={`rounded text-gray-700 text-lg focus:outline-none focus:shadow-outline focus:bg-white bg-liteWhite ${meta.touched && meta.error ? "border-liteRed" : ""
              } ${props.className ? props.className : ``}`}
          />
        </div>
      )}
      {props.isNumber && (
        <InputNumber
          // id="inputBox"
          onChange={(value) => {
            // console.log("value", value);
            if (!preventText) {
              setFieldValue(props.name, value);
              if (props.onChange) {
                props.onChange(value);
              }
            }
          }}
          onFocus={() => {
            setTouched({ [props.name]: true }, true);
            setIsFocusing(true);
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          onBlur={() => {
            setIsFocusing(false);
            if (props.onBlur) {
              props.onBlur();
            }
          }}
          value={values[props.name]}
          type="number"
          {...props}
          size="large"
          className={`text-gray-700 text-lg ant-input-bg focus:outline-none focus:shadow-outline focus:bg-white rounded w-full border bg-liteWhite ${meta.touched && meta.error ? "border-liteRed" : ""
            } ${props.className ? props.className : ``}`}
        />
      )}
    </InputContainer>
  );
};
ReactInput.defaultProps = {
  showError: true,
  preventText: false,
};
ReactInput.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  isPassword: PropTypes.bool,
  isNumber: PropTypes.bool,
  preventText: PropTypes.bool,
  showError: PropTypes.bool,
};
export default ReactInput;
