import { useQuery } from "@apollo/client";
import { Avatar, notification, Tabs } from "antd";
import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { GET_EXPLORE_COLLECTION, GET_EXPLORE_PLAYLIST } from "../../../client/graphql";
import ExpertCard from "../../../components/Cards/ExpertCard";
import ExploreHeading from "../../../components/common/ExploreHeading";
import { TabContainer } from "../../../components/Style/TabContainer";
import Images, { DefaultAvatar } from "../../../themes/Images";

const { TabPane } = Tabs;
const ExploreContainer = styled.div`
  height: 290px;
  width: 240px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  & .titleContainer {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;

const Constants = {
  EXPERT_ROLE: 4,
  CURATOR_ROLE: 5,
};

const Collection = ({ data = {}, onOpen }) => {
  return (
    <div className=" flex">
      <div>
        <ExploreContainer className="">
          <img
            className="w-full h-full object-cover"
            src={data.background_image}
            alt="Explore card"
          />
          <div className="absolute left-0 right-0 bottom-0 top-36 titleContainer"></div>
          <div className=" px-3 pb-2 absolute bottom-0 left-0 right-0">
            <div className="font-Nunito text-xl text-white font-bold">
              {data.name || data.title || "Collection title"}
            </div>
            <div className="mt-2 font-light font-Nunito text-sm text-white">{`Cards ${
              data.cards?.length || 0
            }  •   Completed ${data.completedCount || 0}`}</div>
          </div>
        </ExploreContainer>
      </div>
    </div>
  );
};

const PlayList = Collection;

export default function ExpertContent() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [playlists, setPlaylist] = useState([]);
  const [collections, setCollections] = useState([]);
  const history = useHistory();

  const {loading: loadingCollection} = useQuery(GET_EXPLORE_COLLECTION, {
    variables: {
      isTag: true,
      lcId: parseInt(id),
      search: "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if(data.getExploreCollection){
        setCollections(data.getExploreCollection.collection);
        setUser(data.getExploreCollection.user);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const {loading: loadingPlaylist} = useQuery(GET_EXPLORE_PLAYLIST, {
    variables: {
      isTag: true,
      lcId: parseInt(id),
      search: "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if(data.getExplorePlaylist){
        setPlaylist(data.getExplorePlaylist.playlist);
        setUser(data.getExplorePlaylist.user);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  })

  const onCollectionOpen = useCallback((collectionId, userRole) => {
    console.log(collectionId, userRole);
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/collections/expert/${collectionId}`);
    } else {
      history.push(`/collections/${collectionId}`);
    }
  }, []);

  const onPlayListOpen = useCallback((playlistId, userRole) => {
    console.log(playlistId, userRole);
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/playlists/expert/${playlistId}`);
    } else {
      history.push(`/playlists/${playlistId}`);
    }
  }, []);

  return (
    <>
      <div className="bg-white">
        <ExploreHeading
          title="Expert content"
          // onClick={() => {
          //   history.goBack();
          // }}
        ></ExploreHeading>
        <div className="px-12">
          {user !== null && (
            <div className="mt-6 rounded-md pt-3 pb-4 pl-4 pr-6 bg-ghostWhite">
              <div className="flex">
                <div className=" w-20 ">
                  <Avatar size={74} src={user.profile_image || DefaultAvatar} />
                </div>

                <div className="ml-4">
                  <div className="mt-2 font-semibold font-Nunito text-black flex">
                    <div>{user.name} </div>
                    {user.role_id === Constants.EXPERT_ROLE && (
                      <img
                        src={Images.VerifiedIcon}
                        alt="verified Icon"
                        className="ml-2"
                      />
                    )}
                  </div>
                  <div className="mt-2 text-textDark h9">{user.bio}</div>
                </div>
              </div>
            </div>
          )}
          <div>
            <TabContainer>
              <Tabs
                defaultActiveKey="1"
                onChange={(key) => {
                  console.log(key);
                }}
                className=" -mr-5 mt-5"
              >
                <TabPane tab="Explore On the Go" key="1">
                  {!collections.length && !(loadingCollection)  && (
                    <div className="flex flex-col items-center justify-center bg-white pt-28 pb-20">
                      <img
                        src={Images.CollectionEmpty}
                        alt="Collection Empty"
                        className="ml-2"
                      />
                      <div className="nunito-normal-lg mt-10">
                        Learning content available as cards in collections would
                        appear here.
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-3 gap-5 ">
                    {collections.length !== 0 &&
                      collections.map((collection, collectionIndex) => {
                        return (
                          <div
                            className=" inline-block w-full mt-5 "
                            key={`expert-content-${collectionIndex}`}
                          >
                            <ExpertCard
                              data={{ ...collection, user: user }}
                              onClick={() => {
                                onCollectionOpen(collection.id, user?.role_id);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </TabPane>
                <TabPane tab="Explore One by One" key="2">
                  {!playlists.length && !(loadingPlaylist) && (
                    <div className="flex flex-col items-center justify-center bg-white pt-28 pb-20">
                      <img
                        src={Images.CollectionEmpty}
                        alt="Collection Empty"
                        className="ml-2"
                      />
                      <div className="nunito-normal-lg mt-10">
                        Learning content available as cards in learning path
                        would appear here.
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-3 gap-5 ">
                    {playlists.length !== 0 &&
                      playlists.map((playlist, playlistIndex) => {
                        return (
                          <div
                            className=" inline-block w-full  mt-5 "
                            key={`playlist-expert-${playlistIndex}`}
                          >
                            <ExpertCard
                              data={{ ...playlist, user: user }}
                              onClick={() => {
                                onPlayListOpen(playlist.id, user?.role_id);
                              }}
                              isPlaylist
                            />
                          </div>
                        );
                      })}
                  </div>
                </TabPane>
              </Tabs>
              {(loadingCollection || loadingPlaylist) && (
                <div className="grid grid-cols-3 gap-5">
                  {[{}, {}, {}].map((listing, listingIndex) => {
                    return (
                      <div className="w-full mt-5">
                        <ExpertCard
                          data={{
                            cards: [{}, {}, {}],
                          }}
                          onClick={() => {}}
                          loading
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </TabContainer>
          </div>
        </div>
      </div>
    </>
  );
}
