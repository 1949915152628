// Custom icons will be imported in the corresponding pages
module.exports = {
  //   A403: require("../static/images/A403.svg"),
  //Auth
  AuthLayout: require("../static/images/auth/layout.png").default,
  AuthPasscodeLayout: require("../static/images/auth/layoutPasscode.png")
    .default,
  BackIcon: require("../static/images/icons/chevron-left.svg").default,
  BackMobileIcon: require("../static/images/icons/arrow-left.svg").default,
  CheckedIcon: require("../static/images/icons/checked.svg").default,
  FourOFour: require("../static/images/emptyState/404asset.png").default,

  //Navbar
  Logo: require("../static/images/logo.svg").default,
  ExploreIcon: require("../static/images/icons/Navbar/explore-active.svg")
    .default,
  ExploreActiveIcon: require("../static/images/icons/Navbar/explore-active.svg")
    .default,
  JournalIcon: require("../static/images/icons/Navbar/journal.svg").default,
  JournalActiveIcon: require("../static/images/icons/Navbar/bookOpen.svg")
    .default,
  NotificationIcon: require("../static/images/icons/Navbar/notifications.svg")
    .default,
  ScheduleIcon: require("../static/images/icons/Navbar/schedule.svg").default,
  WorkshopIcon: require("../static/images/icons/Navbar/workshop.svg").default,
  ProfileDownArrow: require('../static/images/icons/Navbar/profileDownArrow.svg').default,
  BreadcrumbArrowIcon: require("../static/images/icons/chevron-right.svg")
    .default,
  DefaultAvatar: require("../static/images/icons/default-avatar.svg").default,

  //Cards
  ExploreCardShadow: require("../static/images/cards/cardShadow.svg").default,
  DoCardIcon: require("../static/images/cards/do.svg").default,
  WatchCardIcon: require("../static/images/cards/watch.svg").default,
  VideoIcon: require("../static/images/cards/video-player.svg").default,
  PictureIcon: require("../static/images/cards/picture.svg").default,
  ProgressChartIcon: require("../static/images/cards/progress-chart.svg")
    .default,
  PdfIcon: require("../static/images/cards/pdf.svg").default,
  VerifiedIcon: require("../static/images/cards/verified.svg").default,
  CarouselLeft: require("../static/images/icons/carousel-chevron-left.svg")
    .default,
  PlaylistIcon: require("../static/images/icons/playlist.svg").default,
  WishlistIcon: require("../static/images/icons/wishlist.svg").default,

  WishlistBlueIcon: require("../static/images/icons/wishlistBlue.svg").default,
  BackBlueIcon: require("../static/images/icons/chevron-left-colored.svg")
    .default,
  YoutubeIcon: require("../static/images/icons/youtube.svg").default,
  LearnArrowIcon: require("../static/images/icons/arrow-right.svg").default,
  ScheduleStickyIcon: require("../static/images/icons/schedule.svg").default,
  CounterupArrow: require("../static/images/icons/counter.svg").default,
  TimerIcon: require("../static/images/icons/timer.svg").default,
  NotesIcon: require("../static/images/icons/notes.svg").default,
  VoiceIcon: require("../static/images/icons/voice.svg").default,
  CameraIcon: require("../static/images/icons/Camera.svg").default,
  ImageIcon: require("../static/images/icons/image.svg").default,
  doIcon: require("../static/images/icons/doIcon.svg").default,
  watchIcon: require("../static/images/icons/watchIcon.svg").default,
  doPlaylistIcon: require("../static/images/icons/playlistDo.svg").default,
  watchPlaylistIcon: require("../static/images/icons/playlistWatch.svg")
    .default,
  CongratsStarIcon: require("../static/images/icons/congratsStar.svg").default,
  PlaylistScheduleIcon: require("../static/images/icons/playlistSchedule.svg")
    .default,
  PlaylistCheckedIcon: require("../static/images/icons/playlistChecked.svg")
    .default,
  UploadPhotoIcon: require("../static/images/icons/uploadPhoto.svg").default,
  PlayIcon: require("../static/images/icons/play.svg").default,
  WhiteCheckedIcon: require("../static/images/icons/checkedWhite.svg").default,
  NoteIcon: require("../static/images/icons/NotesIcon.svg").default,
  DoodleIcon: require("../static/images/icons/newDoodle.svg").default,
  MicIcon: require("../static/images/icons/Mic.svg").default,
  ImagesIcon: require("../static/images/icons/Images.svg").default,
  ExpertIcon: require("../static/images/icons/Expert icon 20px.svg").default,
  LCFeedbackIcon: require("../static/images/icons/LC feedback.svg").default,
  CalendarDoIcon: require("../static/images/icons/calendarDo.svg").default,
  CalendarWatchIcon: require("../static/images/icons/calendarWatch.svg")
    .default,
  LogoIcon: require("../static/images/icons/breadcrumb-logo-color.svg").default,
  StarIcon: require("../static/images/icons/star.svg").default,
  StepsIcon: require("../static/images/icons/steps.svg").default,
  VideoWatchIcon: require("../static/images/icons/video.svg").default,
  MessageIcon: require("../static/images/icons/message-circle.svg").default,
  WorkShopIcon: require("../static/images/icons/Group 1565.svg").default,
  giftIcon: require("../static/images/icons/giftIcon.svg").default,
  championIcon: require("../static/images/icons/champion2.svg").default,
  whatsAppIcon: require("../static/images/icons/whatsapp.svg").default,
  twitterIcon: require("../static/images/icons/twitter.svg").default,
  linkIcon: require("../static/images/icons/link.svg").default,
  facebookIcon: require("../static/images/icons/facebook.svg").default,

  //Empty State
  CollectionEmptyState:
    require("../static/images/emptyState/CollectionCards.png").default,
  NoFilterResults: require("../static/images/emptyState/EmptyFilterResults.svg")
    .default,
  CollectionEmpty: require("../static/images/emptyState/CollectionEmpty.svg")
    .default,
  CartEmpty: require("../static/images/emptyState/empty_cart.svg").default,

  //Learning path
  LearningPathCloseIcon: require("../static/images/icons/Close.svg").default,
  LearningPathAddIcon: require("../static/images/icons/add.svg").default,
  LearningPathAddBlueIcon: require("../static/images/icons/addBlue.svg")
    .default,
  ScheduleCloseIcon: require("../static/images/icons/schedule-close.svg")
    .default,
  DoDetailsCardIcon: require("../static/images/cards/doDetails.svg").default,
  WatchDetailsCardIcon: require("../static/images/cards/watchDetails.svg")
    .default,
  NotesAudioBg: require("../static/images/icons/audiobg.svg").default,

  JournalAudioBg: require("../static/images/icons/Journal audio bg.svg")
    .default,
  PlayAudio: require("../static/images/icons/Polygon.svg").default,

  // Settings
  SettingIcon: require("../static/images/icons/settings.svg").default,
  SelectedInterestIcon: require("../static/images/icons/interest-check.svg")
    .default,
  ClockIcon: require("../static/images/icons/clock.svg").default,
  FlameIcon: require("../static/images/icons/flame.svg").default,
  UserProfileIcon: require("../static/images/emptyState/user_profile.svg")
    .default,
  UserProfileDarkIcon: require("../static/images/emptyState/user_profile_dark.svg")
    .default,
  UserResponsiveProfileIcon:
    require("../static/images/emptyState/user_responsive_profile.svg").default,
  WorkshopCheckIcon: require("../static/images/icons/checkedGreen.svg").default,
  ScheduleLearnEmpty: require("../static/images/cards/learnEmpty.svg").default,
  ScheduleLearningEmpty: require("../static/images/cards/learningEmpty.svg")
    .default,
  ScheduleFinishEmpty: require("../static/images/cards/finishEmpty.svg")
    .default,
  NotificationCheckIcon: require("../static/images/icons/checkedIcon.svg")
    .default,
  NotificationArrowIcon: require("../static/images/icons/notificationArrow.svg")
    .default,
  WishlistEmptyState: require("../static/images/emptyState/wishlist.svg")
    .default,
  SomethingWentWrong:
    require("../static/images/emptyState/something-went-wrong.svg").default,
  SearchCloseIcon: require("../static/images/icons/searchClose.svg").default,
  PdfRefIcon: require("../static/images/icons/pdf.svg").default,
  InternetState: require("../static/images/emptyState/nointernet.svg").default,
  SearchNotFound: require("../static/images/emptyState/search-empty-result.svg")
    .default,
  LoginEmptySearch: require("../static/images/emptyState/loginEmptyState.svg")
    .default,
  PaymentEmptyState: require("../static/images/emptyState/payment.svg").default,
  LearnerEmptyState: require("../static/images/emptyState/learner.svg").default,
  EnrollLearnerEmptyState: require("../static/images/emptyState/paymentEmptyState.svg").default,
  OneTimePaymentArrow: require("../static/images/icons/oneTimePayment.svg").default,
  paymentSuccessEmptyState: require("../static/images/emptyState/paymentSuccess.png").default,
  paymentFailureEmptyState: require("../static/images/emptyState/cross.png").default,

  PaymentCheckedIcon: require("../static/images/icons/paymentChecked.svg")
    .default,
  WhiteCloseIcon: require("../static/images/icons/closeWhite.svg").default,
  JournalEmptyState: require("../static/images/emptyState/journalEmpty.svg").default

};
