import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../components/common/Loader";
import Images from "../../../themes/Images";

const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`;
export default function OnboardingCenterLayout(props) {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.token) {
      history.push("/dashboard");
    }
  }, [])
  return (
    <div>
      <div>
        <Loader loading={props.loading} />
        <div className="h-full min-w-full relative">
          <div className="absolute top-0 left-0 right-0 bottom-0 -z-1">
            <img
              src={Images.AuthPasscodeLayout}
              className="w-full h-full object-cover"
              alt="Layout"
            />
          </div>
          <div className="min-h-screen py-8 md:py-14  flex justify-center items-center">
            <div className="max-w-2xl mx-auto w-full">
              <div className="mx-auto w-11/12">
                <CardContainer className="rounded-xl bg-white px-6 py-8 relative h-full ">
                  {props.children}
                </CardContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
