import React from "react";
import { Offline, Online } from "react-detect-offline";
import { Route, Switch } from "react-router-dom";
import GenericNotFound from "../components/common/GenericNotFound";
import InternetOffline from "../components/common/InternetOffline";
import AvatarSelection from "../pages/Auth/avatarSelect";
import ForgotPasscode from "../pages/Auth/ForgotPasscode";
import Login from "../pages/Auth/login";
import Passcode from "../pages/Auth/Passcode";
import Cart from "../pages/Cart";
import CollectionDetails from "../pages/Collection/Details";
import ExpertCollectionContentList from "../pages/Collection/Expert/Content";
import ExpertContent from "../pages/Collection/Expert/listing";
import ListingCollection from "../pages/Collection/listing";
import Dashboard from "../pages/dashboard";
import Interest from "../pages/Interest";
import CalendarComponent from "../pages/Journal/calendarIndex";
import Mywishlist from "../pages/MyWishlist";
import PaymentProceed from "../pages/payment/proceed";
import PaymentSuccess from "../pages/payment/success";
import ExpertPlaylistContentList from "../pages/Playlist/Expert/Content";
import ListingPlaylist from "../pages/Playlist/listing";
import Profile from "../pages/Profile";
import ProfileCompletedCards from "../pages/Profile/collection";
import ParentProfile from "../pages/Profile/parent";
import ProfilePlaylist from "../pages/Profile/playlists";
import Schedule from "../pages/Schedule";
import Search from "../pages/Search";
import CardSearch from "../pages/Search/cardSearch";
import Workshops from "../pages/Workshops";
import EventsWorkshop from "../pages/Workshops/events";
import WorkshopDetailIndex from "../pages/Workshops/main";
import JournalIndex from "../pages/Journal/main"
import Auth from "./Auth";
import WorkshopPaymentSuccess from "../pages/payment/workshopPaySuccess";

const OfflineWrapper = ({ children }) => {
  return (
    <div>
      {typeof navigator !== "undefined" && /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(navigator.userAgent) ? <>{children}</>
        : <><Offline>
          <InternetOffline />
        </Offline>
          <Online>{children}</Online>
        </>}
    </div>
  );
};

export default function Routes() {
  return (
    <main>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <OfflineWrapper>
              <Login {...props} />
            </OfflineWrapper>
          )}
        />
        <Route
          path="/passcode/:type?"
          render={(props) => (
            <OfflineWrapper>
              <Passcode {...props} />
            </OfflineWrapper>
          )}
        />
        <Route
          exact
          path="/pickavatar"
          render={(props) => (
            <OfflineWrapper>
              <AvatarSelection {...props} />
            </OfflineWrapper>
          )}
        />
        <Route
          path="/forgot-passcode"
          render={(props) => (
            <OfflineWrapper>
              <ForgotPasscode {...props} />
            </OfflineWrapper>
          )}
        />
        <Auth exact path="/dashboard" component={Dashboard} />
        <Auth exact path="/journal" component={JournalIndex} />

        <Auth exact path="/journal/calendar" component={CalendarComponent} />
        {/*
        <Auth
          exact
          path="/collections/:collectionId"
          component={ListingCollection}
        />
        <Auth
          exact
          path="/collections/expert/contents"
          component={ExpertCollectionContentList}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/collections/expert/:collectionId",
            "/collections/:collectionId",
          ]}
          component={ListingCollection}
          baseRoute="explore"
        />
        <Auth
          exact
          path="/collections/expert/contents/:id"
          component={ExpertContent}
          baseRoute="explore"
        />
        <Auth
          exact
          path="/playlists/expert/contents"
          component={ExpertPlaylistContentList}
          baseRoute="explore"
        />
        <Auth
          exact
          path="/playlists/:playListId"
          component={ListingPlaylist}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/collections/expert/:collectionId/:activityId",
            "/playlists/expert/:playlistId/:activityId",
          ]}
          component={CollectionDetails}
          baseRoute="explore"
        />

        <Auth
          exact
          path="/collections/:collectionId/feedback"
          component={Feedback}
          baseRoute="explore"
        />
        <Auth
          exact
          path="/collections/:collectionId/interest"
          component={Interest}
          baseRoute="explore"
        />
         */}

        <Auth
          exact
          path={[
            "/collections/expert/contents",
            "/collections/curator/contents",
          ]}
          component={ExpertCollectionContentList}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/collections/expert/:collectionId",
            "/collections/curator/:collectionId",
            "/collections/:collectionId",
          ]}
          component={ListingCollection}
          baseRoute="explore"
        />
        <Auth
          exact
          path={["/playlists/expert/contents", "/playlists/curator/contents"]}
          component={ExpertPlaylistContentList}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/collections/expert/contents/:id",
            "/playlists/expert/contents/:id",
            "/collections/curator/contents/:id",
            "/playlists/curator/contents/:id",
          ]}
          component={ExpertContent}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/playlists/curator/:playListId",
            "/playlists/expert/:playListId",
            "/playlists/:playListId",
          ]}
          component={ListingPlaylist}
          baseRoute="explore"
        />
        <Auth
          exact
          path={[
            "/collections/expert/:collectionId/:activityId",
            "/playlists/expert/:playlistId/:activityId",
            "/collections/curator/:collectionId/:activityId",
            "/playlists/curator/:playlistId/:activityId",
            "/collections/:collectionId/:activityId",
            "/playlists/:playlistId/:activityId",
          ]}
          component={CollectionDetails}
          baseRoute="explore"
        />
        <Auth
          exact
          path={["/interest/:interestId", "/tags/:tagId"]}
          component={Interest}
          baseRoute="explore"
        />
        <Auth exact path="/schedule" component={Schedule} />
        <Auth exact path="/mywishlist" component={Mywishlist} />
        <Auth exact path="/profile" component={Profile} />
        <Auth
          exact
          path={["/profile/collections", "/profile/expert/collections"]}
          component={ProfileCompletedCards}
        />
        <Auth exact path="/profile/playlists" component={ProfilePlaylist} />
        <Auth
          exact
          path="/workshops"
          component={Workshops}
          baseRoute="workshops"
        />
        <Auth
          exact
          path="/workshops/upcoming"
          component={EventsWorkshop}
          baseRoute="workshops"
        />
        <Auth
          exact
          path="/workshops/past"
          component={EventsWorkshop}
          baseRoute="workshops"
        />
        <Auth
          exact
          path="/workshops/:id"
          component={WorkshopDetailIndex}
          baseRoute="workshops"
        />
        <Auth exact path="/search" component={Search} baseRoute="search" />
        <Auth exact path="/cart" component={Cart} baseRoute="cart" />
        <Auth
          exact
          path={["/search/cards/:type/:name", "/search/experts/:type/:name"]}
          component={CardSearch}
          baseRoute="search"
        />
        <Auth
          exact
          path={["/parent/profile", "/parent/settings", "/parent/billings", "/parent/password"]}
          component={ParentProfile}
          baseRoute="profile"
        />
        <Auth exact path="/payment/proceed" component={PaymentProceed} baseRoute="payment" />
        <Auth exact path="/payment/verify/:orderId/:paymentId" component={PaymentSuccess} baseRoute="payment" />
        <Auth exact path="/payment/workshop/verify" component={WorkshopPaymentSuccess} baseRoute="payment" />

        <Route exact path="*" component={GenericNotFound} />
      </Switch>
    </main>
  );
}
