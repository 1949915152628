import React from "react";
import styled from "styled-components";
import Images from "../../themes/Images";

const InterestContainer = styled.div`
  max-width: ${(props) => (props.fixed ? "211px" : "100%")};
  width: 100%;
  /* min-width: 211px; */
  height: 120px;
  color:#fff;
  flex-shrink: 0;
  overflow: hidden;
  .bg {
    background: linear-gradient(
      179.53deg,
      rgba(0, 0, 0, 0) 0.41%,
      rgba(0, 0, 0, 0.5) 99.59%
    );
  }

  filter: drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.12));
  border-radius: 5px;
  .selectedInterest {
    background: linear-gradient(
        179.53deg,
        rgba(0, 0, 0, 0) 0.41%,
        rgba(0, 0, 0, 0.5) 99.59%
      ),
      linear-gradient(0deg, rgba(66, 192, 239, 0.9), rgba(66, 192, 239, 0.9));
  }
  /* &.min-w-100 {
    min-width: 100%;
  } */
`;

export default function InterestCard({
  image,
  title,
  loading,
  isMargin = true,
  isSelected,
  key,
  fixed,
  className = "",
}) {
  return (
    <InterestContainer
      fixed={fixed}
      className={`relative ${className} ${isMargin ? "mr-5" : ""}`}
    >
      {isSelected && !loading && (
        <div className="absolute inset-0 selectedInterest flex items-center justify-center z-10">
          <img src={Images.SelectedInterestIcon} alt="Interest Card img" />
        </div>
      )}
      {!loading && (
        <>
          <img
            src={image}
            alt="Interest Img"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg"></div>
          <div className=" pt-2 px-3 pb-2 absolute text-left top-0 w-full h5 z-20">
            {title}
          </div>
        </>
      )}
      {loading && <div className="w-full h-full loader"></div>}
    </InterestContainer>
  );
}
