import { Form } from "antd";
import { Field } from "formik";
import * as React from "react";

export default function ReactForm(props) {
  return (
    <Field>
      {({ form: { handleReset, handleSubmit } }) => (
        <Form onReset={handleReset} onFinish={handleSubmit} {...props}>
          {props.children}
        </Form>
      )}
    </Field>
  );
}
