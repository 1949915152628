import React from "react";
import { Cell, Label, Pie, PieChart, Text } from "recharts";
import "./Chart.css";

const ReportsChart = (props) => {
  const data = [
    { value: props.data.watch_cards_hours, color: "#FFC90D" },
    { value: props.data.do_cards_hours, color: "#23ABDD" },
  ];

  const renderTotalHours = (data) => {
    const {
      viewBox: { cx, cy },
    } = data;
    return (
      <React.Fragment>
        <Text
          x={cx}
          y={cy - 13}
          textAnchor="middle"
          verticalAnchor="middle"
          fill="#5432BA"
          fontSize="24px"
          fontWeight="800"
        >
          {props.data.total_hours}
        </Text>
        <Text
          className="max-weekChart-hr"
          x={cx}
          y={cy + 13}
          textAnchor="middle"
          verticalAnchor="middle"
          fill="#5432BA"
          fontSize="20px"
        >
          hour(s)
        </Text>
        <Text
          className="mbl-weekChart-hr"
          x={cx}
          y={cy + 13}
          textAnchor="middle"
          verticalAnchor="middle"
          fill="#5432BA"
          fontSize="20px"
        >
          hrs
        </Text>
      </React.Fragment>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
    fill,
    ...rest
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <React.Fragment>
        <filter
          id="shadow"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feDropShadow
            dx="0"
            dy="0"
            stdDeviation="2"
            floodColor="#A9A9A9"
            floodOpacity="1"
          />
        </filter>
        <circle
          cx={x}
          cy={y}
          r="20"
          stroke={fill}
          strokeWidth="1"
          style={{
            fill: "#FFFFFF",
            filter: "drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.16))",
          }}
        />
        <text
          x={x}
          y={y}
          fill={fill}
          textAnchor="middle"
          fontWeight="800"
          dominantBaseline="central"
        >
          {value}
        </text>
      </React.Fragment>
    );
  };

  return (
    <div style={{ height: 300, width: 300 }}>
      <PieChart width={300} height={300}>
        <Pie
          data={data}
          dataKey="value"
          isAnimationActive={false}
          innerRadius={55}
          outerRadius={90}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color} key={`cell-${index}`} />
          ))}
          <Label position="center" content={renderTotalHours} />
        </Pie>
      </PieChart>
    </div>
  );
};

export default ReportsChart;
