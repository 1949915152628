import React from "react";
import { Img } from 'react-image';
import styled from "styled-components";
import Images from "../../themes/Images";

const ExploreContainer = styled.div`
  height: ${(props) =>
    props.customSize?.height ? props.customSize?.height : "290px"};
  width: ${(props) =>
    props.customSize?.width ? props.customSize?.width : "240px"};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  color:#fff;
  & .titleContainer {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;
export default function PlaylistCard({
  customSize = {},
  loading,
  isPlaylist,
  data = {},
  onClick,
}) {
  return (
    <ExploreContainer
      className={onClick ? "cursor-pointer" : ""}
      customSize={customSize}
      onClick={onClick}
    >
      {isPlaylist && (
        <img
          src={Images.PlaylistIcon}
          className="absolute img-fluid right-2 top-2"
          alt="wishlist"
        />
      )}
      {loading && <div className="loader h-full"></div>}
      {!loading && (
        <Img
          className="w-full h-full object-cover"
          src={data.background_image}
          loader={<div className="w-full h-full loader"></div>}
          alt="Explore card"
        />
      )}
      <div className="absolute left-0 right-0 bottom-0 top-36 titleContainer"></div>
      <div className=" px-3 pb-2 absolute bottom-0 left-0 right-0">
        <div
          className={`font-Nunito text-xl font-bold ${loading ? "loader rounded w-full h-4" : ""
            }`}
        >
          {!loading ? data.name || data.title || "Collection title" : ""}
        </div>
        <div
          className={`mt-2 font-light font-Nunito text-sm ${loading ? "loader rounded w-8/12 h-3" : ""
            }`}
        >
          {loading
            ? ""
            : `${data.completedCount !== (data.cards?.length || data.count)
              ? `Cards ${data.cards?.length || data.count || 0}`
              : ""
            } ${data.completedCount !== (data.cards?.length || data.count) &&
              data.cards?.length !== 0 &&
              data.completedCount !== 0
              ? "•   "
              : ""
            }
                 ${data.completedCount !== 0
              ? `Completed ${data.completedCount || 0}`
              : ""
            } `}

          {/* // Cards ${
            //     data.completedCount === (data.cards?.length || data.count)
            //       ? "0"
            //       : `${data.cards?.length || data.count || 0}`
            //   }   •   Completed ${data.completedCount || 0}`} */}
        </div>
      </div>
    </ExploreContainer>
  );
}
