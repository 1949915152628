import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { GET_STUDENT_IMAGES, PICK_AVATAR } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Images from "../../themes/Images";
import { BackButtonHandler } from "../../utils";
import BackButton from "./components/BackButton";
import OnboardingCenterLayout from "./layout/OnboardingCenterLayout";

const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`;
export default function AvatarSelection() {
  const history = useHistory();
  const location = useLocation();
  const [selectedImg, setSelectedImg] = useState(null);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const [avatarList, setAvatarList] = useState([]);
  /*const avatarList = [
    {
      url: "https://altius-testing-images.s3.ap-south-1.amazonaws.com/nBbBvphRlGqaZjNp.png",
      bgColor: "#FFF6D6",
    },
    {
      url: "https://altius-testing-images.s3.ap-south-1.amazonaws.com/nBbBvphRlGqaZjNp.png",
      bgColor: "#E6DDFF",
    },
    {
      url: "https://altius-testing-images.s3.ap-south-1.amazonaws.com/nBbBvphRlGqaZjNp.png",
      bgColor: "#D8F4FF",
    },
    {
      url: "https://altius-testing-images.s3.ap-south-1.amazonaws.com/nBbBvphRlGqaZjNp.png",
      bgColor: "#FFDCE6",
    },
  ];*/

  useEffect(() => {
    if (!user_details || user_details.isProfileImage) {
      history.push("/");
    }
  }, []);

  const Avatar = (props) => {
    return (
      <div
        className={`text-center cursor-pointer`}
        onClick={() => {
          if (props.onSelect) props.onSelect();
        }}
      >
        <div
          className={`md:h-24 md:w-24 w-20 h-20 rounded-full relative m-auto flex items-center justify-center`}
          style={{ backgroundColor: props.bgColor }}
        >
          <img
            src={props.url}
            className={` ${props.bgColor ? "w-16 h-16" : ""} `}
            alt="avatar"
          />
          {props.isSelected && (
            <img
              src={Images.CheckedIcon}
              alt="CheckedIcon"
              className="absolute right-0 bottom-0"
            />
          )}
        </div>
      </div>
    );
  };
  // useEffect(() => {
  //   let params = new URLSearchParams(location.search);
  //   console.log(params.get("forward"));
  //   let type = params.get("forward");
  //   setForwardType(type);
  // }, [location.search]);

  const { loading: loadingAvatarList } = useQuery(GET_STUDENT_IMAGES, {
    onCompleted: (data) => {
      if (data.getStudentImages) {
        let avartarImgList = [];
        let userLetter = user_details.username.charAt(0).toUpperCase();
        data.getStudentImages.Images.map((avatarImages) => {
          let avatar = avatarImages.student_images;
          if (avatar.url_type === "letter" && avatar.character === userLetter) {
            avartarImgList.push(avatarImages);
          } else if (avatar.url_type === "emoji") {
            avartarImgList.push(avatarImages);
          }
        });
        setAvatarList(avartarImgList);
      }
    },
  });

  const [updateAvatar, { loading: updatingAvatar }] = useMutation(PICK_AVATAR, {
    onCompleted: (data) => {
      if (
        data.ipadStudentImageUpload &&
        data.ipadStudentImageUpload.is_updated
      ) {
        let user_details = JSON.parse(localStorage.getItem("user_details"));
        localStorage.setItem(
          "user_details",
          JSON.stringify({
            ...user_details,
            avatar: selectedImg.url,
            bgColor: selectedImg.bgColor,
          })
        );
        history.push(
          `/passcode/${user_details.new_student ? `create` : `login`}`
        );
      } else {
        notification.error({
          message: "Profile Image not updated.Something went wrong.",
        });
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return (
    <OnboardingCenterLayout loading={updatingAvatar || loadingAvatarList}>
      <div className="flex justify-between h-9 items-center cursor-pointer">
        <div
          onClick={() => {
            BackButtonHandler(history);
            // history.push("/");
          }}
        >
          <BackButton />
        </div>
        <div className="absolute left-0 right-0 text-center pointer-events-none">
          <div className="authHeading">Select Avatar</div>
        </div>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 gap-4 mt-10 md:mt-12">
        {avatarList.map((avatarImages, avatarIndex) => {
          let avatar = avatarImages.student_images;
          return (
            <React.Fragment key={`Avatar-${avatarIndex}`}>
              <Avatar
                url={avatar.image_url}
                bgColor={avatar.bg_colour}
                isSelected={selectedImg && selectedImg.url === avatar.image_url}
                onSelect={() => {
                  setSelectedImg({
                    url: avatar.image_url,
                    bgColor: avatar.bg_colour,
                  });
                }}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div
        className={`block text-center mt-16 w-56 mx-auto ${
          selectedImg?.url ? `` : `opacity-50 pointer-events-none`
        } `}
      >
        <Button
          onClick={() => {
            if (selectedImg) {
              updateAvatar({
                variables: {
                  username: user_details.username,
                  profilePic: selectedImg.url,
                  bgColor: selectedImg.bgColor,
                },
              });
            } else {
              notification.info({
                message: "Please select one image to proceed",
              });
            }
          }}
          primary
        >
          Select
        </Button>
      </div>
    </OnboardingCenterLayout>
  );
}
