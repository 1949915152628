import { useMutation } from "@apollo/client";
import { Form, Input, Modal, notification } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { CREATE_PASSCODE, LOGIN } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Images, { DefaultAvatar } from "../../themes/Images";
import { generateRandomString } from "../../utils";
import BackButton from "./components/BackButton";
import OnboardingCenterLayout from "./layout/OnboardingCenterLayout";

export default function Passcode() {
  const history = useHistory();
  const [formRef] = Form.useForm(); //useRef();
  const { type } = useParams();
  const [operationType, setOperationType] = useState(type || "login");
  const [passCode, setPassCode] = useState(null);
  const [confirmPassCode, setConfirmPassCode] = useState(null);
  const [showLoginBlock, setShowLoginBlock] = useState(false);
  const user_details = useMemo(() => JSON.parse(localStorage.getItem("user_details")), []);
  const userName = user_details?.username;
  const avatar = user_details?.avatar || DefaultAvatar;
  const bg_color = user_details?.bgColor;

  useEffect(() => {
    if (!user_details) {
      //|| (user_details.new_student && type === "login")) {
      history.push("/");
    } else if (
      user_details &&
      !user_details.new_student &&
      !user_details.change_password &&
      type === "create"
    ) {
      history.push("/");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    ...(operationType === "create" && {
      passcode: Yup.string()
        .required("Please enter passcode")
        .min(4, "Must have 4 numbers.")
        .max(4, "Must have 4 numbers.")
        .matches(/^\d+$/, "Should be only numbers"),
    }),
    ...(operationType === "confirm" && {
      confirmPasscode: Yup.string()
        .required("Please enter passcode")
        .max(4, "Maximum 4 characters expected")
        .matches(/^\d+$/, "Should be only numbers"),
    }),
  });

  const [userLogin, { loading: loggingIn }] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.ipadLoginV2) {
        console.log(data?.ipadLoginV2);
        let users = [];
        let found;
        if (!data.ipadLoginV2?.student_details?.isParentAdded) {
          if (data.ipadLoginV2?.student_details?.Onboarded_days <= 1) {
            let user_details = JSON.parse(localStorage.getItem("user_details"));
            user_details.isParentAdded = false;
            user_details.Onboarded_days =
              data.ipadLoginV2?.student_details?.Onboarded_days;
            localStorage.setItem("user_details", JSON.stringify(user_details));
          } else {
            setShowLoginBlock(true);
            return;
          }
        }
        if (localStorage.getItem("userList")) {
          users = JSON.parse(localStorage.getItem("userList"));
          found = users.filter((user) => user.name === userName);
        }
        if (!found || !found.length) {
          users.splice(0, 0, {
            name: data.ipadLoginV2.student_details?.username,
            avatar: data.ipadLoginV2.student_details?.profile_imageUrl,
            bgColor: data.ipadLoginV2.student_details?.BG_color,
          });
          localStorage.setItem("userList", JSON.stringify(users.slice(0, 3)));
        }
        //setting token
        localStorage.setItem("token", data.ipadLoginV2.student_details?.token);
        if (Boolean(data?.ipadLoginV2?.student_details?.show_trial))
          localStorage.setItem(
            "showTrialStarts",
            data?.ipadLoginV2?.student_details?.show_trial
          );
        if (Boolean(data?.ipadLoginV2?.student_details?.expired)) {

          localStorage.setItem(
            "showTrialEnd",
            data?.ipadLoginV2?.student_details?.expired &&
            data?.ipadLoginV2?.student_details?.expiry_type === "free trial"
          );
          localStorage.setItem(
            "showSubscriptionEnd",
            data?.ipadLoginV2?.student_details?.expired &&
            data?.ipadLoginV2?.student_details?.expiry_type === "Learner Access"
          );
        }

        //generating device token
        if (!localStorage.getItem("deviceToken")) {
          localStorage.setItem("deviceToken", generateRandomString(10));
        }
        history.push("/dashboard");
      }
    },
    onError: (error) => {
      console.log(error);
      notification.error({
        message: error.message,
      });
    },
  });
  const [CreatePasscode, { loading: creatingPasscode }] = useMutation(
    CREATE_PASSCODE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.ipadResetPasswordV2) {
          userLogin({
            variables: {
              username: userName,
              password: confirmPassCode,
            },
          });
        }
      },
      onError: (error) => {
        console.log(error);
        notification.error({
          message: error.message,
        });
      },
    }
  );

  return (
    <div>
      <OnboardingCenterLayout loading={creatingPasscode || loggingIn}>
        <div className="flex justify-between h-9 items-center">
          <BackButton />
          <div className=" absolute left-0 right-0 text-center">
            <div className="authHeading">{`${operationType === "create"
              ? "Create"
              : operationType === "confirm"
                ? "Confirm"
                : ""
              } Passcode`}</div>
          </div>
        </div>
        <div className="xl:mt-12 mt-12">
          <div className="text-center">
            <div
              className="h-24 w-24 rounded-full m-auto"
              style={{ backgroundColor: bg_color || "whitesmoke" }}
            >
              <img src={avatar} alt="avatar" />
            </div>
            <div className="pt-5 font-normal text-xl">{userName}</div>
          </div>
          <div className=" px-0 md:px-10 lg:px-16 mt-10">
            <Form
              layout="vertical"
              form={formRef}
              onFinish={(values) => {
                console.log(values, operationType);
                if (operationType === "create") {
                  setOperationType("confirm");
                } else if (operationType === "confirm") {
                  //console.log(passCode, confirmPassCode);
                  if (passCode === confirmPassCode) {
                    CreatePasscode({
                      variables: {
                        username: userName,
                        passcode: passCode,
                        is_newLogin:
                          localStorage.getItem("newStudent") === "true",
                        trial_start_date: moment().format(
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                      },
                    });
                  } else {
                    notification.error({
                      message: "Passcode and Confirm Passcode not matching.",
                    });
                  }
                } else if (operationType === "login") {
                  userLogin({
                    variables: {
                      username: userName,
                      password: passCode,
                    },
                  });
                }
              }}
            >
              {operationType !== "confirm" && (
                <Form.Item name="passcode"
                  rules={[
                      {
                        required: true,
                        message: "Please input your passcode!",
                      },
                    ]}>
                  <Input.Password
                    name="passcode"
                    preventText
                    placeholder={`Enter your passcode`}
                    small
                    autoFocus
                    className={"w-full py-4 px-5 rounded-l-3xl rounded-r-3xl"}
                    onChange={(evt) => {
                      if (
                        !isNaN(evt.target.value) &&
                        String(evt.target.value).length <= 4
                      ) {
                        setPassCode(evt.target.value);
                        formRef.setFieldsValue({
                          passcode: evt.target.value,
                        });
                      } else {
                        setPassCode(String(evt.target.value).slice(0, -1));
                        formRef.setFieldsValue({
                          passcode: String(evt.target.value).slice(0, -1),
                        });
                      }
                    }}
                  ></Input.Password>
                </Form.Item>
              )}
              {operationType === "confirm" && (
                <Form.Item name="confirmPasscode" 
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your passcode!",
                    },
                  ]}>
                  <Input.Password
                    name="confirmPasscode"
                    preventText
                    value={confirmPassCode}
                    placeholder={`Re-Enter your passcode`}
                    small
                    className={"w-full py-4 px-5 rounded-l-3xl rounded-r-3xl"}
                    onChange={(evt) => {
                      if (
                        !isNaN(evt.target.value) &&
                        String(evt.target.value).length <= 4
                      ) {
                        setConfirmPassCode(evt.target.value);
                        formRef.setFieldsValue({
                          confirmPasscode: evt.target.value,
                        });
                      } else {
                        setConfirmPassCode(
                          String(evt.target.value).slice(0, -1)
                        );
                        formRef.setFieldsValue({
                          confirmPasscode: String(evt.target.value).slice(
                            0,
                            -1
                          ),
                        });
                      }
                    }}
                  ></Input.Password>
                </Form.Item>
              )}
              {/* {operationType === "create" && ( */}
              <div className="mt-3 text-base font-normal text-center text-lightGray">
                Note: The passcode should be only numbers upto 4 digits
                <div
                  className="lg:my-4 text-base font-bold text-center text-lightGray cursor-pointer select-none"
                  onClick={() => {
                    history.push("/forgot-passcode");
                  }}
                >
                  Forgot passcode?
                </div>
              </div>
              {/* )} */}
              <div className={`block text-center xl:mt-16 mt-12 w-56 mx-auto`}>

                <div
                  className={`${formRef.getFieldValue("passcode")
                    ? ``
                    : `opacity-50 pointer-events-none`
                    }`}
                >
                  <Button
                    onClick={async () => {
                      formRef.submit();
                    }}
                    primary
                  >{`${operationType === "create" ? "Create Passcode" : "Login"
                    }`}</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </OnboardingCenterLayout>
      <Modal
        visible={showLoginBlock}
        onCancel={() => {
          // setShowLoginBlock(false);
        }}
        closable={false}
        closeIcon={false}
        width={480}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

      // title="Choose where you want to learn today"
      >
        <div className=" px-5">
          <img
            src={Images.LoginEmptySearch}
            alt="Login Empty State"
            className="mx-auto"
          />
          <div className="h4 text-center mt-8">
            To continue enjoying Breadcrumb, please ask your parent to connect
            their profile to yours
          </div>
          <div className=" mt-10">
            <div className=" w-32 mx-auto">
              <Button
                secondary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_WEB_APP}accountSetup`,
                    "_blank"
                  );
                }}
              >
                Set up
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
