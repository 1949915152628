import { RightOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Image, notification, Typography } from "antd";
import parse from "html-react-parser";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ADD_TO_SCHEDULE_ACTIVITY,
  ADD_TO_WISHLIST_ACTIVITY,
  FINISH_ACTIVITY,
  GET_BREADCRUMB_FOR_COLLECTION,
  GET_BREADCRUMB_FOR_PLAYLIST,
  GET_COLLECTION_ACTIVITY_DETAILS,
  START_PAUSE_ACTIVITY
} from "../../../client/graphql";
import Breadcrumb from "../../../components/common/Breadcrumb";
import Button from "../../../components/common/Form/Button";
import Loader from "../../../components/common/Loader";
import Notesbutton from "../../../components/common/Notes/Notesbutton";
import NotesViewModal from "../../../components/common/NotesViewModal";
import StickyBottom from "../../../components/common/StickyBottom";
import Images from "../../../themes/Images";
import { base64regex, NotesBg } from "../../../utils";
import Learn from "./components/Learn";
import NotesFinish from "./components/NotesFinish";
import PrerequisiteCard from "./components/PrerequisiteCard";
import ScheduleTimer from "./components/ScheduleTimer";
import Steps from "./components/Steps";
import YoutubePlayer from "./components/YoutubePlayer";
import Feedback from "./feedback";

const { Paragraph, Text } = Typography;

const TitleBlock = ({ title, children }) => {
  return (
    <div className=" h7 text-lightGray mb-4 uppercase mt-5">{children}</div>
  );
};
const CardImgContainer = styled.div`
  .cardImage img,
  .cardImage video {
    height: 420px;
  }
  .thumbnail {
    min-width: 132px;
    width: 140px;
    height: 74px;
    min-height: 66px;
  }
  .tag {
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    &.blue {
      background: #c8edff;
      color: #49bff9;
    }
    &.yellow {
      background: #fffbc3;
      color: #f8c947;
    }
    &.green {
      color: #68b415;
      background: #d8ffad;
    }
    &.gray {
      background: #f0f0f0;
      color: #4f4f4f;
    }
  }
`;
const ImageContainer = styled.div`
  .ant-image {
    width: 100%;
    height: 100%;
  }
`;
const ContentPictureStyle = styled.div`
  width: 215px;
  height: 140px;
  & .bg {
    background: var(--notesBgColor);
  }
`;

const CarouselCard = ({
  thumbnailList = [],
  bottomCard,
  isWishlist = false,
  status,
  loading,
  cardType,
  isPlaylist = false,
}) => {
  const [selectedCard, setSelectedCard] = React.useState(0);
  return (
    <CardImgContainer>
      <div className="cardImage">
        {thumbnailList.length !== 0 && thumbnailList[selectedCard]?.isVideo && (
          <div>
            <video
              src={thumbnailList[selectedCard].src}
              className="w-full  object-cover rounded-lg"
            />
          </div>
        )}

        {thumbnailList.length !== 0 && !thumbnailList[selectedCard]?.isVideo && (
          <div className="relative overflow-hidden rounded-lg">
            <img className="w-full object-cover rounded-lg filter blur-3xl absolute top-0"
              src={thumbnailList[selectedCard].src}
              alt="Explore card"></img>
            <img
              className=" rounded-lg mx-auto relative"
              src={thumbnailList[selectedCard].src}
              alt="Explore card"
            />
          </div>
        )}
      </div>
      <div className="flex mt-7">
        <div className="w-8/12 relative">
          {thumbnailList.length > 6 && (
            <>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`rotate-180 transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft - 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft + 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
            </>
          )}
          <div
            className="flex items-center overflow-auto "
            id="collection-details-thumb"
          >
            {thumbnailList.map((thumbnail, thumbnailIndex) => {
              return (
                <div
                  className={`thumbnail cursor-pointer mr-4 p-1 ${thumbnailIndex === selectedCard
                    ? "border border-darkGray rounded-md"
                    : ""
                    }`}
                  onClick={() => {
                    setSelectedCard(thumbnailIndex);
                  }}
                  key={`thumbnail-${thumbnailIndex}`}
                >
                  {thumbnail.isVideo && (
                    <div className="h-full">
                      <video
                        width={200}
                        height={100}
                        src={thumbnail.src}
                        className=" rounded-md"
                      />
                    </div>
                  )}
                  {!thumbnail.isVideo && (
                    <div className="h-full">
                      <img
                        // width={200}
                        // height={100}
                        className=" w-full h-full object-cover rounded-md"
                        src={thumbnail.src}
                        alt="Explore card"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className=" w-4/12">
          <div>
            <div className="flex justify-end items-start">
              <div>
                {status === "Scheduled" && (
                  <div className="font-Nunito font-semibold text-xs tag blue">
                    Scheduled
                  </div>
                )}
                {status === "Completed" && (
                  <div className="font-Nunito font-semibold text-xs tag green">
                    Completed
                  </div>
                )}
                {status === "In - Progress" && (
                  <div className="font-Nunito font-semibold text-xs tag yellow">
                    In - Progress
                  </div>
                )}
                {status === "Not Start" && isWishlist && (
                  <div className="font-Nunito font-semibold text-xs tag gray">
                    Wishlist
                  </div>
                )}
              </div>
              <div className="ml-4">
                {loading && <div className=" loader w-5 rounded h-5"></div>}
                {!loading && (
                  <>
                    {cardType === "do" && (
                      <>
                        <img
                          width={60}
                          height={60}
                          src={
                            isPlaylist
                              ? Images.doPlaylistIcon
                              : Images.DoDetailsCardIcon
                          }
                          alt="Do Card"
                        />
                      </>
                    )}
                    {cardType === "watch" && (
                      <>
                        <img
                          width={60}
                          height={60}
                          src={
                            isPlaylist
                              ? Images.watchPlaylistIcon
                              : Images.WatchDetailsCardIcon
                          }
                          alt="Watch Card"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardImgContainer>
  );
};
var timeIntervalKey;
var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Details() {
  const history = useHistory();

  const location = useLocation();
  const { collectionId, playlistId, activityId } = useParams();
  const [isExpert, setIsExpert] = useState(false);
  const [showIsScheduled, setShowIsScheduled] = React.useState(false);
  const [isScheduled, setIsScheduled] = React.useState(false);
  const [isStarted, setIsStarted] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isFeedback, setIsFeedback] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const [pathInfo, setPathInfo] = useState(["List", "Card"]);
  const [collectionInfo, setCollectionInfo] = useState(undefined);
  const [activityInfo, setActivityInfo] = useState(undefined);
  const [feedbackInfo, setFeedbackInfo] = useState(undefined);

  const [timeEstimated, setTimeEstimated] = useState(20);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timeSpent, setTimeSpent] = useState(0);
  const [isNotesModalVisible, setIsNotesModalVisible] = React.useState(false);
  const [scheduleData, setScheduleData] = React.useState({});

  const [isLoading, setIsLoading] = useState(false);

  const pauseActivity = useCallback(async (evt) => {
    // evt.preventDefault();
    // evt.returnValue = "";
    // console.log(startedRef?.current, !pasueRef?.current);
    if (isStarted && !isPaused) {
      // console.log("running", "manually pausing");
      let pauseData = await startOrPauseActivity({
        variables: {
          activityId: activityId,
          pause: moment().format("yyyy-MM-DDTHH:mm:ss"),
        },
      });
      if (pauseData.data) {
        console.log("activity paused");
      }
    }
  });

  useEffect(() => {
    return () => {
      pauseActivity();
    };
  }, [isStarted, isPaused]);

  useEffect(() => {
    setIsExpert(location.pathname.includes(`/expert/`));
  }, [location.pathname]);

  const { loading: fetchingActivity, refetch: refetchActivity } = useQuery(
    GET_COLLECTION_ACTIVITY_DETAILS,
    {
      skip: !activityId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        activityId: activityId,
      },
      onCompleted: (data) => {
        if (data.ipadActivityDetailV2) {
          setCollectionInfo(data.ipadActivityDetailV2.collection_details);
          let activityData = data.ipadActivityDetailV2;
          let materialsSorted = [];
          let stepsSorted = [];
          if (activityData.materials_required) {
            materialsSorted = Array.from(activityData.materials_required).sort(
              (a, b) => {
                if (!a.position || !b.position) {
                  return 0;
                }
                return a.position - b.position;
              }
            );
          }
          if (activityData.steps) {
            stepsSorted = Array.from(activityData.steps).sort(
              (a, b) => parseInt(a.position) - parseInt(b.position)
            );
          }
          let timeSpent = 0;
          if (activityData.estimated_time) {
            let [hours, minutes] = String(activityData.estimated_time).split(
              ":"
            );
            let totalMins = parseInt(hours) * 60 + parseInt(minutes);
            setTimeEstimated(totalMins * 60);
            if (activityData.activity_in_details.paused_time) {
              let [mins, secs, ms] = String(
                activityData.activity_in_details.paused_time
              )
                .trim()
                .split(":");
              timeSpent = parseInt(mins) * 60 + parseInt(secs);
              setTimeElapsed(timeSpent); //in seconds
              setTimeSpent(timeSpent);
            }
            timeIntervalKey = setInterval(() => {
              setTimeElapsed((elapsed) => elapsed + 60);
            }, 60000);
          }

          if (
            activityData.activity_in_details.activity_status === "Scheduled"
          ) {
            setIsScheduled(true);
          } else if (
            activityData.activity_in_details.activity_status === "In - Progress"
          ) {
            setIsStarted(true);
          } else if (
            activityData.activity_in_details.activity_status === "Completed"
          ) {
            // setIsStarted(true);
            setIsCompleted(true);
            // if (activityData.activity_in_details.feedbacklist && !activityData.activity_in_details.feedbacklist.length) {
            //   setIsFeedback(true);
            // }
          }

          let path = [...pathInfo];
          path[1] = activityData.activity_in_details.name;
          setPathInfo(path);
          console.log(
            [
              ...((activityData.activity_in_details || {})
                .activity_nodes_images || []),
            ].sort((a, b) => {
              console.log(a);
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
          );
          setActivityInfo({
            ...activityData.activity_in_details,
            cardType:
              activityData.activity_in_details.default_activities_id === 2 ||
                activityData.activity_in_details.default_activities_id === 3
                ? "watch"
                : activityData.activity_in_details.default_activities_id === 4
                  ? "do"
                  : "watch",
            activity_details: activityData.activity_details,
            estimated_time: activityData.estimated_time,
            images: activityData.images,
            materials_required: materialsSorted,
            stepsList: stepsSorted,
            prerequisite_details: activityData.prerequisite_details,
            activity_nodes_images: [
              ...((activityData.activity_in_details || {})
                .activity_nodes_images || []),
            ].sort((a, b) => {
              return (
                moment(b.createdAt, "x").unix() -
                moment(a.createdAt, "x").unix()
              );
            }),
          });
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );
  const { loading: fetchingBreadcrumb } = useQuery(
    GET_BREADCRUMB_FOR_COLLECTION,
    {
      skip: !collectionId || collectionId === "cards",
      fetchPolicy: "network-only",
      variables: {
        collections_id: collectionId,
      },
      onCompleted: (data) => {
        if (data.ipadActivityList && data.ipadActivityList.collection_details) {
          let path = [...pathInfo];
          path[0] = data.ipadActivityList.collection_details.name;
          setPathInfo(path);
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const { loading: fetchingBreadcrumbPl } = useQuery(
    GET_BREADCRUMB_FOR_PLAYLIST,
    {
      skip: !playlistId || playlistId === "cards",
      fetchPolicy: "network-only",
      variables: {
        playlistId: playlistId,
      },
      onCompleted: (data) => {
        if (data.ipadPlaylistListV2 && data.ipadPlaylistListV2.playlist_details) {
          let path = [...pathInfo];
          path[0] = data.ipadPlaylistListV2.playlist_details[0].title;
          setPathInfo(path);
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const [addToSchedule, { loading: scheduling }] = useLazyQuery(
    ADD_TO_SCHEDULE_ACTIVITY,
    {
      fetchPolicy: "network-only",
      variables: {
        activityId: scheduleData?.activityId || activityId,
        collectionId: scheduleData?.collectionId || collectionId,
        lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      },
      onCompleted: (data) => {
        setTimeout(() => {
          if (data.addToScheduleV3) {
            refetchActivity()
            setShowIsScheduled(true);
            setIsScheduled(true);
          }
          setIsLoading(false);
        }, 1000);
      },
      onError: (err) => {
        notification.error({ message: err.message });
        setIsLoading(false);
      },
    }
  );

  const [addToWishList, { loading: addingWishList }] = useLazyQuery(
    ADD_TO_WISHLIST_ACTIVITY,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        activityId: activityId,
        lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
      },
      onCompleted: (data) => {
        if (data.addToWishListV2) {
          refetchActivity();
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  const [startOrPauseActivity, { loading: startOrPauseLoading }] = useMutation(
    START_PAUSE_ACTIVITY,
    {
      onCompleted: (data) => {
        if (data.activityStartAndPauseV2) {
          // setIsStarted(data.activityStartAndPauseV2.is_start);
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  const [finishActivity, { loading: finishActivityLoading }] = useMutation(
    FINISH_ACTIVITY,
    {
      onCompleted: (data) => {
        if (data.finishActivityV3) {
          clearInterval(timeIntervalKey);
          setIsStarted(false);
          setIsFeedback(true);
          window?.scrollTo(0, 0);
          // document?.body?.scrollTop = 0;
          // document?.documentElement?.scrollTop = 0;
          let [hours, mins, secs] = String(
            data.finishActivityV3.finish_details.time
          )
            .trim()
            .split(":");
          setTimeSpent(
            (parseInt(hours) * 60 + parseInt(mins)) * 60 + parseInt(secs)
          );
          setFeedbackInfo({
            ...data.finishActivityV3.finish_details,
            interestData: [
              ...data.finishActivityV3.finish_details?.all_tags?.interests,
              ...data.finishActivityV3.finish_details?.all_tags?.data_points.map(
                (datapoints) => {
                  let dp = { ...datapoints };
                  dp.type = "tag";
                  return dp;
                }
              ),
            ].sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }),
            bloomstax:
              data.finishActivityV3.finish_details.all_tags.data_types[0],
            multipleIntel:
              data.finishActivityV3.finish_details.all_tags.data_types[1],
            innovationSkills:
              data.finishActivityV3.finish_details.all_tags.data_types[2],
          });
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  return (
    <div>
      <Loader
        loading={
          isLoading ||
          fetchingActivity ||
          scheduling ||
          addingWishList ||
          fetchingBreadcrumb ||
          fetchingBreadcrumbPl ||
          startOrPauseLoading ||
          finishActivityLoading
        }
      />
      {!isFeedback && (
        <>
          <div className="container mx-auto bg-white pt-7 px-8 min-h-screen">
            <div>
              {collectionId !== "cards" && playlistId !== "cards" && (
                <Breadcrumb
                  routes={[
                    {
                      url: location.pathname.includes("collections")
                        ? `/collections/${location.pathname.includes("expert")
                          ? `expert/`
                          : ``
                        }${collectionId}`
                        : `/playlists/${location.pathname.includes("expert")
                          ? `expert/`
                          : ``
                        }${playlistId}`,
                      label: pathInfo[0] || "",
                    },
                    { url: "", label: activityInfo?.name },
                  ]}
                />
              )}
              <div className="h3 text-left mb-4">{activityInfo?.name}</div>
              {activityInfo?.thumb_images &&
                activityInfo?.thumb_images.length !== 0 && (
                  <CarouselCard
                    status={activityInfo?.activity_status}
                    cardType={activityInfo?.cardType}
                    loading={fetchingActivity}
                    isWishlist={activityInfo?.is_wishlist}
                    thumbnailList={
                      activityInfo?.thumb_images
                        ? activityInfo.thumb_images.map((image) => {
                          return {
                            src: image.image_url,
                            isVideo: false,
                          };
                        })
                        : []
                    }
                    isPlaylist={activityInfo?.playlist_id}
                  />
                )}
              {isExpert && (
                <div
                  className={`mt-1 font-semibold font-Nunito text-normal text-blue flex ${fetchingActivity ? "loader rounded h-3 w-8/12  mt-2" : ""
                    }`}
                >
                  {fetchingActivity
                    ? ""
                    : `By ${activityInfo?.user?.name || ""}`}{" "}
                  {activityInfo?.user?.role_id === 4 && (
                    <img
                      src={Images.VerifiedIcon}
                      alt="verified Icon"
                      className="ml-2"
                    />
                  )}
                </div>
              )}
              <div className="flex items-center justify-center mt-5">
                <div className="grid gap-40 grid-cols-3 w-8/12">
                  {activityInfo?.cardType === "do" && (
                    <div>
                      <div className="mb-3  h7 text-lightGray">Difficulty</div>
                      <div className=" text-textDark h5">
                        {activityInfo?.difficulty}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="mb-3  h7 text-lightGray">
                      {activityInfo?.cardType === "do"
                        ? "Time"
                        : "Total watch time"}
                    </div>
                    <div className=" text-textDark h5">
                      {activityInfo?.time || activityInfo?.total_time || 0} mins
                    </div>
                  </div>
                  <div>
                    <div className="mb-3  h7 text-lightGray">
                      Adult supervision
                    </div>
                    <div className=" text-textDark h5">
                      {activityInfo?.safety}
                    </div>
                  </div>
                </div>
                <div className="w-4/12">
                  {activityInfo?.safety === "Required" &&
                    activityInfo?.safety_desc && (
                      <div className="h7 py-2 px-3 w-full bg-cultWhite rounded">
                        {activityInfo?.safety_desc}
                      </div>
                    )}
                </div>
              </div>

              {activityInfo?.trailer_url && (
                <div id="watchtrailer">
                  <TitleBlock>Watch trailer</TitleBlock>
                  <YoutubePlayer
                    poster="https://plchldr.co/i/450x655?bg=EB6361"
                    videoURL={String(activityInfo?.trailer_url).replace(
                      `watch?v=`,
                      `embed/`
                    )}
                  ></YoutubePlayer>
                  <div className="flex justify-between mt-3">
                    <div></div>
                    <div className=" h6 flex gap-3">
                      <span className=" font-medium">Source:</span>
                      <a
                        href={activityInfo?.trailer_credit_url}
                        className=" text-skyBlue"
                      >
                        {activityInfo?.trailer_credit_text}
                      </a>
                    </div>
                  </div>
                  <div className="mt-4 mb-8 border-b border-gray4 "></div>
                </div>
              )}
              <div className="h7 text-black mt-7" id="textLongDesc">
                {activityInfo?.breif_desc || ""}
                {/* {(isStarted || isCompleted) && ( */}
                <div className="mt-5">
                  {parse(String(activityInfo?.long_desc || ""))}
                </div>
                {/* )} */}
              </div>
              {activityInfo?.prerequisite_details?.length !== 0 && (
                <div className="mt-5">
                  <TitleBlock>YOU SHOULD ALREADY KNOW</TitleBlock>
                  <div className=" flex flex-wrap w-full gap-4 ">
                    {(activityInfo?.prerequisite_details || []).map(
                      (material, materialIndex) => (
                        <div key={`Prerequisite-${materialIndex}`}>
                          <PrerequisiteCard
                            status={
                              material.activity_status ||
                              material.playlist_status
                            }
                            imgURL={
                              material?.thumb_images?.length
                                ? material.thumb_images[0].image_url
                                : material.background_image
                            }
                            title={material.name}
                            hideStatus={false}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              {/* <div className="mt-4 mb-8 border-b border-gray4 "></div> */}
              {(isStarted || isCompleted) && activityInfo?.video_link && (
                <div id="watchHowTo">
                  <TitleBlock>Watch</TitleBlock>
                  <YoutubePlayer
                    poster="https://plchldr.co/i/450x655?bg=EB6361"
                    videoURL={String(activityInfo?.video_link).replace(
                      `watch?v=`,
                      `embed/`
                    )}
                  ></YoutubePlayer>
                  <div className="flex justify-between mt-3">
                    <div></div>
                    <div className=" h6 flex gap-3">
                      <span className=" font-medium">Source:</span>
                      <a
                        href={activityInfo?.video_credit_link}
                        className=" text-skyBlue"
                      >
                        {activityInfo?.video_credit_text}
                      </a>
                    </div>
                  </div>
                  <div className="mt-4 mb-8 border-b border-gray4 "></div>
                </div>
              )}
              <div className="mt-4"></div>
              {activityInfo?.materials_required &&
                activityInfo.materials_required.length !== 0 && (
                  <div className="mt-5">
                    <TitleBlock>YOU WOULD NEED</TitleBlock>
                    <div className=" flex flex-wrap ">
                      {activityInfo.materials_required.map((material) => (
                        <div className="mr-7 w-20 mb-4">
                          <div className=" w-20 h-20 rounded bg-black bg-opacity-30 overflow-hidden">
                            {/* <Image
                              src={material.image}
                              alt="Material Img"
                              /> */}
                            <img
                              className="w-full h-full object-cover "
                              src={material.image}
                              alt="Material img"
                            />
                          </div>
                          <div className="h9 mt-2 text-center">
                            {material.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {(isStarted || isCompleted) && activityInfo?.pdf_url && (
                <div className="mt-5">
                  <TitleBlock>PDF</TitleBlock>
                  <a
                    href={activityInfo?.pdf_url}
                    target="_blank"
                    className=" text-skyBlue inline-block"
                    rel="noreferrer"
                  >
                    <div className=" flex flex-wrap gap-4 items-center ">
                      <img src={Images.PdfRefIcon} alt="PDF URL" />

                      {activityInfo?.pdf_name}
                    </div>
                  </a>
                  <div className="flex justify-between mt-3">
                    <div>{/* {activityInfo?.pdf_name} */}</div>
                    <div className=" h6 flex gap-3">
                      <span className=" font-medium">Source:</span>
                      <a
                        href={activityInfo?.pdf_source_url}
                        className=" text-skyBlue"
                      >
                        {activityInfo?.pdf_source_text}
                      </a>
                    </div>
                  </div>
                  <div className="mt-4 mb-8 border-b border-gray4 "></div>
                </div>
              )}

              {/* <div className="mt-4 mb-8 border-b border-gray4 "></div> */}
              {(isStarted || isCompleted) &&
                activityInfo?.stepsList &&
                activityInfo?.stepsList.length !== 0 && (
                  <>
                    <TitleBlock>
                      Steps to do ({(activityInfo?.stepsList || []).length})
                    </TitleBlock>
                    <Steps data={activityInfo?.stepsList} />
                    <div className="flex justify-between mt-3">
                      <div></div>
                      <div className=" h6 flex gap-3">
                        <span className=" font-medium">Source:</span>
                        <a
                          href={activityInfo?.steps_source_link}
                          className=" text-skyBlue"
                        >
                          {activityInfo?.steps_source_text}
                        </a>
                      </div>
                    </div>
                    <div className="mt-4 mb-8 border-b border-gray4 "></div>
                  </>
                )}
              {(isStarted || isCompleted) &&
                activityInfo?.images &&
                activityInfo?.images.length !== 0 && (
                  <div className="pb-10">
                    <TitleBlock>Use this images to learn from</TitleBlock>
                    <div className=" flex flex-wrap gap-4">
                      {activityInfo?.images.map((content) => (
                        <ImageContainer className=" w-40 h-20 flex-shrink-0 ">
                          <Image
                            src={content.image_url}
                            alt="learn from"
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </ImageContainer>
                      ))}
                    </div>
                    <div className="flex justify-between mt-3">
                      <div></div>
                      <div className=" h6 flex gap-3">
                        <span className=" font-medium">Source:</span>
                        <a
                          href={activityInfo?.image_source_link}
                          className=" text-skyBlue"
                        >
                          {activityInfo?.image_source_text}
                        </a>
                      </div>
                    </div>
                    <div className="mt-4 mb-8 border-b border-gray4 "></div>
                  </div>
                )}
              <div id="notesSection">
                {(isStarted || isCompleted) &&
                  activityInfo?.activity_nodes_images &&
                  activityInfo?.activity_nodes_images.length !== 0 && (
                    <div>
                      <TitleBlock>
                        LEARNING CONTENT PICTURES AND NOTES
                      </TitleBlock>
                      <div className="mt-6 flex flex-wrap ">
                        {activityInfo?.activity_nodes_images.map(
                          (pictureStyle, styleIndex) => {
                            return (
                              <ContentPictureStyle
                                className="relative mr-4 mb-4 rounded overflow-hidden cursor-pointer"
                                key={`PictureStyle-${styleIndex}`}
                                onClick={() => {
                                  setIsNotesModalVisible(true);
                                  setModalData({
                                    url: pictureStyle?.image,
                                    notes: pictureStyle?.notes,
                                  });
                                }}
                              >
                                <img
                                  src={
                                    pictureStyle.type === "doodle" ||
                                      pictureStyle.type === "captured" ||
                                      pictureStyle.type === "upload" ||
                                      pictureStyle.type === "uploaded"
                                      ? pictureStyle.image
                                      : pictureStyle.type === "audio"
                                        ? Images.NotesAudioBg
                                        : NotesBg(pictureStyle.notes)
                                  }
                                  alt="notes img"
                                  className="w-full h-full rounded"
                                />

                                <div className="absolute inset-0 bg"></div>
                                <div className="absolute bottom-0 p-2">
                                  <div className="h6 text-pureWhite">
                                    {`${moment(
                                      pictureStyle.createdAt,
                                      "x"
                                    ).format("MMM DD, YYYY")} - ${pictureStyle.type === "captured" ||
                                      pictureStyle.type === "upload" ||
                                      pictureStyle.type === "uploaded"
                                      ? "Photo"
                                      : pictureStyle.type === "doodle"
                                        ? "Doodle"
                                        : pictureStyle.type === "audio"
                                          ? "Audio"
                                          : "Notes"
                                      }`}
                                    {(pictureStyle.type === "captured" ||
                                      pictureStyle.type === "upload" ||
                                      pictureStyle.type === "audio" ||
                                      pictureStyle.type === "uploaded") && (
                                        <Paragraph
                                          ellipsis={{
                                            rows: 2,
                                          }}
                                          className="mb-0 h6 text-pureWhite"
                                        >
                                          {pictureStyle.notes
                                            ? base64regex.test(pictureStyle.notes)
                                              ? decodeURIComponent(
                                                escape(
                                                  window.atob(
                                                    pictureStyle.notes
                                                  )
                                                )
                                              )
                                              : pictureStyle.notes
                                            : ""}
                                        </Paragraph>
                                      )}
                                  </div>
                                </div>
                              </ContentPictureStyle>
                            );
                          }
                        )}
                      </div>
                      <div className="mt-4 mb-8 border-b border-gray4 "></div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {showIsScheduled && (
            <div className=" w-60 p-4 fixed flex shadow-7xl right-8 bottom-20 rounded-xl bg-white ">
              <img
                src={Images.ScheduleStickyIcon}
                alt="Schedule Icon"
                className="mr-4"
              />
              <div>
                <div className="nunito-semibold-lg text-textDark tracking-tighter">
                  Added to schedule
                </div>
                <Link to="/schedule">
                  <div
                    className="flex items-center cursor-pointer mt-4 text-blue text-right nunito-semibold-xs justify-end"
                    onClick={() => { }}
                  >
                    Go to Schedule <RightOutlined className="ml-1" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          <StickyBottom
            className={
              playlistId && activityInfo?.activity_status === "Not Start"
                ? `hidden`
                : ``
            }
          >
            <div className="flex justify-between items-center  px-12 bg-white  py-3">
              {isStarted && (
                <>
                  <div>
                    <ScheduleTimer
                      isPaused={isPaused}
                      onPause={async () => {
                        let pauseData = await startOrPauseActivity({
                          variables: {
                            activityId: activityId,
                            pause: moment().format("yyyy-MM-DDTHH:mm:ss"),
                            lc_id: localStorage.lc_id
                              ? parseInt(localStorage.lc_id)
                              : lc_id,
                          },
                        });
                        if (pauseData.data) {
                          setIsPaused(true);
                          clearInterval(timeIntervalKey);
                        }
                      }}
                      onContinue={async () => {
                        let pauseData = await startOrPauseActivity({
                          variables: {
                            activityId: activityId,
                            start: moment().format("yyyy-MM-DDTHH:mm:ss"),
                            lc_id: localStorage.lc_id
                              ? parseInt(localStorage.lc_id)
                              : lc_id,
                          },
                        });
                        if (pauseData.data) {
                          setIsPaused(false);
                          timeIntervalKey = setInterval(() => {
                            setTimeElapsed((elapsed) => elapsed + 60);
                          }, 60000);
                        }
                      }}
                      timeLeft={timeElapsed <= timeEstimated}
                      time={
                        timeElapsed <= timeEstimated
                          ? ((timeEstimated - timeElapsed) / 60).toFixed(0)
                          : ((timeElapsed - timeEstimated) / 60).toFixed(0)
                      }
                    />
                  </div>
                  <NotesFinish
                    type={activityInfo?.cardType}
                    title={activityInfo?.name}
                    playlists={activityInfo?.activity_details}
                    disabled={activityInfo?.activity_nodes_images?.length === 0}
                    onSuccess={() => {
                      refetchActivity();
                    }}
                    onFinish={() => {
                      if (activityInfo?.activity_nodes_images?.length)
                        finishActivity({
                          variables: {
                            lc_id: localStorage.lc_id
                              ? parseInt(localStorage.lc_id)
                              : lc_id,
                            activityId: activityId,
                            playlistId: playlistId,
                            finish: moment().format("yyyy-MM-DDTHH:mm:ss"),
                          },
                        });
                    }}
                  />
                </>
              )}
              {!isStarted && !isCompleted && !isFeedback && (
                <>
                  <div className="nunito-bold-2xl text-textDark px-4">
                    Getting Started
                  </div>
                  <div className="flex ">
                    <>
                      <div className="mr-5">
                        <Button
                          className="font-Nunito text-darkGray border border-darkGray pl-3 pr-6  py-2 flex text-base font-bold"
                          onClick={() => {
                            addToWishList();
                          }}
                        >
                          {activityInfo?.is_wishlist ? (
                            <div className=" flex justify-center items-center">
                              <img
                                src={Images.LearningPathCloseIcon}
                                alt="Close Icon"
                                className="mr-3"
                              />
                              {`Remove from wishlist`}
                            </div>
                          ) : (
                            <div className=" flex justify-center items-center">
                              <img
                                src={Images.WishlistIcon}
                                alt="Wishlist Icon"
                                className="mr-3"
                              />
                              {`Add to wishlist`}
                            </div>
                          )}
                        </Button>
                      </div>
                      <div>
                        {isScheduled ? (
                          <Learn
                            type={activityInfo?.cardType}
                            materials={activityInfo?.materials_required}
                            estimate={`${activityInfo?.time}m`}
                            prerequisite_details={
                              activityInfo?.prerequisite_details
                            }
                            onStartLearning={async (timeEstimatedInMins) => {
                              setShowIsScheduled(false);
                              setTimeEstimated(timeEstimatedInMins * 60);
                              timeIntervalKey = setInterval(() => {
                                setTimeElapsed((elapsed) => elapsed + 60);
                              }, 60000);

                              let startData = await startOrPauseActivity({
                                variables: {
                                  activityId: activityId,
                                  start: moment().format("yyyy-MM-DDTHH:mm:ss"),
                                  estimated: `${String(
                                    Math.floor(timeEstimatedInMins / 60)
                                  ).padStart(2, "0")}:${String(
                                    timeEstimatedInMins % 60
                                  ).padStart(2, "0")}`,
                                  lc_id: localStorage.lc_id
                                    ? parseInt(localStorage.lc_id)
                                    : lc_id,
                                },
                              });
                              if (startData.data.activityStartAndPauseV2) {
                                setIsStarted(
                                  startData.data.activityStartAndPauseV2
                                    .is_start
                                );
                              }
                            }}
                            onSchedule={(data) => {
                              setScheduleData(data);
                              setIsLoading(true);
                              addToSchedule();
                            }}
                          />
                        ) : (
                          <Button
                            secondary
                            className="font-Nunito text-base text-pureWhite px-6  py-2 flex font-bold"
                            onClick={() => {
                              setIsLoading(true);
                              addToSchedule();
                            }}
                          >
                            <img
                              src={Images.LearningPathAddIcon}
                              alt="Add Icon"
                              className="mr-3"
                            />
                            {` Add to schedule`}
                          </Button>
                        )}
                      </div>
                    </>
                  </div>
                </>
              )}
              {isCompleted && (
                <>
                  <div className="nunito-bold-2xl text-textDark px-4">
                    Completed in {(timeSpent / 60).toFixed(0)}m{" "}
                    {(timeSpent % 60).toFixed(0)}s
                  </div>

                  <Notesbutton
                    type={activityInfo?.cardType}
                    onSuccess={() => {
                      refetchActivity();
                    }}
                    title={"Make notes"}
                    enableCrop={true}
                  />
                </>
              )}
            </div>
          </StickyBottom>
        </>
      )}
      {isFeedback && (
        <>
          <Feedback
            data={feedbackInfo}
            path={pathInfo}
            activityId={activityId}
            cardType={activityInfo?.cardType}
            cover={activityInfo?.thumb_images[0]}
            title={activityInfo?.name}
            estimate={(timeEstimated / 60).toFixed(0)}
            actual={timeSpent ? (timeSpent / 60).toFixed(0) : 0}
            onDone={() => {
              setIsFeedback(false);
              setIsCompleted(true);
              history.push("/journal");
            }}
          />
        </>
      )}
      <NotesViewModal
        isModalVisible={isNotesModalVisible}
        handleClose={() => {
          setIsNotesModalVisible(false);
          setModalData(null);
        }}
        data={modalData}
      />
    </div>
  );
}
