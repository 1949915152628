import React from "react";

export default function ChartLegend(props) {
  return (
    <div>
      <div className="flex justify-content-between items-center h-full pt-4  pb-2 mb-4">
        <div className="flex justify-content-between flex-column h-full">
          <div>
            <div className="flex">
              <p className=" w-1/2 p-0 h9 flex items-center">
                <span className="mr-4 w-5 h-5 border rounded border-blue bg-blue bg-opacity-30" />{" "}
                <span>Previous month’s progress</span>
              </p>
              <p className=" w-1/2 p-0 h9 flex items-center">
                <span className="mr-4 w-5 h-5 border rounded  border-rose bg-rose bg-opacity-30" />
                <span>Current month’s progress</span>
              </p>
            </div>
          </div>
          <div>
            {(props.data || []).map((data, dataIndex) => {
              return (
                <p key={`DataPoints-${dataIndex}`} className="h9">
                  {data}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Incase of Text alignment issue refer radar.js search caption function
 */
