import { useQuery } from "@apollo/client";
import { notification, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { GET_INTEREST_VIEW, GET_TAG_VIEW } from "../../client/graphql";
import ImageCard from "../../components/Cards/ImageCard";
import Breadcrumb from "../../components/common/Breadcrumb";
import Loader from "../../components/common/Loader";
import { BackBlueDualIcon } from "../../themes/dualImages";
const { Paragraph, Text } = Typography;
const TitleBlock = ({ title, children }) => {
  return (
    <div className=" h3 text-textDark mb-5 mt-4 text-left">{children}</div>
  );
};
const IntroContainer = styled.div`
  .img {
    min-width: 230px;
    height: 138px;
    border-radius: 4px;
    overflow: hidden;
  }
`;
var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Interest(props) {
  const [ellipsis, setEllipsis] = React.useState(true);
  const [interestInfo, setInterestInfo] = useState(undefined);
  // const { interestId, tagId } = useParams();
  const { loading: fetchingDetails } = useQuery(GET_INTEREST_VIEW, {
    skip: !props.interestId,
    fetchPolicy: "network-only",
    variables: {
      id: props.interestId,
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
    },
    onCompleted: (data) => {
      if (data.iPadInterestView) {
        console.log(data.iPadInterestView);
        setInterestInfo(data.iPadInterestView.tags_details);
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const { loading: fetchingTagDetails } = useQuery(GET_TAG_VIEW, {
    skip: !props.tagId,
    fetchPolicy: "network-only",
    variables: {
      id: props.tagId,
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
    },
    onCompleted: (data) => {
      if (data.iPadTagView) {
        console.log(data.iPadTagView);
        setInterestInfo(data.iPadTagView.tags_details);
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  return (
    <div>
      <Loader loading={fetchingDetails || fetchingTagDetails} />
      <div className="container mx-auto px-8">
        <div className="flex justify-between">
          <Breadcrumb
            routes={[
              { url: "", label: props.path[0] },
              { url: "", label: props.path[1] },
              { url: "", label: interestInfo?.tag.name },
            ]}
          />
          {props.goBack && (
            <>
              <div
                className=" px-5 py-2 text-blue border border-blue rounded-4xl flex items-center cursor-pointer"
                onClick={() => {
                  props.goBack && props.goBack();
                }}
              >
                {/* <img
                  src={Images.BackBlueIcon}
                  alt="Back button"
                  className="mr-3"
                /> */}
                <div className="mr-3">

                  <BackBlueDualIcon />
                </div>
                Back
              </div>
            </>
          )}
        </div>
        <TitleBlock>{interestInfo?.tag.name}</TitleBlock>
        <IntroContainer className="flex">
          <div className="img mr-4">
            <img
              src={interestInfo?.tag.profile_image}
              alt="Into img"
              className="w-full h-full object-cover"
            />
          </div>
          <Paragraph
            ellipsis={
              ellipsis
                ? {
                  rows: 6,
                  expandable: true,
                  symbol: <div className="inline-block h6 ">Read more</div>,
                }
                : false
            }
          >
            {interestInfo?.tag.desc}
          </Paragraph>
        </IntroContainer>
        <div className=" mt-2 mb-4">
          <TitleBlock>Similar learning content</TitleBlock>
        </div>
        <div className="grid grid-cols-4 gap-x-4 gap-y-1">
          {interestInfo?.card_details?.map((card, cardIndex) => {
            return (
              <div className={` px-2`} key={`Cards-${cardIndex}`}>
                <ImageCard
                  title={card.name || ``}
                  coverImage={
                    card.activities_thumb_images &&
                      card.activities_thumb_images.length
                      ? card.activities_thumb_images[0].image_url
                      : undefined
                  }
                  status={card.activity_status}
                  cardType={
                    card.default_activities_id === 2 ||
                      card.default_activities_id === 3
                      ? "watch"
                      : card.default_activities_id === 4
                        ? "do"
                        : "watch"
                  }
                  isWishlist={card.is_wishlist}
                  difficultyLevel={card.difficulty}
                  timeLevel={card.time ? `${card.time} mins` : ""}
                  videoRefIcon={card.video_link || card.url}
                  stepsRefIcon={card.steps}
                  imgRefIcon={card.activity_reference_images}
                  pdfRefIcon={card.pdf_url}
                  loading={false}
                />
              </div>
            );
          })}
          {!interestInfo?.card_details?.length && (
            <div className="col-span-4 font-semibold text-normal text-center">
              No Similar Content found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
