import styled from "styled-components";

export const TabContainer = styled.div`
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    background: rgb(var(--blue));
  }
  .ant-tabs-tab {
    font-family: Nunito;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #828282;
    &.ant-tabs-tab-active {
      & .ant-tabs-tab-btn {
        color: rgb(var(--blue)) !important;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-color: transparent;
  }
`;
