import React from "react";
import styled, { css } from "styled-components";
const ButtonContainer = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  & > div {
    border-radius: 50px;
  }
  ${props => (props.primary || props.secondary || props.danger) && css`
    color: #fff
  `}
`;
export default function Button({
  onClick,
  children,
  secondary,
  primary,
  secondaryGhost,
  className,
  danger,
  disabled = false,
}) {
  function debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  return (
    <ButtonContainer
      disabled={disabled}
      className={disabled ? " opacity-50 pointer-events-none" : ""}
      primary={primary}
      secondary={secondary}
      danger={danger}
    >
      <div
        className={` font-Nunito text-center select-none ${primary ? " bg-violet border border-violet" : ""
          } ${secondary ? " bg-blue border-blue border" : ""} ${secondaryGhost ? "border-secondaryGhost border text-secondaryGhost bg-white" : ""
          } ${danger ? "border-rose border  bg-rose" : ""} ${className ? className : "py-3 text-base font-bold"
          }`}
        onClick={onClick && debounce(() => onClick())}
      >
        {children}
      </div>
    </ButtonContainer>
  );
}
