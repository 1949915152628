import { useLazyQuery } from "@apollo/client";
import { Button as AntdButton, notification, Popover } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ADD_ALL_TO_SCHEDULE,
  ADD_TO_WISHLIST,
  GET_PLAYLIST_DETAILS
} from "../../client/graphql";
import ImageCard from "../../components/Cards/ImageCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import StickyBottom from "../../components/common/StickyBottom";
import Images from "../../themes/Images";

const PopOverContainer = (props) => {
  return (
    (props.playlists || []).length !== 0 && (
      <div className="relative">
        <div id="playlistNotes"></div>
        <Popover
          placement="top"
          getPopupContainer={() => document.getElementById("playlistNotes")}
          title={null}
          overlayClassName="rounded-lg pb-0 mb-3 shadow-5xl overflow-scroll w-72 max-h-60 "
          content={
            <div className="h9 text-charcoal ">
              {(props.playlists || []).map((playlist, playlistIndex) => {
                return (
                  <div
                    className={`flex items-center ${
                      playlistIndex + 1 === (props.playlists || []).length
                        ? ""
                        : "mb-3"
                    }  relative`}
                    key={`Playlist-${playlistIndex}`}
                  >
                    <div
                      className={`h5 px-3 rounded-xl ${
                        playlist.activity_status === "Completed"
                          ? `bg-green`
                          : playlist.activity_status === "In - Progress"
                          ? `bg-yellow`
                          : `bg-violet`
                      } text-pureWhite  `}
                    >
                      {playlist.position + 1}
                    </div>
                    <div className="ml-3">{playlist.name}</div>
                    {playlist.activity_status === "Completed" && (
                      <img
                        src={Images.PlaylistCheckedIcon}
                        alt="PlaylistCheckedIcon"
                        className="absolute right-2 top-1"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          }
          trigger="click"
        >
          <div className="mr-8 w-">
            <img
              src={Images.PlaylistScheduleIcon}
              alt="Playlist Icon"
              className="cursor-pointer"
            />
          </div>
        </Popover>
      </div>
    )
  );
};

export default function Listing() {
  // Store only the incompleted cards
  const location = useLocation();
  const history = useHistory();
  const { playListId } = useParams();
  const [playListInfo, setPlayListInfo] = React.useState(null);
  const [cardsList, setCardsList] = React.useState([]);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [showAllCards, setshowAllCards] = React.useState(false);
  // Store only the completed cards
  const [completedCardsList, setcompletedCardsList] = React.useState([]);
  const [showContent, setShowContent] = React.useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (playListId) {
      fetchPlaylistInfo({
        variables: {
          playlistId: playListId,
        },
      });
    }
  }, [playListId]);

  React.useEffect(() => {
    if (location.pathname.includes("/playlists/expert")) {
      setShowContent({
        isExpertPlaylist: true,
      });
    } else if (location.pathname.includes("/playlists")) {
      setShowContent({
        isPlaylist: true,
      });
    }
  }, []);

  const [fetchPlaylistInfo, { loading: playlistLoading }] = useLazyQuery(
    GET_PLAYLIST_DETAILS,
    {
      skip: !playListId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.ipadPlaylistListV2) {
          console.log(data.ipadPlaylistListV2);
          let playListInfo = data.ipadPlaylistListV2.playlist_details[0];
          if(playListInfo.playlist_status === 'Scheduled'){
            setIsScheduled(true);
          }
          setPlayListInfo(playListInfo);
          if (data.ipadPlaylistListV2.all_activities) {
            let completedList = [];
            let cardList = [];
            for (let activity of data.ipadPlaylistListV2.all_activities) {
              let status =
                activity.activity_status || activity.status || "Not Start";
              if (status === "Completed") {
                completedList.push(activity);
              } else {
                cardList.push(activity);
              }
            }
            setcompletedCardsList(
              completedList.sort((a, b) => a.position - b.position)
            );
            setCardsList(cardList.sort((a, b) => a.position - b.position));

            if (data.ipadPlaylistListV2.total_playlist === completedList.length) {
              setIsCompleted(true);
            }
          }
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const [AddToWishlist,{loading: addingWishlist}] = useLazyQuery(ADD_TO_WISHLIST, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      playlistId: playListId,
    },
    onCompleted: (data) => {
      if (data.addToWishListV2) {
        if (data.addToWishListV2.is_updated) {
          fetchPlaylistInfo({
            variables: {
              playlistId: playListId,
            },
          });
        }
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const [AddAllToSchedule,{loading: addingSchedule}] = useLazyQuery(ADD_ALL_TO_SCHEDULE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      playlistId: playListId,
    },
    onCompleted: (data) => {
      setTimeout(() => {
        if (data.addToSchedulePlaylistLearner) {
          if (data.addToSchedulePlaylistLearner.is_schedule) {
            fetchPlaylistInfo({
              variables: {
                playlistId: playListId,
              },
            });
            notification.success({
              message: "Added to schedule",
            });
          }
        }
        setIsLoading(false);
      }, 1000);
    },
    onError: (error) => {
      notification.error({ message: error.message });
      setIsLoading(false);
    },
  });

  const onCardOpen = useCallback(
    (cardId) => {
      if (showContent.isExpertPlaylist) {
        history.push(`/playlists/expert/${playListId}/${cardId}`);
      } else {
        history.push(`/playlists/${playListId}/${cardId}`);
      }
    },
    [playListId, showContent]
  );

  return (
    <div spinning={playlistLoading}>
      <Loader loading={isLoading || playlistLoading || addingWishlist || addingSchedule} />
      <div className="bg-white">
        <ExploreHeading
          title={playListInfo?.name || playListInfo?.title}
          onClick={() => {
            history.push("/dashboard");
          }}
        ></ExploreHeading>
        <div className="px-12">
          {showContent?.isPlaylist && (
            <div className="mt-6 rounded-md pt-3 pb-4 pl-4 pr-6 bg-ghostWhite">
              <div className="font-Nunito font-bold text-textDark">
                About {playListInfo?.name || playListInfo?.title || ""}
              </div>
              <div className="font-Nunito font-normal text-sm text-textDark mt-2 ">
                {playListInfo?.description}
              </div>
            </div>
          )}
          {showContent.isExpertPlaylist && (
            <div className="mt-6 rounded-md pt-3 pb-4 pl-4 pr-6 bg-ghostWhite">
              <div className="font-Nunito font-bold text-textDark">
                About {playListInfo?.name || playListInfo?.title || ""}
              </div>
              <div>
                <div className="mt-2 font-semibold font-Nunito text-white flex">
                  <AntdButton
                    type="link"
                    className="px-0"
                    onClick={() => {
                      history.push(
                        `/playlists/expert/contents/${playListInfo?.user?.id}`
                      );
                    }}
                  >
                    {`By ${playListInfo?.user?.name || ""}`}{" "}
                  </AntdButton>
                  {playListInfo?.user?.role_id === 4 && (
                    <img
                      src={Images.VerifiedIcon}
                      alt="verified Icon"
                      className="ml-2"
                    />
                  )}
                </div>
                <div className="mt-2 font-Nunito font-normal text-sm text-textDark">
                  {playListInfo?.description}
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <div className="font-Nunito font-bold text-textDark text-xl flex justify-between items-center">
              {`Cards - ${cardsList.length || 0}`}
            </div>
            {cardsList.length === 0 && (
              <div className="mt-8 text-center">
                <img
                  src={Images.CollectionEmptyState}
                  alt="Empty State"
                  className="w-96 mx-auto"
                />
                <div className="mt-8 font-Nunito text-textDark text-lg">
                  {playListInfo?.completion_msg}
                  {/* Seems like you have already completed the contents separately */}
                </div>
              </div>
            )}
            <div className="grid grid-cols-4 gap-x-5 gap-y-1 mt-5">
              {playlistLoading &&
                [{}, {}, {}, {}].map((card, cardIndex) => {
                  return (
                    <div>
                      <ImageCard
                        title={``}
                        coverImage={
                          card.thumb_images && card.thumb_images.length
                            ? card.thumb_images[0].image_url
                            : undefined
                        }
                        status={
                          card.activity_status
                            ? card.activity_status
                            : card.card_status
                        }
                        cardType={
                          card.default_activities_id === 2 ||
                          card.default_activities_id === 3
                            ? "watch"
                            : card.default_activities_id === 4
                            ? "do"
                            : "watch"
                        }
                        isWishlist={playListInfo?.is_wishlist}
                        difficultyLevel={card.difficulty}
                        timeLevel={``}
                        videoRefIcon={card.video_link || card.url}
                        stepsRefIcon={card.steps}
                        imgRefIcon={card.activity_reference_images}
                        pdfRefIcon={card.pdf_url}
                        loading
                      />
                    </div>
                  );
                })}
              {cardsList.map((card, cardIndex) => {
                if (cardIndex < 4 || showAllCards)
                  return (
                    <div
                      className={` cursor-pointer `}
                      onClick={() => {
                        onCardOpen(card.id);
                      }}
                    >
                      <ImageCard
                        orderNumber={card.position + 1}
                        title={card.name || `Card ${cardIndex + 1}`}
                        coverImage={
                          card.activities_thumb_images &&
                          card.activities_thumb_images.length
                            ? card.activities_thumb_images[0].image_url
                            : undefined
                        }
                        status={
                          card.activity_status
                            ? card.activity_status
                            : "Not Start"
                        }
                        cardType={
                          card.default_activities_id === 2 ||
                          card.default_activities_id === 3
                            ? "watch"
                            : card.default_activities_id === 4
                            ? "do"
                            : "watch"
                        }
                        isWishlist={playListInfo?.is_wishlist}
                        difficultyLevel={card.difficulty}
                        timeLevel={`${card.time} mins`}
                        videoRefIcon={card.video_link || card.url}
                        stepsRefIcon={card.steps}
                        imgRefIcon={card.activity_reference_images}
                        pdfRefIcon={card.pdf_url}
                        onWishlist={() => {
                          AddToWishlist();
                        }}
                      />
                    </div>
                  );
              })}
            </div>
            {cardsList.length > 4 && !showAllCards && (
              <div className="mt-5">
                <div className=" w-32 mx-auto  cursor-pointer">
                  <Button
                    secondaryGhost
                    onClick={() => {
                      setshowAllCards(true);
                    }}
                  >
                    {`+ ${cardsList.length - 4} more`}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {completedCardsList && completedCardsList.length !== 0 && (
            <div className="mt-4">
              <div className="font-Nunito font-bold text-textDark text-xl">
                {`Completed - ${completedCardsList.length || 0}`}
              </div>
              <div className="grid grid-cols-4 gap-x-5 gap-y-1 mt-5">
                {playlistLoading &&
                  [{}, {}, {}, {}].map((card, cardIndex) => {
                    return (
                      <div>
                        <ImageCard
                          title={``}
                          coverImage={
                            card.thumb_images && card.thumb_images.length
                              ? card.thumb_images[0].image_url
                              : undefined
                          }
                          status={
                            card.activity_status
                              ? card.activity_status
                              : card.card_status
                          }
                          cardType={
                            card.default_activities_id === 2 ||
                            card.default_activities_id === 3
                              ? "watch"
                              : card.default_activities_id === 4
                              ? "do"
                              : "watch"
                          }
                          isWishlist={card.is_wishlist}
                          difficultyLevel={card.difficulty}
                          timeLevel={``}
                          videoRefIcon={card.video_link || card.url}
                          stepsRefIcon={card.steps}
                          imgRefIcon={card.activity_reference_images}
                          pdfRefIcon={card.pdf_url}
                          loading
                        />
                      </div>
                    );
                  })}
                {completedCardsList.map((card, cardIndex) => {
                  if (cardIndex < 4 || showAllCards)
                    return (
                      <div
                        className={`cursor-pointer`}
                        onClick={() => {
                          onCardOpen(card.id);
                        }}
                      >
                        <ImageCard
                          title={card.name || `Card ${cardIndex + 1}`}
                          // orderNumber={card.position + 1}
                          coverImage={
                            card.activities_thumb_images &&
                            card.activities_thumb_images.length
                              ? card.activities_thumb_images[0].image_url
                              : undefined
                          }
                          status={
                            card.activity_status
                              ? card.activity_status
                              : card.card_status
                          }
                          cardType={
                            card.default_activities_id === 2 ||
                            card.default_activities_id === 3
                              ? "watch"
                              : card.default_activities_id === 4
                              ? "do"
                              : "do"
                          }
                          isWishlist={card.is_wishlist}
                          difficultyLevel={card.difficulty}
                          timeLevel={`${card.time} mins`}
                          videoRefIcon={card.video_link || card.url}
                          stepsRefIcon={card.steps}
                          imgRefIcon={card.activity_reference_images}
                          pdfRefIcon={card.pdf_url}
                          onWishlist={() => {
                            AddToWishlist();
                          }}
                        />
                      </div>
                    );
                })}
              </div>
            </div>
          )}
        </div>
        <StickyBottom>
          <div className="flex justify-between items-center  px-12 bg-white  py-3">
            <div className="nunito-bold-2xl text-textDark px-4">
              {isCompleted
                ? "Completed this learning path!"
                : "Getting Started"}
            </div>
            <div className="flex ">
              {isCompleted && (
                <>
                  {/* <PopOverContainer
                    playlists={[...cardsList, ...completedCardsList]}
                  /> */}
                  {/* <Notesbutton
                    type={"do"}
                    onSuccess={() => {
                      fetchPlaylistInfo({
                        variables: {
                          playlistId: playListId,
                        },
                      });
                    }}
                    title={"Make notes"}
                  /> */}
                </>
              )}
              {!isCompleted && (
                <>
                  <div>
                    <PopOverContainer
                      playlists={[...completedCardsList, ...cardsList]}
                    />
                  </div>
                  <div className="mr-5">
                    <Button
                      className="font-Nunito text-darkGray border border-darkGray px-4  py-3 flex  font-bold"
                      onClick={() => {
                        AddToWishlist();
                      }}
                    >
                      {playListInfo?.is_wishlist ? (
                        <div className="pr-3 flex justify-center items-center">
                          <img
                            src={Images.LearningPathCloseIcon}
                            alt="Close Icon"
                            className="mr-3"
                          />
                          {`Remove from wishlist`}
                        </div>
                      ) : (
                        <div className="pr-3 flex justify-center items-center">
                          <img
                            src={Images.WishlistIcon}
                            alt="Wishlist Icon"
                            className="mr-3"
                          />
                          {`Add to wishlist`}
                        </div>
                      )}
                    </Button>
                  </div>
                  {isScheduled ? (
                    <div className=" w-182">
                      <Button
                        primary
                        className="font-Nunito text-base text-pureWhite px-6  py-2 flex font-bold justify-center border border-violet"
                        onClick={() => {
                          onCardOpen(cardsList[0].id);
                        }}
                      >
                        <img
                          src={Images.LearnArrowIcon}
                          alt="Arrow Icon"
                          className="mr-3"
                        />
                        {`Learn`}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      secondary
                      className="font-Nunito px-4  py-3 flex font-bold text-pureWhite"
                      onClick={() => {
                        setIsLoading(true);
                        AddAllToSchedule();
                      }}
                    >
                      <img
                        src={Images.LearningPathAddIcon}
                        alt="Add Icon"
                        className="mr-3"
                      />
                      {` Add all to my schedule`}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </StickyBottom>
      </div>
    </div>
  );
}
