import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import WorkshopCard from "../../components/Cards/WorkshopCard";
import CarouselContainer from "../../components/common/CarouselContainer";

export default function WorkshopSearch({
  featuredWorkshop = [],
  upcomingWorkshop = [],
  pastWorkshop = [],
}) {
  const history = useHistory();
  // const [featuredWorkshop, setfeaturedWorkshop] = useState([]);
  // const [upcomingWorkshop, setupcomingWorkshop] = useState([]);
  // const [pastWorkshop, setpastWorkshop] = useState([]);

  return (
    <div>
      <div
        className="mx-auto p-6 pt-0 "
      // style={{
      //   boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
      //   borderRadius: "3px",
      // }}
      >
        {featuredWorkshop?.length !== 0 && (
          <>
            <div className="nunito-bold-xl text-textDark">
              Featured events or workshops
            </div>
            <div className="relative my-6 ">
              <CarouselContainer
                id={"featuredWorkshop"}
                data={featuredWorkshop}
                maxCount={3}
              />
              <div
                id="featuredWorkshop"
                className=" flex items-center overflow-auto "
              >
                {(featuredWorkshop || []).map((workshop, workshopIndex) => {
                  return (
                    <div
                      key={`Workshop-${workshopIndex}`}
                      className="mr-5 cursor-pointer"
                      onClick={() => {
                        history.push(`/workshops/${workshop.id}`);
                      }}
                    >
                      <WorkshopCard
                        thumbnail={workshop?.featured_image[0]?.image_url}
                        author={workshop?.content_creator?.profile_image}
                        time={moment(workshop?.workshop_date, "x").format(
                          "dddd | DD MMM 'YY"
                        )}
                        title={workshop?.workshop_name}
                        tags={workshop?.interest_details
                          ?.slice(0, 2)
                          .map((interest) => interest?.name)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {upcomingWorkshop?.length !== 0 && (
          <>
            <div className="flex justify-between">
              <div className="nunito-bold-xl text-textDark">
                Upcoming events
              </div>
              {/* <Link to="/workshops/upcoming">
                <div className="h7 cursor-pointer text-textDark">View all</div>
              </Link> */}
            </div>
            <div className="relative my-6">
              <CarouselContainer
                id={"upcomingWorkshop"}
                data={upcomingWorkshop}
                maxCount={3}
              />
              <div
                id="upcomingWorkshop"
                className=" flex items-center overflow-auto "
              >
                {upcomingWorkshop.map((workshop, workshopIndex) => {
                  return (
                    <div
                      key={`UpcomingWorkshop-${workshopIndex}`}
                      className="mr-5 cursor-pointer"
                      onClick={() => {
                        history.push(`/workshops/${workshop.id}`);
                      }}
                    >
                      <WorkshopCard
                        thumbnail={workshop?.featured_image[0]?.image_url}
                        author={workshop?.content_creator?.profile_image}
                        time={moment(workshop?.workshop_date, "x").format(
                          "dddd | DD MMM 'YY"
                        )}
                        title={workshop?.workshop_name}
                        tags={workshop?.interest_details
                          ?.slice(0, 2)
                          .map((interest) => interest?.name)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {pastWorkshop?.length !== 0 && (
          <>
            <div className="flex justify-between">
              <div className="nunito-bold-xl text-textDark">
                Past Popular Events
              </div>
              {/* <Link to="/workshops/past">
                <div className="h7 cursor-pointer text-textDark">View all</div>
              </Link> */}
            </div>
            <div className="relative my-6">
              <CarouselContainer
                id={"pastWorkshop"}
                data={pastWorkshop}
                maxCount={3}
              />
              <div
                id="pastWorkshop"
                className=" flex items-center overflow-auto "
              >
                {pastWorkshop.map((workshop, workshopIndex) => {
                  return (
                    <div
                      key={`pastWorkshop-${workshopIndex}`}
                      className="mr-5 filter grayscale cursor-pointer"
                      onClick={() => {
                        history.push(`/workshops/${workshop.id}`);
                      }}
                    >
                      <WorkshopCard
                        thumbnail={workshop?.featured_image[0]?.image_url}
                        author={workshop?.content_creator?.profile_image}
                        time={moment(workshop?.workshop_date, "x").format(
                          "dddd | DD MMM 'YY"
                        )}
                        title={workshop?.workshop_name}
                        tags={workshop?.interest_details
                          ?.slice(0, 2)
                          .map((interest) => interest?.name)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
