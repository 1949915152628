import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GET_LEARNING_CENTRE } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Images from "../../themes/Images";

const PaymentStyle = styled.div`
  .paymentHeading {
    position: absolute;
    left: 55px;
    right: 55px;
    text-align: center;
  }
  .paymentContainer {
    background: rgba(248, 201, 71, 0.1);
    /* Brand colors/Brand yellow */

    border: 1px solid #f8c947;
  }
  .bg-ghostWhite {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .activeText {
    color: #3ec1f0;
  }
  .validSubscription {
    color: #bdbdbd;
  }
`;

export default function WorkshopPaymentSuccess() {
  const history = useHistory();
  const [learner, setLearner] = useState(undefined);
  const { data, loading: gettingLearnerInfo } = useQuery(GET_LEARNING_CENTRE, {
    onCompleted: (data) => {
      setLearner(data?.getlearnerDetails?.user_details);
    },
  });
  return (
    <PaymentStyle className=" mt-20 mb-24 max-w-xl mx-auto relative">
      <div className="">
        <div className="nunito-bold-lg text-pureWhite text-center whitespace-nowrap">
          Your payment was successful!
        </div>
      </div>
      <div className="mt-3 bg-ghostWhite rounded-xl p-8 text-pureWhite text-center ">
        <img
          src={Images.paymentSuccessEmptyState}
          alt="Payment Success"
          className="mx-auto"
        />
        <div className="mt-8 nunito-bold-sm">
          {
            "The payment invoice and the workshop details have been sent to your "
          }
          {learner?.phone_no && (
            <>
              whatsapp number{" "}
              <span className="text-yellow">{learner?.phone_no} </span> and{" "}
            </>
          )}
          <br />
          email <span className="text-yellow">{learner?.email} </span> .
        </div>
        <div className="mt-8 w-64 mx-auto">
          <Button
            secondary
            className="h6 font-bold bg-violet border-violet px-8  py-3 text-center flex justify-center items-center"
            onClick={() => {
              history.push("/workshops");
            }}
          >
            Explore more Workshops
          </Button>
        </div>
      </div>
    </PaymentStyle>
  );
}
