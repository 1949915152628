import { DownOutlined } from '@ant-design/icons';
import { useQuery } from "@apollo/client";
import { Modal, notification } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Img } from 'react-image';
import styled from "styled-components";
import { GET_NEAR_LC } from "../../client/graphql";
import NavContext from "../../context/NavContext";
import Images from "../../themes/Images";

const LCContainer = styled.div`
  width: 250px;
  height: 150px;
  /* background: #e3e3e3; */
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid transparent;
  &.active{
    border-color:rgba(var(--blue),1)
  }
`;
var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
const LCCard = (lc) => {
  return (
    <div className=" my-5 block">
      <LCContainer className={lc.active ? "active" : ""} >
        <Img
          className="w-full h-full object-cover"

          src={lc.data.profile_image}
          alt="Explore card"
          loader={<div className="w-full h-full loader"></div>}
        />
        {" "}
      </LCContainer>
    </div>
  );
};
const LCSelectContainer = styled.div`
  /* width: 210px;
  .slick-list {
    height: 500px !important;
  } */
  height: 550px;
  overflow: scroll;
  /* opacity: 0.1; */
  border-left: 1px solid rgba(0,0,0,0.1);
`;
const HomeCard = styled.div`
  width: 212px;
  height: 145px;
  background: #ffffff;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color:#000;
`;
const CircleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: -1; */
  min-height: 500px;
  .large {
    position: absolute;
    width: 431px;
    height: 431px;
    background: #d5f1fb;
    opacity: 0.2;
    border-radius: 100%;
  }
  .small {
    position: absolute;
    width: 321px;
    height: 321px;

    background: #3ec0f1;
    opacity: 0.2;
    border-radius: 100%;
  }
  .large,
  .small {
    animation-name: fade-circle-bg;
    animation-delay: 0;
    animation-direction: forward;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  @keyframes fade-circle-bg {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;
export default function LCSelectModal(props) {
  const [learningCenterDetails, setLearningCenterDetails] = useState([]);
  const [showSliderRef, setShowSliderRef] = useState(false);
  const userNavContext = useContext(NavContext);
  const [selectedLc, setSelectedLc] = useState({})
  let LcSlider = useRef();
  const { data } = useQuery(GET_NEAR_LC, {
    variables: {
      lat: "13.007598",
      long: "80.236152",
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getNearLC) {
        setLearningCenterDetails(data.getNearLC.learningCenterDetails);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  useEffect(() => {
    if (!props.visible) {
      (learningCenterDetails || []).map((details, detailsIndex) => {
        let selected_lc_id = localStorage.lc_id || lc_id;
        if (details.id + "" === selected_lc_id + "") {
          setSelectedLc(details)
          props?.selectedLc(details?.displayName);
        }
      });
    }
  }, [learningCenterDetails, localStorage.lc_id]);
  useEffect(() => {
    if (showSliderRef && props.visible) {
      (learningCenterDetails || []).map((details, detailsIndex) => {
        let selected_lc_id = localStorage.lc_id || lc_id;
        if (details.id + "" === selected_lc_id + "") {
          setSelectedLc(details)
          LcSlider?.current?.goTo(
            detailsIndex - 1 < 0
              ? learningCenterDetails.length - 1
              : detailsIndex - 1
          );
        }
      });
    }
  }, [props.visible, showSliderRef, learningCenterDetails]);
  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={() => {
          setShowSliderRef(false);
          props.onClose();
        }}
        width={900}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

      // title="Choose where you want to learn today"
      >
        <div className="h3">Choose where you want to learn today</div>
        <div className="h5 text-blue text-center mt-3">You have selected: {selectedLc?.displayName} </div>

        <div className="flex items-center">
          <HomeCard
            className="px-6 flex justify-center flex-col ml-28 cursor-pointer "
            onClick={() => {
              localStorage.setItem(
                "lc_id",
                // (curr + 1) % (learningCenterDetails || []).length
                lc_id
              );
              let detailsIndex = 0;
              let selected_lc = learningCenterDetails.filter((lc, lcIndex) => {
                if (parseInt(lc.id) === lc_id) {
                  detailsIndex = lcIndex;
                  return lc;
                }
              });
              LcSlider?.current?.goTo(
                detailsIndex - 1 < 0
                  ? learningCenterDetails.length - 1
                  : detailsIndex - 1
              );
              setSelectedLc(selected_lc[0])
              props?.selectedLc(selected_lc[0]?.displayName);
              userNavContext.setUserInfo({
                ...userNavContext.userInfo,
                ...{
                  lc_id,
                },
              });
              props.onClose();
            }}
          >
            <img src={Images.Logo} alt="logo" />
            <div className="nunito-bold-lg text-right mt-3"> at Home</div>
          </HomeCard>
          <div className="relative ml-20 pl-20 ">
            <LCSelectContainer className=" mt-8 " id="lcScroller">
              {learningCenterDetails.map((lc, lcIndex) => {
                return <div className="cursor-pointer" onClick={() => {
                  localStorage.setItem(
                    "lc_id",
                    lc.id
                  );
                  props?.selectedLc(
                    lc?.displayName
                  );
                  setSelectedLc(lc)
                  userNavContext.setUserInfo({
                    ...userNavContext.userInfo,
                    ...{
                      lc_id:
                        lc.id,
                    },
                  });
                  props.onClose();
                }}><LCCard data={lc} key={`LC-${lcIndex}`} active={lc.id === selectedLc.id} /></div>;
              })}
            </LCSelectContainer>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center pl-20">
              <div className="bg-blue text-pureWhite h5 px-5 py-1 rounded-t-md cursor-pointer" onClick={() => {
                document.getElementById("lcScroller").scrollTo({
                  top: document.getElementById("lcScroller").scrollTop + 250,
                  behavior: "smooth",
                });
              }}>
                Scroll for more <DownOutlined style={{
                  color: "#fff", fontSize: 14, marginRight: 10

                }} />
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}
