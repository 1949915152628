import { useMutation, useQuery } from "@apollo/client";
import { notification } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { UPDATE_EXPIRE_DATE, VIEW_USER } from "../../../client/graphql";
import NavContext from "../../../context/NavContext";
import Loader from "../Loader";
import NotificationAlert from "../NotificationAlert";
import Nav from "./Nav";
import NavDark from "./NavDark";


export default function NavSection(props) {
  const userNavContext = useContext(NavContext);
  const [mode, setMode] = React.useState("DARK_MODE")
  const [loading, setLoading] = React.useState(false)
  const [updateExpireDate] = useMutation(UPDATE_EXPIRE_DATE, {
    onCompleted: (res) => {
      if (res?.updateExpiryDate?.message) {
        window.location.reload();
      }
    }
  })
  const { loading: loadingUser } = useQuery(VIEW_USER, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      let expire_date = moment
        .unix((response?.viewUser?.student_details?.expiry_date || response?.viewUser?.user_details?.expiry_date) / 1000).endOf('day');
      let isExpire = moment().endOf("day").isAfter(moment(expire_date))
      let UserDetails = response?.viewUser?.student_details || response?.viewUser?.user_details
      let beta_user = response?.viewUser?.student_details ? UserDetails?.beta_user : true;

      let contentExpire = !beta_user ? UserDetails?.card_count >= UserDetails?.completed_learning_content : false;
      if ((isExpire || contentExpire) && !UserDetails.expired && !window.location.pathname.includes("/payment/verify")) {
        if ((response?.viewUser?.user_details && !response?.viewUser?.user_details?.is_paid) || response?.viewUser?.student_details) {

          // updateExpireDate(
          //   {
          //     variables: {
          //       subscription_type: response?.viewUser?.student_details ? "Student" : "Parent"
          //     }
          //   }
          // )
        }
        //Mutation
      }
      if (response.viewUser) {
        if (response?.viewUser?.student_details) {
          document.body.classList.remove("dark");

          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            ...{
              mode: 'LIGHT_MODE',
            },
            showTrial: response?.viewUser?.student_details?.show_trial,
            isLearner: true,
            expiry_date: response?.viewUser?.student_details?.expiry_date ? moment(response?.viewUser?.student_details?.expiry_date / 1).utc() : userNavContext?.userInfo?.expiry_date,
            expired: response?.viewUser?.student_details?.expired,
            is_parent: response?.viewUser?.student_details?.is_parent,
            is_renew: response?.viewUser?.student_details?.is_renew,
            profile_image: JSON.parse(localStorage.getItem("user_details"))?.avatar,
            signup_type: null,
            expiry_type: response?.viewUser?.student_details?.expiry_type,
          });
          setMode("LIGHT_MODE")

        } else if (response?.viewUser?.user_details) {
          document.body.classList.add("dark");

          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            ...{
              mode: "DARK_MODE",
              parentInfo: response?.viewUser?.user_details,
            },
            showTrial: response?.viewUser?.user_details?.show_trial,
            childCount: response?.viewUser?.user_details.child_details?.length,
            isLearner: response?.viewUser?.user_details ? response?.viewUser?.user_details.is_learner : true,
            expiry_date: response?.viewUser?.user_details?.expiry_date ? moment(response?.viewUser?.user_details?.expiry_date / 1).utc() : userNavContext?.userInfo?.expiry_date,
            expired: response?.viewUser?.user_details?.expired,
            is_renew: response?.viewUser?.user_details?.is_renew,
            signup_type: response?.viewUser?.user_details?.signupType,
            expiry_type: response?.viewUser?.user_details?.expiry_type,
          });
          setMode("DARK_MODE")

        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  // useLayoutEffect(() => {
  //   window.onload = function exampleFunction() {

  //     setLoading(false)
  //   }
  // }, [])
  return (
    <div>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999999, background: 'white', display: loadingUser || loading ? "block" : "none" }}>
        <Loader loading={loadingUser || loading} />
      </div>
      {mode === "LIGHT_MODE" && <Nav />}
      {mode === "DARK_MODE" && <NavDark />}
      {userNavContext?.userInfo?.subs?.type && localStorage.showNotification !== "true" &&
        <NotificationAlert />}
    </div>
  )
}
