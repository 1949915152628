import { useMutation } from "@apollo/client";
import { Avatar, DatePicker, Input, Modal, notification } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { EDIT_PROFILE } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import NavContext from "../../context/NavContext";
import { DefaultAvatar } from "../../themes/Images";
import { avatarMap } from "../../utils";
import AvatarPicker from "./avatarPicker";

const InputContainer = styled.div`
  .ant-input-affix-wrapper input,
  .ant-input-affix-wrapper {
    background-color: #f4f4f4;
    border-color: transparent;
    /* height:60px; */
    &.border-liteRed {
      border-color: #d1d5db;
    }
  }
  .ant-input-affix-wrapper.bg-red-100 input,
  .ant-input-affix-wrapper.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .ant-input {
    border-radius: 62px;
  }
  .ant-picker {
    border-radius: 62px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
`;

export default function EditProfile(props) {
  const [showAvatarPicker, setShowAvatarPicker] = useState(false);
  const nameRef = useRef();
  const userNavContext = useContext(NavContext);
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(undefined);
  const [selectedImg, setSelectedImg] = useState({});

  useEffect(() => {
    setFirstName(props.data?.fname);
    setLastName(props.data?.lname);
    setDob(moment(props.data?.dob));
    const user_details = JSON.parse(localStorage.getItem("user_details"));
    setSelectedImg({
      url: user_details?.avatar,
      bgColor: user_details?.bgColor,
    });
  }, [props.data]);

  const [editProfile] = useMutation(EDIT_PROFILE, {
    onCompleted: (data) => {
      if (data.EditStudentAboutMe?.is_updated) {
        notification.success({ message: "Profile updated" });
        let user_details = JSON.parse(localStorage.getItem("user_details"));
        localStorage.setItem(
          "user_details",
          JSON.stringify({
            ...user_details,
            avatar: selectedImg.url,
            bgColor: selectedImg.bgColor,
          })
        );
        userNavContext.setUserInfo({
          ...userNavContext.userInfo,
          profile_image: selectedImg.url,
        });
        if (props.onSave) props.onSave();
      }
    },
  });

  return (
    <div>
      <Modal
        visible={props.visible}
        closable={false}
        footer={null}
        width={"50%"}
      >
        <div className="px-4 mt-2">
          <div className="nunito-bold-2xl mb-9">About me</div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-3">
            <div className="col-span-2 mb-5">
              <div
                className="flex flex-col h-24 w-24 rounded-full m-auto"
                style={{
                  backgroundColor: selectedImg?.bgColor || "whitesmoke",
                }}
              >
                <Avatar
                  src={selectedImg?.url || DefaultAvatar}
                  style={{
                    backgroundColor: "#FFF6D6",
                    color: "#4C39B3",
                    width: "100%",
                    height: "100%",
                    fontSize: 32,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {avatarMap((firstName || "") + " " + (lastName || ""), 3)}
                </Avatar>
                {/* <img src={selectedImg?.url} alt="avatar" /> */}
              </div>
              <div className="flex justify-center">
                <Button
                  secondaryGhost
                  className="mt-2 font-Nunito text-base px-4 py-0 text-center flex font-normal justify-center items-center"
                  onClick={() => {
                    setShowAvatarPicker(true);
                  }}
                >
                  Change
                </Button>
              </div>
            </div>
            <div className="flex flex-col mt-5 pr-5">
              <div className="mr-16 mb-1 font-semibold text-gray-700">
                My First Name
              </div>
              <InputContainer>
                <Input
                  ref={nameRef}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    if (!e.target.value) {
                      setErrors({ fname: "Required" });
                    } else {
                      setErrors({});
                    }
                  }}
                  onBlur={(evt) => {
                    if (!evt.target.value) {
                      nameRef.current.focus();
                    }
                  }}
                  size="small"
                  onPressEnter={() => {}}
                  className={`px-4 py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg ${
                    errors["fname"] ? `border border-liteRed` : ``
                  }`}
                />
              </InputContainer>
            </div>
            <div className="flex flex-col mt-5 pr-5">
              <div className="mr-16 mb-1 font-semibold text-gray-700">
                My Last name
              </div>
              <InputContainer>
                <Input
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  size="large"
                  onPressEnter={() => {}}
                  className={`px-4 py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                />
              </InputContainer>
            </div>
            <div className="flex flex-col mt-5 pr-5">
              <div className="mr-16 mb-1 font-semibold text-gray-700">
                I was born on
              </div>
              <InputContainer>
                <DatePicker
                  allowClear={false}
                  value={dob}
                  placeholder={""}
                  suffixIcon={false}
                  format={`MMM DD, YYYY`}
                  onChange={(val) => {
                    setDob(val);
                  }}
                  disabledDate={(currentData) =>
                    currentData.isAfter(moment().subtract(4, "years"))
                  }
                  size="large"
                  className={`w-full px-4 py-2 text-gray-700 ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                />
              </InputContainer>
            </div>
            <div className="row-start-4 col-span-2 flex justify-end pr-5 mt-5">
              <Button
                secondaryGhost
                className="font-Nunito text-base px-8 py-2 text-center flex font-normal justify-center items-center"
                onClick={() => {
                  if (props.onCancel) props.onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                secondary
                className="ml-2 font-Nunito text-base px-8  py-2 text-center flex font-normal justify-center items-center"
                disabled={errors["fname"]}
                onClick={() => {
                  editProfile({
                    variables: {
                      firstName: firstName,
                      lastName: lastName,
                      dob: moment(dob).format("MMM DD, YYYY"),
                      profile_image: selectedImg.url,
                      BG_color: selectedImg.bgColor,
                    },
                  });
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showAvatarPicker}
        footer={null}
        onCancel={() => {
          setShowAvatarPicker(false);
        }}
      >
        <AvatarPicker
          onChange={(selected) => {
            setSelectedImg(selected);
            setShowAvatarPicker(false);
          }}
        />
      </Modal>
    </div>
  );
}
