import { useMutation, useQuery } from "@apollo/client";
import { Modal, Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  IPADNOTIFICATION, VIEW_WORKSHOP,
  WORKSHOP_ATTENDANCE,
  WORKSHOP_INTERESTED
} from "../../client/graphql";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import Notesbutton from "../../components/common/Notes/Notesbutton";
import NotesViewModal from "../../components/common/NotesViewModal";
import StickyBottom from "../../components/common/StickyBottom";
import Images from "../../themes/Images";
import { base64regex, NotesBg } from "../../utils";
import MaterialModal from "../Collection/Details/components/MaterialModal";

let { Paragraph } = Typography;

const ContentPictureStyle = styled.div`
  width: 215px;
  height: 140px;
  flex-shrink: 0;
  & .bg {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 91.33%
    );
  }
`;

const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: "nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    /* @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    } */
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    } */
    color: #57479c;
    max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`;
const ProfileContainer = styled.div`
  width: 125px;
  height: 125px;
  background:  url(${props => props.dark ? Images.UserProfileDarkIcon : Images.UserProfileIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  .profilePicture {
    width: 70px;
    height: 70px;
  }
`;

const DateView = ({ date, time, every, duration }) => {
  return (
    <DateContainer className="d-flex">
      {every && every.length && (
        <div className="d-flex">
          <div className="subTitle">{every}</div>
        </div>
      )}
      <div>
        <h4 className="title white-space-nowrap">
          {date ? moment(parseInt(date)).format(`ddd, Do MMM ’YY`) : ""}
        </h4>
        <div className="desc mt-1">
          {time ? moment(time, "h m a").format("h:mm A") : ""}{" "}
          {duration ? `- ${duration}` : ""}
        </div>
      </div>
    </DateContainer>
  );
};
const CardImgContainer = styled.div`
  .cardImage img,
  .cardImage video {
    height: 420px;
  }
  .thumbnail {
    min-width: 132px;
    width: 140px;
    height: 74px;
    min-height: 66px;
  }
  .tag {
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    &.blue {
      background: #c8edff;
      color: #49bff9;
    }
    &.yellow {
      background: #fffbc3;
      color: #f8c947;
    }
    &.green {
      color: #68b415;
      background: #d8ffad;
    }
    &.gray {
      background: #f0f0f0;
      color: #4f4f4f;
    }
  }
`;
const CarouselCard = ({
  thumbnailList = [],
  bottomCard,
  isWishlist = false,
  status,
  loading,
  cardType,
  isPlaylist = false,
}) => {
  const [selectedCard, setSelectedCard] = React.useState(0);
  return (
    <CardImgContainer>
      <div className="cardImage">
        {thumbnailList.length !== 0 && thumbnailList[selectedCard]?.isVideo && (
          <div className=" w-full h-500 object-cover rounded relative ">
            <iframe
              title="Youtube Video player"
              id="youtubeFrame"
              className={` w-full h-full absolute inset-0 `}
              src={thumbnailList[selectedCard].src}
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            />
          </div>
        )}
        {thumbnailList.length !== 0 && !thumbnailList[selectedCard]?.isVideo && (
          <div>
            <img
              className=" w-full h-500 object-cover rounded "
              src={thumbnailList[selectedCard].src}
              alt="Explore card"
            />
          </div>
        )}
      </div>
      <div className="flex mt-7">
        <div className="w-8/12 relative">
          {thumbnailList.length > 6 && (
            <>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`rotate-180 transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft - 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft + 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
            </>
          )}
          <div
            className="flex items-center overflow-auto "
            id="collection-details-thumb"
          >
            {thumbnailList.map((thumbnail, thumbnailIndex) => {
              if (thumbnail.src)
                return (
                  <div
                    className={`thumbnail cursor-pointer  mr-4 p-1 ${thumbnailIndex === selectedCard
                      ? "border border-darkGray rounded-md"
                      : ""
                      }`}
                    onClick={() => {
                      setSelectedCard(thumbnailIndex);
                    }}
                    key={`thumbnail-${thumbnailIndex}`}
                  >
                    {thumbnail.isVideo && (
                      <div className="h-full relative">
                        {/* <video
                        width={200}
                        height={100}
                        src={thumbnail.src}
                        className=" rounded-md"
                      /> */}

                        <iframe
                          title="Youtube Video player"
                          id="youtubeFrame"
                          className={` w-full h-full absolute inset-0 pointer-events-none  rounded-md`}
                          src={thumbnail.src}
                          allowfullscreen="allowfullscreen"
                          mozallowfullscreen="mozallowfullscreen"
                          msallowfullscreen="msallowfullscreen"
                          oallowfullscreen="oallowfullscreen"
                          webkitallowfullscreen="webkitallowfullscreen"
                        />
                      </div>
                    )}
                    {!thumbnail.isVideo && (
                      <div className="h-full">
                        <img
                          // width={200}
                          // height={100}
                          className=" w-full h-full object-cover rounded-md"
                          src={thumbnail.src}
                          alt="Explore card"
                        />
                      </div>
                    )}
                  </div>
                );
            })}
          </div>
        </div>
        <div className=" w-4/12"></div>
      </div>
    </CardImgContainer>
  );
};
export default function WorkshopDetails() {
  const [workshopData, setworkshopData] = React.useState({});
  const [resultData, setData] = React.useState({});
  const [workshopDuration, setWorkshopDuration] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isNotesModalVisible, setIsNotesModalVisible] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const user_details = useMemo(() => JSON.parse(localStorage.getItem("user_details")), []);
  const [showParentSetup, setShowParentSetup] = React.useState(false);

  const showNotesModal = (value) => {
    setIsNotesModalVisible(value);
  };

  const handleClose = () => {
    setIsNotesModalVisible(false);
    setModalData(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (zoom) => {
    setIsModalVisible(false);
    joinWorkshop({
      variables: {
        id: parseInt(id),
      },
    });
    window.open(zoom?.join_url, "_blank");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { id } = useParams();

  const location = useLocation();

  const { data, loading, refetch } = useQuery(VIEW_WORKSHOP, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(id),
      isNotification: location.search.includes("notification=true")
        ? true
        : false,
      isReferenceNotification: location.search.includes("reference_image=true")
        ? true
        : false,
    },
  });

  console.log();

  const {
    data: notificationData,
    loading: notificationDataLoading,
    refetchNotification,
  } = useQuery(IPADNOTIFICATION, {
    fetchPolicy: "network-only",
  });

  const [showInterest, { loading: interestLoading }] = useMutation(
    WORKSHOP_INTERESTED,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const [joinWorkshop, { loading: joinLoading }] = useMutation(
    WORKSHOP_ATTENDANCE,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  React.useEffect(() => {
    if (data) {
      setworkshopData(data?.ipadViewWorkshop?.workshop_details[0]);
      setData(data?.ipadViewWorkshop);
      if (data?.ipadViewWorkshop?.workshop_details[0]?.Dates?.length > 0) {
        const duration =
          data?.ipadViewWorkshop?.workshop_details[0]?.Dates[
          data?.ipadViewWorkshop?.workshop_details[0]?.Dates?.length - 1
          ];
        setWorkshopDuration(duration?.duration.split(" "));
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (workshopData) {
      if (workshopData?.Dates?.length > 0) {
        setWorkshopDuration(workshopData?.Dates[0]?.duration?.split(" "));
      }
    }
  }, [workshopData]);

  React.useEffect(() => {
    if (!user_details?.isParentAdded && user_details?.Onboarded_days <= 1) {
      setShowParentSetup(true);
    }
  }, [user_details]);

  const getStatusText = () => {
    let closed = 0,
      date = moment(),
      workshopTime = moment(
        workshopData?.Dates[workshopData?.Dates?.length - 1]?.start_time,
        "HH:MM:SS"
      ),
      workshopDate = moment.unix(
        workshopData?.Dates[workshopData?.Dates?.length - 1]?.workshop_Date /
        1000
      );
    let workShopHours = parseInt(workshopTime.format("HH"));
    let workShopMins = parseInt(workshopTime.format("MM"));
    let currentHour = parseInt(moment().format("HH"));
    let currentMins = parseInt(moment().format("MM"));
    if (date.isBefore(workshopDate)) {
      closed = 0;
    } else if (date.isSame(workshopDate)) {
      if (currentHour < workShopHours - 1) {
        closed = 0;
      } else if (currentHour === workShopHours - 1) {
        if (currentMins < workShopMins) {
          closed = 0;
        } else {
          closed = 2;
        }
      } else {
        closed = 1;
      }
    } else if (currentHour === workShopHours) {
      if (currentMins < workShopMins) {
        closed = 0;
      } else {
        closed = 2;
      }
    } else {
      closed = 0;
    }
    if (closed === 1) {
      return "Registrations closed. Do check our other upcoming events.";
    } else if (closed === 2) {
      return "Sorry! This workshop has already begun. Do check our other upcoming events.";
    } else {
      return "Are you interested in attending this workshop?";
    }
  };

  const checkCompletedStatus = () => {
    let status = 0,
      workshopTime = moment(
        workshopData?.Dates[workshopData?.Dates?.length - 1]?.start_time,
        "HH:MM:SS"
      );
    let workShopHours = parseInt(workshopTime.format("HH"));
    let workShopMins = parseInt(workshopTime.format("MM"));
    let currentHour = parseInt(moment().format("HH"));
    let currentMins = parseInt(moment().format("MM"));
    let workshopDurationHours = parseInt(workshopDuration[0]?.split("")[0]);
    let workshopDurationMins = parseInt(workshopDuration[1]?.split("")[0]);
    if (currentHour > workShopHours + workshopDurationHours) {
      if (currentMins > workShopMins + workshopDurationMins) {
        status = 0; //Join button disabled
      } else {
        status = 1; //Join button enabled
      }
    } else if (currentHour === workShopHours + workshopDurationHours) {
      if (currentMins > workShopMins + workshopDurationMins) {
        status = 0; //Join button disabled
      } else {
        status = 1; //Join button enabled
      }
    } else {
      status = 1;
    }
    return status;
  };

  const checkMissedStatus = () => {
    let date = moment(),
      workshopTime = moment(
        workshopData?.Dates[workshopData?.Dates?.length - 1]?.end_time,
        "h m a"
      ),
      workshopDate = moment.unix(workshopData?.Dates[0]?.workshop_Date / 1000);

    if (workshopDate.isSame(date, "day")) {
      if (moment().isAfter(workshopTime)) {
        return true;
      } else {
        return false;
      }
    } else if (workshopDate.isBefore(date, "day")) {
      return false;
    } else {
      return true;
    }
  };

  const checkYetToStartStatus = () => {
    let status = 0;
    if (checkMissedStatus()) {
      status = 0;
    } else {
      status = 1;
    }
    return status;
  };

  const findDifference = (date) => {
    const start_time = moment(date?.start_time, "h m a").subtract(
      10,
      "minutes"
    );
    const end_time = moment(date?.end_time, "h m a");
    const isBetween = moment().isBetween(start_time, end_time);
    return isBetween;
  };

  const isPastWorkshop = () => {
    const lastSession =
      workshopData?.Dates?.length > 0
        ? moment(
          workshopData?.Dates[workshopData?.Dates?.length - 1]?.end_time,
          "hh mm ss"
        )
        : moment();
    const lastDay =
      workshopData?.Dates?.length > 0
        ? moment.unix(
          workshopData?.Dates[workshopData?.Dates?.length - 1]
            ?.workshop_Date / 1000
        )
        : moment();
    if (lastDay.isBefore(moment(), "day")) {
      return true;
    } else if (
      lastDay.isSame(moment(), "day") &&
      moment().isAfter(lastSession, "hours")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CarouselContainer = ({ data, id, maxCount }) => {
    return (
      (data || []).length > maxCount && (
        <div className="mt-2">
          <div className="absolute top-0 bottom-0 flex items-center justify-center " />

          <div className="absolute top-0 bottom-0 flex items-center justify-center " />
        </div>
      )
    );
  };

  const ImageContainer = ({ image, id }) => {
    return (
      <div style={{ maxWidth: "90%" }} className="mt-4">
        <CarouselContainer id={id} data={image} maxCount={4} />
        <div
          id={id}
          className="flex items-center overflow-auto flex-start flex-wrap"
        >
          {image
            ?.slice(0)
            ?.reverse()
            ?.map((pictureStyle, styleIndex) => {
              return (
                <ContentPictureStyle
                  className="relative mr-4 mb-4 rounded overflow-hidden cursor-pointer"
                  key={`PictureStyle-${styleIndex}`}
                  onClick={() => {
                    setModalData({
                      url: pictureStyle?.image,
                      notes: pictureStyle?.notes,
                    });
                    showNotesModal(true);
                  }}
                >
                  <img
                    src={
                      pictureStyle.type === "doodle" ||
                        pictureStyle.type === "captured" ||
                        pictureStyle.type === "uploaded" ||
                        pictureStyle.type === "upload"
                        ? pictureStyle.image
                        : pictureStyle.type === "audio"
                          ? Images.NotesAudioBg
                          : NotesBg(pictureStyle.notes)
                    }
                    alt="notes img"
                    className="w-full h-full rounded"
                  />

                  <div className="absolute inset-0 bg"></div>
                  <div className="absolute bottom-0 p-2">
                    <div className="h6 text-pureWhite">
                      {`${moment(pictureStyle.createdAt, "x").format(
                        "MMM DD, YYYY"
                      )} - ${pictureStyle.type === "captured" ||
                        pictureStyle.type === "upload" ||
                        pictureStyle.type === "uploaded"
                        ? "Photo"
                        : pictureStyle.type === "doodle"
                          ? "Doodle"
                          : pictureStyle.type === "audio"
                            ? "Audio"
                            : "Notes"
                        }`}
                      {(pictureStyle.type === "captured" ||
                        pictureStyle.type === "upload" ||
                        pictureStyle.type === "uploaded" ||
                        pictureStyle.type === "audio") && (
                          <Paragraph
                            ellipsis={{
                              rows: 2,
                            }}
                            className="mb-0 h6 text-pureWhite"
                          >
                            {pictureStyle.notes
                              ? base64regex.test(pictureStyle.notes)
                                ? decodeURIComponent(
                                  escape(window.atob(pictureStyle.notes))
                                )
                                : pictureStyle.notes
                              : ""}
                          </Paragraph>
                        )}
                    </div>
                  </div>
                </ContentPictureStyle>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Loader
        loading={
          loading || interestLoading || joinLoading || notificationDataLoading
        }
      />
      <ExploreHeading
        title={workshopData?.workshop_name || "Title"}
        isBack
      ></ExploreHeading>
      <div
        className="container mx-auto p-8 bg-white"
        style={{
          boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
          borderRadius: "3px",
        }}
      >
        {/* <img
          src={
            workshopData?.featured_image?.length > 0
              ? workshopData?.featured_image[0]?.image_url
              : null
          }
          className=" w-full h-500 object-cover rounded "
          alt="Workshop img"
        /> */}
        <CarouselCard
          loading={loading}
          thumbnailList={
            workshopData?.featured_image
              ? [
                  ...workshopData.featured_image.map((image) => {
                    return {
                      src: image.image_url,
                      isVideo: false,
                    };
                  }),
                  ...[
                    workshopData?.trailer_link
                      ? {
                          src: String(workshopData?.trailer_link).replace(
                            `watch?v=`,
                            `embed/`
                          ),
                          isVideo: true,
                        }
                      : {},
                  ],
                ]
              : []
          }
        />
        <div className=" mt-5 flex justify-between">
          <div className=" max-w-85">
            <div className=" w-full">
              <div className="flex overflow-auto">
                {workshopData?.interest_details?.map((tag, tagIndex) => {
                  return (
                    <div
                      key={`Tag-${tagIndex}`}
                      className="nunito-semibold-xs bg-flashWhite px-3 py-1 text-pureBlack rounded-full whitespace-nowrap mr-3"
                    >
                      {tag.name || "Tag"}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex mt-4 pt-1 w-11/12">
              <div
                className="w-full flex overflow-scroll"
                id={`workshop-scroll1`}
              >
                {workshopData &&
                  workshopData.workshop_plan_id &&
                  [1, 2, 3].includes(parseInt(workshopData.workshop_plan_id)) &&
                  workshopData.repeats_type == "dnr" &&
                  workshopData.Dates &&
                  workshopData.Dates.length > 0 &&
                  workshopData.Dates.map((x, index) => (
                    <span
                      className="pr-3 d-inline-block"
                      key={`WorkshopData-${index}`}
                    >
                      <DateView
                        date={x.workshop_Date}
                        time={x.start_time}
                        duration={
                          workshopData.workshop_plan_id == 3 &&
                          !workshopData.is_common_timing
                            ? `${x.duration ? `${x.duration}` : ""}`
                            : ""
                        }
                      />
                    </span>
                  ))}
                {workshopData &&
                  workshopData.workshop_plan_id &&
                  (workshopData.workshop_plan_id == 1 ||
                    workshopData.workshop_plan_id == 2) &&
                  workshopData.repeats_type != "dnr" &&
                  workshopData.Dates &&
                  workshopData.Dates.length > 0 &&
                  workshopData.Dates.map((x, dateIndex) => (
                    <span
                      className="pr-3 inline-block"
                      key={`DateView-${dateIndex}`}
                    >
                      <DateView
                        date={x.workshop_Date}
                        time={x.start_time}
                        every={workshopData.repeatDays}
                        duration={x?.duration}
                      />
                    </span>
                  ))}
              </div>
              {workshopData.Dates && workshopData.Dates.length > 7 && (
                <img
                  className="ml-3 cursor-pointer"
                  src={Images.CarouselLeft}
                  alt="arrow"
                  onClick={() => {
                    // console.log(
                    //   document.getElementById(`workshop-${index}`).scrollLeft
                    // )
                    document.getElementById(`workshop-scroll1`).scrollTo({
                      left:
                        document.getElementById(`workshop-scroll1`).scrollLeft +
                        250,
                      behavior: "smooth",
                    });
                  }}
                />
              )}
            </div>
            <div className="pb-1 mt-1 pt-2 h9 text-blue flex">
              {workshopData.workshop_type
                ? workshopData.workshop_type == "Paid"
                  ? `\u20B9 ${workshopData.workshop_amount}`
                  : "FREE"
                : ""}
              <div className="text-rose flex justify-center items-center h9">
                {parseInt(workshopData?.available_seats) === 0 &&
                workshopData?.workshop_registration_status === false ? (
                  <span>Registrations closed!</span>
                ) : workshopData.IsPast ? (
                  ``
                ) : parseInt(workshopData?.available_seats) === 0 &&
                  workshopData?.workshop_registration_status === false ? (
                  <span>Registrations closed!</span>
                ) : workshopData.available_seats > 0 &&
                  workshopData.workshop_registration_status ? (
                  workshopData.available_seats > 10 &&
                  workshopData.workshop_registration_status ? (
                    <img src={Images.ClockIcon} className="mr-2" alt="flames" />
                  ) : (
                    <img src={Images.FlameIcon} className="mr-2" alt="flames" />
                  )
                ) : (
                  ""
                )}
                {!workshopData.workshop_registration_status
                  ? ``
                  : workshopData.available_seats > 0
                  ? workshopData.available_seats > 10
                    ? ``
                    : `Hurry! `
                  : ""}
                {!workshopData.workshop_registration_status ? (
                  ``
                ) : workshopData.available_seats > 0 ? (
                  `Only ${
                    workshopData.available_seats
                      ? workshopData.available_seats
                      : ""
                  } seat${
                    workshopData.available_seats
                      ? workshopData.available_seats > 1
                        ? `s`
                        : ``
                      : ``
                  } left`
                ) : (
                  <span className="text-green">We are fully booked!</span>
                )}
              </div>
            </div>
          </div>
          <div className=" flex-shrink-0">
            <ProfileContainer
              className={"relative showBg"}
              dark={document.body.classList.contains("dark")}
            >
              <img
                src={
                  workshopData.content_creator
                    ? workshopData.content_creator.profile_image
                    : "https://via.placeholder.com/80"
                }
                className=" rounded-full profilePicture mx-auto absolute left-0 right-0 mt-4"
                alt="profile"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../static/images/icons/profile_no_bg.svg";
                }}
              />
              <div className=" absolute left-0 right-0 bottom-2">
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: false,
                  }}
                  className=" font-Nunito text-xs text-pureWhite text-center font-bold mb-0 w-full px-3"
                >
                  {workshopData.content_creator
                    ? workshopData.content_creator.displayName
                      ? workshopData.content_creator.displayName
                      : workshopData.content_creator.name
                    : "..."}
                </Paragraph>
              </div>
            </ProfileContainer>
          </div>
        </div>
        {workshopData.description && (
          <div className=" mt-5  ">
            <h5 className="h6 text-base	font-semibold text-lightGray">ABOUT</h5>
            <div
              className="h7 mt-3"
              dangerouslySetInnerHTML={{
                __html: workshopData.description
                  ? workshopData.description
                  : "",
              }}
            />
          </div>
        )}
        {workshopData.skills_required && (
          <div className="mt-5 ">
            <h5 className="h6 text-base	font-semibold text-lightGray">
              YOU SHOULD ALREADY KNOW
            </h5>
            <div
              className="h7 mt-3"
              dangerouslySetInnerHTML={{
                __html: workshopData.skills_required
                  ? workshopData.skills_required
                  : "",
              }}
            />
          </div>
        )}
        {workshopData?.materials_details &&
          workshopData?.materials_details.length > 0 && (
            <div className="mt-5">
              <h5 className="h6 text-base	font-semibold text-lightGray">
                YOU WOULD NEED
              </h5>
              <div className="flex mt-3">
                <div
                  className="row overflow-scroll flex"
                  id="workshopMat-scroll"
                >
                  {workshopData?.materials_details.map((x, index) => {
                    return (
                      <div
                        key={`Material-${index}`}
                        className="mr-4 text-center"
                      >
                        <div className=" w-20 h-20">
                          <img
                            src={x.image}
                            alt={x.name}
                            className="rounded w-full h-full object-cover "
                          />
                        </div>
                        <Paragraph className=" w-20 h9 text-center font-bold mb-0 px-3 my-2">
                          {x.name}
                        </Paragraph>
                      </div>
                    );
                  })}
                </div>

                {workshopData?.materials_details.length > 12 && (
                  <img
                    className="ml-3 cursor-pointer"
                    src={Images.CarouselLeft}
                    alt="arrow"
                    onClick={() => {
                      document.getElementById(`workshopMat-scroll`).scrollTo({
                        left:
                          document.getElementById(`workshopMat-scroll`)
                            .scrollLeft + 250,
                        behavior: "smooth",
                      });
                    }}
                  />
                )}
              </div>
            </div>
          )}
        {notificationData?.iPadNotificationV2?.completed_workshops?.length >
          0 &&
          notificationData?.iPadNotificationV2?.completed_workshops[0]?.pdf_name
            ?.length === 0 && (
            <div className="mt-5">
              <h5 className="h6 text-base	font-semibold text-lightGray">
                USE THESE REFERENCES TO LEARN FROM
              </h5>
              <div className=" w-40 h-28 mt-3">
                <img
                  src={
                    notificationData?.iPadNotificationV2?.completed_workshops[0]
                      ?.reference_link
                  }
                  alt={"reference"}
                  className="rounded w-full h-full object-cover "
                />
              </div>
            </div>
          )}
        {notificationData?.iPadNotificationV2?.completed_workshops?.length >
          0 &&
          notificationData?.iPadNotificationV2?.completed_workshops[0]?.pdf_name
            ?.length > 0 && (
            <div className="mt-5">
              <h5 className="h6">USE THESE REFERENCES TO LEARN FROM</h5>
              <div
                className=" w-28 h-28 mt-3 cursor-pointer"
                onClick={() => {
                  window.open(
                    notificationData?.iPadNotificationV2?.completed_workshops[0]
                      ?.reference_link,
                    "_blank"
                  );
                }}
              >
                <img
                  src={Images.PdfRefIcon}
                  alt={"reference"}
                  className="rounded w-full h-full object-cover "
                />
              </div>
              <p className="mt-2 h4 mb-4">
                {
                  notificationData?.iPadNotificationV2?.completed_workshops[0]
                    ?.pdf_name
                }
              </p>
            </div>
          )}
        {workshopData.learning_outcome && (
          <div className="mt-5">
            <h5 className="h6">LEARNING OUTCOME</h5>
            <div
              className="h7 mt-3"
              dangerouslySetInnerHTML={{
                __html: workshopData.learning_outcome
                  ? workshopData.learning_outcome
                  : "",
              }}
            />
          </div>
        )}

        {workshopData.workshop_notes?.length > 0 && (
          <div className="mt-5">
            <h5 className="h6">WORKSHOP NOTES</h5>
            <ImageContainer image={workshopData?.workshop_notes} />
          </div>
        )}
      </div>
      <Modal
        visible={showParentSetup}
        onCancel={() => {
          // setShowLoginBlock(false);
        }}
        closable={false}
        closeIcon={false}
        width={480}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

        // title="Choose where you want to learn today"
      >
        <div className=" px-5">
          <img
            src={Images.LoginEmptySearch}
            alt="Login Empty State"
            className="mx-auto"
          />
          <div className="h4 text-center mt-8">
            In order to attend a workshop, your parent has to complete setting
            up their profile
          </div>
          <div className=" mt-10 flex items-center justify-center">
            <div className=" w-40 mx-auto">
              <Button
                secondaryGhost
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  localStorage.setItem("remindProfileSetup", true);
                  setShowParentSetup(false);
                }}
              >
                Remind me later
              </Button>
            </div>
            <div className=" w-32 mx-auto">
              <Button
                secondary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  setShowParentSetup(false);
                  window.open(
                    `${process.env.REACT_APP_WEB_APP}accountSetup`,
                    "_blank"
                  );
                }}
              >
                Set up
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <MaterialModal
        data={workshopData?.materials_details}
        visible={isModalVisible}
        onContinue={() => {
          handleOk(workshopData?.workshop_zoom);
        }}
        onCancel={() => {
          handleCancel();
        }}
      />
      <NotesViewModal
        isModalVisible={isNotesModalVisible}
        handleClose={handleClose}
        data={modalData}
      />
      <StickyBottom>
        <div className="flex justify-between items-center  px-12 bg-white  py-3">
          {parseInt(workshopData?.available_seats) === 0 &&
            resultData?.registration_status === null &&
            workshopData?.workshop_registration_status === true &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  No seats available for this workshop.
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) === 0 &&
            workshopData?.workshop_registration_status === false &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  Registrations closed. Do check our other upcoming events.
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === null &&
            workshopData?.workshop_registration_status === true &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  {getStatusText()}
                </div>
                {getStatusText() ===
                  "Are you interested in attending this workshop?" && (
                  <Button
                    secondary
                    className=" text-pureWhite px-9 py-3 h5 w-52"
                    onClick={() => {
                      showInterest({
                        variables: {
                          id: parseInt(id),
                        },
                      });
                    }}
                  >
                    {`I’m interested`}
                  </Button>
                )}
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === null &&
            workshopData?.workshop_registration_status === false &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  Registrations closed. Do check our other upcoming events.
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 && isPastWorkshop() && (
            <>
              <div className="nunito-bold-xl text-rose px-4">
                This workshop has wrapped up, stay tuned for more!
              </div>
            </>
          )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === "Pending" &&
            workshopData?.workshop_registration_status === true &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  Please wait for your parent’s approval
                </div>
                <Button
                  secondary
                  className=" text-pureWhite px-9 py-3 h5 w-52 opacity-50"
                  onClick={() => {}}
                >
                  {`Pending approval`}
                </Button>
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === "Pending" &&
            workshopData?.workshop_registration_status === false &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  Registrations closed. Do check our other upcoming events.
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === "Registered" &&
            workshopData?.Dates?.length === resultData?.attendedList?.length &&
            checkCompletedStatus() === 0 &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  This workshop has wrapped up, stay tuned for more!
                </div>
                <div className="flex items-center justify-center">
                  <div className="mr-4">
                    <Notesbutton
                      type={"do"}
                      onSuccess={() => {}}
                      title={"Workshop notes"}
                      enableCrop={true}
                    />
                  </div>
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === "Registered" &&
            workshopData?.Dates?.length === resultData?.attendedList?.length &&
            checkCompletedStatus() === 1 &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  This workshop has wrapped up, stay tuned for more!
                </div>
                <div className="flex items-center justify-center">
                  <div className="mr-4">
                    <Notesbutton
                      type={"do"}
                      onSuccess={() => {}}
                      title={"Workshop notes"}
                      enableCrop={true}
                    />
                  </div>
                  <Button
                    secondary
                    className={
                      findDifference(workshopData?.Dates[0])
                        ? ` px-9 py-2 h5 w-182 text-pureWhite`
                        : ` px-9 py-2 h5 w-182 opacity-50 text-pureWhite`
                    }
                    onClick={() => {
                      //check every second to enable join now button
                      if (workshopData?.materials_details.length > 0) {
                        if (findDifference(workshopData?.Dates[0])) {
                          showModal();
                        }
                      } else {
                        if (findDifference(workshopData?.Dates[0])) {
                          handleOk(workshopData?.workshop_zoom);
                        }
                      }
                    }}
                  >
                    {`Join now`}
                  </Button>
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) >= 0 &&
            resultData?.registration_status === "Registered" &&
            (resultData?.attendedList?.length === 0 ||
              resultData?.attendedList === null) &&
            workshopData?.Dates?.length > 0 &&
            checkYetToStartStatus() === 0 && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  You’ve missed attending this workshop.
                </div>
              </>
            )}
          {parseInt(workshopData?.available_seats) >= 0 &&
            resultData?.registration_status === "Registered" &&
            (resultData?.attendedList?.length === 0 ||
              resultData?.attendedList === null) &&
            workshopData?.Dates?.length > 0 &&
            checkYetToStartStatus() === 1 &&
            !isPastWorkshop() && (
              <>
                <div
                  className="nunito-bold-xl text-rose px-4"
                  style={{ color: "#555555" }}
                >
                  You have successfully registered for this workshop. Kindly
                  join on the mentioned time.
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    secondary
                    className={
                      findDifference(workshopData?.Dates[0])
                        ? `text-pureWhite px-9 py-2 h5 w-182`
                        : `text-pureWhite px-9 py-2 h5 w-182 opacity-50`
                    }
                    onClick={() => {
                      //check every second to enable join now button
                      if (workshopData?.materials_details.length > 0) {
                        if (findDifference(workshopData?.Dates[0])) {
                          showModal();
                        }
                      } else {
                        if (findDifference(workshopData?.Dates[0])) {
                          handleOk(workshopData?.workshop_zoom);
                        }
                      }
                    }}
                  >
                    {`Join now`}
                  </Button>
                </div>
              </>
            )}
          {/* in-progress */}
          {parseInt(workshopData?.available_seats) > 0 &&
            resultData?.registration_status === "Registered" &&
            resultData?.attendedList?.length > 0 &&
            workshopData?.Dates?.length > 0 &&
            workshopData?.Dates?.length !== resultData?.attendedList?.length &&
            !isPastWorkshop() && (
              <>
                <div className="nunito-bold-xl text-rose px-4">
                  The next session will be happening on{" "}
                  {moment
                    .unix(
                      workshopData?.Dates[
                        resultData?.attendedList?.length - 1 || 0
                      ]?.workshop_Date / 1000
                    )
                    .format("MMMM DD, YYYY")}
                </div>
                <div className="flex items-center justify-center">
                  <div className="mr-4">
                    <Notesbutton
                      type={"do"}
                      onSuccess={() => {}}
                      title={"Workshop notes"}
                      enableCrop={true}
                    />
                  </div>
                  <Button
                    secondary
                    className={
                      findDifference(workshopData?.Dates[0])
                        ? `text-pureWhite px-9 py-2 h5 w-182`
                        : `text-pureWhite px-9 py-2 h5 w-182 opacity-50`
                    }
                    onClick={() => {
                      //check every second to enable
                      if (workshopData?.materials_details?.length > 0) {
                        if (findDifference(workshopData?.Dates[0])) {
                          showModal();
                        }
                      } else {
                        if (findDifference(workshopData?.Dates[0])) {
                          handleOk(workshopData?.workshop_zoom);
                        }
                      }
                    }}
                  >
                    {`Join now`}
                  </Button>
                </div>
              </>
            )}
        </div>
      </StickyBottom>
    </div>
  );
}
