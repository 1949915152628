import { useMutation } from "@apollo/client";
import { Form, Input, notification } from "antd";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { CHANGE_PROFILE_PASSWORD } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";

export default function ChangePasscode(props) {
  const history = useHistory();
  const [formRef] = Form.useForm(); //useRef();
  const [operationType, setOperationType] = useState("verify");
  const [oldPassword, setOldPassword] = useState(undefined);
  const [passCode, setPassCode] = useState(null);
  const [confirmPassCode, setConfirmPassCode] = useState(null);

  const user_details = useMemo(() => JSON.parse(localStorage.getItem("user_details")), []);
  const userName = user_details?.username;
  const avatar = user_details?.avatar;
  const bg_color = user_details?.bgColor;

  const [changePasscode, { loading: creatingPasscode }] = useMutation(
    CHANGE_PROFILE_PASSWORD,
    {
      fetchPolicy: "network-only",
      onError: (error) => {
        console.log(error);
        notification.error({
          message: error.message,
        });
        setOperationType("verify");
      },
    }
  );
  return (
    <div className="p-5">
      <Loader loading={creatingPasscode} />
      {/* <img src={Images.Logo} alt="logo" className="mx-auto " /> */}
      <div className="flex justify-between h-9 items-center mt-4">
        <div className=" absolute left-0 right-0 text-center">
          <div className="font-bold text-2xl text-gray-700">{`${
            operationType === "create"
              ? "Create your"
              : operationType === "confirm"
              ? "Confirm your"
              : "Enter your current"
          } passcode`}</div>
        </div>
      </div>
      <div className="xl:mt-24 mt-12">
        <div className="text-center">
          <div
            className="h-24 w-24 rounded-full m-auto"
            style={{ backgroundColor: bg_color || "whitesmoke" }}
          >
            <img src={avatar} alt="avatar" />
          </div>
          <div className="pt-5 font-normal text-xl">{userName}</div>
        </div>
        <div className=" px-0 md:px-10 lg:px-16 mt-10">
          <Form
            layout="vertical"
            form={formRef}
            onFinish={async (values) => {
              console.log(values, operationType);
              if (operationType === "verify") {
                let { data } = await changePasscode({
                  variables: {
                    oldPassword: oldPassword,
                    password: "",
                  },
                });
                if (data.ipadChangePassword?.status) {
                  setOperationType("create");
                } else {
                  notification.error({ message: "Passcode entered is wrong." });
                }
              } else if (operationType === "create") {
                setOperationType("confirm");
              } else if (operationType === "confirm") {
                //console.log(passCode, confirmPassCode);
                if (passCode === confirmPassCode) {
                  let { data } = await changePasscode({
                    variables: {
                      oldPassword: oldPassword,
                      password: passCode,
                    },
                  });
                  if (data.ipadChangePassword?.is_password_changed) {
                    notification.success({ message: "Passcode changed" });
                    if (props.onSuccess) props.onSuccess();
                  }
                } else {
                  notification.error({
                    message: "Passcode and Confirm Passcode not matching.",
                  });
                }
              }
            }}
          >
            {operationType === "verify" && (
              <Form.Item name="oldPassword">
                <Input.Password
                  name="passcode"
                  preventText
                  placeholder={`Enter your passcode`}
                  small
                  autoFocus
                  className={"w-full py-4 px-5 rounded-l-3xl rounded-r-3xl"}
                  onChange={(evt) => {
                    if (
                      !isNaN(evt.target.value) &&
                      String(evt.target.value).length <= 4
                    ) {
                      setOldPassword(evt.target.value);
                      formRef.setFieldsValue({
                        oldPassword: evt.target.value,
                      });
                    } else {
                      setOldPassword(String(evt.target.value).slice(0, -1));
                      formRef.setFieldsValue({
                        oldPassword: String(evt.target.value).slice(0, -1),
                      });
                    }
                  }}
                ></Input.Password>
              </Form.Item>
            )}
            {operationType === "create" && (
              <Form.Item name="passcode">
                <Input.Password
                  name="passcode"
                  preventText
                  placeholder={`Enter your passcode`}
                  small
                  autoFocus
                  className={"w-full py-4 px-5 rounded-l-3xl rounded-r-3xl"}
                  onChange={(evt) => {
                    if (
                      !isNaN(evt.target.value) &&
                      String(evt.target.value).length <= 4
                    ) {
                      setPassCode(evt.target.value);
                      formRef.setFieldsValue({
                        passcode: evt.target.value,
                      });
                    } else {
                      setPassCode(String(evt.target.value).slice(0, -1));
                      formRef.setFieldsValue({
                        passcode: String(evt.target.value).slice(0, -1),
                      });
                    }
                  }}
                ></Input.Password>
              </Form.Item>
            )}
            {operationType === "confirm" && (
              <Form.Item name="confirmPasscode">
                <Input.Password
                  name="confirmPasscode"
                  value={confirmPassCode}
                  placeholder={`Re-Enter your passcode`}
                  small
                  className={"w-full py-4 px-5 rounded-l-3xl rounded-r-3xl"}
                  onChange={(evt) => {
                    if (
                      !isNaN(evt.target.value) &&
                      String(evt.target.value).length <= 4
                    ) {
                      setConfirmPassCode(evt.target.value);
                      formRef.setFieldsValue({
                        confirmPasscode: evt.target.value,
                      });
                    } else {
                      setConfirmPassCode(String(evt.target.value).slice(0, -1));
                      formRef.setFieldsValue({
                        confirmPasscode: String(evt.target.value).slice(0, -1),
                      });
                    }
                  }}
                ></Input.Password>
              </Form.Item>
            )}
            {operationType === "create" && (
              <div className="mt-3 text-base font-normal text-center text-lightGray">
                Note: The passcode should be only numbers upto 4 digits
              </div>
            )}
            <div
              className={`block text-center xl:mt-24 mt-12 w-56 mx-auto ${
                formRef.getFieldValue("oldPassword") ||
                formRef.getFieldValue("passcode")
                  ? ``
                  : `opacity-50 pointer-events-none`
              } `}
            >
              <Button
                onClick={async () => {
                  formRef.submit();
                }}
                primary
              >{`${operationType === "confirm" ? "Submit" : "Next"}`}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
