import { Modal } from "antd";
import React from "react";
import Button from "../../../../components/common/Form/Button";
import PrerequisiteCard from "./PrerequisiteCard";

export default function Prerequisite(props) {
  return (
    <div>
      <Modal
        width={"70%"}
        title={null}
        visible={props.showPrerequisite}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          props.setShowPrerequisite(false);
        }}
      >
        <div className=" nunito-semibold-2xl ">
          You might want to complete these before you start with - Learn to make
          paintings using your hands and feet
        </div>
        <div className="mt-8 mb-3 border-b border-gray4 "></div>
        {(props?.data || []).map((cardStatus, cardIndex) => {
          return (
            <div key={`CardStatus-${cardIndex}`}>
              <PrerequisiteCard
                status={
                  cardStatus.activity_status || cardStatus.playlist_status
                }
                imgURL={
                  cardStatus?.thumb_images?.length
                    ? cardStatus.thumb_images[0].image_url
                    : cardStatus.background_image
                }
                title={cardStatus.name}
                viewCard={true}
                hideCard={false}
                id={cardStatus.id}
                isViewCard
                onSchedule={props.onSchedule}
                setShowPrerequisite={props.setShowPrerequisite}
              />
            </div>
          );
        })}
        <div className=" flex justify-end mt-10">
          <div className=" w-24">
            <Button
              secondaryGhost
              className={
                "font-Nunito text-base px-6  py-2  font-bold flex items-center justify-center"
              }
              onClick={() => {
                props.setShowPrerequisite(false);
                props.onSkip();
              }}
            >
              Skip
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
