import { Modal } from "antd";
import React from "react";
import Audio from "./Audio";
import Button from "./Form/Button";

const NotesViewModal = ({ data, isModalVisible, handleClose }) => {
  const imageFormats = ["jpg", "jpeg", "png", "svg"];
  const audioFormats = ["m4a", "mp3", "mp4", "wav"];
  const isImage = imageFormats.includes(
    data?.url?.split(".")[data?.url?.split(".")?.length - 1]
  );
  const isAudio = audioFormats.includes(
    data?.url?.split(".")[data?.url?.split(".")?.length - 1]
  );
  return (
    <Modal
      visible={isModalVisible}
      centered
      width="60%"
      footer={null}
      className="overflow-auto rounded"
      closable={false}
      destroyOnClose
    >
      <div className="block">
        <Button
          secondaryGhost
          className="font-Nunito text-base px-6 py-2 flex font-bold w-24 ml-auto"
          onClick={() => {
            handleClose();
          }}
        >
          {`Close`}
        </Button>
      </div>
      <div className="flex mt-2" style={{ minHeight: "40vh" }}>
        {data?.url && (
          <div className="w-1/2">
            {isImage ? (
              <img
                className="rounded"
                src={data?.url}
                style={{
                  //minWidth: "100%",
                  //height: "100%",
                  margin: "auto",
                  objectFit: "contain"
                }}
                alt="Notes Img"
              />
            ) : isAudio ? (
              <Audio url={data?.url} />
            ) : null}
          </div>
        )}
        {data?.notes && (
          <div className="w-1/2 ml-2 font-semibold font-nunito text-lg">
            <p>{decodeURIComponent(escape(window.atob(data?.notes)))}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NotesViewModal;
