import { useMutation } from "@apollo/client";
import { Modal, notification, Spin } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { SEND_REQUEST } from "../../client/graphql";
import NavContext from "../../context/NavContext";
import Images from "../../themes/Images";
import Button from "./Form/Button";

export default function SubscriptionEndModal({ showSubscripitonExpired }) {
  const history = useHistory();
  const [shoModal, setShowModal] = useState(null);
  const userNavContext = useContext(NavContext);
  const [isReqSent, setIsReqSent] = useState(false);

  useEffect(() => {
    setShowModal(showSubscripitonExpired);
  }, [showSubscripitonExpired]);

  useEffect(() => {
    if (localStorage.getItem("learnerRequestSent")) {
      setIsReqSent(true);
    }
  }, [localStorage.getItem("learnerRequestSent")]);

  const [sendRequestToParent, { loading: requestingParent }] = useMutation(
    SEND_REQUEST,
    {
      onCompleted: (data) => {
        if (data?.sendRequest?.status === "Success") {
          notification.success({ message: "Request sent to parent" });
          localStorage.setItem("learnerRequestSent", true);
          setIsReqSent(true);
        }
      },
    }
  );

  return (
    <div>
      <Modal
        closable={false}
        visible={shoModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={550}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}
        className="paymentModel"
        centered
        // title="Choose where you want to learn today"
      >
        <div className="text-3xl font-bold font-Nunito ">
          Your access has expired
          <br />
          <span
            className="text-xl font-bold font-Nunito "
            style={{ color: "#B9B9B9" }}
          >
            Enroll yourself as a learner
          </span>
        </div>
        <div className="flex ">
          <div>
            <div className=" mt-10 flex h6 ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Unlimited access to watch and do cards
            </div>
            <div className=" mt-3 flex h6 ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Access to your schedule and wishlist
            </div>
            <div className=" mt-3 flex h6 ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Access to new content
            </div>
            <div className="mt-8 h6">
              {userNavContext?.userInfo?.mode === "DARK_MODE"
                ? `Access 150+ Learning content for just ₹5500 / Year + GST`
                : `To continue your learning journey, we need permission from your
              parents`}
            </div>
            <div className="mt-6 inline-block">
              <Button
                primary
                className={`font-Nunito text-base px-6 py-2 flex font-bold`}
                disabled={requestingParent || isReqSent}
                onClick={() => {
                  if (!isReqSent && !requestingParent) {
                    if (userNavContext?.userInfo?.mode === "DARK_MODE") {
                      history.push("/payment/proceed");
                    } else {
                      if (userNavContext?.userInfo?.is_parent) {
                        sendRequestToParent();
                      } else {
                        userNavContext.setUserInfo({
                          ...userNavContext.userInfo,
                          ...{
                            showParentSetup: true,
                          },
                        });
                      }
                    }
                  }
                  setShowModal(false);
                }}
              >
                {userNavContext?.userInfo?.mode === "DARK_MODE"
                  ? `Proceed to one-time payment`
                  : isReqSent
                  ? `Request sent`
                  : `Send a request to parent`}
              </Button>
              <Spin spinning={requestingParent} className="ml-4" />
              {isReqSent && (
                <div className="mt-2">
                  Meanwhile, would you like to access{" "}
                  <Link to="/workshops">
                    {" "}
                    <a className=" underline">workshops?</a>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <img src={Images.PaymentEmptyState} alt=" Payment Imag" />
          </div>
        </div>
      </Modal>
    </div>
  );
}
