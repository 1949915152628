import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { notification } from "antd";

let token = localStorage.getItem("token");
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API,
  headers: {
    ...(localStorage.getItem("token")
      ? { token: localStorage.getItem("token") }
      : {}),
    device_token: localStorage.getItem("deviceToken") || null,
  },
});

const authLink = setContext((_, { headers }) => {
  let userToken = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      ...(userToken ? { token: userToken } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    if (networkError.statusCode === 401) {
      notification.error({
        message: "Session expired. Please log in again",
      });
      if (window?.stop) {
        window?.stop();
      }
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  // uri: process.env.REACT_APP_API,
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  headers: {
    ...(token ? { token: token } : {}),
    device_token: localStorage.getItem("deviceToken") || null,
  },
});

export default client;
