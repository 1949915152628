import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { BackBlueDualIcon } from "../../themes/dualImages";
import { BackButtonHandler } from "../../utils";

export const ExploreContainer = styled.div`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
`;
export default function ExploreHeading({
  title,
  onClick,
  isBack = true,
  rightChildren,
}) {
  const history = useHistory();
  return (
    <ExploreContainer className="flex py-5 px-12 relative bg-white z-1 ">
      <div className="inline-block z-10">
        {isBack && (
          <div
            className=" px-5 py-2 text-blue border border-blue rounded-4xl flex items-center cursor-pointer"
            onClick={() => {
              if (onClick) {
                onClick && onClick();
              } else {
                BackButtonHandler(history);
              }
            }}
          >
            <div className="mr-3">

              <BackBlueDualIcon />
            </div>
            {/* <img src={Images.BackBlueIcon} alt="Back button" className="mr-3" /> */}
            Back
          </div>
        )}
      </div>
      {title && (
        <div className="absolute inset-0 text-center flex items-center justify-center h3 pointer-events-none">
          {title}
        </div>
      )}
      {rightChildren || <></>}
    </ExploreContainer>
  );
}
