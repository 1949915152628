import React from "react";
import Images from "../../themes/Images";

export default function CarouselContainer({ data, id, maxCount }) {
  return (
    (data || []).length > maxCount && (
      <>
        <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
          <img
            src={Images.CarouselLeft}
            alt="imageRight"
            className={`rotate-180 transform shadow-3xl rounded-full w-11 h-11 cursor-pointer z-10`}
            onClick={() => {
              document.getElementById(id).scrollTo({
                left: document.getElementById(id).scrollLeft - 250,
                behavior: "smooth",
              });
            }}
          />
        </div>
        <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
          <img
            src={Images.CarouselLeft}
            alt="imageRight"
            className={`transform shadow-3xl rounded-full w-11 h-11 cursor-pointer z-10`}
            onClick={() => {
              document.getElementById(id).scrollTo({
                left: document.getElementById(id).scrollLeft + 250,
                behavior: "smooth",
              });
            }}
          />
        </div>
      </>
    )
  );
}
