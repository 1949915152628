import { useQuery } from "@apollo/client";
import { Avatar, Modal, notification, Popover, Spin, Typography } from "antd";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GET_NOTIFICATION, PARENT_NOTIFICATION } from "../../../client/graphql";
import NavContext from "../../../context/NavContext";
import { NotificationDualIcon } from "../../../themes/dualImages";
import Images, { DefaultAvatar } from "../../../themes/Images";
import { avatarMap } from "../../../utils";
import Button from "../Form/Button";
import LCSelectModal from "../LCSelectModal";
import NotificationAlert from "../NotificationAlert";
import SubscriptionEndModal from "../SubscriptionExpiredModal";
import TrialEndModal from "../TrialExpiredModal";
import TrialStartModal from "../TrialStartModal";
import {
  CartIcon,
  ExploreIcon,
  JournalIcon,
  ScheduleIcon,
  WorkshopIcon
} from "./NavIcon";
import SearchInput from "./SearchInput";
const { Paragraph } = Typography;

const NavContainer = styled.div`
  .navItem:hover .fill svg path {
    fill: white;
  }
  .navItem:hover .stroke svg path {
    stroke: white;
  }
`;
const ProfileContainer = styled.div`
  padding: 5px;
  padding-right: 10px;
  background: #42c0ef;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  font-weight: 500;
  .userName {
    font-weight: 600;
  }
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  @media (max-width: 576px) {
    padding-right: 10px;
    width: 60px;
    height: 36px;
    .profileArrow {
      width: 10px;
    }
    .userName {
      display: none;
    }
  }
`;

const MenuItem = ({ Image, title, link, baseRoute, route, imageClass }) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <NavContainer>
      <NavLink
        to={`/${link}`}
        isActive={(match, location) => {
          if (baseRoute) {
            if (baseRoute === route) {
              setIsActive(true);
              return true;
            }
          }
          if (!match) {
            setIsActive(false);
            return false;
          }
          if (location.pathname === `/${link}`) {
            setIsActive(true);
            return true;
          }
        }}
      >
        <div
          className={` navItem  cursor-pointer flex justify-center items-center pt-2 pb-3 mx-3 hover:text-pureWhite hover:border-blue border-transparent border-b-4  ${isActive ? "border-blue text-pureWhite" : ""
            }`}
        // onClick={() => {
        //   history.push(`/${link}`);
        // }}
        >
          <span className={`mr-2 ${imageClass || ""}`}>
            <Image isActive={isActive}></Image>
          </span>
          <span className="font-Nunito text-sm font-semibold">{title}</span>
        </div>
      </NavLink>
    </NavContainer>
  );
};
const PopoverContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  width: 165px;

  & .popheading {
    font-weight: bold;
  }
  & .menuItem {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
  }
  .border {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
  }
`;

export default function Nav(props) {
  const location = useLocation();
  const user_details = useMemo(() => JSON.parse(localStorage.getItem("user_details")), []);
  const [showLCList, setshowLCList] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [currentLc, setCurrentLc] = useState("Home");
  const isRemindProfileSetup = localStorage.getItem("remindProfileSetup");
  const [showParentSetup, setShowParentSetup] = useState(false);
  const [showPopups, setShopPopups] = useState(false);
  const avatar = user_details?.avatar;
  const userNavContext = useContext(NavContext);
  const [parentNotification, setparentNotification] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState({});

  React.useEffect(() => {
    if (!isRemindProfileSetup) {
      if (!user_details?.isParentAdded && user_details?.Onboarded_days <= 1) {
        setShowParentSetup(true);
      }
    }
  }, [user_details, isRemindProfileSetup]);

  React.useEffect(() => {
    if (
      location.pathname.includes("journal") ||
      location.pathname.includes("workshop") ||
      location.pathname.includes("profile") ||
      location.pathname.includes("parent") ||
      location.pathname.includes("payment/proceed")
    ) {
      setShopPopups(true);
    } else {
      setShopPopups(false);

    }

  }, [location.pathname]);

  const { loading: loadingNotification } = useQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
    pollInterval: 20000,
    onCompleted: (response) => {
      if (response.iPadNotificationV2) {
        let notificationArr = [];
        if (response.iPadNotificationV2.workshops) {
          notificationArr.push(...response.iPadNotificationV2.workshops);
        }
        if (response.iPadNotificationV2.completed_workshops) {
          notificationArr.push(
            ...response.iPadNotificationV2.completed_workshops
          );
        }
        notificationArr = notificationArr.map((noti) => {
          return {
            workshop_name: noti.workshop_name,
            comment: noti.comment,
            createdAt: noti.createdAt,
            workshop_id: noti.workshop_id ? noti.workshop_id : noti.id,
            reference_link: noti.reference_link || "",
            pdf_name: noti.pdf_name || "",
          };
        });
        setNotifications(notificationArr);
        if (response.iPadNotificationV2?.access?.expiry_type) {
          let subs = {
            expired: response.iPadNotificationV2?.access?.expired,
            access_end_date: response.iPadNotificationV2?.access?.access_end_date,

            type: response.iPadNotificationV2?.access?.expiry_type,
            heading:
              response.iPadNotificationV2?.access?.access_status === "Free"
                ? "Free trial"
                : "Subscription",
          };
          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            ...{
              subs: subs,
              expiry_date: response.iPadNotificationV2?.access?.access_end_date ? moment(
                response.iPadNotificationV2?.access?.access_end_date / 1
              ).utc() : userNavContext?.userInfo?.expiry_date,
            },
          });
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const { data, loading } = useQuery(PARENT_NOTIFICATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {

      if (data) {
        setparentNotification(data?.parentNotification?.notification_details);
        setNotificationDetails(data?.parentNotification?.parent_notification)

      }
    }
  });



  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };
  const PopoverContent = (
    <PopoverContainer>
      <div className="popheading menuItem pt-0">Switch to</div>
      <div className="ml-4 menuItem cursor-pointer" onClick={(e) => {
        e.stopPropagation()

        history.push("/parent/profile"
        )
      }}>My Profile</div>
      {((userNavContext?.userInfo?.parentInfo || {}).child_details || []).map(
        (student, studentIndex) => {
          return (
            <div
              className={`ml-4 menuItem cursor-pointer capitalize`}
              onClick={(e) => {
                e.stopPropagation();

                window.open(
                  `${process.env.REACT_APP_WEB_APP}parent/dashboard?child=${(student || {}).username || ""
                  }`
                );
              }}
              key={`Student-${studentIndex}`}
            >
              {(student || {}).username || ""}'s Profile
            </div>
          );
        }
      )}
      {((userNavContext?.userInfo?.parentInfo || {}).child_details || [])
        .length < 4 && (
          <div
            className="menuItem border-b cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()

              window.open(
                `${process.env.REACT_APP_WEB_APP}parent/workshops?is_child=true`,
                "_blank"
              );
            }}
          >
            {" "}
            + Add new child profile
          </div>
        )}
      <div className="menuItem pb-0 cursor-pointer" onClick={(e) => {
        e.stopPropagation()
        history.push("/parent/billings")
      }}>Accounts & Billing</div>
      <div className="menuItem pb-0 cursor-pointer" onClick={(e) => {
        e.stopPropagation()
        setshowLCList(true);
      }}>Switch Learning Centre </div>
    </PopoverContainer>
  );
  const AvatarDropDown = () => (
    <div>
      <Popover placement="bottomRight" className="menuPopover" content={PopoverContent} trigger="click">

        <ProfileContainer className="flex justify-between items-center mr-4 bg-violet">
          <div className="flex-shrink-0">
            <Avatar
              src={(userNavContext?.userInfo?.parentInfo || {}).profile_image || DefaultAvatar}
              style={{
                backgroundColor: "#FFF6D6",
                color: "#4C39B3",
              }}
              size={27}
            >
              {avatarMap(
                ((userNavContext?.userInfo?.parentInfo || {}).parentDetails || {})
                  .name || "",
                3
              )}
            </Avatar>
          </div>
          <Paragraph
            ellipsis={{
              rows: 1,
              // expandable: false,
              // suffix: "...",
            }}
            className="userName mb-0 px-1"
          >
            {`${(userNavContext?.userInfo?.parentInfo || {}).name || ""}`}
          </Paragraph>

          <div className="flex-shrink-0">
            <img
              src={Images.ProfileDownArrow}
              alt="Profile Arrow"
              className="profileArrow"
            />
          </div>
        </ProfileContainer>
      </Popover>
    </div>
  );
  // console.log(useHistory())
  return (
    <>
      <div className="sticky left-0 right-0 top-0 flex justify-between items-center px-12 bg-white py-4 z-20">
        <div className="flex-shrink-0 flex items-center justify-center">
          <div className=" flex-shrink-0">
            <Link
              to={
                location.pathname.includes("dashboard")
                  ? "/dashboard?ref=logo"
                  : "/dashboard"
              }
            >
              <img src={Images.Logo} alt="logo" className="mx-auto " />
            </Link>
          </div>
          {/* <div className="lg:ml-5 xl:ml-12 text-center  mx-2 lg:w-20 xl:w-40">
            <div
              className="flex items-center ant-dropdown-link text-blue whitespace-nowrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setshowLCList(true);
              }}
            >
              <Paragraph
                ellipsis={{
                  rows: 1,
                  // expandable: false,
                  // suffix: "...",
                }}
                className="userName mb-0 px-1"
              >
                {currentLc}
              </Paragraph>
              <DownOutlined />
            </div>
          </div> */}
        </div>
        {/* <div className="flex justify-center items-center flex-shrink-0"> */}
        {/* </div> */}
        {/* <div className=" flex-shrink-0"> */}
        {/* <SearchInput isSmall></SearchInput> */}
        <div className="flex justify-center items-center flex-shrink-0">
          <div className="flex justify-center items-center flex-shrink-0">
            <MenuItem
              Image={ExploreIcon}
              title={"Explore"}
              link={"dashboard"}
              route="explore"
              baseRoute={props.baseRoute}
              imageClass={"stroke"}
            />
            <MenuItem
              Image={ScheduleIcon}
              title={"Schedule"}
              link={`schedule`}
              route="schedule"
              baseRoute={props.baseRoute}
              imageClass={"stroke"}
            />
            <MenuItem
              Image={JournalIcon}
              title={"Journal"}
              link={"journal"}
              route="journal"
              baseRoute={props.baseRoute}
              imageClass={"stroke"}
            />
            <MenuItem
              Image={WorkshopIcon}
              title={"Workshops"}
              route="workshops"
              link="workshops"
              baseRoute={props.baseRoute}
              imageClass={"fill"}
            />
            <MenuItem
              Image={CartIcon}
              title={"Cart"}
              route="cart"
              link="cart"
              baseRoute={props.baseRoute}
              imageClass={"stroke"}
            />
          </div>
          <SearchInput isSmall></SearchInput>
          <div className="ml-3 mr-5 w-5 flex-shrink-0">
            <Popover
              placement="bottomRight"
              title={null}
              overlayInnerStyle={{
                borderRadius: 10,
              }}
              visible={visible}
              onVisibleChange={handleVisibleChange}
              content={
                <div
                  className={` pt-1 pb-2 w-96 ${(notifications || []).length === 0 ? " h-16" : "h-52"
                    } overflow-scroll`}
                >
                  {loadingNotification && (
                    <div className="text-center h-full">
                      <Spin spinning={loadingNotification || loading}></Spin>
                    </div>
                  )}
                  {!loadingNotification && (notifications || []).length === 0 && (
                    <>
                      <div className="flex justify-center items-center h4 h-full">
                        No new notifications available
                      </div>
                    </>
                  )}
                  {!loadingNotification && (notifications || []).length !== 0 && (
                    <>
                      <div className=" h9 ">
                        Notifications ({notifications.length})
                      </div>
                      {notifications.map((notification, notificationIndex) => {
                        let createdAt = moment(
                          notification.createdAt,
                          "x"
                        ).format("DD-MMM-YYYY hh:mm:ss");
                        return (
                          <div
                            className="flex justify-start items-center mt-3 cursor-pointer"
                            key={`Notification-${notificationIndex}`}
                            onClick={() => {
                              history.push(
                                `/workshops/${notification.workshop_id
                                }?notification=true${notification.reference_link
                                  ? "&reference_image=true"
                                  : ""
                                }`
                              );
                              setVisible(false);
                            }}
                          >
                            <div className="flex items-start pt-4 border-t border-gray4 w-full ">
                              {!notification.reference_link &&
                                !notification.pdf_name && (
                                  <img
                                    src={Images.NotificationCheckIcon}
                                    alt="Checked Icon"
                                    className="mr-2"
                                  />
                                )}
                              {notification.reference_link && (
                                <img
                                  src={notification.reference_link}
                                  alt="Reference Link"
                                  className=" w-16 h-10 object-cover mr-2 rounded "
                                />
                              )}
                              {notification.pdf_name && (
                                <img
                                  src={Images.PdfRefIcon}
                                  alt="Reference Link"
                                  className=" w-6 h-8 object-cover mr-2 rounded "
                                />
                              )}
                              <div>
                                <div className="h9 mr-3 text-lightGray ">
                                  {notification.comment}
                                </div>
                                <div className=" text-lightGray h11 mt-2">
                                  {moment(createdAt).fromNow()}
                                </div>
                              </div>
                            </div>
                            <div className=" flex-shrink-0">
                              <img
                                src={Images.NotificationArrowIcon}
                                alt="Notification Arrow"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              }
              trigger="click"
            >
              <div className="relative">
                {(notifications || []).length !== 0 && (
                  <div className="absolute top-0 -right-1 w-1.5 h-1.5 rounded-full bg-red"></div>
                )}
                {/* <img
                  src={Images.NotificationIcon}
                  alt="Notifications"
                  className=" cursor-pointer"
                /> */}

                <NotificationDualIcon />
              </div>
            </Popover>
          </div>
          {/* <Link to="/parent/profile"> */}
          <AvatarDropDown />

          {/* <Avatar icon={<UserOutlined />} src={avatar} /> */}
          {/* </Link> */}
        </div>
        {/* </div> */}
      </div>
      <LCSelectModal
        visible={showLCList}
        onClose={() => {
          setshowLCList(false);
        }}
        onOpen={() => {
          setshowLCList(true);
        }}
        selectedLc={(name) => {
          setCurrentLc(name);
        }}
      />
      <Modal
        visible={showParentSetup}
        onCancel={() => {
          // setShowLoginBlock(false);
        }}
        closable={false}
        closeIcon={false}
        width={480}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

      // title="Choose where you want to learn today"
      >
        <div className=" px-5">
          <img
            src={Images.LoginEmptySearch}
            alt="Login Empty State"
            className="mx-auto"
          />
          <div className="h4 text-center mt-8">
            To ensure your safety while using the app, your parent has to
            complete their profile set up.
          </div>
          <div className=" mt-10 flex items-center justify-center">
            <div className=" w-40 mx-auto">
              <Button
                secondaryGhost
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  localStorage.setItem("remindProfileSetup", true);
                  setShowParentSetup(false);
                }}
              >
                Remind me later
              </Button>
            </div>
            <div className=" w-32 mx-auto">
              <Button
                secondary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  setShowParentSetup(false);
                  window.open(
                    `${process.env.REACT_APP_WEB_APP}accountSetup`,
                    "_blank"
                  );
                }}
              >
                Set up
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {userNavContext?.userInfo?.showTrial && !showPopups && (
        <TrialStartModal
          showTrialStarted={userNavContext?.userInfo?.showTrial}
        />
      )}
      {userNavContext?.userInfo?.expired && userNavContext?.userInfo?.expiry_type !== "Paid" && !showPopups && !showParentSetup && (
        <TrialEndModal showTrialExpired={userNavContext?.userInfo?.expired} />
      )}
      {userNavContext?.userInfo?.expired && userNavContext?.userInfo?.expiry_type === "Paid" && !showPopups && !showParentSetup && (
        <SubscriptionEndModal showSubscripitonExpired={true} />
      )}
      {parentNotification?.length !== 0 && (
        <NotificationAlert
          title="Subscription Request"
          isColored
          desc={
            <div className=" font-Nunito text-base text-pureWhite font-extrabold mt-2">
              {" "}
              {parentNotification?.map((user) => user.username)?.join(", ")} has
              requested a subscription to continue their learning journey •{" "}
              <Link
                to={`/parent/billings`}
                onClick={() => {
                  localStorage.setItem("showNotification", "true");
                }}
              >
                {" "}
                <a className="underline">Subscribe now </a>
              </Link>{" "}
            </div>
          }
        />
      )}

      {notificationDetails?.notification_type &&
        localStorage.showNotification !== "true" && <NotificationAlert />}
    </>
  );
}
