import styled from "styled-components";
import Images from "../../themes/Images";

const ExpertContainer = styled.div`
  /* width: 410px; */
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
  color:#fff;
  .titleContainer {
    background: linear-gradient(
      180.34deg,
      rgba(0, 0, 0, 0) 48.97%,
      rgba(0, 0, 0, 0.4) 76.86%,
      rgba(0, 0, 0, 0.6) 99.71%
    );
  }
`;

export default function ExpertCard({
  data = {},
  onClick,
  loading,
  isPlaylist,
}) {
  return (
    <>
      <ExpertContainer className="relative cursor-pointer" onClick={onClick}>
        {loading && <div className="loader h-full"></div>}
        {isPlaylist && (
          <img
            src={Images.PlaylistIcon}
            className="absolute img-fluid right-2 top-2"
            alt="wishlist"
          />
        )}
        {!loading && (
          <img
            className="w-full h-full object-cover"
            src={data.background_image}
            alt="Explore card"
          />
        )}
        <div className="titleContainer absolute inset-0"></div>
        <div className=" px-3 pb-2 absolute bottom-0 w-full">
          <div
            className={`font-Nunito text-xl  font-bold ${loading ? "loader rounded h-4 w-10/12" : ""
              }`}
          >
            {data.name || data.title || ""}
          </div>
          <div
            className={`mt-1 font-light font-Nunito text-normal  flex ${loading ? "loader rounded h-3 w-8/12  mt-2" : ""
              }`}
          >
            {loading ? "" : `By ${data.user?.name || ""}`}{" "}
            {data.user?.role_id === 4 && (
              <img
                src={Images.VerifiedIcon}
                alt="verified Icon"
                className="ml-2"
              />
            )}
          </div>
          <div
            className={`mt-1 font-light font-Nunito text-sm  ${loading ? "loader rounded h-3 w-1/2 mt-2" : ""
              }`}
          >
            {loading
              ? ""
              : `${data.cards?.length || data.count === 0
                ? `Cards ${data.cards?.length || data.count || 0}`
                : ""
              } ${data.cards?.length !== 0 &&
                data.count !== 0 &&
                data.completedCount !== 0
                ? "•   "
                : ""
              } ${data.completedCount
                ? `Completed ${data.completedCount || 0}`
                : ""
              }`}
          </div>
        </div>
      </ExpertContainer>
    </>
  );
}
