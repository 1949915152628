import { useLazyQuery, useQuery } from "@apollo/client";
import { Calendar, Card, Divider, Modal, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ADULT_JOURNAL, CALENDAR_VIEW } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import { BackBlueDualIcon } from "../../themes/dualImages";
import Images from "../../themes/Images";
import { base64regex, NotesBg } from "../../utils";

const ContentPictureStyle = styled.div`
  width: 215px;
  height: 140px;
  flex-shrink: 0;
  & .bg {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 91.33%
    );
  }
`;

const { Paragraph, Text } = Typography;

const ParentCalendarComponent = () => {
  const [date, setDate] = React.useState(moment().format("MMMM yyyy"));
  const [journals, setJournals] = React.useState(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [calendarData, setCalendarData] = React.useState({});

  const showModal = (value) => {
    setIsModalVisible(value);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setJournals(null);
  };

  const { data: calendarView, loading: viewLoading } = useQuery(CALENDAR_VIEW, {
    variables: {
      date: moment().format("YYYY-MM-DD"),
    },
    fetchPolicy: "network-only",
  });

  const [getJournalData, { data: journalData, loading: journalLoading }] =
    useLazyQuery(ADULT_JOURNAL);

  const [
    getCalendarView,
    { data: calendarViewData, loading: calendarDataLoading },
  ] = useLazyQuery(CALENDAR_VIEW);

  React.useEffect(() => {
    if (journalData) {
      setJournals(journalData?.JournalLearner?.overall_journal_list);
    }
  }, [journalData]);

  React.useEffect(() => {
    if (calendarView) {
      setCalendarData(calendarView);
    }
  }, [calendarView]);

  React.useEffect(() => {
    if (calendarViewData) {
      setCalendarData(calendarViewData);
    }
  }, [calendarViewData]);

  const history = useHistory();

  const onSelect = (value) => {
    setDate(value.format("MMMM yyyy"));
    getJournalData({
      variables: {
        date: value.format("YYYY-MM-DD"),
      },
    });
    showModal(true);
  };

  const onPanelChange = (value) => {
    setDate(value.format("MMMM yyyy"));
    showModal(false);
    getCalendarView({
      variables: {
        date: value.format("YYYY-MM-DD"),
      },
    });
  };

  const getListData = (value) => {
    let obj = {
      do: false,
      watch: false,
      content: "",
      playlist: false,
    };
    calendarData?.iPadCalenderView?.calender_details?.map((details) => {
      if (
        moment(details?.date).format("YYYY-MM-DD") ===
        moment(value).format("YYYY-MM-DD")
      ) {
        obj.do = details?.activity;
        obj.watch = details?.libraries;
        obj.playlist = details?.playlist;
        obj.content = details?.journal_image_notes_state
          ? "Click to view Journal"
          : "";
      }
      // return;
    });
    return obj;
  };

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <div>
        <div className="flex justify-start mb-2 items-baseline gap-4">
          {listData?.playlist && (
            <div>
              <img src={Images.PlaylistIcon} alt="event" />
            </div>
          )}
          {listData?.do && (
            <div>
              <img src={Images.CalendarDoIcon} alt="event" />
            </div>
          )}{" "}
          {listData?.watch && (
            <div>
              <img src={Images.CalendarWatchIcon} alt="event" />
            </div>
          )}
        </div>
        <p>{listData?.content}</p>
      </div>
    );
  }

  const CarouselContainer = ({ data, id, maxCount }) => {
    return (
      (data || []).length > maxCount && (
        <div className="mt-2">
          <div className="absolute top-0 bottom-0 flex items-center justify-center " />

          <div className="absolute top-0 bottom-0 flex items-center justify-center " />
        </div>
      )
    );
  };

  const ImageContainer = ({ image, id }) => {
    return (
      <div style={{ maxWidth: "90%" }} className="mt-4">
        <CarouselContainer id={id} data={image} maxCount={4} />
        <div
          id={id}
          className="flex items-center overflow-auto flex-start flex-wrap"
        >
          {image
            ?.slice(0)
            ?.reverse()
            ?.map((pictureStyle, styleIndex) => {
              return (
                <ContentPictureStyle
                  className="relative mr-4 mb-4 rounded overflow-hidden cursor-pointer"
                  key={`PictureStyle-${styleIndex}`}
                >
                  <img
                    src={
                      pictureStyle.type === "doodle" ||
                        pictureStyle.type === "captured" ||
                        pictureStyle.type === "uploaded" ||
                        pictureStyle.type === "upload"
                        ? pictureStyle.image
                        : pictureStyle.type === "audio"
                          ? Images.NotesAudioBg
                          : NotesBg(pictureStyle.notes)
                    }
                    alt="notes img"
                    className="w-full h-full rounded"
                  />

                  <div className="absolute inset-0 bg"></div>
                  <div className="absolute bottom-0 p-2">
                    <div className="h6 text-pureWhite">
                      {`${moment(pictureStyle.createdAt, "x").format(
                        "MMM DD, YYYY"
                      )} - ${pictureStyle.type === "captured" ||
                        pictureStyle.type === "upload" ||
                        pictureStyle.type === "uploaded"
                        ? "Photo"
                        : pictureStyle.type === "doodle"
                          ? "Doodle"
                          : pictureStyle.type === "audio"
                            ? "Audio"
                            : "Notes"
                        }`}
                      {(pictureStyle.type === "captured" ||
                        pictureStyle.type === "upload" ||
                        pictureStyle.type === "uploaded" ||
                        pictureStyle.type === "audio") && (
                          <Paragraph
                            ellipsis={{
                              rows: 2,
                            }}
                            className="mb-0 h6 text-pureWhite"
                          >
                            {pictureStyle.notes
                              ? base64regex.test(pictureStyle.notes)
                                ? decodeURIComponent(
                                  escape(window.atob(pictureStyle.notes))
                                )
                                : pictureStyle.notes
                              : ""}
                          </Paragraph>
                        )}
                    </div>
                  </div>
                </ContentPictureStyle>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <Card>
      <div className="flex px-16 pb-8 items-baseline">
        <Button
          secondaryGhost
          className="font-Nunito text-base px-6 py-2 flex font-bold items-center"
          onClick={() => {
            history.goBack();
          }}
        >
          <div className="mr-3">
            <BackBlueDualIcon />
          </div>

          {/* <img src={Images.BackBlueIcon} alt="Add Icon" className="mr-3" /> */}
          {`Back`}
        </Button>
        <div style={{ marginLeft: "35%" }}>
          <h1 className="date text-3xl font-bold">{date}</h1>
        </div>
      </div>
      <Calendar
        onPanelChange={onPanelChange}
        onSelect={onSelect}
        validRange={[moment().subtract(4, "months"), moment()]}
        mode={"month"}
        fullscreen={true}
        dateCellRender={dateCellRender}
      />
      <Modal
        visible={isModalVisible}
        centered
        width="80%"
        footer={null}
        className="overflow-auto"
        closable={false}
      >
        {journals !== null ? (
          <div
            className={`${journalLoading ? "loader rounded h-full w-full" : ""
              }`}
          >
            <Card className="w-full px-16">
              <p className="text-center font-bold text-xl mt-4">
                A snapshot of all that I’ve learnt and would like to reflect on
                <span className="float-right">
                  <Button
                    secondaryGhost
                    className="font-Nunito text-base px-6 py-2 flex font-bold"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {`Close`}
                  </Button>
                </span>
              </p>
              {journals && journals?.length > 0
                ? journals?.map((journal, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="py-8">
                        <h1 className="date text-3xl font-bold">
                          {journal?.date}
                        </h1>
                      </div>
                      <div
                        // style={{ background: "rgba(242, 252, 255, 1)" }}
                        className="px-8 py-4 bg-lightWhite"
                      >
                        {journal?.card_details?.map((entry, i) => {
                          return (
                            <div
                              className="flex space-around gap-4 items-start"
                              key={`${index},${i}`}
                            >
                              <div className="flex-auto w-20">
                                <div>
                                  {entry?.activity_status !==
                                    "In - progress" && (
                                      <p className="text-center font-bold font-nunito mb-4">
                                        {moment
                                          .unix(entry?.createdAt / 1000)
                                          .format("hh:mmA") ||
                                          moment(
                                            entry?.createdAt / 1000
                                          ).format("hh:mmA")}
                                      </p>
                                    )}
                                  {entry?.activity_status ===
                                    "In - progress" && (
                                      <p className="text-center font-bold font-nunito mb-4">
                                        {moment(entry?.createdAt).format(
                                          "hh:mmA"
                                        )}
                                      </p>
                                    )}
                                  {entry?.type === "activities" && (
                                    <img
                                      src={
                                        (entry?.default_activities_id === 2 ||
                                          entry?.default_activities_id ===
                                          3) &&
                                          entry?.playlist_id === null
                                          ? Images.watchIcon
                                          : (entry?.default_activities_id ===
                                            1 ||
                                            entry?.default_activities_id ===
                                            2 ||
                                            entry?.default_activities_id ===
                                            3 ||
                                            entry?.default_activities_id ===
                                            4) &&
                                            entry?.playlist_id !== null
                                            ? Images.watchPlaylistIcon
                                            : entry?.default_activities_id ===
                                              4 && entry?.playlist_id === null
                                              ? Images.doIcon
                                              : entry?.default_activities_id ===
                                                4 && entry?.playlist_id !== null
                                                ? Images.doPlaylistIcon
                                                : null
                                      }
                                      alt="icon"
                                      className="mx-auto"
                                      style={{ width: "34px" }}
                                    />
                                  )}
                                  {entry?.type === "journal" && (
                                    <img
                                      src={
                                        entry?.journal_state === "uploaded" ||
                                          entry?.journal_state === "upload" ||
                                          entry?.journal_state === "captured" ||
                                          entry?.journal_state === "pictures"
                                          ? Images.ImagesIcon
                                          : entry?.journal_state === "notes"
                                            ? Images.NotesIcon
                                            : entry?.journal_state === "audio"
                                              ? Images.MicIcon
                                              : Images.DoodleIcon
                                      }
                                      alt="icon"
                                      className="mx-auto"
                                      style={{ width: "34px" }}
                                    />
                                  )}
                                  {entry?.type === "feedback" && (
                                    <img
                                      src={Images.LCFeedbackIcon}
                                      alt="icon"
                                      className="mx-auto"
                                      style={{ width: "34px" }}
                                    />
                                  )}
                                  {entry?.type === "milestone" && (
                                    <img
                                      src={Images.LogoIcon}
                                      alt="icon"
                                      className="mx-auto"
                                      style={{ width: "34px" }}
                                    />
                                  )}
                                  {entry?.type === "workshop" && (
                                    <img
                                      src={Images.WorkShopIcon}
                                      alt="icon"
                                      className="mx-auto"
                                      style={{ width: "34px" }}
                                    />
                                  )}
                                </div>
                              </div>
                              {entry?.type === "workshop" && (
                                <div
                                  className={
                                    journal?.card_details?.length > 0 &&
                                      i < journal?.card_details?.length - 1
                                      ? `flex-auto w-full connector`
                                      : `flex-auto w-full relative`
                                  }
                                >
                                  <Card
                                    className="rounded pb-4"
                                    style={{ border: "1px solid #FFC90D" }}
                                  >
                                    <div className="flex">
                                      <div className="mr-4">
                                        <img
                                          src={entry?.image}
                                          style={{
                                            width: "215px",
                                            height: "140px",
                                            flexShrink: 0,
                                          }}
                                        />
                                      </div>
                                      <div className="w-full">
                                        <p className="font-nunito font-bold text-lg mb-2 feedback">
                                          Hurrah! You have attended a
                                          workshop! -{" "}
                                          <span style={{ color: `rgb(var(--black))` }}>
                                            {entry?.name}
                                          </span>
                                        </p>
                                        <p style={{ color: "#40B7F5" }}>
                                          By {entry?.user?.name}
                                        </p>
                                        <span>
                                          {" "}
                                          <Button
                                            secondaryGhost
                                            className="font-Nunito text-base px-6 py-2 flex font-bold float-right"
                                            onClick={() => {
                                              history.push(
                                                `workshops/${entry?.card_id}`
                                              );
                                            }}
                                          >
                                            View workshop
                                          </Button>
                                        </span>
                                      </div>
                                    </div>
                                    {entry?.pictures?.length > 0 && (
                                      <>
                                        <Divider
                                          style={{
                                            borderTop:
                                              "1px solid rgb(var(--blue))",
                                            marginBottom: "0",
                                          }}
                                        />
                                        <ImageContainer
                                          className="mt-2"
                                          image={entry?.pictures}
                                          id={entry?.id}
                                        />
                                      </>
                                    )}
                                  </Card>
                                </div>
                              )}
                              {entry?.type === "activities" && (
                                <div
                                  className={
                                    journal?.card_details?.length > 0 &&
                                      i < journal?.card_details?.length - 1
                                      ? `flex-auto w-full connector`
                                      : `flex-auto w-full relative`
                                  }
                                >
                                  <Card className="rounded pb-4">
                                    <p
                                      className={
                                        entry?.activity_status === "start"
                                          ? "started"
                                          : entry?.activity_status ===
                                            "finish"
                                            ? "completed"
                                            : entry?.activity_status ===
                                              "In - progress"
                                              ? "in-progress"
                                              : null
                                      }
                                    >
                                      {entry?.activity_status === "start"
                                        ? "STARTED"
                                        : entry?.activity_status === "finish"
                                          ? "FINISHED"
                                          : entry?.activity_status ===
                                            "In - progress"
                                            ? "IN-PROGRESS"
                                            : null}
                                      {entry?.activity_status ===
                                        "finish" && (
                                          <span style={{ color: "#828282" }}>
                                            {" "}
                                            • Total Time Spent -{" "}
                                            {parseInt(
                                              entry?.total_time_spent?.split(
                                                ":"
                                              )[0]
                                            ) > 0 &&
                                              `${entry?.total_time_spent?.split(
                                                ":"
                                              )[0]
                                              }h`}{" "}
                                            {parseInt(
                                              entry?.total_time_spent?.split(
                                                ":"
                                              )[1]
                                            ) > 0 &&
                                              `${entry?.total_time_spent?.split(
                                                ":"
                                              )[1]
                                              }m`}{" "}
                                            {parseInt(
                                              entry?.total_time_spent?.split(
                                                ":"
                                              )[2]
                                            ) > 0 &&
                                              `${entry?.total_time_spent?.split(
                                                ":"
                                              )[2]
                                              }s`}{" "}
                                          </span>
                                        )}
                                      {(entry?.activity_status ===
                                        "In - progress" ||
                                        entry?.activity_status ===
                                        "finish") && (
                                          <span>
                                            {" "}
                                            <Button
                                              secondaryGhost
                                              className="font-Nunito text-base px-6 py-2 flex font-bold float-right"
                                              onClick={() => {
                                                history.push(
                                                  `/collections/cards/${entry?.card_id}`
                                                );
                                              }}
                                            >
                                              View activity
                                            </Button>
                                          </span>
                                        )}
                                    </p>
                                    <p className="font-bold text-lg font-Nunito mt-1 mb-2">
                                      {entry?.name}
                                    </p>
                                    <div className="flex flex-start align-baseline gap-2">
                                      {entry?.user?.role_id === 4 && (
                                        <>
                                          <p
                                            className="text-sm"
                                            style={{
                                              color: "rgb(var(--blue))",
                                            }}
                                          >
                                            by {entry?.user?.name}{" "}
                                          </p>
                                          <img src={Images?.ExpertIcon} />
                                        </>
                                      )}
                                      <p style={{ color: "#828282" }}>
                                        At {entry?.lc_name}
                                      </p>
                                    </div>
                                    {entry?.pictures &&
                                      entry?.pictures?.length > 0 && (
                                        <>
                                          <Divider
                                            style={{
                                              borderTop:
                                                "1px solid rgb(var(--blue))",
                                              marginBottom: "0",
                                            }}
                                          />
                                          <div className="flex flex-start gap-4 align-baseline my-2">
                                            {entry?.steps_view && (
                                              <img src={Images?.StepsIcon} />
                                            )}
                                            {entry?.video_view && (
                                              <img
                                                src={Images?.VideoWatchIcon}
                                              />
                                            )}
                                          </div>

                                          <ImageContainer
                                            image={entry?.pictures}
                                            id={entry?.id}
                                          />
                                        </>
                                      )}
                                  </Card>
                                </div>
                              )}
                              {entry?.type === "feedback" && (
                                <div
                                  className={
                                    journal?.card_details?.length > 0 &&
                                      i < journal?.card_details?.length - 1
                                      ? `flex-auto w-full connector`
                                      : `flex-auto w-full relative`
                                  }
                                >
                                  <Card className="rounded pb-4">
                                    <div className="flex justify-between items-center gap-2">
                                      <p>
                                        <span className="feedback">
                                          FEEDBACK
                                        </span>{" "}
                                        • By {entry?.user?.name}
                                      </p>
                                    </div>
                                    <p className="font-bold text-lg font-Nunito mt-1 mb-2">
                                      {entry?.name}
                                    </p>
                                    <p
                                      className="font-Nunito mt-1 mb-2"
                                      style={{ color: "#828282" }}
                                    >
                                      {entry?.feedback_desc}
                                    </p>
                                    <p className="font-light text-base font-Nunito mt-1 mb-2 ml-2">
                                      {entry?.journal_feedback?.feedback}
                                    </p>
                                    {entry?.student_response && (
                                      <p
                                        className="font-light p-4"
                                        style={{
                                          color: "#40B7F5",
                                          background: "#EFFBFF",
                                          borderRadius: "5px",
                                          fontFamily: "Indie Flower",
                                          fontStyle: "normal",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={Images.MessageIcon}
                                            alt=""
                                            className="mr-2 inline"
                                          />
                                        </span>
                                        <span>
                                          {decodeURIComponent(
                                            window.atob(
                                              entry?.student_response
                                            )
                                          )}
                                        </span>
                                      </p>
                                    )}
                                  </Card>
                                </div>
                              )}
                              {entry?.type === "journal" &&
                                (entry?.journal_state === "uploaded" ||
                                  entry?.journal_state === "upload" ||
                                  entry?.journal_state === "captured" ||
                                  entry?.journal_state === "pictures" ||
                                  entry?.journal_state === "doodle") &&
                                entry?.image?.length > 0 && (
                                  <div
                                    className={
                                      journal?.card_details?.length > 0 &&
                                        i < journal?.card_details?.length - 1
                                        ? `flex-auto w-full connector`
                                        : `flex-auto w-full relative`
                                    }
                                  >
                                    <Card className="rounded pb-4">
                                      <div className="flex">
                                        <div className="w-4/12">
                                          <p className="font-nunito font-bold text-lg">
                                            Journal picture / note
                                          </p>
                                          <div
                                            className="rounded mt-4"
                                            style={{
                                              width: "244px",
                                              height: "170px",
                                            }}
                                          >
                                            <img
                                              src={entry?.image}
                                              alt="journal-image"
                                              className="object-contain bg-waterColor rounded-md"
                                              style={{
                                                width: "244px",
                                                height: "137px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-8/12">
                                          <p>
                                            {decodeURIComponent(
                                              window.atob(entry?.notes)
                                            )}
                                          </p>
                                        </div>
                                        {entry?.journal_feedback?.length > 0
                                          ? entry?.journal_feedback?.map(
                                            (feedback) => {
                                              return (
                                                <div className="w-8/12">
                                                  <div className="flex justify-between items-center gap-2">
                                                    <p>
                                                      <span className="feedback">
                                                        FEEDBACK
                                                      </span>{" "}
                                                      • By{" "}
                                                      {feedback?.lc_name}
                                                    </p>
                                                  </div>
                                                  <p className="mt-2">
                                                    {feedback?.feedback}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )
                                          : null}
                                      </div>
                                    </Card>
                                  </div>
                                )}
                              {entry?.type === "journal" &&
                                (entry?.image?.length === 0 ||
                                  entry?.image === null) && (
                                  <div
                                    className={
                                      journal?.card_details?.length > 0 &&
                                        i < journal?.card_details?.length - 1
                                        ? `flex-auto w-full connector`
                                        : `flex-auto w-full relative`
                                    }
                                  >
                                    <Card className="rounded pb-4">
                                      <p className="font-nunito font-bold text-lg mb-2">
                                        Journal picture / note
                                      </p>
                                      <p>
                                        {decodeURIComponent(
                                          window.atob(entry?.notes)
                                        )}
                                      </p>
                                      <p className="font-bold text-lg font-Nunito mt-1 mb-2">
                                        {entry?.name}
                                      </p>
                                      {entry?.journal_feedback?.length > 0
                                        ? entry?.journal_feedback?.map(
                                          (feedback) => {
                                            return (
                                              <div className="w-8/12">
                                                <div className="flex justify-between items-center gap-2">
                                                  <p>
                                                    <span className="feedback">
                                                      FEEDBACK
                                                    </span>{" "}
                                                    • By {feedback?.lc_name}
                                                  </p>
                                                </div>
                                                <p className="mt-2">
                                                  {feedback?.feedback}
                                                </p>
                                              </div>
                                            );
                                          }
                                        )
                                        : null}
                                    </Card>
                                  </div>
                                )}
                              {entry?.type === "journal" &&
                                entry?.journal_state === "audio" && (
                                  <div
                                    className={
                                      journal?.card_details?.length > 0 &&
                                        i < journal?.card_details?.length - 1
                                        ? `flex-auto w-full connector`
                                        : `flex-auto w-full relative`
                                    }
                                  >
                                    <Card className="rounded pb-4">
                                      <p className="font-nunito font-bold text-lg mb-2">
                                        Journal audio
                                      </p>
                                      <audio
                                        controls
                                        src={entry?.image}
                                        style={{ width: "100%" }}
                                        className="mt-2"
                                      ></audio>
                                      <p className="mt-2">
                                        {decodeURIComponent(
                                          window.atob(entry?.notes)
                                        )}
                                      </p>
                                    </Card>
                                  </div>
                                )}
                              {entry?.type === "milestone" &&
                                entry?.activity_status !==
                                "finish_playlist" && (
                                  <div
                                    className={
                                      journal?.card_details?.length > 0 &&
                                        i < journal?.card_details?.length - 1
                                        ? `flex-auto w-full connector`
                                        : `flex-auto w-full relative`
                                    }
                                  >
                                    <Card className="rounded pb-4">
                                      <div className="flex">
                                        <div className="flex-1">
                                          <p
                                            className="mb-2 font-nunito text-2xl font-bold"
                                            style={{
                                              color: "#57479C",
                                            }}
                                          >
                                            Doing Great!
                                          </p>
                                          <p className="font-bold font-nunito text-lg">
                                            You just completed{" "}
                                            {entry?.activity_status} learning
                                            cards on Breadcrumb! How awesome
                                            is that? Keep going. Happy
                                            learning!
                                          </p>
                                        </div>
                                        <div className="flex-1">
                                          <div className="relative">
                                            <img
                                              src={Images.StarIcon}
                                              alt="milestone"
                                              className="absolute right-1"
                                            />
                                            {entry?.activity_status?.length >
                                              1 && (
                                                <p
                                                  className="absolute right-10 top-8 font-bold"
                                                  style={{ color: "#EC1A58" }}
                                                >
                                                  {entry?.activity_status}
                                                </p>
                                              )}
                                            {entry?.activity_status
                                              ?.length === 1 && (
                                                <p
                                                  className="absolute right-11 top-8 font-bold"
                                                  style={{ color: "#EC1A58" }}
                                                >
                                                  {entry?.activity_status}
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                )}
                              {entry?.type === "milestone" &&
                                entry?.activity_status ===
                                "finish_playlist" && (
                                  <div
                                    className={
                                      journal?.card_details?.length > 0 &&
                                        i < journal?.card_details?.length - 1
                                        ? `flex-auto w-full connector`
                                        : `flex-auto w-full relative`
                                    }
                                  >
                                    <Card className="rounded pb-4">
                                      <div className="flex">
                                        <div className="flex-1">
                                          <p
                                            className="mb-2 font-nunito text-2xl font-bold"
                                            style={{
                                              color: "#EC1A58",
                                            }}
                                          >
                                            Hooray! You completed a Expert
                                            collection
                                          </p>
                                          <p className="font-bold font-nunito text-lg">
                                            You seem to enjoy learning from a
                                            variety of sources and
                                            inspirations. Keep exploring and
                                            learning!
                                          </p>
                                        </div>
                                        <div className="flex-1">
                                          <div className="relative">
                                            <img
                                              src={Images.championIcon}
                                              alt="milestone"
                                              className="absolute right-1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })
                : null}
            </Card>
          </div>
        ) : (
          <Card className="w-full px-16">
            <p className="text-center font-bold text-xl mt-4">
              No Journal entries for this day
              <span className="float-right">
                <Button
                  secondaryGhost
                  className="font-Nunito text-base px-6 py-2 flex font-bold"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {`Close`}
                </Button>
              </span>
            </p>
          </Card>
        )}
      </Modal>
    </Card>
  );
};

export default ParentCalendarComponent;
