import React, { useState, useEffect, useContext } from "react";
import CartEmpty from "./components/CartEmptyComponent";
import { Row, Col, Card, Divider, Button, Modal, Form, Checkbox, notification } from "antd";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  VIEW_CART,
  PAYMENT_WORKSHOP_CART,
  UPDATE_CART,
  STUDENT_LIST,
  UPDATE_STUDENT_WORKSHOP_CART,
  GET_LEARNING_CENTRE,
  LEARNER_WORKSHOP_CART_VERIFY,
} from "../../client/graphql";
import styled from "styled-components";
import Images from "../../themes/Images";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import NavContext from "../../context/NavContext";
import Loader from "../../components/common/Loader";
import { useHistory } from "react-router-dom";

const DateView = ({ date, time, every, duration }) => {
  return (
    <DateContainer className="d-flex">
      {every && every.length && (
        <div className="d-flex">
          <div className="subTitle">{every}</div>
        </div>
      )}
      <div>
        <h4 className="title white-space-nowrap">
          {date ? moment(parseInt(date)).format(`ddd, Do MMM ’YY`) : ""}
        </h4>
        <div className="desc mt-1">
          {time ? moment(time, "h m a").format("h:mm A") : ""}{" "}
          {duration ? `- ${duration}` : ""}
        </div>
      </div>
    </DateContainer>
  );
};

const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: "nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    /* @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    } */
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    } */
    color: #57479c;
    max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`;

const Cart = () => {
  const userNavContext = useContext(NavContext);
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [savedForLaterData, setSavedForLater] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [workshop, setWorkshop] = useState(null);
  const [cartLearners, setCartLearners] = useState([]);
  const [laterLearners, setLaterLearners] = useState([]);
  const [form] = Form.useForm();
  const { data, loading, refetch } = useQuery(VIEW_CART, {
    fetchPolicy: "no-cache",
  });

  const { data: learnerDetails } = useQuery(GET_LEARNING_CENTRE);

  const [payment, { loading: paymentLoading, error }] = useMutation(
    PAYMENT_WORKSHOP_CART
  );

  const [updateCart, { loading: updateCartLoading, error: cartUpdateError }] =
    useMutation(UPDATE_CART);

  const [verifyPayment, { loading: verifyingCartPayment }] = useLazyQuery(
    LEARNER_WORKSHOP_CART_VERIFY,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (resp) => {
        if (
          resp.learnerWorkshopCartVerify &&
          resp.learnerWorkshopCartVerify.msg === "payment success"
        ) {
          // notification.success({
          //   message: "Payment success",
          // });
          history.push(`/payment/workshop/verify`);
        } else {
          notification.error({
            message: "Payment failed",
          });
        }
        refetch();
      },
      onError: (error) => {
        notification.error({
          message: "Payment failed",
        });
      },
    }
  );

  const [
    updateLearners,
    { loading: learnerUpdateLoading, error: learnerUpdateError },
  ] = useMutation(UPDATE_STUDENT_WORKSHOP_CART);

  const { data: studentList, loading: listLoading } = useQuery(STUDENT_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      setCartData(data?.viewCart?.cart);
      setSavedForLater(data?.viewCart?.save_for_later);
    }
  }, [data]);

  useEffect(() => {
    let sampleObject = {},
      sampleArray = [];
    sampleObject.label =
      studentList?.getDashboard?.user_details?.first_name + " (myself)";
    sampleObject.value = "";
    sampleArray.push(sampleObject);
    studentList?.getDashboard?.students?.forEach((student) => {
      sampleObject = {};
      sampleObject.label = student?.student_details?.fname;
      sampleObject.value = student?.id;
      sampleArray.push(sampleObject);
    });
    setCheckBoxValue(sampleArray);
  }, [studentList]);

  const showModal = (cartItem) => {
    setWorkshop(cartItem);
    const learners = cartItem?.learners?.map((learner) => {
      if (learner?.registration_type === "parent") {
        return "";
      } else {
        return parseInt(learner?.id);
      }
    });
    setCartLearners([...learners]);
    form.setFieldsValue({
      members: [...learners],
    });
    setIsModalVisible(true);
  };

  const onPayClick = () => {
    const cartIDs = data?.viewCart?.cart?.map((item) => item?.id);
    const price = parseFloat(data?.viewCart?.Price) * 100;
    payment({
      variables: {
        cart_id: [...cartIDs],
        amount: price,
      },
    }).then((res) => {
      let orderId = res?.data?.payWorkshopCart?.data?.id;
      payWithRazorpay(orderId, price);
      // console.log(res?.data?.paymentWorkshopCart?.data);
    });
  };

  const onPaymentCompleted = async (response) => {
    //console.log(response);
    verifyPayment({
      variables: {
        payment_request_id: response.razorpay_payment_id,
        order_id: response.razorpay_order_id,
      },
    });
  };

  const loadScript=(src)=> {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  const payWithRazorpay = async (order_id, amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount.toString(),
      currency: "INR",
      name: "Workshop",
      description: `WorkshopID: ${data?.viewCart?.cart?.map(
        (item) => item?.id
      )}`,
      order_id: order_id,
      handler: onPaymentCompleted,
      prefill: {
        name: userNavContext?.userInfo?.parentInfo.name,
        email: learnerDetails?.getlearnerDetails?.user_details?.email,
        contact: learnerDetails?.getlearnerDetails?.user_details?.phone_no,
      },
      notes: {
        address: "notes",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleUpdateClick = (saveLater, deleteCart) => {
    let id;
    if (saveLater !== null) {
      id = saveLater === 1 ? cartData[0]?.id : savedForLaterData[0]?.id;
    } else {
      id = cartData?.length > 0 ? cartData[0]?.id : savedForLaterData[0]?.id;
    }
    updateCart({
      variables: {
        cart_id: id,
        isDelete: deleteCart,
        isSaveLater: saveLater,
      },
    }).then(() => {
      refetch();
    });
  };

  function onChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  const handleOk = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    let payloadObject = {},
      payloadArray = [];
    values?.members?.forEach((member) => {
      if (member === "") {
        payloadObject.student_id = null;
        payloadObject.registration_type = "parent";
      } else {
        payloadObject.student_id = member;
        payloadObject.registration_type = "student";
      }
      payloadArray.push(payloadObject);
      payloadObject = {};
    });
    updateLearners({
      variables: {
        workshop_id: workshop?.workshop_details?.id,
        registration_details: [...payloadArray],
        cart_id: workshop?.id,
      },
    }).then((res) => {
      refetch();
      handleOk();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Loader
        loading={
          loading ||
          paymentLoading ||
          updateCartLoading ||
          learnerUpdateLoading ||
          verifyingCartPayment ||
          listLoading
        }
      />
      {data?.viewCart?.cart?.length > 0 ||
      data?.viewCart?.save_for_later?.length > 0 ? (
        <Row gutter={16} className="mt-4 px-4">
          <Col span={18}>
            {data?.viewCart?.cart?.length > 0 && (
              <Card
                className="rounded mt-2"
                title={
                  <p className="h3 text-left mt-2">
                    My cart ({data?.viewCart?.cart?.length})
                  </p>
                }
                headStyle={{
                  paddingBottom: 0,
                }}
                bodyStyle={{
                  paddingTop: 0,
                }}
              >
                <Divider style={{ borderTop: "0.5px solid white" }} />
                {cartData?.map((item) => {
                  return (
                    <>
                      <Row className="mb-4">
                        <Col>
                          <p>
                            Selected for&nbsp;
                            <span className="h5">
                              {item.learners &&
                                item.learners.length > 0 &&
                                item.learners
                                  .map((stud) => stud.username)
                                  .join(", ")}
                            </span>
                            <EditOutlined
                              className="ml-2 cursor-pointer"
                              style={{
                                display: "inline",
                                verticalAlign: "2px",
                                fontSize: "1rem",
                              }}
                              onClick={() => showModal(item)}
                            />
                          </p>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <img
                            src={
                              item?.workshop_details?.featured_image[0]
                                ?.image_url
                            }
                            className="w-100 h-100 rounded"
                            style={{ minHeight: "200px" }}
                          />
                        </Col>
                        <Col span={8}>
                          <>
                            <Row>
                              <Col>
                                <p className="h3">
                                  {item?.workshop_details?.workshop_name}
                                </p>
                              </Col>
                            </Row>
                            <Row gutter={16} className="mt-4">
                              <Col span={8}>
                                <p>AGE GROUP</p>
                                <p>{item?.workshop_details?.age_group}</p>
                              </Col>
                              <Col span={8}>
                                <p>SESSIONS</p>
                                <p>
                                  {item?.workshop_details?.workshop_session}
                                </p>
                              </Col>
                              <Col span={8}>
                                <p> DURATION</p>
                                <p>
                                  {item?.workshop_details?.workshop_duration}{" "}
                                  {item.learners.length > 1 ? `each` : ""}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col className="mr-2">
                                <p
                                  style={{
                                    color: "rgb(66, 192, 239)",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  ₹{item?.workshop_details?.workshop_amount}
                                </p>
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    color: "#ea1f59",
                                  }}
                                >
                                  Only {item?.workshop_details?.available_seats}{" "}
                                  seats remaining
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <div className="flex mt-4 pt-1 w-11/12">
                                <div
                                  className="w-full flex overflow-scroll"
                                  id={`workshop-scroll1`}
                                >
                                  {item?.workshop_details &&
                                    item?.workshop_details.workshop_plan_id &&
                                    [1, 2, 3].includes(
                                      parseInt(
                                        item?.workshop_details.workshop_plan_id
                                      )
                                    ) &&
                                    item?.workshop_details.repeats_type ==
                                      "dnr" &&
                                    item?.workshop_details.Dates &&
                                    item?.workshop_details.Dates.length > 0 &&
                                    item?.workshop_details.Dates.map(
                                      (x, index) => (
                                        <span
                                          className="pr-3 d-inline-block"
                                          key={`item?.workshop_details-${index}`}
                                        >
                                          <DateView
                                            date={x.workshop_Date}
                                            time={x.start_time}
                                            duration={
                                              item?.workshop_details
                                                .workshop_plan_id == 3 &&
                                              !item?.workshop_details
                                                .is_common_timing
                                                ? `${
                                                    x.duration
                                                      ? `${x.duration}`
                                                      : ""
                                                  }`
                                                : ""
                                            }
                                          />
                                        </span>
                                      )
                                    )}
                                  {item?.workshop_details &&
                                    item?.workshop_details.workshop_plan_id &&
                                    (item?.workshop_details.workshop_plan_id ==
                                      1 ||
                                      item?.workshop_details.workshop_plan_id ==
                                        2) &&
                                    item?.workshop_details.repeats_type !=
                                      "dnr" &&
                                    item?.workshop_details.Dates &&
                                    item?.workshop_details.Dates.length > 0 &&
                                    item?.workshop_details.Dates.map(
                                      (x, dateIndex) => (
                                        <span
                                          className="pr-3 inline-block"
                                          key={`DateView-${dateIndex}`}
                                        >
                                          <DateView
                                            date={x.workshop_Date}
                                            time={x.start_time}
                                            every={
                                              item?.workshop_details.repeatDays
                                            }
                                            duration={x?.duration}
                                          />
                                        </span>
                                      )
                                    )}
                                </div>
                                {item?.workshop_details.Dates &&
                                  item?.workshop_details.Dates.length > 7 && (
                                    <img
                                      className="ml-3 cursor-pointer"
                                      src={Images.CarouselLeft}
                                      alt="arrow"
                                      onClick={() => {
                                        // console.log(
                                        //   document.getElementById(`workshop-${index}`).scrollLeft
                                        // )
                                        document
                                          .getElementById(`workshop-scroll1`)
                                          .scrollTo({
                                            left:
                                              document.getElementById(
                                                `workshop-scroll1`
                                              ).scrollLeft + 250,
                                            behavior: "smooth",
                                          });
                                      }}
                                    />
                                  )}
                              </div>
                            </Row>
                          </>
                        </Col>
                        <Col span={8} className="text-right">
                          <Button
                            type="link"
                            onClick={() => handleUpdateClick(1, false)}
                          >
                            Save for later
                          </Button>
                          {/* <span>|</span> */}
                          <Button
                            type="link"
                            onClick={() => handleUpdateClick(null, true)}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Card>
            )}
            {data?.viewCart?.save_for_later &&
              data?.viewCart?.save_for_later?.length > 0 && (
                <Card
                  className="rounded mt-2"
                  title={
                    <p className="h3 text-left mt-2">
                      Saved for later ({data?.viewCart?.save_for_later?.length})
                    </p>
                  }
                  headStyle={{
                    paddingBottom: 0,
                  }}
                  bodyStyle={{
                    paddingTop: 0,
                  }}
                >
                  <Divider style={{ borderTop: "0.5px solid white" }} />
                  {savedForLaterData?.map((item) => {
                    return (
                      <>
                        <Row className="mb-4">
                          <Col>
                            <p>
                              Selected for&nbsp;
                              <span className="h5">
                                {item.learners &&
                                  item.learners.length > 0 &&
                                  item.learners
                                    .map((stud) => stud.username)
                                    .join(", ")}
                              </span>
                              <EditOutlined
                                className="ml-2 cursor-pointer"
                                style={{
                                  display: "inline",
                                  verticalAlign: "2px",
                                  fontSize: "1rem",
                                }}
                                onClick={() => showModal(item)}
                              />
                            </p>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={8}>
                            <img
                              src={
                                item?.workshop_details?.featured_image[0]
                                  ?.image_url
                              }
                              className="w-100 h-100 rounded"
                              style={{ minHeight: "200px" }}
                            />
                          </Col>
                          <Col span={8}>
                            <>
                              <Row>
                                <Col>
                                  <p className="h3">
                                    {item?.workshop_details?.workshop_name}
                                  </p>
                                </Col>
                              </Row>
                              <Row gutter={16} className="mt-4">
                                <Col span={8}>
                                  <p>AGE GROUP</p>
                                  <p>{item?.workshop_details?.age_group}</p>
                                </Col>
                                <Col span={8}>
                                  <p>SESSIONS</p>
                                  <p>
                                    {item?.workshop_details?.workshop_session}
                                  </p>
                                </Col>
                                <Col span={8}>
                                  <p> DURATION</p>
                                  <p>
                                    {item?.workshop_details?.workshop_duration}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col className="mr-2">
                                  <p
                                    style={{
                                      color: "rgb(66, 192, 239)",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    ₹{item?.workshop_details?.workshop_amount}
                                  </p>
                                </Col>
                                <Col>
                                  <p
                                    style={{
                                      color: "#ea1f59",
                                    }}
                                  >
                                    Only{" "}
                                    {item?.workshop_details?.available_seats}{" "}
                                    seats remaining
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <div className="flex mt-4 pt-1 w-11/12">
                                  <div
                                    className="w-full flex overflow-scroll"
                                    id={`workshop-scroll1`}
                                  >
                                    {item?.workshop_details &&
                                      item?.workshop_details.workshop_plan_id &&
                                      [1, 2, 3].includes(
                                        parseInt(
                                          item?.workshop_details
                                            .workshop_plan_id
                                        )
                                      ) &&
                                      item?.workshop_details.repeats_type ==
                                        "dnr" &&
                                      item?.workshop_details.Dates &&
                                      item?.workshop_details.Dates.length > 0 &&
                                      item?.workshop_details.Dates.map(
                                        (x, index) => (
                                          <span
                                            className="pr-3 d-inline-block"
                                            key={`item?.workshop_details-${index}`}
                                          >
                                            <DateView
                                              date={x.workshop_Date}
                                              time={x.start_time}
                                              duration={
                                                item?.workshop_details
                                                  .workshop_plan_id == 3 &&
                                                !item?.workshop_details
                                                  .is_common_timing
                                                  ? `${
                                                      x.duration
                                                        ? `${x.duration}`
                                                        : ""
                                                    }`
                                                  : ""
                                              }
                                            />
                                          </span>
                                        )
                                      )}
                                    {item?.workshop_details &&
                                      item?.workshop_details.workshop_plan_id &&
                                      (item?.workshop_details
                                        .workshop_plan_id == 1 ||
                                        item?.workshop_details
                                          .workshop_plan_id == 2) &&
                                      item?.workshop_details.repeats_type !=
                                        "dnr" &&
                                      item?.workshop_details.Dates &&
                                      item?.workshop_details.Dates.length > 0 &&
                                      item?.workshop_details.Dates.map(
                                        (x, dateIndex) => (
                                          <span
                                            className="pr-3 inline-block"
                                            key={`DateView-${dateIndex}`}
                                          >
                                            <DateView
                                              date={x.workshop_Date}
                                              time={x.start_time}
                                              every={
                                                item?.workshop_details
                                                  .repeatDays
                                              }
                                              duration={x?.duration}
                                            />
                                          </span>
                                        )
                                      )}
                                  </div>
                                  {item?.workshop_details.Dates &&
                                    item?.workshop_details.Dates.length > 7 && (
                                      <img
                                        className="ml-3 cursor-pointer"
                                        src={Images.CarouselLeft}
                                        alt="arrow"
                                        onClick={() => {
                                          // console.log(
                                          //   document.getElementById(`workshop-${index}`).scrollLeft
                                          // )
                                          document
                                            .getElementById(`workshop-scroll1`)
                                            .scrollTo({
                                              left:
                                                document.getElementById(
                                                  `workshop-scroll1`
                                                ).scrollLeft + 250,
                                              behavior: "smooth",
                                            });
                                        }}
                                      />
                                    )}
                                </div>
                              </Row>
                            </>
                          </Col>
                          <Col span={8} className="text-right">
                            <Button
                              type="link"
                              onClick={() => handleUpdateClick(0, false)}
                            >
                              Move to cart
                            </Button>
                            {/* <span>|</span> */}
                            <Button
                              type="link"
                              onClick={() => handleUpdateClick(null, true)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Card>
              )}
          </Col>
          {data?.viewCart?.cart?.length > 0 && (
            <Col span={6}>
              <Card
                className="rounded mt-2"
                title={<p className="h3 text-left">Purchase summary</p>}
                bodyStyle={{
                  paddingTop: 0,
                }}
              >
                <Divider style={{ borderTop: "0.5px solid white" }} />
                <Row>
                  <Col span={12}>Price (items)</Col>
                  <Col span={12}>
                    <p className="float-right">₹{data?.viewCart?.Price}</p>
                  </Col>
                </Row>
                <Divider style={{ borderTop: "0.5px solid white" }} />
                <Row>
                  <Col span={20}>
                    <p className="h5">Total amount</p> <br />
                    (Excluding GST and transaction fees)
                  </Col>
                  <Col span={4}>
                    <p className="float-right">₹{data?.viewCart?.Price}</p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col span={24}>
                    <Button
                      className="p-3 h5 rounded"
                      type="primary"
                      block
                      style={{
                        background: "#ea1f59",
                        border: "1px solid #ea1f59",
                        height: "auto",
                      }}
                      onClick={onPayClick}
                    >
                      Proceed to payment
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      ) : (
        <CartEmpty />
      )}
      <Modal
        title="Learner selection"
        onCancel={handleCancel}
        visible={isModalVisible}
        okText={"Register"}
        effect="fadeInUp"
        footer={null}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <p>Please select the learner to make the purchase</p>
        <Form
          name="cart edit form"
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="mt-6"
          layout="horizontal"
          form={form}
        >
          <Form.Item name="members" wrapperCol={{ span: 24 }}>
            <Checkbox.Group onChange={onChange}>
              {checkBoxValue?.map((item) => {
                return (
                  <div className="ml-0">
                    <Checkbox value={item?.value} className="ml-4 mb-4">
                      {item?.label}
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 18 }}>
            <Button type="primary" htmlType="submit">
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Cart;
