import { useMutation } from "@apollo/client";
import { Modal, notification } from "antd";
import gql from "graphql-tag";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NavContext from "../../context/NavContext";
import Images from "../../themes/Images";
import Button from "./Form/Button";

export const UPDATE_TRAIL = gql`
  mutation updateTrial($subscription_type: String, $is_trial: Boolean) {
    updateTrial(subscription_type: $subscription_type, is_trial: $is_trial) {
      message
    }
  }
`;
export default function TrialStartModal({ showTrialStarted }) {
  const [showModal, setShowModal] = useState(null);
  const history = useHistory();
  const userNavContext = useContext(NavContext);

  useEffect(() => {
    setShowModal(showTrialStarted);
  }, [showTrialStarted]);
  const [updateTrial] = useMutation(UPDATE_TRAIL, {
    onError: (error) => {
      notification.error({
        message: "Something went wrong",
      });
    }
  });

  const updateTrialData = async (isRedirect = false) => {
    localStorage.setItem("showTrialStarts", false);
    let resp = await updateTrial({
      variables: {
        subscription_type: "Parent",
        is_trial: false,
      },
    });
    setShowModal(false);
    userNavContext.setUserInfo({
      ...userNavContext.userInfo,
      ...{
        showTrial: false,
      },
    });
    if (isRedirect) {
      history.push(`/payment/proceed`);
    } else if (resp.data && resp.data.updateTrial?.message) {
      window.location.reload();
    }
  };

  return (
    <div>
      <Modal
        // closable={false}
        visible={showModal}
        onCancel={() => {
          updateTrialData(false);
        }}
        width={660}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}
        className="paymentModel"
        centered
      // title="Choose where you want to learn today"
      >
        <div className="text-3xl font-bold font-Nunito pr-3 ">
          You are all set to be a self directed learner <br />
          <span
            className="text-xl font-bold font-Nunito "
            style={{ color: "#B9B9B9" }}
          >
            {`On ${userNavContext?.userInfo?.expiry_date.format("DD MMM YYYY")} you will have to renew your access to continue enjoying`}
            {/* You will have access until{" "}
            {userNavContext?.userInfo?.expiry_date.format("DD MMM YYYY")} */}
          </span>
        </div>
        <div className="flex ">
          <div>
            <div className=" mt-10 flex h6 items-start ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Exploring interest based collections
              {/* for 72 hours */}
            </div>
            {/* <div className=" mt-3 flex h6 items-start ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Try and complete 10 learning cards in that time
            </div> */}
            <div className=" mt-3 flex h6 items-start ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Scheduling and planning your day
            </div>
            <div className=" mt-3 flex h6 items-start ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Journaling as pictures, voice notes and doodles
            </div>
            <div className=" mt-3 flex h6 items-start ">
              <img
                src={Images.PaymentCheckedIcon}
                alt="payment check"
                className="mr-2"
              />
              Wish-listing your favourite cards
            </div>
            {userNavContext?.userInfo?.mode === "DARK_MODE" && (
              <div className="h6 mt-3">
                For unlimited content, access to schedule and journal -{" "}
                <a
                  className=" underline"
                  onClick={() => {
                    updateTrialData(true);
                  }}
                >
                  Subscribe now
                </a>
              </div>
            )}
            <div className="mt-6 inline-block">
              <Button
                secondary
                className="font-Nunito text-base bg-violet border-violet text-center px-6 py-3 font-bold w-64 "
                onClick={updateTrialData}
              >
                {`Got it`}
              </Button>
            </div>
          </div>

          <div className="flex justify-center">
            <img src={Images.PaymentEmptyState} alt=" Payment Imag" />
          </div>
        </div>
        {userNavContext?.userInfo?.mode === "DARK_MODE" && (
          <div className="mt-4">
            You will still be able to access{" "}
            <Link to="/workshops">
              {" "}
              <a className=" underline">workshops</a>
            </Link>{" "}
            without the yearly subscription
          </div>
        )}
      </Modal>
    </div>
  );
}
