export const ExploreIcon = ({ isActive }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 22C18.0228 22 22.5 17.5229 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5229 6.97715 22 12.5 22Z"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12H22.5"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 2C15.0013 4.73835 16.4228 8.29204 16.5 12C16.4228 15.708 15.0013 19.2617 12.5 22C9.99872 19.2617 8.57725 15.708 8.5 12C8.57725 8.29204 9.99872 4.73835 12.5 2V2Z"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ScheduleIcon = ({ isActive }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 4H5.5C4.39543 4 3.5 4.89543 3.5 6V20C3.5 21.1046 4.39543 22 5.5 22H19.5C20.6046 22 21.5 21.1046 21.5 20V6C21.5 4.89543 20.6046 4 19.5 4Z"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 2V6"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 2V6"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 10H21.5"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const JournalIcon = ({ isActive }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 3H8.5C9.56087 3 10.5783 3.42143 11.3284 4.17157C12.0786 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.1839 19.4413 11.6213 18.8787C11.0587 18.3161 10.2956 18 9.5 18H2.5V3Z"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 3H16.5C15.4391 3 14.4217 3.42143 13.6716 4.17157C12.9214 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.8161 19.4413 13.3787 18.8787C13.9413 18.3161 14.7044 18 15.5 18H22.5V3Z"
      stroke={isActive ? "#fff" : "#828282"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const WorkshopIcon = ({ isActive }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4638 9.90747C10.1792 9.90747 8.31006 8.03829 8.31006 5.75374C8.31006 3.46919 10.1792 1.60001 12.4638 1.60001C14.7483 1.60001 16.6175 3.46919 16.6175 5.75374C16.6175 8.03829 14.7483 9.90747 12.4638 9.90747ZM12.4638 3.05381C11.01 3.05381 9.86771 4.19609 9.86771 5.64989C9.86771 7.1037 11.01 8.24598 12.4638 8.24598C13.9176 8.24598 15.0599 7.1037 15.0599 5.64989C15.0599 4.19609 13.8138 3.05381 12.4638 3.05381Z"
      fill={isActive ? "#fff" : "#828282"}
    />
    <path
      d="M6.2841 17.4156C3.99955 17.4156 2.13037 15.5464 2.13037 13.2619C2.13037 10.9773 3.99955 9.10812 6.2841 9.10812C8.56866 9.10812 10.4378 10.9773 10.4378 13.2619C10.4378 15.5464 8.56866 17.4156 6.2841 17.4156ZM6.2841 10.5619C4.8303 10.5619 3.68802 11.7042 3.68802 13.158C3.68802 14.6118 4.8303 15.7541 6.2841 15.7541C7.73791 15.7541 8.88019 14.6118 8.88019 13.158C8.88019 11.7042 7.63407 10.5619 6.2841 10.5619Z"
      fill={isActive ? "#fff" : "#828282"}
    />
    <path
      d="M10.4385 22.2962C10.0231 22.2962 9.60775 21.9846 9.60775 21.4654C9.60775 21.0501 9.50391 20.6347 9.40007 20.2193C9.29623 19.8039 8.98469 19.4924 8.56932 19.1809C8.25779 18.8693 7.84242 18.6617 7.42705 18.454C6.5963 18.1424 5.66171 18.1424 4.83096 18.454C4.41559 18.6617 4.00022 18.8693 3.68869 19.1809C3.37716 19.4924 3.16947 19.9078 2.96179 20.3232C2.7541 20.7385 2.7541 21.1539 2.7541 21.5693C2.7541 21.9846 2.44257 22.4 1.92335 22.4C1.40414 22.4 1.30029 21.9846 1.30029 21.5693C1.30029 20.9462 1.40414 20.3232 1.71567 19.7001C1.92335 19.077 2.33873 18.5578 2.7541 18.1424C3.16947 17.7271 3.79253 17.3117 4.31175 17.104C5.55787 16.5848 6.90783 16.5848 8.05011 17.104C8.67317 17.3117 9.19238 17.7271 9.60775 18.1424C10.0231 18.5578 10.4385 19.1809 10.6462 19.7001C10.8539 20.3232 11.0616 20.9462 11.0616 21.5693C11.1654 21.9846 10.8539 22.2962 10.4385 22.2962Z"
      fill={isActive ? "#fff" : "#828282"}
    />
    <path
      d="M18.6425 17.4155C16.3579 17.4155 14.4888 15.5463 14.4888 13.2618C14.4888 10.9772 16.3579 9.10806 18.6425 9.10806C20.9271 9.10806 22.7962 10.9772 22.7962 13.2618C22.7962 15.5463 20.9271 17.4155 18.6425 17.4155ZM18.6425 10.5619C17.1887 10.5619 16.0464 11.7041 16.0464 13.158C16.0464 14.6118 17.1887 15.754 18.6425 15.754C20.0963 15.754 21.2386 14.6118 21.2386 13.158C21.2386 11.7041 19.9925 10.5619 18.6425 10.5619Z"
      fill={isActive ? "#fff" : "#828282"}
    />
    <path
      d="M22.7954 22.2962C22.3801 22.2962 21.9647 21.9846 21.9647 21.4654C21.9647 21.0501 21.8608 20.6347 21.757 20.2193C21.5493 19.8039 21.3416 19.3886 21.0301 19.077C20.7186 18.7655 20.3032 18.5578 19.8878 18.3501C19.0571 18.0386 18.1225 18.0386 17.2917 18.3501C16.8764 18.5578 16.461 18.7655 16.1495 19.077C15.8379 19.3886 15.6302 19.8039 15.4226 20.2193C15.2149 20.6347 15.2149 21.0501 15.2149 21.4654C15.2149 21.8808 14.9033 22.2962 14.3841 22.2962C13.8649 22.2962 13.6572 21.9846 13.6572 21.5693C13.6572 20.9462 13.7611 20.3232 14.0726 19.7001C14.2803 19.077 14.6957 18.5578 15.111 18.1424C15.5264 17.7271 16.1495 17.3117 16.6687 17.104C17.9148 16.5848 19.2648 16.5848 20.407 17.104C21.0301 17.3117 21.5493 17.7271 21.9647 18.1424C22.3801 18.5578 22.7954 19.1809 23.0031 19.7001C23.2108 20.3232 23.4185 20.9462 23.4185 21.5693C23.5223 21.9846 23.2108 22.2962 22.7954 22.2962Z"
      fill={isActive ? "#fff" : "#828282"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4558 11.4856H14.7634C14.4639 11.2193 14.0867 11.0307 13.7095 10.8421L13.7095 10.8421C12.8788 10.5305 11.9442 10.5305 11.1134 10.8421L11.1134 10.8421C10.7362 11.0307 10.359 11.2193 10.0596 11.4856H8.23535C8.43869 11.1652 8.68571 10.8814 8.93273 10.6344C9.34811 10.219 9.97117 9.80364 10.4904 9.59596C11.7365 9.07674 13.0865 9.07674 14.2287 9.59596C14.8518 9.80364 15.371 10.219 15.7864 10.6344C16.0222 10.8702 16.258 11.1729 16.4558 11.4856Z"
      fill={isActive ? "#fff" : "#828282"}
    />
  </svg>
);
export const CartIcon = ({ isActive }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.0442 21.7976C13.0442 21.0529 13.6419 20.4553 14.363 20.4553C15.09 20.4553 15.6819 21.0354 15.6819 21.7976C15.6819 22.5715 15.0784 23.1633 14.363 23.1633C13.6477 23.1633 13.0442 22.5715 13.0442 21.7976Z" stroke={isActive ? "#fff" : "#828282"} stroke-width="1.67324" />
    <path d="M19.7699 21.7976C19.7699 21.0529 20.3677 20.4553 21.0888 20.4553C21.7814 20.4553 22.3886 21.0261 22.4077 21.8056C22.4035 22.5756 21.8017 23.1633 21.0888 23.1633C20.3735 23.1633 19.7699 22.5715 19.7699 21.7976Z" stroke={isActive ? "#fff" : "#828282"} stroke-width="1.67324" />
    <path d="M12.5393 16.7727L10.8358 11.2733H11.2013H24.9L23.2398 16.7543L23.2387 16.758C23.0174 17.501 22.362 17.9877 21.5804 17.9877H14.2002C13.4337 17.9877 12.7584 17.4925 12.5405 16.7767L12.5405 16.7767L12.5393 16.7727Z" stroke={isActive ? "#fff" : "#828282"} stroke-width="1.67324" />
  </svg>


);
