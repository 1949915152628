import React from "react";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: "Mon",
    cards: 40,
  },
  {
    name: "Tue",
    cards: 30,
  },
  {
    name: "Wed",
    cards: 20,
  },
  {
    name: "Thu",
    cards: 27,
  },
  {
    name: "Fri",
    cards: 10,
  },
  {
    name: "Sat",
    cards: 23,
  },
  {
    name: "Sun",
    cards: 34,
  },
];
export default function ReBarChart(props) {
  console.log(props)
  const [showChart, setshowChart] = React.useState(false);
  React.useEffect(() => {
    if (document && document.getElementById("weeklyReport")) {
      setshowChart(true);
    }
  }, []);
  return (
    <div>
      {/* <ResponsiveContainer width="100%" height="100%"> */}
      {showChart && (
        <BarChart
          width={document.getElementById("weeklyReport").offsetWidth}
          height={160}
          data={props.weeklyData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="name"
            interval="preserveStartEnd"
            tickCount={10}
            stroke="#BBBBBB"
            tickMargin={8}
            label={{
              value: "Days",
              position: "left",
              offset: 10,
              fill: "#BBB",
            }}
            posit
          />
          <YAxis
            tickMargin={8}
            stroke="#BBBBBB"
            label={{
              value: "Cards",
              angle: -90,
              offset: 10,
              position: "insideLeft",
              fill: "#bbb",
            }}
          />
          <Tooltip
            cursor={{
              barSize: 20,
              width: 0,
            }}
          />
          <Bar dataKey="cards" fill={`rgb(var(--blue))`} barSize={12} width={12} />
        </BarChart>
      )}
      {/* </ResponsiveContainer> */}
    </div>
  );
}
