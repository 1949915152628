import { useMutation } from '@apollo/client';
import { Form, Input, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/common/Form/Button';
import { USER_CHANGE_PASSWORD } from "../../../client/graphql";
import { useHistory } from 'react-router-dom';
import { InfoCircleOutlined } from "@ant-design/icons";
const InputContainer = styled.div`
  .ant-input-affix-wrapper input,
  .ant-input-affix-wrapper {
    border-radius: 62px;

    /* background-color: #f4f4f4;
    border-color: transparent; */
    /* height:60px; */
    &.border-liteRed {
      border-color: #d1d5db;
    }
  }
  .ant-input-affix-wrapper.bg-red-100 input,
  .ant-input-affix-wrapper.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .ant-input {
    border-radius: 62px;
  }
  .ant-picker {
    border-radius: 62px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
`;
export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [formRef] = Form.useForm();
  const history = useHistory();

  const [ChangePasswordValue] = useMutation(USER_CHANGE_PASSWORD, {
    onCompleted: (resp) => {
      if(resp.changeLearnerPassword && resp.changeLearnerPassword.user_details?.password){
        notification.success({ message: "Password changed successfully" });
        history.push(`/parent/profile`);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  })

  return (
    <div>
      <div className="flex justify-between ">
        <div className="nunito-bold-2xl mb-9">Change Password</div>
        <div className="flex items-center mb-3">
          <Button
            secondary
            className="font-Nunito text-base px-6 py-2 flex font-bold"
            onClick={async () => {
              let validated = await formRef.validateFields();
              if (validated) {  
                await ChangePasswordValue({
                  variables: {
                    oldpassword: currentPassword,
                    password: newPassword,
                  },
                });
              }
            }}
          >
            {`Save Changes`}
          </Button>
        </div>
      </div>
      <Form name="changePassword" className=" max-w-md" form={formRef}>
        <div className="flex flex-col mt-5 ">
          <div className="mr-16 mb-3 font-semibold text-pureWhite ">
            Enter current password
          </div>
          <InputContainer>
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                size="small"
                onPressEnter={() => {}}
                onChange={(event) => {
                  setCurrentPassword(event.target.value);
                }}
                placeholder={"Enter your Current Password"}
                className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg`}
                // iconRender={(visible) => (<div className="h6 text-blue">{visible ? "Show" : "Hide"}</div>)}
              />
            </Form.Item>
          </InputContainer>
        </div>
        <div className="flex flex-col mt-5 ">
          <div className="mr-16 mb-3 font-semibold text-pureWhite ">
            Enter new password
          </div>
          <InputContainer>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  pattern: /^(?=.*\d)(?=.*[!@#$_%^&*])(?=.*[a-zA-z]).{8,}$/,
                  message: "Password must contain 8 or more characters with a mix of capital and small letters, numbers and symbols",
                },
              ]}
            >
              <Input.Password
                size="small"
                onPressEnter={() => {}}
                className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg
                      `}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                placeholder={"Enter New Password"}
                // iconRender={(visible) => (<div className="h6 text-blue">{visible ? "Show" : "Hide"}</div>)}
              />
            </Form.Item>
          </InputContainer>
        </div>
        <div className="flex flex-col mt-5 ">
          <div className="mr-16 mb-3 font-semibold text-pureWhite ">
            Enter confirm password
          </div>
          <InputContainer>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your confirm password!",
                },
                {
                  pattern: /^(?=.*\d)(?=.*[!@#$_%^&*])(?=.*[a-zA-z]).{8,}$/,
                  message: "Password must contain 8 or more characters with a mix of capital and small letters, numbers and symbols",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error("Password mismatch!"));
                  },
                }),
              ]}
            >
              <Input.Password
                size="small"
                onPressEnter={() => {}}
                className={`px-4 py-2 text-pureWhite ant-input-bg focus:outline-none focus:shadow-outline  bg-liteWhite focus:bg-white text-lg
                      `}
                placeholder={"Confirm Password"}
                // iconRender={(visible) => (<div className="h6 text-blue">{visible ? "Show" : "Hide"}</div>)}
              />
            </Form.Item>
          </InputContainer>
        </div>
        <div className = "text-pureWhite opacity-80">
          <InfoCircleOutlined className = "mr-2"/>
            {`Use 8 or more characters with a mix of capital and small letters, numbers and symbols`} 
        </div>
      </Form>
    </div>
  );
}
