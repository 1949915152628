import { Popover } from "antd";
import React from "react";
import { NotesDualIcon } from "../../../themes/dualImages";
import Button from "../Form/Button";
import Notes from "./Notes";
import NotesAudio from "./NotesAudio";
import NotesPhotoUpload from "./NotesPhotoUpload";

export default function Notesbutton(props) {
  return (
    <div className="relative">
      <div id="notesPopover"></div>
      <Popover
        placement="top"
        title={null}
        getPopupContainer={() => document.getElementById("notesPopover")}
        overlayClassName="rounded-lg pb-0 mb-3 shadow-5xl overflow-hidden w-182 z-10"
        content={
          <div className="h9 text-charcoal">
            {/* Only for do card */}
            {props.type === "do" && (
              <NotesPhotoUpload
                onSuccess={props.onSuccess}
                title={props.title}
                enableCrop={props.enableCrop || false}
              />
            )}
            {/* common for all type */}
            <NotesAudio onSuccess={props.onSuccess} title={props.title} />
            <Notes onSuccess={props.onSuccess} title={props.title} />
          </div>
        }
        trigger={"click"}
      >
        <div className="w-182 whitespace-nowrap" id="notesAddButton">
          <Button
            secondaryGhost
            className="font-Nunito text-base px-6  py-2 text-center flex font-bold justify-center items-center"
            onClick={() => { }}

          >
            <div className="mr-3">
              <NotesDualIcon />
            </div>

            {props?.title}
          </Button>
        </div>
      </Popover>
    </div>
  );
}
