import { useMutation, useQuery } from "@apollo/client";
import {
  Button, Card, Checkbox, Col, Form, Modal, notification, Row, Tag, Typography
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  PARENT_VIEW_WORKSHOP, REGISTER_FREE_WORKSHOP, REGISTER_WORKSHOP, STUDENT_LIST
} from "../../../client/graphql";
import ExploreHeading from "../../../components/common/ExploreHeading";
import Loader from "../../../components/common/Loader";
import Images from "../../../themes/Images";

let { Paragraph } = Typography;

const ProfileContainer = styled.div`
  width: 125px;
  height: 125px;
  background: url(${Images.UserProfileDarkIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  .profilePicture {
    width: 70px;
    height: 70px;
  }
`;

const CardImgContainer = styled.div`
  .cardImage img,
  .cardImage video {
    height: 420px;
  }
  .thumbnail {
    min-width: 132px;
    width: 140px;
    height: 74px;
    min-height: 66px;
  }
  .tag {
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    &.blue {
      background: #c8edff;
      color: #49bff9;
    }
    &.yellow {
      background: #fffbc3;
      color: #f8c947;
    }
    &.green {
      color: #68b415;
      background: #d8ffad;
    }
    &.gray {
      background: #f0f0f0;
      color: #4f4f4f;
    }
  }
`;

const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: "nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    /* @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    } */
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    } */
    color: #57479c;
    max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`;

const DateView = ({ date, time, every, duration }) => {
  return (
    <DateContainer className="d-flex">
      {every && every.length && (
        <div className="d-flex">
          <div className="subTitle">{every}</div>
        </div>
      )}
      <div>
        <h4 className="title white-space-nowrap">
          {date ? moment(parseInt(date)).format(`ddd, Do MMM ’YY`) : ""}
        </h4>
        <div className="desc mt-1">
          {time ? moment(time, "h m a").format("h:mm A") : ""}{" "}
          {duration ? `- ${duration}` : ""}
        </div>
      </div>
    </DateContainer>
  );
};

const CarouselCard = ({ thumbnailList = [] }) => {
  const [selectedCard, setSelectedCard] = React.useState(0);
  return (
    <CardImgContainer>
      <div className="cardImage">
        {thumbnailList.length !== 0 && thumbnailList[selectedCard]?.isVideo && (
          <div className=" w-full h-500 object-cover rounded relative ">
            <iframe
              title="Youtube Video player"
              id="youtubeFrame"
              className={` w-full h-full absolute inset-0 `}
              src={thumbnailList[selectedCard].src}
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            />
          </div>
        )}
        {thumbnailList.length !== 0 && !thumbnailList[selectedCard]?.isVideo && (
          <div>
            <img
              className=" w-full h-500 object-cover rounded "
              src={thumbnailList[selectedCard].src}
              alt="Explore card"
            />
          </div>
        )}
      </div>
      <div className="flex mt-7">
        <div className="w-8/12 relative">
          {thumbnailList.length > 6 && (
            <>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`rotate-180 transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft - 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
              <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`transform shadow-3xl rounded-full w-8 h-8 cursor-pointer`}
                  onClick={() => {
                    document
                      .getElementById(`collection-details-thumb`)
                      .scrollTo({
                        left:
                          document.getElementById(`collection-details-thumb`)
                            .scrollLeft + 250,
                        behavior: "smooth",
                      });
                  }}
                />
              </div>
            </>
          )}
          <div
            className="flex items-center overflow-auto "
            id="collection-details-thumb"
          >
            {thumbnailList.map((thumbnail, thumbnailIndex) => {
              if (thumbnail.src)
                return (
                  <div
                    className={`thumbnail cursor-pointer  mr-4 p-1 ${thumbnailIndex === selectedCard
                      ? "border border-darkGray rounded-md"
                      : ""
                      }`}
                    onClick={() => {
                      setSelectedCard(thumbnailIndex);
                    }}
                    key={`thumbnail-${thumbnailIndex}`}
                  >
                    {thumbnail.isVideo && (
                      <div className="h-full relative">
                        {/* <video
                        width={200}
                        height={100}
                        src={thumbnail.src}
                        className=" rounded-md"
                      /> */}

                        <iframe
                          title="Youtube Video player"
                          id="youtubeFrame"
                          className={` w-full h-full absolute inset-0 pointer-events-none  rounded-md`}
                          src={thumbnail.src}
                          allowfullscreen="allowfullscreen"
                          mozallowfullscreen="mozallowfullscreen"
                          msallowfullscreen="msallowfullscreen"
                          oallowfullscreen="oallowfullscreen"
                          webkitallowfullscreen="webkitallowfullscreen"
                        />
                      </div>
                    )}
                    {!thumbnail.isVideo && (
                      <div className="h-full">
                        <img
                          // width={200}
                          // height={100}
                          className=" w-full h-full object-cover rounded-md"
                          src={thumbnail.src}
                          alt="Explore card"
                        />
                      </div>
                    )}
                  </div>
                );
            })}
          </div>
        </div>
        <div className=" w-4/12"></div>
      </div>
    </CardImgContainer>
  );
};

const WorkshopDetail = () => {
  const [workshopData, setWorkshopData] = useState({});
  const [materials, setMaterials] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFreeModalVisible, setIsFreeModalVisible] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [facebook, setFacebook] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [twitter, setTwitter] = useState("");
  const [registered, setRegistered] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [freeForm] = Form.useForm();
  const encodedURL = encodeURIComponent(process.env.REACT_APP_LAND_URL);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showFreeModal = () => {
    setIsFreeModalVisible(true);
  };

  const { data, loading, refetch } = useQuery(PARENT_VIEW_WORKSHOP, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "no-cache",
  });

  const { data: studentList, loading: listLoading } = useQuery(STUDENT_LIST, {
    fetchPolicy: "no-cache",
  });

  const [addToCart, { loading: registerLoading }] =
    useMutation(REGISTER_WORKSHOP);

  const [registerWorkshop, { loading: registerFreeLoading }] = useMutation(
    REGISTER_FREE_WORKSHOP
  );

  useEffect(() => {
    if (data) {
      setWorkshopData(data?.viewWorkshop?.workshop_details);
      setMaterials(data?.viewWorkshop?.Materials_required);
    }
  }, [data]);

  const getValidLearners = (ageGroup, learners) => {
    let ageArray = ageGroup.split("-");
    ageArray[1] = ageArray[1].split("+")[0];
    ageArray = ageArray.map((age) => parseInt(age));
    const filteredLearners = (learners || []).filter((learner) => {
      let learnerAgeArray = learner?.student_details?.age_group.split("-");
      learnerAgeArray[1] = learnerAgeArray[1].split("+")[0];
      learnerAgeArray = learnerAgeArray.map((age) => parseInt(age));
      if (learnerAgeArray[1] <= ageArray[1]) {
        return learner;
      }
    });
    return filteredLearners;
  };

  useEffect(() => {
    if (data && studentList) {
      let filteredLearners = [],
        isParentRegistered,
        registeredLearners = [];
      let validLearners = getValidLearners(
        data?.viewWorkshop?.workshop_details?.age_group,
        studentList?.getDashboard?.students
      );
      if (data?.viewWorkshop?.workshop_details?.RegisteredChild?.length > 0) {
        registeredLearners =
          data?.viewWorkshop?.workshop_details?.RegisteredChild?.map(
            (learners) => parseInt(learners.id)
          );
        const registeredLearnersType =
          data?.viewWorkshop?.workshop_details?.RegisteredChild?.map(
            (learners) => learners.registration_type
          );
        isParentRegistered = registeredLearnersType.includes("parent");
        filteredLearners = validLearners?.filter((student) => {
          if (!registeredLearners?.includes(student.id)) return student;
        });
      } else {
        filteredLearners = validLearners;
      }
      if (
        filteredLearners.length === 0 &&
        data?.viewWorkshop?.workshop_details?.age_group.match("18") &&
        isParentRegistered
      ) {
        setRegistered(true);
      }
      let sampleObject = {},
        sampleArray = [];
      if (
        data?.viewWorkshop?.workshop_details?.age_group.match("18") &&
        !isParentRegistered
      ) {
        sampleObject.label =
          studentList?.getDashboard?.user_details?.first_name + " (myself)";
        sampleObject.value = "";
        sampleArray.push(sampleObject);
      }
      filteredLearners?.forEach((student) => {
        sampleObject = {};
        sampleObject.label = student?.student_details?.fname;
        sampleObject.value = student?.id;
        sampleArray.push(sampleObject);
      });
      setCheckBoxValue(sampleArray);
    }
  }, [studentList, data]);

  useEffect(() => {
    if (workshopData) {
      setFacebook(`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`);
      setWhatsapp(
        `https://api.whatsapp.com/send/?phone&text=I+just+discovered+this+fabulous+workshop+*${workshopData?.workshop_name}*+on+Breadcrumb!+Do+check+it+out+${encodedURL}+and+follow+@learnwithbreadcrumb+for+more.&app_absent=0`
      );
      setTwitter(`https://twitter.com/intent/tweet?url=${encodedURL}`);
    }
  }, [workshopData]);

  function onChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  const handleOk = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleFreeOk = () => {
    freeForm.resetFields();
    setIsFreeModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleCancelFree = () => {
    freeForm.resetFields();
    setIsFreeModalVisible(false);
  };

  const onFinish = (values) => {
    let payloadObject = {},
      payloadArray = [];
    values?.members?.forEach((member) => {
      if (member === "") {
        payloadObject.student_id = null;
        payloadObject.registration_type = "parent";
      } else {
        payloadObject.student_id = member;
        payloadObject.registration_type = "student";
      }
      payloadArray.push(payloadObject);
      payloadObject = {};
    });
    addToCart({
      variables: {
        workshop_id: id,
        registration_details: [...payloadArray],
      },
    }).then((res) => {
      handleOk();
      refetch();
    });
  };

  const onFreeFinish = (values) => {
    let payloadObject = {},
      payloadArray = [];
    values?.members?.forEach((member) => {
      if (member === "") {
        payloadObject.student_id = null;
        payloadObject.registration_type = "parent";
      } else {
        payloadObject.student_id = member;
        payloadObject.registration_type = "student";
      }
      payloadArray.push(payloadObject);
      payloadObject = {};
    });
    registerWorkshop({
      variables: {
        workshop_id: id,
        registration_details: [...payloadArray],
      },
    }).then((res) => {
      handleFreeOk();
      refetch();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFreeFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Loader
        loading={
          loading || listLoading || registerLoading || registerFreeLoading
        }
      />
      <ExploreHeading
        title={workshopData?.workshop_name || "Title"}
        isBack
        onClick={() => {
          history.goBack();
        }}
      ></ExploreHeading>
      <Row gutter={16} className="mt-4 px-4">
        <Col span={14}>
          <Card className="rounded mt-2">
            <div>
              <CarouselCard
                // loading={loading}
                thumbnailList={
                  workshopData?.featured_image
                    ? [
                      ...workshopData?.featured_image.map((image) => {
                        return {
                          src: image.image_url,
                          isVideo: false,
                        };
                      }),
                      ...[
                        workshopData?.trailer_link
                          ? {
                            src: String(workshopData?.trailer_link).replace(
                              `watch?v=`,
                              `embed/`
                            ),
                            isVideo: true,
                          }
                          : {},
                      ],
                    ]
                    : []
                }
              />
            </div>
            <div className=" mt-5  ">
              <h5 className="h6">ABOUT</h5>
              <div
                className="h7 mt-3"
                dangerouslySetInnerHTML={{
                  __html: workshopData?.description
                    ? workshopData?.description
                    : "",
                }}
              />
            </div>
            <div className="mt-5 ">
              <h5 className="h6">YOU SHOULD ALREADY KNOW</h5>
              <div
                className="h7 mt-3"
                dangerouslySetInnerHTML={{
                  __html: workshopData?.prerequisites
                    ? workshopData?.prerequisites
                    : "",
                }}
              />
            </div>
            {materials && materials?.length > 0 && (
              <div className="mt-5">
                <h5 className="h6">YOU WOULD NEED</h5>
                <div className="flex mt-3">
                  <div
                    className="row overflow-scroll flex"
                    id="workshopMat-scroll"
                  >
                    {materials?.map((x, index) => {
                      return (
                        <div
                          key={`Material-${index}`}
                          className="mr-4 text-center"
                        >
                          <div className=" w-20 h-20">
                            <img
                              src={x.image}
                              alt={x.name}
                              className="rounded w-full h-full object-cover "
                            />
                          </div>
                          <Paragraph className=" w-20 h9 text-center font-bold mb-0 px-3 my-2">
                            {x.name}
                          </Paragraph>
                        </div>
                      );
                    })}
                  </div>

                  {materials?.length > 12 && (
                    <img
                      className="ml-3 cursor-pointer"
                      src={Images.CarouselLeft}
                      alt="arrow"
                      onClick={() => {
                        document.getElementById(`workshopMat-scroll`).scrollTo({
                          left:
                            document.getElementById(`workshopMat-scroll`)
                              .scrollLeft + 250,
                          behavior: "smooth",
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="mt-5 ">
              <h5 className="h6">LEARNING OUTCOME</h5>
              <div
                className="h7 mt-3"
                dangerouslySetInnerHTML={{
                  __html: workshopData?.learning_outcome
                    ? workshopData?.learning_outcome
                    : "",
                }}
              />
            </div>
          </Card>
        </Col>
        <Col span={10}>
          <Card className="rounded mt-2">
            <Row>
              <Col>
                <p className="h3">{workshopData?.workshop_name}</p>
              </Col>
            </Row>
            <Row gutter={16} className="mt-4">
              <Col span={4}>
                <p className="text-center">AGE GROUP</p>
                <p className="text-center">{workshopData?.age_group}</p>
              </Col>
              <Col span={4}>
                <p className="text-center">SESSIONS</p>
                <p className="text-center">{workshopData?.workshop_session}</p>
              </Col>
              <Col span={4}>
                <p className="text-center"> DURATION</p>
                <p className="text-center">{workshopData?.workshop_duration}</p>
              </Col>
            </Row>
            <Row>
              <div className="flex mt-4 pt-1 w-11/12">
                <div
                  className="w-full flex overflow-scroll"
                  id={`workshop-scroll1`}
                >
                  {workshopData &&
                    workshopData.workshop_plan_id &&
                    [1, 2, 3].includes(
                      parseInt(workshopData.workshop_plan_id)
                    ) &&
                    workshopData.repeats_type == "dnr" &&
                    workshopData.Dates &&
                    workshopData.Dates.length > 0 &&
                    workshopData.Dates.map((x, index) => (
                      <span
                        className="pr-3 d-inline-block"
                        key={`WorkshopData-${index}`}
                      >
                        <DateView
                          date={x.workshop_Date}
                          time={x.start_time}
                          duration={
                            workshopData.workshop_plan_id == 3 &&
                              !workshopData.is_common_timing
                              ? `${x.duration ? `${x.duration}` : ""}`
                              : ""
                          }
                        />
                      </span>
                    ))}
                  {workshopData &&
                    workshopData.workshop_plan_id &&
                    (workshopData.workshop_plan_id == 1 ||
                      workshopData.workshop_plan_id == 2) &&
                    workshopData.repeats_type != "dnr" &&
                    workshopData.Dates &&
                    workshopData.Dates.length > 0 &&
                    workshopData.Dates.map((x, dateIndex) => (
                      <span
                        className="pr-3 inline-block"
                        key={`DateView-${dateIndex}`}
                      >
                        <DateView
                          date={x.workshop_Date}
                          time={x.start_time}
                          every={workshopData.repeatDays}
                          duration={x?.duration}
                        />
                      </span>
                    ))}
                </div>
                {workshopData.Dates && workshopData.Dates.length > 7 && (
                  <img
                    className="ml-3 cursor-pointer"
                    src={Images.CarouselLeft}
                    alt="arrow"
                    onClick={() => {
                      document.getElementById(`workshop-scroll1`).scrollTo({
                        left:
                          document.getElementById(`workshop-scroll1`)
                            .scrollLeft + 250,
                        behavior: "smooth",
                      });
                    }}
                  />
                )}
              </div>
            </Row>
            <Row className="mt-4">
              <Col className="mr-2">
                {parseInt(workshopData?.workshop_amount) > 0 ? (
                  <p
                    style={{
                      color: "rgb(66, 192, 239)",
                      textTransform: "uppercase",
                    }}
                  >
                    ₹{workshopData?.workshop_amount}
                  </p>
                ) : (
                  <p
                    style={{
                      color: "rgb(66, 192, 239)",
                      textTransform: "uppercase",
                    }}
                  >
                    FREE
                  </p>
                )}
              </Col>
              <Col>
                <p
                  style={{
                    color: "#ea1f59",
                  }}
                >
                  Only {workshopData?.available_seats} seats remaining
                </p>
              </Col>
            </Row>
            <Row className="mt-4 items-center justify-between" gutter={16}>
              {checkBoxValue?.length === 0 && !registered ? (
                <p>
                  This workshop matches neither your child nor your age
                  category.
                </p>
              ) : parseInt(workshopData?.workshop_amount) > 0 ? (
                <Col>
                  {workshopData?.is_cart === false &&
                    workshopData?.workshop_registration_status === true && (
                      <Button
                        type="primary"
                        className="rounded py-3 px-16 h5 bg-blue border-blue"
                        style={{ height: "auto" }}
                        onClick={showModal}
                      >
                        Add to cart
                      </Button>
                    )}
                  {workshopData?.is_cart === false &&
                    workshopData?.workshop_registration_status === false && (
                      <Button
                        type="secondary"
                        className="rounded py-3 px-16 h5 border-secondaryGhost border text-secondaryGhost bg-white"
                        style={{ height: "auto", cursor: "default" }}
                      >
                        Registration closed
                      </Button>
                    )}
                  {workshopData?.is_cart === true && (
                    <Button
                      type="primary"
                      className="rounded py-3 px-16 h5 bg-blue border-blue"
                      style={{ height: "auto" }}
                      onClick={() => history.push("/cart")}
                    >
                      Go to cart
                    </Button>
                  )}
                </Col>
              ) : (
                <Col>
                  {workshopData?.workshop_registration_status === true &&
                    !registered ? (
                    <Button
                      type="primary"
                      className="rounded py-3 px-16 h5 bg-blue border-blue"
                      style={{ height: "auto" }}
                      onClick={showFreeModal}
                    >
                      Register
                    </Button>
                  ) : workshopData?.workshop_registration_status === true &&
                    registered ? (
                    <Button
                      type="secondary"
                      className="rounded py-3 px-16 h5 border-secondaryGhost border text-secondaryGhost bg-white"
                      style={{ height: "auto", cursor: "default" }}
                    >
                      Registered
                    </Button>
                  ) : (
                    <Button
                      type="secondary"
                      className="rounded py-3 px-16 h5 border-secondaryGhost border text-secondaryGhost bg-white"
                      style={{ height: "auto", cursor: "default" }}
                    >
                      Registration closed
                    </Button>
                  )}
                </Col>
              )}
              <Col>
                <Row gutter={16} className="items-baseline">
                  <Col>
                    <img
                      src={Images?.linkIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_LAND_URL}/workshopdetails?id=${id}`
                        );
                        notification.success({
                          message: "Link copied!",
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <img
                      src={Images?.twitterIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(twitter, "_blank");
                      }}
                    />
                  </Col>
                  <Col>
                    <img
                      src={Images?.whatsAppIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(whatsapp, "_blank");
                      }}
                    />
                  </Col>
                  <Col>
                    <img
                      src={Images?.facebookIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(facebook, "_blank");
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          {workshopData?.RegisteredChild?.length > 0 && (
            <Card className="rounded mt-4">
              <Row gutter={16}>
                <Col span={8}>
                  <p className="text-left">STATUS</p>
                </Col>
                <Col span={8}>
                  <p className="text-left">REGISTERED FOR</p>
                </Col>
                <Col span={8}>
                  <p className="text-left"> NEXT SESSION ON</p>
                </Col>
              </Row>
              {workshopData?.RegisteredChild?.map((child, index) => {
                return (
                  <Row className="mt-2">
                    <Col span={8}>
                      <Tag color="success">Registered</Tag>
                    </Col>
                    <Col span={8}>
                      <p className="text-left px-2">
                        {child?.fname || child?.username}
                      </p>
                    </Col>
                    {index === 0 && (
                      <Col span={8}>
                        <p className="text-left px-2">
                          {workshopData?.workshop_duration}
                        </p>
                      </Col>
                    )}
                  </Row>
                );
              })}
              <Row className="mt-4">
                <Col>
                  <p className="h5">Meeting link:</p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <a
                    href={JSON.parse(workshopData?.zoom_meeting)?.join_url}
                    className="h5"
                    style={{ color: "#57479c" }}
                  >
                    {JSON.parse(workshopData?.zoom_meeting)?.join_url}
                  </a>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p>
                    Meeting ID: {JSON.parse(workshopData?.zoom_meeting)?.id}
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p>
                    Password: {JSON.parse(workshopData?.zoom_meeting)?.password}
                  </p>
                </Col>
              </Row>
            </Card>
          )}
          <Card className="rounded mt-4">
            <Row>
              <Col>
                <p className="h3">Content Creator</p>
              </Col>
            </Row>
            <Row gutter={16} className="mt-4 ml-4">
              <Col span={8}>
                <div className=" flex-shrink-0">
                  <ProfileContainer className={"relative showBg"}>
                    <img
                      src={
                        workshopData?.user_details
                          ? workshopData?.user_details.profile_image
                          : "https://via.placeholder.com/80"
                      }
                      className=" rounded-full profilePicture mx-auto absolute left-0 right-0 mt-4"
                      alt="profile"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "../static/images/icons/profile_no_bg.svg";
                      }}
                    />
                    <div className=" absolute left-0 right-0 bottom-2">
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          expandable: false,
                        }}
                        className=" font-Nunito text-xs text-pureWhite text-center font-bold mb-0 w-full px-3"
                      >
                        {workshopData?.user_details
                          ? workshopData?.user_details.displayName
                            ? workshopData?.user_details.displayName
                            : workshopData?.user_details.name
                          : "..."}
                      </Paragraph>
                    </div>
                  </ProfileContainer>
                </div>
              </Col>
              <Col span={16}>
                <h5 className="h6">ABOUT {workshopData?.user_details?.name}</h5>
                <p className="mt-4">{workshopData?.user_details?.bio}</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Learner selection"
        onCancel={handleCancel}
        visible={isModalVisible}
        okText={"Register"}
        effect="fadeInUp"
        footer={null}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <p>Please select the learner to make the purchase</p>
        <Form
          name="member selection form"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="mt-6"
          layout="horizontal"
          form={form}
        >
          <Form.Item name="members" wrapperCol={{ span: 24 }}>
            <Checkbox.Group onChange={onChange}>
              {checkBoxValue?.map((item) => {
                return (
                  <div className="ml-0">
                    <Checkbox value={item?.value} className="ml-4 mb-2">
                      {item?.label}
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 18 }}>
            <Button type="primary" htmlType="submit" className="bg-blue border-blue">
              Register
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Learner selection"
        onCancel={handleCancelFree}
        visible={isFreeModalVisible}
        okText={"Register"}
        effect="fadeInUp"
        footer={null}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <p>Please select the learner to make the purchase</p>
        <Form
          name="member selection form"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFreeFinish}
          onFinishFailed={onFreeFinishFailed}
          autoComplete="off"
          className="mt-6"
          layout="horizontal"
          form={freeForm}
        >
          <Form.Item name="members" wrapperCol={{ span: 24 }}>
            <Checkbox.Group onChange={onChange}>
              {checkBoxValue?.map((item) => {
                return (
                  <div className="ml-0">
                    <Checkbox value={item?.value} className="ml-4 mb-2">
                      {item?.label}
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 18 }}>
            <Button type="primary" htmlType="submit" className="bg-blue border-blue">
              Register
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WorkshopDetail;
