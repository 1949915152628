import { RightOutlined } from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import GenericNotFound from "../../../components/common/GenericNotFound";
import NavContext from "../../../context/NavContext";
import Billings from "./billings";
import ChangePassword from "./changePassword";
import Profile from "./profile";

const ParentMenuStyle = styled.div`
  position:relative;
  &.active::before{
    content:"";
    position: absolute;
    inset:0;
    background:linear-gradient(270deg, rgba(255, 201, 13, 0.28) 0%, rgba(255, 201, 13, 0) 67.42%);
    border-radius: 5px;
  }
`;
const ProfileContainer = styled.div`
  .bg-liteGreen{
    background:#27AE60
  }
`

const ParentMenuItem = ({ isActive, title, onClick }) => {
  return <ParentMenuStyle className={`nunito-bold-lg mb-3 ${isActive ? " text-yellow active" : " text-pureWhite "}  cursor-pointer`} onClick={onClick}>{title}
    {isActive &&
      <div className="absolute top-0 bottom-0 right-2 flex items-center">
        <RightOutlined style={{
          color: "rgba(--var(--yellowDark))"
        }} />
      </div>}
  </ParentMenuStyle>
}

export default function ParentProfile() {
  const history = useHistory();
  const userNavContext = useContext(NavContext);
  const [selectedActive, setSelectedActive] = React.useState("account")
  React.useEffect(() => {
    let pathname = history?.location?.pathname;
    setSelectedActive(pathname?.includes("/profile") ? "account" :
      pathname?.includes("/password") ?
        "password" : pathname?.includes("/billings") ? "billings" : pathname?.includes("/settings") ? "settings" : "")
  }, [history?.location?.pathname])
  const decoded = jwt_decode(localStorage.getItem("token"));

  if (decoded.role_id !== 3) {
    return <GenericNotFound />
  }
  return (
    <ProfileContainer>
      <div className="px-20 mt-4 flex gap-20">
        <div className="w-52 flex-shrink-0">
          <div className="nunito-bold-lg text-blue border-b-4 border-blue inline-block pb-2">
            Accounts & Billing
          </div>
          <div className=" mt-9 w-40 pr-2">
            <ParentMenuItem
              title={"Account"}
              isActive={selectedActive === "account"}
              onClick={() => {
                history.push("/parent/profile");
              }}
            />
            {!["google", "facebook", "apple"].includes(userNavContext?.userInfo?.signup_type) && (
              <ParentMenuItem
                title={"Password"}
                isActive={selectedActive === "password"}
                onClick={() => {
                  history.push("/parent/password");
                }}
              />
            )}
            <ParentMenuItem
              title={"Billing"}
              isActive={selectedActive === "billings"}
              onClick={() => {
                history.push("/parent/billings");
              }}
            />
            {/* <ParentMenuItem title={"Settings"} isActive={selectedActive === "settings"} onClick={() => {
              history.push("/parent/settings")
            }} /> */}
          </div>
        </div>
        <div className="w-full">
          {selectedActive === "billings" && <Billings />}
          {selectedActive === "account" && <Profile />}
          {selectedActive === "password" && <ChangePassword />}
        </div>
      </div>
    </ProfileContainer>
  );
}
