import ReactGA from "react-ga";

export const triggerGAEvent = (category, action, label = "") => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};

export const generateRandomString = (length) => {
  var text = "";
  var possibleChars = "ABCDEFGHIJKLMNOPQRTSUVWXYZ0123456789";
  for (var i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );
  return text;
};

export async function getS3UploadUrl(fileName, contentType) {
  const query = `{"operationName":null,"variables":{},"query":"{\\n  getS3SignedUrl(key: \\"${fileName}\\", contentType: \\"${contentType}\\") {\\n    signedUrl\\n  }\\n}\\n"}`;
  let postData = await fetch(`${process.env.REACT_APP_API}`, {
    method: "POST",
    body: query,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { data: uploadLink } = await postData.json();
  return uploadLink.getS3SignedUrl.signedUrl;
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function (e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          xhr.open("put", urlList[index], true);
          if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf"
          ) {
            xhr.setRequestHeader("Content-Type", file.type);
          } else if (file.type === "audio/wav" || file.type === "audio/webm") {
            xhr.setRequestHeader("Content-Type", file.type);
          } else {
            xhr.setRequestHeader("Content-Type", "binary/octet-stream");
          }
          xhr.send(file);
        })
    );

    Promise.all(formUploadPromiseArray)
      .then((urls) => {
        resolve(urls.map((url) => url.split("?")[0]));
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const NotesBg = (notes) => {
  let decoded_data = base64regex.test(notes)
    ? decodeURIComponent(escape(window.atob(notes)))
    : notes;
  let data = "";
  let arr = decoded_data.match(/.{1,50}\b/g);
  if (arr) {
    arr.forEach((text, index) => {
      if (index < 9) {
        data =
          data +
          `<tspan y='${7 + index * 5}' x='4' font-size='4.5'>${text}</tspan>`;
      }
    });
  }
  let fillState = document.body.classList.contains('dark') ? 'white' : 'black';
  return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><style> .FontAvenirMedium { font-family: Avenir; letter-spacing: 0.3px }</style><text x='4' y='7' fill='${fillState}' class='FontAvenirMedium'>${data}</text></svg>`;
};

export const BackButtonHandler = (history) => {
  if (
    document.referrer &&
    document.referrer.includes(process.env.REACT_APP_FRONTEND_API)
  ) {
    history.goBack();
  } else {
    history.push("/dashboard");
  }
};
export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};
// base64regex.test(notes) ? window.atob(notes) : notes

// export const SetTokenCookie = () => {
//   var baseDomain = '.learnwithbreadcrumb.com', expireAfter = new Date();
//   expireAfter.setDate(expireAfter.getDate() + 1);
// document.cookie = "token={'token':" + localStorage.token + "}; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
// }

export const SetTokenCookie = () => {
  var baseDomain = '.learnwithbreadcrumb.com', expireAfter = new Date();
  const d = new Date();
  d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = "Token" + "=" + localStorage.token + ";" + expires + "; domain=" + baseDomain + ";path=/";
}
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
export const deleteCookie = (name) => {
  var path = "/", domain = ".learnwithbreadcrumb.com";
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path=" + path : "") +
      ((domain) ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}