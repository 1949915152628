import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { UPDATE_EXPIRE_DATE, VIEW_USER } from "../../client/graphql";
import NavContext from "../../context/NavContext";
import ChildJournal from "./index";
import ParentJournal from "./parentJournal";

const JournalIndex = () => {
  const userNavContext = useContext(NavContext);
  const [updateExpireDate] = useMutation(UPDATE_EXPIRE_DATE, {
    onCompleted: (res) => {
      if (res?.updateExpiryDate?.message) {
        window.location.reload();
      }
    }
  })
  const { loading: loadingUser } = useQuery(VIEW_USER, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      let expire_date = moment
        .unix(response?.viewUser?.student_details?.expiry_date || response?.viewUser?.user_details?.expiry_date / 1000).endOf('day')
      let isExpire = moment().endOf("day").isAfter(moment(expire_date))
      let UserDetails = response?.viewUser?.student_details || response?.viewUser?.user_details
      let beta_user = response?.viewUser?.student_details ? UserDetails?.beta_user : true;

      let contentExpire = beta_user ? UserDetails?.card_count >= UserDetails?.completed_learning_content : false;
      if ((isExpire || contentExpire) && !UserDetails.expired) {
        if ((response?.viewUser?.user_details && !response?.viewUser?.user_details?.is_paid) || response?.viewUser?.student_details) {

          // updateExpireDate(
          //   {
          //     variables: {
          //       subscription_type: response?.viewUser?.student_details ? "Student" : "Parent"
          //     }
          //   }
          // )
        }
        //Mutation
      }
      if (response.viewUser) {
        if (response?.viewUser?.student_details) {
          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            expiry_date: moment.unix(response?.viewUser?.student_details?.expiry_date / 1000),
            expired: response?.viewUser?.student_details?.expired
          });
        } else if (response?.viewUser?.user_details) {
          userNavContext.setUserInfo({
            ...userNavContext.userInfo,
            expiry_date: moment.unix(response?.viewUser?.user_details?.expiry_date / 1000),
            expired: response?.viewUser?.user_details?.expired
          });

        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  return (
    <>
      {userNavContext?.userInfo?.mode === "LIGHT_MODE" ? (
        <ChildJournal />
      ) : (
        <ParentJournal />
      )}
    </>
  );
};

export default JournalIndex;