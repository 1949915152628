import { useLazyQuery } from "@apollo/client";
import { notification } from "antd";
import { Formik } from "formik";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { VALIDATE_USERNAME, GET_STUDENT_IMAGES } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import FormItem from "../../components/common/Form/FormItem";
import ReactForm from "../../components/common/Form/ReactForm";
import ReactInput from "../../components/common/Form/ReactInput";
import Images from "../../themes/Images";
import OnboardingLayout from "./layout/OnboardingLayout";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter username"),
});
const LoginContainer = styled.div`
  height: 100%;
  /* min-height: 90vh; */
  @media (min-width: 1024px) {
    /* min-height: 80vh; */
    height:100%;
    max-height:40vh;
    /* max-height:500px; */
    /* min-height: 80vh; 500vh */
  }
  .inputButtonContainer{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    width:120px;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
display:flex;
justify-content:center;
align-items:center;
border-top-left-radius:0;
border-bottom-left-radius:0;
  }
`;
export default function Login() {
  const history = useHistory();
  const formRef = useRef();
  const [loggedInUser, setLoggedInUser] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const userList = JSON.parse(localStorage.getItem("userList"));
  const [previousLogins, setPreviousLogins] = React.useState(userList || []);
  const [avatarList, setAvatarList] = React.useState([]);
  React.useEffect(() => {
    localStorage.removeItem("user_details");
    // let userList = JSON.parse(localStorage.loggedInUser || "[]");
    // setLoggedInUser(userList); //set max of 3 last login 3 must be allowed remove this comment once added
  }, []);

  React.useEffect(() => {
    getStudentImages({});
  }, [previousLogins]);

  const [getStudentImages, { loading: loadingStudentImages }] = useLazyQuery(
    GET_STUDENT_IMAGES,
    {
      skip: previousLogins.length === 0,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: (data) => {
        let avatarMap = {};
        if (data.getStudentImages) {
          previousLogins.map((user, index) => {
            if (!user.avatar) {
              data.getStudentImages.Images.map((avatarImages) => {
                let avatar = avatarImages.student_images;
                if (avatar.url_type === "letter" && user.name.charAt(0).toUpperCase() === avatar.character) {
                  avatarMap[index] = avatar.image_url;
                  setAvatarList({
                    ...avatarMap
                  });
                }
              });
            }
          });
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  const [validateUserName, { loading: validatingUserName }] = useLazyQuery(
    VALIDATE_USERNAME,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: (data) => {
        try {
          if (data.userNameValidation && data.userNameValidation.is_available) {
            localStorage.setItem(
              "user_details",
              JSON.stringify({
                username: userName,
                isProfileImage: data.userNameValidation.isProfileImage,
                avatar: data.userNameValidation?.profile_url || "",
                bgColor: data.userNameValidation?.bg_color || "",
                new_student: data.userNameValidation.new_student,
                change_password: data.userNameValidation.ChangePasswordPage,
              })
            );
            setTimeout(() => {
              if (data.userNameValidation.ChangePasswordPage) {
                history.push("/passcode/create");
              } else if (data.userNameValidation.new_student) {
                localStorage.setItem("newStudent", true);
                if (!data.userNameValidation.isProfileImage) {
                  history.push("/pickavatar");
                } else {
                  history.push("/passcode/create");
                }
              } else {
                localStorage.setItem("newStudent", false);
                history.push("/passcode/login");
              }
            }, 100);
          } else {
            notification.error({
              message: "Username not found",
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  return (
    <OnboardingLayout loading={validatingUserName || loadingStudentImages}>
      <div className="flex justify-between h-9 items-center">
        {/* <BackButton /> */}
        <div className=" absolute left-0 right-0 text-center">
          <img src={Images.Logo} alt="logo" className="mx-auto w-44 lg:w-52 " />
        </div>
      </div>
      <LoginContainer className="flex lg:justify-evenly flex-col">
        <div>
          <div
            className={`mt-8 flex flex-col ${previousLogins?.length ? `justify-between` : `justify-center`
              }`}
          >
            <div className=" font-Nunito font-bold text-textDark  text-2xl lg:text-4xl text-center select-none">
              Log into your account
            </div>
            <div className="font-Nunito text-base lg:text-lg text-center mt-8 select-none">
              {`${previousLogins?.length
                ? "You can tap on your avatar or enter your username"
                : ""
                }`}
            </div>
            {previousLogins?.length !== 0 && (
              <div
                className={`grid grid-cols-${previousLogins.length} gap-5 mt-12`}
              >
                {previousLogins.map((user, userIndex) => {
                  return (
                    <div
                      key={`user-${userIndex}`}
                      className="flex flex-col items-center cursor-pointer"
                      onClick={() => {
                        formRef.current.setFieldValue("username", user.name);
                        setUserName(user.name);
                        setTimeout(() => {
                          formRef.current.handleSubmit();
                        }, 100);
                      }}
                    >
                      <div
                        className={`md:h-24 md:w-24 w-20 h-20 rounded-full relative m-auto flex items-center justify-center`}
                        style={{
                          backgroundColor: user.bgColor || "whitesmoke",
                        }}
                      >
                        <img
                          src={user.avatar || avatarList[userIndex]}
                          alt="avatar"
                          //className={` ${user.bgColor ? "w-16 h-16" : ""} `}
                        />
                      </div>
                      <span className="mt-3 ">{user.name}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div>
          {/* <div className="block">
            <div
              className={`mt-7 grid grid-cols-${loggedInUser.length} gap-9 px-16 `}
            >
              {loggedInUser.map((user, userIndex) => {
                return (
                  <div className="text-center" key={`User-${userIndex}`}>
                    <div className="h-24 w-24 rounded-full bg-gray-50 m-auto"></div>
                    <div className="pt-5 font-normal text-xl">{user.name}</div>
                  </div>
                );
              })}
            </div>
          </div> */}
          <div className=" px-0 md:px-10 lg:px-16 pt-9">
            <Formik
              innerRef={formRef}
              initialValues={{
                username: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values.username);
                validateUserName({
                  variables: {
                    name: values.username,
                  },
                });
              }}
            >
              <ReactForm layout="vertical">
                <div style={{ position: 'relative' }}>
                  <FormItem name="username" >
                    <ReactInput
                      name="username"
                      placeholder={`${previousLogins?.length ? "Or enter" : "Enter"
                        } your username`}
                      className={"w-full py-4 px-5 pr-32"}
                      onChange={(evt) => {
                        setUserName(evt.target.value);
                      }}
                    ></ReactInput>
                    <Button
                      onClick={() => {
                        if (userName !== "") {
                          validateUserName({
                            variables: {
                              name: userName,
                            },
                          });
                        }
                      }}
                      className={`inputButtonContainer ${userName !== "" ? `` : `opacity-50 pointer-events-none`
                        }`}
                      primary
                    >
                      Let's go
                    </Button>                </FormItem>
                </div>
              </ReactForm>
            </Formik>
          </div>
        </div>

        {/* <div>
          <div
            className={`block text-center mt-12 lg:mt-6 w-52 lg:w-56 mx-auto ${
              userName !== "" ? `` : `opacity-50 pointer-events-none`
            }`}
          >
            <Button
              onClick={() => {
                if (userName !== "") {
                  validateUserName({
                    variables: {
                      name: userName,
                    },
                  });
                }
              }}
              primary
            >
              Let's go
            </Button>
          </div>
        </div> */}
      </LoginContainer>
      <div className={`block text-center mt-12 lg:mt-6 w-52 lg:w-56 mx-auto `}>
        <div className="mb-4 text-xl ">Don't have an account?</div>
        <div>
          <Button
            secondary
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_APP}signup`,
                "_blank"
              );
            }}
          >
            Create a new one
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
}
