import { useQuery } from "@apollo/client";
import { notification, Tabs } from "antd";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { GET_WISHLIST } from "../../client/graphql";
import ExpertCard from "../../components/Cards/ExpertCard";
import ImageCard from "../../components/Cards/ImageCard";
import PlaylistCard from "../../components/Cards/PlaylistCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import { TabContainer } from "../../components/Style/TabContainer";
import Images from "../../themes/Images";

const { TabPane } = Tabs;

const Constants = {
  EXPERT_ROLE: 4,
  CURATOR_ROLE: 5,
};

const CollectionListing = ({ loading, cardsList, onClick }) => {
  const [showAllCards, setshowAllCards] = React.useState(false);
  const history = useHistory();

  return (
    <>
      <div className="grid grid-cols-4 gap-x-5 gap-y-1 mt-6">
        {cardsList &&
          loading &&
          [{}, {}, {}, {}].map((card, cardIndex) => {
            return (
              <div>
                <ImageCard
                  title={``}
                  coverImage={
                    card.thumb_images && card.thumb_images.length
                      ? card.thumb_images[0].image_url
                      : undefined
                  }
                  status={
                    card.activity_status
                      ? card.activity_status
                      : card.card_status
                  }
                  cardType={
                    card.default_activities_id === 2 ||
                      card.default_activities_id === 3
                      ? "watch"
                      : card.default_activities_id === 4
                        ? "do"
                        : "watch"
                  }
                  isWishlist={card.is_wishlist}
                  difficultyLevel={card.difficulty}
                  timeLevel={``}
                  videoRefIcon={card.video_link || card.url}
                  stepsRefIcon={card.steps}
                  imgRefIcon={card.activity_reference_images}
                  pdfRefIcon={card.pdf_url}
                  loading
                />
              </div>
            );
          })}
        {cardsList &&
          cardsList.map((card, cardIndex) => {
            if (cardIndex < 4 || showAllCards)
              return (
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    //history.push(`/collections/cards/${card.id}`);
                    if (onClick) onClick(card);
                  }}
                >
                  <ImageCard
                    title={card.name || `Card ${cardIndex + 1}`}
                    coverImage={
                      card.thumb_images && card.thumb_images.length
                        ? card.thumb_images[0].image_url
                        : undefined
                    }
                    status={
                      card.activity_status
                        ? card.activity_status
                        : card.card_status
                    }
                    cardType={
                      card.default_activities_id === 2 ||
                        card.default_activities_id === 3
                        ? "watch"
                        : card.default_activities_id === 4
                          ? "do"
                          : "watch"
                    }
                    isWishlist={card.is_wishlist}
                    difficultyLevel={card.difficulty}
                    timeLevel={card.time ? `${card.time} mins` : ""}
                    videoRefIcon={card.video_link || card.url}
                    stepsRefIcon={card.steps}
                    imgRefIcon={card.activity_reference_images}
                    pdfRefIcon={card.pdf_url}
                  />
                </div>
              );
          })}
      </div>
      {cardsList && cardsList.length > 4 && !showAllCards && (
        <div className="mt-5 pb-10">
          <div className=" w-32 mx-auto  cursor-pointer">
            <Button
              secondaryGhost
              onClick={() => {
                setshowAllCards(true);
              }}
            >
              {`+ ${cardsList.length - 4} more`}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
const WishlistEmptyState = ({ data }) => {
  return (
    <div className="text-center mt-10">
      <img
        src={Images.WishlistEmptyState}
        alt="Wishlist Empty State"
        className="mx-auto"
      />
      <div className=" mt-10 nunito-normal-lg">{data}</div>
    </div>
  );
};

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Mywishlist() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState("1");
  const [cardsList, setcardsList] = React.useState([]);
  const [playlist, setPlaylist] = React.useState([]);
  const [expertPlaylist, setExpertPlayList] = React.useState([]);
  const [exportCards, setExportCards] = React.useState([]);
  const [collectionLoading, setcollectionLoading] = React.useState(false);

  const onPlayListOpen = useCallback((playlistId, userRole) => {
    console.log(playlistId, userRole);
    if (
      userRole === Constants.EXPERT_ROLE ||
      userRole === Constants.CURATOR_ROLE
    ) {
      history.push(`/playlists/expert/${playlistId}`);
    } else {
      history.push(`/playlists/${playlistId}`);
    }
  }, []);

  const { loading: loadingWishList } = useQuery(GET_WISHLIST, {
    fetchPolicy: "network-only",
    variables: {
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
    },
    onCompleted: (response) => {
      if (response.IpadWishListV2) {
        if (response.IpadWishListV2.cards) {
          setcardsList(response.IpadWishListV2.cards || []);
          setPlaylist(response.IpadWishListV2.playlist || []);
          setExpertPlayList(response.IpadWishListV2.expert_playlist || []);
          setExportCards(response.IpadWishListV2.exports_cards || []);
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return (
    <div>
      <Loader loading={loadingWishList} />
      <div>
        <ExploreHeading title={"My Wishlist"}></ExploreHeading>
        {cardsList.length === 0 &&
          exportCards.length === 0 &&
          playlist.length === 0 &&
          expertPlaylist.length === 0 &&
          !loadingWishList && (
            <WishlistEmptyState data="Your wishlisted cards and learning paths will appear here" />
          )}
        {(cardsList.length !== 0 ||
          exportCards.length !== 0 ||
          playlist.length !== 0 ||
          expertPlaylist.length !== 0) &&
          !loadingWishList && (
            <>
              <TabContainer>
                <Tabs
                  defaultActiveKey="1"
                  onChange={(key) => {
                    setSelectedTab(key);
                  }}
                  className=" -ml-5 px-20"
                >
                  <TabPane
                    tab={`Cards (${cardsList.length + exportCards.length})`}
                    key="1"
                  ></TabPane>
                  <TabPane
                    tab={`One by One (${playlist.length + expertPlaylist.length
                      })`}
                    key="2"
                  ></TabPane>
                </Tabs>
              </TabContainer>
              <div className="px-16">
                {selectedTab === "1" && (
                  <>
                    <CollectionListing
                      cardsList={cardsList}
                      loading={false}
                      onClick={(card) => {
                        history.push(`/collections/cards/${card.id}`);
                      }}
                    ></CollectionListing>
                    {!loadingWishList &&
                      cardsList.length === 0 &&
                      exportCards.length === 0 && (
                        <WishlistEmptyState data="Your wishlisted cards will appear here" />
                      )}
                    {exportCards.length !== 0 && (
                      <div className="mt-4">
                        <div className="nunito-bold-xl text-textDark">
                          Experts and curator cards
                        </div>
                        <CollectionListing
                          cardsList={exportCards}
                          loading={false}
                          onClick={(card) => {
                            history.push(
                              `/collections/expert/cards/${card.id}`
                            );
                          }}
                        ></CollectionListing>
                      </div>
                    )}
                  </>
                )}
                {selectedTab === "2" && (
                  <>
                    <div className="flex flex-wrap">
                      {playlist.map((playlists, playlistIndex) => {
                        return (
                          <div className="mr-5 mb-5">
                            <PlaylistCard
                              data={playlists}
                              isPlaylist
                              key={`Playlist-${playlistIndex}`}
                              loading={false}
                              onClick={() => {
                                history.push(`/playlists/${playlists.id}`);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {!loadingWishList &&
                      playlist.length === 0 &&
                      expertPlaylist.length === 0 && (
                        <WishlistEmptyState data="Your wishlisted learning paths will appear here" />
                      )}
                    {expertPlaylist.length !== 0 && (
                      <div>
                        <div className="nunito-bold-xl text-textDark mt-4 mb-4">
                          Experts and curator learning paths
                        </div>
                        <div className="grid grid-cols-3 gap-x-5 gap-y-5 mt-6">
                          {expertPlaylist.map((expert, expertIndex) => {
                            return (
                              <ExpertCard
                                data={expert}
                                key={`expert-${expertIndex}`}
                                isPlaylist
                                loading={false}
                                onClick={() => {
                                  onPlayListOpen(
                                    expert.id,
                                    expert?.user?.role_id
                                  );
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
}
