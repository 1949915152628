// import "antd/dist/antd.css";
import React, { useState } from "react";
import NavContext from "./context/NavContext";
// import DarkTheme from "./themes/dark.js";
// import LightTheme from "./themes/default.js";
// import "./themes/css/default.css";
// import "./themes/css/darkVar.css";
import "./index.css";
import Routes from "./routes";
import Images from "./themes/Images";
import { deleteCookie, getCookie } from "./utils";

const LightTheme = React.lazy(() => import("./themes/default.js"));
const DarkTheme = React.lazy(() => import("./themes/dark.js"));
let TYPE_OF_THEME = {
  DEFAULT: "DEFAULT",
  LIGHT_MODE: "LIGHT_MODE",
  DARK_MODE: "DARK_MODE",
};

const ThemeSelector = ({ children, userInfo }) => {
  return (
    <>
      <React.Suspense fallback={<>
      </>}>
        {((userInfo.mode || "LIGHT_MODE") === TYPE_OF_THEME.LIGHT_MODE ||
          !localStorage.token) && <LightTheme />}
        {(userInfo.mode || "LIGHT_MODE") === TYPE_OF_THEME.DARK_MODE && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

function App() {
  const [userInfo, setUserInfo] = useState({});
  React.useEffect(() => {

    if (getCookie('Token')) {
      let token = getCookie('Token');
      deleteCookie("Token")
      localStorage.setItem("token", token)
      setTimeout(() => {
        window?.location?.reload()
      }, 10);
    }
  }, [])

  return (
    <div className="App Container">
      <img
        src={Images.InternetState}
        alt="Internet State"
        className=" invisible absolute -z-1 w-0 h-0"
      />
      <NavContext.Provider value={{ userInfo, setUserInfo }}>
        <ThemeSelector userInfo={userInfo}>
          <Routes />
        </ThemeSelector>
      </NavContext.Provider>
    </div>
  );
}

export default App;
