import React from "react";

import { Field, getIn } from "formik";
import { Form } from "antd";

export default function FormItem({
  name,
  validate,
  showInitialErrorAfterTouched,
  showValidateSuccess,
  children,
  ...restProps
}) {
  return (
    <Field name={name} validate={validate}>
      {({ meta, form: { errors = {}, touched = {}, initialErrors = {} } }) => {
        const error = meta.error;
        const initialError = getIn(initialErrors, name, undefined);
        const isTouched = meta.touched;
        const hasError = error !== undefined && isTouched;
        const hasInitialError = initialError !== undefined;
        const isValid = !error && isTouched;
        const showHelp =
          hasError ||
          (hasInitialError && (!isTouched || showInitialErrorAfterTouched));

        return (
          <Form.Item
            name={name}
            validateStatus={
              hasError || (hasInitialError && !isTouched) ? "error" : undefined
            }
            //hasFeedback={isValid}
            help={
              showHelp && (
                <>
                  <div>
                    {isTouched && error ? (
                      <div className="mt-1 text-xs text-left text-liteRed capitalize">
                        {error}
                      </div>
                    ) : null}
                  </div>
                  {hasInitialError &&
                    (!isTouched || showInitialErrorAfterTouched) && (
                      <li>{initialError}</li>
                    )}
                </>
              )
            }
            {...restProps}
          >
            {children}
          </Form.Item>
        );
      }}
    </Field>
  );
}
