import { Modal, notification } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../../../../components/common/Form/Button";
import Images from "../../../../themes/Images";

const MaterialContainer = styled.div`
  .active {
    background: linear-gradient(
      0deg,
      rgba(62, 193, 240, 0.7),
      rgba(62, 193, 240, 0.7)
    );
  }
`;

export default function MaterialModal(props) {
  const [materialData, setMaterialData] = React.useState([]);
  const [isAllMaterialSelected, setAllMaterialSelected] = React.useState(false);
  useEffect(() => {
    // console.log(props.data);
    if (props.data) {
      setMaterialData(props.data);
    }
  }, [props.data]);
  useEffect(() => {
    let unchecked = materialData.filter((md) => !md.isActive);
    setAllMaterialSelected(unchecked.length);
  }, [materialData]);
  return (
    <div>
      <Modal
        width={504}
        title={null}
        visible={props.visible}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          props && props.onCancel();
        }}
      >
        <div className=" px-3 ">
          <div className="mt-2 text-center nunito-bold-lg text-textDark">
            Materials required checklist
          </div>
          <div className="mt-4 text-center text-lightGray">
            {`Make sure you have all of these and then check them off`}
          </div>
          <MaterialContainer className=" grid grid-cols-4 gap-x-5 mt-6 justify-center ">
            {materialData.map((material, materialIndex) => {
              return (
                <div
                  //className={` ${
                  //  materialIndex && materialIndex % 3 === 0 ? "" : "mr-7"
                  //} w-20   ${material.isActive ? "active" : ""}`}
                  className=""
                  key={`Material-${materialIndex}`}
                  onClick={() => {
                    setMaterialData((md) => {
                      return md.map((material, materialTempindex) => {
                        let isActive = material.isActive || false;
                        if (materialTempindex === materialIndex) {
                          isActive = !material.isActive;
                        }
                        return { ...material, isActive };
                      });
                    });
                  }}
                >
                  <div className="relative w-20 h-20 rounded bg-black bg-opacity-30 shadow-7xl cursor-pointer ">
                    {material.isActive && (
                      <div className="absolute inset-0 flex justify-center items-center active rounded">
                        <img src={Images.WhiteCheckedIcon} alt="Tick Icon" />
                      </div>
                    )}
                    <img
                      src={material.image}
                      alt="Material img"
                      className=" w-full h-full object-cover"
                    />
                  </div>
                  <div className="h9 my-1 text-center w-20">
                    {material.name}
                  </div>
                </div>
              );
            })}
          </MaterialContainer>
          <div className="mt-6 flex items-center justify-center">
            <div className=" w-40 mr-4">
              <Button
                className="font-Nunito text-lightGray border border-lightGray px-3  py-2 flex text-base font-bold justify-center"
                onClick={() => {
                  if (props.onCancel) props.onCancel();
                  // AddToWishlist();
                }}
              >
                Cancel
              </Button>
            </div>

            <div className=" w-40 ">
              <Button
                disabled={isAllMaterialSelected}
                secondary
                className="font-Nunito text-base px-6  py-2 flex font-bold text-center justify-center"
                onClick={() => {
                  let unchecked = materialData.filter((md) => !md.isActive);
                  if (unchecked.length) {
                    notification.warning({
                      message: "All Materials should be selected",
                    });
                    return;
                  }
                  if (props.onContinue) {
                    props.onContinue();
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
