import { useLazyQuery, useQuery } from "@apollo/client";
import { notification, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Board from "react-trello";
import styled from "styled-components";
import {
  GET_KANBAN_LIST,
  REMOVE_TO_SCHEDULE_BY_ACTIVITY,
  SCHEDULE_STATE_CHANGE
} from "../../client/graphql";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loading from "../../components/common/Loader";
import { BackBlueDualIcon, WishlistBlueDualIcon } from "../../themes/dualImages";
import Images from "../../themes/Images";
const { Paragraph } = Typography;

const ScheduleContainer = styled.div`
  .react-trello-board {
    background: rgb(var(--eerieBlack));
    padding: 20px;
    padding-top: 0;
    height: initial;
    justify-content:center;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
    justify-content: center;
    min-height: 300px;
    height: calc(100vh - 60px - 68px);
  }
  .smooth-dnd-container.vertical {
    height: 100%;
  }
  section.react-trello-lane > div {
    width: 100%;
  }
  .scheduleTitle {
    grid-template-columns: repeat(3, minmax(0, 373px)) !important;
    grid-gap: 17px;
    & .min-h {
      overflow: scroll;
      max-height: 100%;
      /* min-height: 300px; */
      height: calc(100vh - 160px - 20px - 6rem);
    }
    &.heading {
      color: rgb(var(--textDark));
    }
  }
`;
const BackgroundContainer = styled.div`
  background: rgb(var(--lightWhite));
  div.buttonPulse {
    position: relative;
    &::after {
      top: 0.75rem;
      /* left: 50%; */
      animation: pulse 1.2s ease infinite;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      z-index: -1;
      background: rgba(var(--blue),0.7);
      content: "";
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }

    50% {
      opacity: 3.5;
    }

    100% {
      transform: scale(6.5);
      opacity: 0;
    }
  }
`;
const ExploreCircleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  /* min-height: 500px; */
  .large {
    position: absolute;
    width: 40px;
    height: 40px;
    background: rgba(var(--blue),0.5);
    /* opacity: 0.2; */
    border-radius: 100%;
  }
  .small {
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(var(--blue),0.8);
    /* opacity: 0.2; */
    border-radius: 100%;
  }
  .large,
  .small {
    animation-name: fade-bg;
    animation-delay: 0;
    animation-direction: forward;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  @keyframes fade-bg {
    0% {
      transform: scale(2);
    }
    50% {
      transform: scale(5.9);
    }
    99% {
      transform: scale(6);
      opacity: 0.2;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
`;
const LearningContainer = styled.div`
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-shadow: 0px 1px 3px rgba(196, 196, 196, 0.1),
    0px 1px 2px rgba(196, 196, 196, 0.1);
  border-radius: 4px;
`;

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Schedule() {
  const [data, setData] = useState({ lanes: [] });
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      let reactLane = document.querySelectorAll("section.react-trello-lane");
      reactLane.forEach(element => {
        element.setAttribute('title', "")
      });
    }, 2000);
  }, [data]);

  const {
    data: kanbanListData,
    loading: loadingKanbanList,
    refetch: refetchKanbanList,
  } = useQuery(GET_KANBAN_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
    },
    onCompleted: (response) => {
      if (response.getKanbanListV3) {
        let lanesData = [];
        lanesData.push({
          id: "lane1",
          title: (
            <div className=" mb-7 h7 color-yellowDark whitespace-normal  ">
              LEARNING CARDS I’VE ADDED TO MY SCHEDULE
            </div>
          ),
          titleStyle: { width: "100%" },
          // label: "2/2",
          cardStyle: { width: "100%" },
          style: { backgroundColor: "#FEFBF1", padding: 15, width: 373 },
          laneStyle: { width: "100%" },
          cards: response.getKanbanListV3.i_want_to_learn.map((wantToLearn) => {
            return {
              ...wantToLearn,
              isReverse: false,
              ...(wantToLearn.playlist_cards?.length
                ? { isPlaylist: true }
                : { isPlaylist: false }),
              isSchedule: true,
              moveToToday: true,
              lane: 1,
              state: 2,
            };
          }),
        });
        lanesData.push({
          id: "lane2",
          title: (
            <div className=" mb-7 h7 color-yellowDark  uppercase whitespace-normal">
              LEARNING CARDS I’m WORKING ON TODAY
            </div>
          ),
          titleStyle: { width: "100%" },
          style: { backgroundColor: "#F2FBF3", padding: 15, width: 373 },
          cards: response.getKanbanListV3.totay_learn.map((todayLearn) => {
            return {
              ...todayLearn,
              isReverse: true,
              ...(todayLearn.playlist_cards?.length
                ? { isPlaylist: true }
                : { isPlaylist: false }),
              isSchedule: false,
              lane: 2,
              moveToToday: true,
              ...(todayLearn.activity_status === "Scheduled" ||
                todayLearn.playlist_status === "Scheduled"
                ? { state: 1 }
                : { state: 2 }),
              draggable:
                todayLearn.activity_status === "Scheduled" ||
                  todayLearn.playlist_status === "Scheduled"
                  ? true
                  : false,
              onClick: () => {
                if (todayLearn.playlist_cards?.length) {
                  history.push(`/playlists/${todayLearn.id}`);
                } else {
                  history.push(`/collections/cards/${todayLearn.id}`);
                }
              },
              isLearn: true,
            };
          }),
        });
        lanesData.push({
          id: "lane3",
          title: (
            <div className=" mb-7 h7 color-yellowDark uppercase whitespace-normal ">
              Learning cards i've worked on before, but i'm yet to complete
            </div>
          ),
          titleStyle: { width: "100%" },
          cardStyle: { height: "100%" },
          droppable: false,
          style: { backgroundColor: "#FDF7FE", padding: 15, width: 373 },
          cards: response.getKanbanListV3.yet_finish.map((yetFinish) => {
            return {
              ...yetFinish,
              isReverse: true,
              ...(yetFinish.playlist_cards?.length
                ? { isPlaylist: true }
                : { isPlaylist: false }),
              isSchedule: false,
              moveToToday: true,
              lane: 3,
              state: 2,
            };
          }),
        });
        setData({ lanes: lanesData });
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const [removeSchedule, { loading: removingSchedule }] = useLazyQuery(
    REMOVE_TO_SCHEDULE_BY_ACTIVITY,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.removeToScheduleV2) {
          if (data.removeToScheduleV2.is_updated) {
            notification.success({
              message: "Schedule Removed Successfully",
            });
            refetchKanbanList();
          }
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );
  const [scheduleStateChange, { loading: stateChanging }] = useLazyQuery(
    SCHEDULE_STATE_CHANGE,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.scheduleStateChangeV2) {
          if (data.scheduleStateChangeV2.is_updated) {
            notification.success({
              message: "Schedule Changed Successfully",
            });
            refetchKanbanList();
          }
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const LearningCard = ({
    onClick,
    isReverse,
    isPlaylist,
    isSchedule,
    isLearn,
    moveToToday,
    state,
    ...props
  }) => {
    return (
      <LearningContainer
        className={`relative mb-4 ${isLearn && `cursor-pointer`}`}
        onClick={onClick}
      >
        <div className="absolute top-0 bottom-0 left-0 flex items-center">
          {!isPlaylist &&
            (props.default_activities_id === 2 ||
              props.default_activities_id === 3) && (
              <div className=" bg-yellow h-20 w-1 rounded-r-md"></div>
            )}
          {!isPlaylist && props.default_activities_id === 4 && (
            <div className=" bg-blue h-20 w-1 rounded-r-md"></div>
          )}
        </div>
        {isPlaylist && (
          <img
            src={Images.PlaylistIcon}
            className="absolute img-fluid right-2 top-2 w-5"
            alt="wishlist"
          />
        )}
        <div className=" nunito-semibold-xs text-lightGray mb-2 ">
          <div>
            {isSchedule &&
              (props.activity_status === "Scheduled" ||
                props.playlist_status === "Scheduled") ? (
              <div
                className="flex cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (props.activity_status === "Scheduled") {
                    removeSchedule({
                      variables: {
                        activity_id: props.id,
                        playlist_id: null,
                      },
                    });
                  } else if (props.playlist_status === "Scheduled") {
                    removeSchedule({
                      variables: {
                        playlist_id: props.id,
                        activity_id: null,
                      },
                    });
                  }
                }}
              >
                <img
                  src={Images.ScheduleCloseIcon}
                  alt="Close Icon"
                  className=" mr-1 w-4"
                />
                Remove
              </div>
            ) : props.paused_time ? (
              `PAUSED - ${moment(props.paused_time).fromNow()}`
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <div className=" w-24 h-14 flex-shrink-0">
            <img
              src={
                props.activities_thumb_images
                  ? props.activities_thumb_images[0].image_url
                  : props?.libraries_thumb_images?.[0]?.background_image
                    ? props?.libraries_thumb_images?.[0]?.background_image
                    : props.background_image
                      ? props.background_image
                      : "https://via.placeholder.com/400x120.png"
              }
              alt="Card img"
              className="w-full h-full object-cover rounded"
            />
          </div>
          <div className=" break-all">
            <Paragraph
              ellipsis={{
                rows: 2,
                // expandable: false,
                // suffix: "...",
              }}
              className=" h9 text-pureBlack mb-0 whitespace-normal pr-2 "
            >
              {props.name}
            </Paragraph>
          </div>
        </div>
        {moveToToday &&
          ((props.lane === 2 &&
            (props.activity_status === "Scheduled" ||
              props.playlist_status === "Scheduled")) ||
            props.lane === 3 ||
            props.lane === 1) ? (
          <div className=" text-right mt-1">
            <div
              className={`inline-flex items-center h11 text-blue cursor-pointer justify-items-center ${isReverse ? "flex-row-reverse" : ""
                }`}
              onClick={(e) => {
                e.stopPropagation();
                scheduleStateChange({
                  variables: {
                    activity_id:
                      props.activities_thumb_images?.[0]?.activities_id,
                    playlist_id: props.id,
                    state: state,
                  },
                });
              }}
            >
              {props.lane === 2 ? "Move To Learn" : "Move To Today"}
              <div
                className={`transform ${isReverse ? "mr-2" : "rotate-180 ml-2"
                  }`}>
                <BackBlueDualIcon />
              </div>
              {/* <img
                src={Images.BackBlueIcon}
                alt="Back button"

              /> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </LearningContainer>
    );
  };

  const handleCardClick = (cardId, metadata, laneId) => {
    (laneId === "lane1"
      ? data.lanes[0]
      : laneId === "lane2"
        ? data.lanes[1]
        : data.lanes[2]
    ).cards.map((card) => {
      if (
        (card.activity_status === "Scheduled" ||
          card.activity_status === "In - Progress") &&
        card.id === cardId
      ) {
        history.push(`/collections/cards/${cardId}`);
      } else if (
        (card.playlist_status === "Scheduled" ||
          card.playlist_status === "In - Progress") &&
        card.id === cardId
      ) {
        history.push(`/playlists/${cardId}`);
      }
    });
  };

  const ModifyLaneData = (data) => {
    let dataSet = [];
    let laneData = data?.lanes?.map((lane, laneIndex) => {
      let laneCards = (lane.cards || []).map((card, cardIndex) => {
        if (card.lane !== laneIndex + 1) {
          dataSet.push({ ...card });
          card.lane = laneIndex + 1;
          card.isSchedule = laneIndex + 1 === 1 ? true : false;
          card.moveToToday = laneIndex + 1 === 2 ? false : true;
          card.draggable = laneIndex + 1 === 2 ? false : true;
          card.isReverse = laneIndex + 1 === 3 ? true : false;
        }
        return card;
      });
      lane.cards = laneCards;
      return lane;
    });
    if (dataSet.length) {
      setData({ lanes: laneData });
      (dataSet || []).map((dataLane) => {
        let laneDataVal;
        if (dataLane.lane === 1) {
          laneDataVal = 2;
        } else if (dataLane.lane === 2) {
          if (
            dataLane.activity_status === "Scheduled" ||
            dataLane.playlist_status === "Scheduled"
          ) {
            laneDataVal = 1;
          } else {
            laneDataVal = 2;
          }
        } else if (dataLane.lane === 3) {
          laneDataVal = 2;
        }
        scheduleStateChange({
          variables: {
            activity_id: dataLane.activities_thumb_images?.[0]?.activities_id,
            playlist_id: dataLane.id,
            state: laneDataVal,
          },
        });
      });
    }
  };
  return (
    <div>
      <Loading
        loading={loadingKanbanList || removingSchedule || stateChanging}
      />
      <BackgroundContainer>
        <ExploreHeading
          isBack={false}
          rightChildren={
            <div className="w-full text-right flex justify-end">
              <div className=" w-182">
                <Button
                  secondaryGhost
                  className="font-Nunito text-base px-8  py-2 text-center flex font-normal justify-center items-center"
                  onClick={() => {
                    history.push("/mywishlist");
                  }}
                >
                  <div className="mr-2">
                    <WishlistBlueDualIcon />
                  </div>
                  {/* <img
                    src={Images.WishlistBlueIcon}
                    alt="Wishlist Blue Icon"
                    className="mr-2"
                  /> */}
                  My Wishlist
                </Button>
              </div>
            </div>
          }
        />
        <div className="container px-10 mx-auto ">
          <ScheduleContainer className="bg-eerieBlack relative ">
            <div className="flex justify-center text-left">

              <div className="grid h3 scheduleTitle heading px-5 pt-5 pb-2 ">
                <div className="">I want to learn</div>
                <div className="">Today, I'm learning</div>
                <div className="">I'm yet to finish</div>
              </div>
            </div>
            <div className="flex justify-center text-left">

              <div className="grid h3 scheduleTitle  absolute top-24 px-5 text-left mt-10 pb-2 ">
                {data.lanes[0]?.cards?.length === 0 ||
                  data.lanes[0]?.cards?.length === undefined ? (
                  <div className="text-black min-h z-10">
                    <img
                      src={Images.ScheduleLearnEmpty}
                      alt="Learn Schedule Empty"
                      className="mx-auto"
                    />
                    <div className=" w-10/12 mx-auto mt-14 h6 text-liteBlack text-center pb-16 ">
                      Where would you like to start?
                      <div className=" w-32 mx-auto mt-6 relative">
                        {/* <div className="relative">
                        <div className=" left-16 top-6"></div>
                      </div> */}
                        <ExploreCircleContainer>
                          <div className="large"></div>
                          <div className="small"></div>
                        </ExploreCircleContainer>
                        <Button
                          size="middle"
                          secondary
                          className="font-Nunito text-lg text-pureWhite px-6  py-2 flex font-bold text-center justify-center "
                          onClick={() => {
                            history.push("/dashboard");
                          }}
                        >
                          Explore
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {data.lanes[1]?.cards?.length === 0 ||
                  data.lanes[1]?.cards?.length === undefined ? (
                  <div className="text-black  min-h z-10">
                    <img
                      src={Images.ScheduleLearningEmpty}
                      alt="Learn Schedule Empty"
                      className="mx-auto"
                    />
                    <div className=" w-10/12 mx-auto mt-14 h6 text-liteBlack text-center mb-10">
                      Long press on cards you want to learn today and drag them
                      here
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {data.lanes[2]?.cards?.length === 0 ||
                  data.lanes[2]?.cards?.length === undefined ? (
                  <div className="text-black  min-h z-10 ">
                    <img
                      src={Images.ScheduleFinishEmpty}
                      alt="Learn Schedule Empty"
                      className="mx-auto"
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <Board
              components={{
                Card: LearningCard,
              }}
              data={data}
              laneDragClass="w-100"
              laneStyle={{ width: "100%" }}
              cardDragClass={{ width: "100%" }}
              cardDropClass={{ width: "100%" }}
              onDataChange={(data) => {
                ModifyLaneData(data);
              }}
              onCardClick={handleCardClick}
              laneDraggable={false}
            />
          </ScheduleContainer>
        </div>
      </BackgroundContainer>
    </div>
  );
}
