import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import styled, { keyframes } from "styled-components";
const fadeDownPath1 = keyframes`
0%,100%{
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
15%{
  opacity: 0;
  -webkit-transform: none;
  transform: none;
}
20% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
50% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
`;
const fadeDownPath2 = keyframes`
0%,100%{
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
15%{
  opacity: 0;
  -webkit-transform: none;
  transform: none;
}
30% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
60% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
`;
const fadeDownPath3 = keyframes`
0%,100%{
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
15%{
  opacity: 0;
  -webkit-transform: none;
  transform: none;
}
40% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
70% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
`;
const fadeDownPath4 = keyframes`
0%,100%{
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
15%{
  opacity: 0;
  -webkit-transform: none;
  transform: none;
}
45% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
75% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
`;
const LoadingAnimation = styled.div`
  svg path {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: linear;

    animation-iteration-count: infinite;
  }
  svg path:nth-child(2) {
    /* animation-duration: 1s; */
    -webkit-animation-name: ${fadeDownPath1};
    animation-name: ${fadeDownPath1};
  }
  svg path:nth-child(3) {
    -webkit-animation-name: ${fadeDownPath2};
    animation-name: ${fadeDownPath2};
  }
  svg path:nth-child(5) {
    /* animation-delay: 1.5s; */
    -webkit-animation-name: ${fadeDownPath3};
    animation-name: ${fadeDownPath3};
  }
  svg path:nth-child(4) {
    /* animation-delay: 1s; */
    -webkit-animation-name: ${fadeDownPath4};
    animation-name: ${fadeDownPath4};
  }
  .loader{

    display:flex;
    height:100vh;
    background: rgba(var(--black),0.5);
    position:fixed;
    inset:0;
    align-items:center;
    justify-content:center;
    z-index:9999999;

  }
`;

export default function Loader(props) {
  const loading = props.loading ? props.loading : false;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;
  React.useEffect(() => {
    if (loading) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [loading]);
  return (
    <LoadingAnimation>
      {loading && (
        <div className="loader">
          {/* <Spin indicator={antIcon}></Spin> */}
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="100" height="100" fill="white" />
            <path
              d="M81.9355 61.6798H24V85.0197H58.6275C71.4991 85.0197 81.9355 74.569 81.9355 61.6798Z"
              fill="#EC1A58"
            />
            <path
              d="M58.6275 38.3398H47.308V61.6797H81.9356C81.9356 48.7905 71.4991 38.3398 58.6275 38.3398Z"
              fill={`rgb(var(--blue))`}
            />
            <path d="M47.308 15H24V38.3399H47.308V15Z" fill="#FFC90D" />
            <path
              d="M47.308 38.3398H24C24 51.229 34.4364 61.6797 47.308 61.6797V38.3398Z"
              fill="#5432BA"
            />
          </svg>
        </div>
      )}
    </LoadingAnimation>
  );
}
