import { RightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Avatar, Modal, notification, Popover, Tabs } from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GET_DATA_POINTS,
  GET_OVERALL_PROGRESS,
  GET_STUDENT_GRAPH,
  GET_STUDENT_WEEKLY_PROGRESS
} from "../../client/graphql";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import Images, { DefaultAvatar } from "../../themes/Images";
import { avatarMap } from "../../utils";
import ChangePasscode from "./changePasscode";
import ReBarChart from "./components/BarChart";
import MyInterest from "./components/MyInterest";
import Radar from "./components/RadarChart";
import Legend from "./components/RadarChart/chartLegend";
import ReportsChart from "./components/ReCharts";
import WeekPicker from "./components/WeekPicker";
import EditProfile from "./editProfile";
const { TabPane } = Tabs;

const TabContainer = styled.div`
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: rgb(var(--blue));
    }
    .ant-tabs-tab-btn {
      font-family: Nunito;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #828282;
    }
  }
  .ant-tabs-ink-bar {
    background: transparent;
    min-height: 4px;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      content: "";
      width: 70%;
      height: 4px;
      background: rgb(var(--blue));
      border-radius: 2px;
    }
  }
`;
const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  border-radius: 16px;
  .blue {
    background: #eefaff;
    color: rgb(var(--blue));
  }
  .red {
    background: #fff4f8;
    color: #ec1a58;
    &.titleBlock {
      color: #ec1a58;
      background: transparent;
    }
  }
  .yellow {
    background: #fffae9;
    color: #ffc90d;
  }
  .green {
    background: #ecffee;
    color: #7bca1c;
    &.titleBlock {
      color: #7bca1c;
      background: transparent;
    }
  }
  .orange {
    color: #f5a623;
    background: #fff7e9;
    &.titleBlock {
      color: #f5a623;
      background: transparent;
    }
  }
`;
const LearningContentCard = ({ count, title, className }) => {
  return (
    <CardContainer className=" pt-7 pb-5 flex items-center justify-center flex-col ">
      <div
        className={` w-24 h-24 text h3 rounded-full flex items-center justify-center ${className || ""
          }`}
      >
        {count}
      </div>
      <div className="mt-6 nunito-bold-xl text-black">{title}</div>
    </CardContainer>
  );
};

const DataPointChart = ({ title, data, onClick }) => {
  return (
    <div>
      <div className="flex items-center">
        <div className="nunito-bold-2xl mr-5">{title}</div>
        <Button
          secondaryGhost
          className="font-Nunito text-sm px-6  py-1 text-center flex font-normal justify-center items-center"
          onClick={() => {
            onClick();
          }}
        >
          View more info
        </Button>{" "}
      </div>
      <div className="flex px-9 pt-10">
        <div className=" w-1/2 bg-white">
          <Radar data={data.data} captions={data.captions} />
        </div>
        <div className=" w-1/2 bg-white">
          <Legend data={[]} studentCount={150} />
        </div>
      </div>
    </div>
  );
};

/*const BloomsTaxonomy = {
  data: [
    {
      data: {
        Analyse: 0.8571428571428571,
        Understand: 1,
        Apply: 1,
        Create: 0.5714285714285714,
        Remember: 0.42857142857142855,
        Evaluate: 0.42857142857142855,
        Music: 0.14285714285714285,
      },
    },
    {
      data: {
        Analyse: 0.14285714285714285,
        Understand: 0.14285714285714285,
        Apply: 0.2857142857142857,
        Create: 0.14285714285714285,
        Remember: 0,
        Evaluate: 0,
        Music: 0,
      },
    },
  ],
  captions: {
    Analyse: "Analyse",
    Understand: "Understand",
    Apply: "Apply",
    Create: "Create",
    Remember: "Remember",
    Evaluate: "Evaluate",
    Music: "Music",
  },
};*/

export default function Profile() {
  const history = useHistory();
  const user_details = useMemo(
    () => { return JSON.parse(localStorage.getItem("user_details")) },
    [localStorage.getItem("user_details")]
  );
  const userName = user_details?.username;
  const avatar = user_details?.avatar;
  const bg_color = user_details?.bgColor;
  const [interestList, setInterestList] = useState([]);
  const [showDataPoints, setShowDataPoints] = useState(false);
  const [weekData, setWeekData] = useState({
    start_date: moment().startOf("week").format("YYYY-MM-DD"),
    end_date: moment().endOf("week").format("YYYY-MM-DD"),
    week: moment().format("YYYY-wo"),
  });
  const [studentDetails, setStudentDetails] = useState([]);
  const [completedLearningContents, setCompletedLearningContents] = useState(
    {}
  );
  const [totalHoursSpent, setTotalHoursSpent] = useState({});
  const [difficulyLevel, setDifficultyLevel] = useState({});
  const [weeklyData, setWeeklyData] = useState({});
  const [averageTime, setAverageTime] = useState("");
  const [bloomsTaxonomy, setBloomsTaxonomy] = useState({
    data: {},
    captions: {},
  });
  const [multipleIntelligence, setMultipleIntelligence] = useState({
    data: {},
    captions: {},
  });
  const [learningAndInnovativeSkills, setLearningAndInnovativeSkills] =
    useState({ data: {}, captions: {} });
  const [dataPointSet, setDataPointSet] = useState({});
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showChangePasscode, setShowChangePasscode] = useState(false);
  const [showParentSetup, setShowParentSetup] = useState(false);
  React.useEffect(() => {
    if (!user_details?.isParentAdded && user_details?.Onboarded_days <= 1) {
      setShowParentSetup(true);
    }
  }, [user_details]);
  const { loading: loadingOverallProgress, refetch: refetchProgress } =
    useQuery(GET_OVERALL_PROGRESS, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        try {
          if (response.IpadOverallProgressNew) {
            setStudentDetails(response.IpadOverallProgressNew.student_details);
            setInterestList(
              [...response.IpadOverallProgressNew.InterestCategorys].sort(
                (a, b) => a.name.localeCompare(b.name)
              )
            );
            setCompletedLearningContents(
              response.IpadOverallProgressNew.completed_learning_contents
            );
            setTotalHoursSpent(
              response.IpadOverallProgressNew.total_hours_spent
            );
            setDifficultyLevel(
              response.IpadOverallProgressNew.dificulity_level
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    });

  const { loading: loadingWeeklyProgress } = useQuery(
    GET_STUDENT_WEEKLY_PROGRESS,
    {
      fetchPolicy: "network-only",
      variables: {
        date: weekData.end_date,
      },
      onCompleted: (response) => {
        if (response.iPadStudentWeeklyProgess) {
          setWeeklyData(
            response.iPadStudentWeeklyProgess.weekly_details.map(
              (weekDetails) => {
                return {
                  name: weekDetails.day.substr(0, 3),
                  cards: weekDetails.cards_count,
                };
              }
            )
          );
          let total = 0;
          response.iPadStudentWeeklyProgess.weekly_details.map(
            (weekDetails) => {
              total = total + weekDetails.time_spent;
            }
          );
          let seconds = total / 7;
          const formatted = moment.utc(seconds * 1000).format("HH:mm:ss");
          let [hours, mins, secs] = formatted.trim().split(":");
          if (hours > 0) {
            setAverageTime(hours + " hours" + mins + " mins");
          } else if (mins > 0) {
            setAverageTime(mins + " mins");
          } else {
            setAverageTime(secs + " secs");
          }
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  const { loading: studentGraphLoading } = useQuery(GET_STUDENT_GRAPH, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response.StudentGraph) {
        if (response.StudentGraph.graph_data) {
          //Blooms Taxonomy
          let bloomsCaptionsObj = {},
            bloomsDataArray = [],
            bloomsPrevMonthObj = { data: {} },
            bloomsCurrMonthObj = { data: {} },
            captions = [],
            bloomsPrevMaxVal,
            bloomsCurrMaxVal;
          response.StudentGraph.graph_data[0].data_points.map((dataPoints) => {
            captions.push(dataPoints);
            bloomsCaptionsObj[`${dataPoints}`] = dataPoints;
          });
          bloomsPrevMaxVal = Math.max(
            ...response.StudentGraph.graph_data[0].values_previous_month
          );
          bloomsCurrMaxVal = Math.max(
            ...response.StudentGraph.graph_data[0].values_current_month
          );
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[0].values_previous_month.length;
            count++
          ) {
            if (bloomsPrevMaxVal !== 0)
              bloomsPrevMonthObj["data"][`${captions[count]}`] =
                response.StudentGraph.graph_data[0].values_previous_month[
                count
                ] / bloomsPrevMaxVal;
            else
              bloomsPrevMonthObj["data"][`${captions[count]}`] =
                response.StudentGraph.graph_data[0].values_previous_month[
                count
                ];
          }
          bloomsDataArray.push(bloomsPrevMonthObj);
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[0].values_current_month.length;
            count++
          ) {
            if (bloomsCurrMaxVal !== 0)
              bloomsCurrMonthObj["data"][`${captions[count]}`] =
                response.StudentGraph.graph_data[0].values_current_month[
                count
                ] / bloomsCurrMaxVal;
            else
              bloomsCurrMonthObj["data"][`${captions[count]}`] =
                response.StudentGraph.graph_data[0].values_current_month[count];
          }
          bloomsDataArray.push(bloomsCurrMonthObj);
          setBloomsTaxonomy({
            captions: bloomsCaptionsObj,
            data: bloomsDataArray,
          });

          //MultipleIntellegence
          let multipleIntCaptionsObj = {},
            multipleIntDataArray = [],
            multipleIntPrevMonthObj = { data: {} },
            multipleIntCurrentMontObj = { data: {} },
            multipleIntcaptions = [],
            multiplePreviousMaxVal,
            multipleCurrMaxVal;
          response.StudentGraph.graph_data[1].data_points.map((dataPoints) => {
            multipleIntcaptions.push(dataPoints);
            multipleIntCaptionsObj[`${dataPoints}`] = dataPoints;
          });
          multiplePreviousMaxVal = Math.max(
            ...response.StudentGraph.graph_data[1].values_previous_month
          );
          multipleCurrMaxVal = Math.max(
            ...response.StudentGraph.graph_data[1].values_current_month
          );
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[1].values_previous_month.length;
            count++
          ) {
            if (multiplePreviousMaxVal !== 0)
              multipleIntPrevMonthObj["data"][`${multipleIntcaptions[count]}`] =
                response.StudentGraph.graph_data[1].values_previous_month[
                count
                ] / multiplePreviousMaxVal;
            else
              multipleIntPrevMonthObj["data"][`${multipleIntcaptions[count]}`] =
                response.StudentGraph.graph_data[1].values_previous_month[
                count
                ];
          }
          multipleIntDataArray.push(multipleIntPrevMonthObj);
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[1].values_current_month.length;
            count++
          ) {
            if (multipleCurrMaxVal !== 0)
              multipleIntCurrentMontObj["data"][
                `${multipleIntcaptions[count]}`
              ] =
                response.StudentGraph.graph_data[1].values_current_month[
                count
                ] / multipleCurrMaxVal;
            else
              multipleIntCurrentMontObj["data"][
                `${multipleIntcaptions[count]}`
              ] =
                response.StudentGraph.graph_data[1].values_current_month[count];
          }
          multipleIntDataArray.push(multipleIntCurrentMontObj);
          setMultipleIntelligence({
            captions: multipleIntCaptionsObj,
            data: multipleIntDataArray,
          });

          //Learning and Innovative Skills
          let learnCaptionsObj = {},
            learnDataArray = [],
            learnPrevMonthObj = { data: {} },
            learnCurrentMonthObj = { data: {} },
            learnCaptions = [],
            learnPrevMaxVal,
            learnCurrMaxVal;
          response.StudentGraph.graph_data[2].data_points.map((dataPoints) => {
            learnCaptions.push(dataPoints);
            learnCaptionsObj[`${dataPoints}`] = dataPoints;
          });
          learnPrevMaxVal = Math.max(
            ...response.StudentGraph.graph_data[2].values_previous_month
          );
          learnCurrMaxVal = Math.max(
            ...response.StudentGraph.graph_data[2].values_current_month
          );
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[2].values_previous_month.length;
            count++
          ) {
            if (learnPrevMaxVal !== 0)
              learnPrevMonthObj["data"][`${learnCaptions[count]}`] =
                response.StudentGraph.graph_data[2].values_previous_month[
                count
                ] / learnPrevMaxVal;
            else
              learnPrevMonthObj["data"][`${learnCaptions[count]}`] =
                response.StudentGraph.graph_data[2].values_previous_month[
                count
                ];
          }
          learnDataArray.push(learnPrevMonthObj);
          for (
            let count = 0;
            count <
            response.StudentGraph.graph_data[2].values_current_month.length;
            count++
          ) {
            if (learnCurrMaxVal !== 0)
              learnCurrentMonthObj["data"][`${learnCaptions[count]}`] =
                response.StudentGraph.graph_data[2].values_current_month[
                count
                ] / learnCurrMaxVal;
            else
              learnCurrentMonthObj["data"][`${learnCaptions[count]}`] =
                response.StudentGraph.graph_data[2].values_current_month[count];
          }
          learnDataArray.push(learnCurrentMonthObj);
          setLearningAndInnovativeSkills({
            captions: learnCaptionsObj,
            data: learnDataArray,
          });
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });
  const [addDataPoints, { loading: loadingDataPoints }] = useLazyQuery(
    GET_DATA_POINTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        let dataSet = {
          grade_name: data?.getDataPoint?.data_grade_name?.name,
          grade_details: data?.getDataPoint?.data_point_details,
        };
        setDataPointSet(dataSet);
        setShowDataPoints(true);
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );
  return (
    <div>
      <div>
        <Loader
          loading={
            loadingOverallProgress ||
            studentGraphLoading ||
            loadingWeeklyProgress
          }
        />
        <ExploreHeading
          isBack={true}
          title={"Profile"}
          rightChildren={
            <div className="w-full text-right flex justify-end">
              <Popover
                placement="bottom"
                title={null}
                overlayClassName="rounded-lg pb-0 mb-3 shadow-5xl overflow-hidden w-182 z-10"
                content={
                  <div className="h9 text-charcoal">
                    <div
                      className="mb-3 cursor-pointer"
                      onClick={() => {
                        setShowEditProfile(true);
                      }}
                    >
                      Edit Details
                    </div>
                    <div
                      className="mb-3 cursor-pointer"
                      onClick={() => {
                        setShowChangePasscode(true);
                      }}
                    >
                      Change Passcode
                    </div>
                    <div
                      className=" cursor-pointer"
                      onClick={() => {
                        let details = localStorage.getItem("userList");
                        localStorage.removeItem("token");
                        localStorage.clear();
                        localStorage.setItem("userList", details);
                        history.push("/");
                      }}
                    >
                      Logout
                    </div>
                  </div>
                }
                trigger={"hover"}
              >
                <div className=" w-36">
                  <Button
                    secondaryGhost
                    className="font-Nunito text-base px-8  py-2 text-center flex font-normal justify-center items-center"
                    // onClick={() => {}}
                  >
                    <img
                      src={Images.SettingIcon}
                      alt="Settings Blue Icon"
                      className="mr-2"
                    />
                    Settings
                  </Button>
                  {showEditProfile && (
                    <EditProfile
                      data={studentDetails}
                      visible={showEditProfile}
                      onCancel={() => {
                        setShowEditProfile(false);
                      }}
                      onSave={() => {
                        setShowEditProfile(false);
                        refetchProgress();
                      }}
                    />
                  )}
                  {showChangePasscode && (
                    <Modal
                      visible={showChangePasscode}
                      footer={null}
                      onCancel={() => {
                        setShowChangePasscode(false);
                      }}
                    >
                      <ChangePasscode
                        onSuccess={() => {
                          setShowChangePasscode(false);
                        }}
                      />
                    </Modal>
                  )}
                </div>
              </Popover>
            </div>
          }
        />
        {!user_details?.isParentAdded && user_details?.Onboarded_days <= 1 && (
          <div
            className="nunito-bold-lg py-3 flex justify-center items-center bg-skyBlue text-white cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_APP}accountSetup`,

                "_blank"
              );
            }}
          >
            Tap here to help your parent connect their profile to yours
            <RightOutlined
              style={{
                color: "#fff",
              }}
              className="ml-1 text-xs "
            />
          </div>
        )}
        <div
          className="container mx-auto p-8 bg-white"
          style={{
            boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
            borderRadius: "3px",
          }}
        >
          <div className=" mt-2">
            <div className="nunito-bold-2xl mb-9">About me</div>
            <div className=" flex">
              <div>
                <div
                  className="h-24 w-24 rounded-full m-auto"
                  style={{ backgroundColor: bg_color || "whitesmoke" }}
                >
                  {/* <img src={avatar} alt="avatar" /> */}
                  <Avatar
                    src={avatar || DefaultAvatar}
                    style={{
                      backgroundColor: "#FFF6D6",
                      color: "#4C39B3",
                      width: "100%",
                      height: "100%",
                      fontSize: 32,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {avatarMap(
                      (studentDetails.fname || "") +
                        " " +
                        (studentDetails.lname || ""),
                      3
                    )}
                  </Avatar>
                </div>
              </div>
              <div className="ml-8 h6">
                <div className="flex">
                  <div className=" w-28 mr-16 text-lightGray">Name</div>
                  <div>
                    {(studentDetails.fname || "") +
                      " " +
                      (studentDetails.lname || "")}
                  </div>
                </div>
                <div className="flex my-3">
                  <div className="w-28 mr-16 text-lightGray">User name</div>
                  <div>{studentDetails.username || ""}</div>
                </div>
                <div className="flex my-3">
                  <div className=" w-28 mr-16 text-lightGray">
                    Date of Birth
                  </div>
                  <div>{studentDetails.dob}</div>
                </div>
                <div className="flex my-3">
                  <div className=" w-28 mr-16 text-lightGray">Parent Email</div>
                  <div>{studentDetails.parent_email}</div>
                </div>
              </div>
            </div>
          </div>

          <MyInterest
            interestList={interestList}
            loading={loadingOverallProgress}
            onSave={refetchProgress}
          />
          <div className=" mt-8">
            <div className="nunito-bold-2xl">Where I stand</div>
            <TabContainer className=" mt-5">
              <Tabs
                defaultActiveKey="1"
                onChange={() => {}}
                className=" overflow-visible"
              >
                <TabPane tab="My overall progress" key="1">
                  <div className="nunito-bold-xl mb-7 mt-4">
                    Completed learning contents
                  </div>
                  <div className=" grid-cols-3 grid gap-14">
                    <Link to="/profile/collections">
                      <LearningContentCard
                        title={"Cards"}
                        count={completedLearningContents.cards}
                        className={"blue"}
                      ></LearningContentCard>
                    </Link>
                    <Link to="/profile/expert/collections">
                      <LearningContentCard
                        title={"Expert Cards"}
                        count={completedLearningContents.expert_curator}
                        className={"red"}
                      />
                    </Link>
                    <Link to="/profile/playlists">
                      <LearningContentCard
                        title={"Learning paths"}
                        count={completedLearningContents.learning_paths}
                        className={"yellow"}
                      />
                    </Link>
                  </div>
                  {totalHoursSpent.total_hours && (
                    <>
                      <div className="nunito-bold-xl mb-6 mt-7">
                        Total hours spent on learning with Breadcrumb
                      </div>
                      <CardContainer>
                        <div className=" px-32 mx-3 flex items-center justify-center">
                          <ReportsChart
                            data={{
                              watch_cards_hours:
                                totalHoursSpent.watch_cards_hours,
                              do_cards_hours: totalHoursSpent.do_cards_hours,
                              total_hours: totalHoursSpent.total_hours,
                            }}
                          />
                          <div className=" ml-16 nunito-semibold-lg">
                            <div className="flex">
                              <img
                                src={Images.DoCardIcon}
                                alt="Do card"
                                className="mr-4"
                              />
                              Time I’ve spent on doing and learning
                            </div>
                            <div className="flex mt-6">
                              <img
                                src={Images.WatchCardIcon}
                                alt="Watch card"
                                className="mr-4"
                              />
                              Time I’ve spent on watching and increasing my
                              knowledge base{" "}
                            </div>
                          </div>
                        </div>
                      </CardContainer>
                    </>
                  )}
                  <div className="grid grid-cols-2 gap-5 mt-6">
                    <div>
                      <div className="nunito-bold-xl mb-6" id="weeklyReport">
                        Weekly progress
                      </div>
                      <CardContainer>
                        <div className=" pt-5 px-8 items-center justify-between mb-3 flex">
                          <div className="h6">
                            Average time per card: {averageTime}
                          </div>
                          <div>
                            <WeekPicker
                              {...weekData}
                              onChange={(date, dateString) => {
                                setWeekData({
                                  start_date: date
                                    .startOf("week")
                                    .format("YYYY-MM-DD"),
                                  end_date: date
                                    .endOf("week")
                                    .format("YYYY-MM-DD"),
                                  week: dateString,
                                });
                              }}
                              className={"cursor-pointer"}
                            />
                          </div>
                        </div>
                        <ReBarChart weeklyData={weeklyData} />
                      </CardContainer>
                    </div>
                    <div>
                      <div className="nunito-bold-xl mb-6">
                        Difficulty Level (Do cards only)
                      </div>
                      <CardContainer>
                        <div className=" py-6 px-14 grid grid-cols-3 gap-14 justify-between">
                          <div className=" pt-7 pb-5 flex items-center justify-center flex-col ">
                            <div
                              className={`green w-20 h-20 text h3 rounded-full flex items-center justify-center nunito-bold-2xl `}
                            >
                              {difficulyLevel.easy}{" "}
                            </div>
                            <div className="green mt-6 nunito-bold-xl titleBlock">
                              Easy
                            </div>
                          </div>
                          <div className=" pt-7 pb-5 flex items-center justify-center flex-col ">
                            <div
                              className={`orange w-20 h-20 text h3 rounded-full flex items-center justify-center nunito-bold-2xl `}
                            >
                              {difficulyLevel.medium}{" "}
                            </div>
                            <div className="orange mt-6 nunito-bold-xl titleBlock">
                              Medium
                            </div>
                          </div>
                          <div className=" pt-7 pb-5 flex items-center justify-center flex-col ">
                            <div
                              className={`red w-20 h-20 text h3 rounded-full flex items-center justify-center nunito-bold-2xl `}
                            >
                              {difficulyLevel.hard}{" "}
                            </div>
                            <div className="red mt-6 nunito-bold-xl titleBlock">
                              Hard
                            </div>
                          </div>
                        </div>
                      </CardContainer>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="My active learning journey" key="2">
                  <div className=" mt-8">
                    <DataPointChart
                      title={"Blooms Taxonomy"}
                      data={bloomsTaxonomy}
                      onClick={() => {
                        addDataPoints({
                          variables: {
                            data_grade_types_id: 1,
                          },
                        });
                      }}
                    ></DataPointChart>
                    <div className=" mt-10">
                      <DataPointChart
                        title={"Multiple Intelligences"}
                        data={multipleIntelligence}
                        onClick={() => {
                          addDataPoints({
                            variables: {
                              data_grade_types_id: 2,
                            },
                          });
                          // setShowDataPoints(true);
                        }}
                      ></DataPointChart>
                    </div>
                    <div className=" mt-10">
                      <DataPointChart
                        title={"21st Century Learning and Innovation Skills"}
                        data={learningAndInnovativeSkills}
                        onClick={() => {
                          addDataPoints({
                            variables: {
                              data_grade_types_id: 3,
                            },
                          });
                          // setShowDataPoints(true);
                        }}
                      ></DataPointChart>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </TabContainer>
          </div>
        </div>
      </div>
      <Modal
        width={650}
        title={null}
        visible={showDataPoints}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowDataPoints(false);
        }}
      >
        <div className="h3 text-left">{dataPointSet?.grade_name}</div>
        <div className=" mt-4 mb-4 border-b border-gray4 "></div>
        <div>
          {(dataPointSet?.grade_details || []).map((grade, gradeIndex) => {
            return (
              <div className=" mb-10">
                <div className="h6 text-skyBlue">
                  {gradeIndex + 1}) {grade.name}
                </div>
                <div className="h8 mt-4">{grade.desc}</div>
              </div>
            );
          })}
        </div>
        <div className="text-right">
          <div className="flex justify-end">
            <Button
              secondary
              className="font-Nunito text-sm text-white px-6  py-1 text-center flex font-normal justify-center items-center"
              onClick={() => {
                setShowDataPoints(false);
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showParentSetup}
        onCancel={() => {
          // setShowLoginBlock(false);
        }}
        closable={false}
        closeIcon={false}
        width={480}
        footer={null}
        bodyStyle={{
          borderRadius: 9,
        }}
        style={{
          borderRadius: 9,
        }}
        getContainer={true}

        // title="Choose where you want to learn today"
      >
        <div className=" px-5">
          <img
            src={Images.LoginEmptySearch}
            alt="Login Empty State"
            className="mx-auto"
          />
          <div className="h4 text-center mt-8">
            You can handover the iPad to your parent so thay can set up their
            Parent Account
          </div>
          <div className=" mt-10 flex items-center justify-center">
            <div className=" w-40 mx-auto">
              <Button
                secondaryGhost
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  setShowParentSetup(false);
                }}
              >
                Not now
              </Button>
            </div>
            <div className=" w-32 mx-auto">
              <Button
                secondary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_WEB_APP}accountSetup`,
                    "_blank"
                  );
                  setShowParentSetup(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
