import React from "react";
import { useHistory } from "react-router";
import Images from "../../../themes/Images";
import { BackButtonHandler } from "../../../utils";

export default function BackButton() {
  const history = useHistory();
  return (
    <div
      className="flex items-center justify-center left-0 z-20 cursor-pointer"
      onClick={() => {
        // history.goBack();
        BackButtonHandler(history);

        // console.log(history);
      }}
    >
      <img src={Images.BackIcon} alt="back icon" className="hidden lg:block" />
      <img src={Images.BackMobileIcon} alt="back icon" className="lg:hidden" />

      <span className="text-darkGray text-base ml-2 hidden lg:block">Back</span>
    </div>
  );
}
