import React from 'react'
import Button from '../common/Form/Button'

export default function PaymentEmptyState({ title, subTitle, buttonText, buttonContainer, onButtonClick, image }) {
  return (
    <div>
      <div className="bg-ghostWhite p-8 rounded-xl flex relative max-w-lg">
        <div>
          <div className=" text-2xl font-bold font-Nunito text-pureWhite">{title}</div>
          <div className=" mt-4">
            {subTitle}
          </div>
          <div className="mt-6 w-64">
            {buttonContainer && buttonContainer}
            {!buttonContainer &&
              <Button
                primary
                // className={
                //   "font-Nunito text-white  font-bold flex items-center justify-center text-sm"
                // }
                onClick={() => {
                  onButtonClick && onButtonClick()
                }}
              >
                {buttonText}
              </Button>}
          </div>
        </div>
        <div className=" w-36 flex-shrink-0" >
          <img src={image} alt="Learner" className="absolute top-2 right-2" />
        </div>
      </div>
    </div>
  )
}
