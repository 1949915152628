import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/common/Form/Button";
import Images from "../../../../themes/Images";
import MaterialModal from "./MaterialModal";
import Prerequisite from "./Prerequisite";

const Counter = ({ type, setValue, defaultData }) => {
  const [data, setData] = useState(defaultData || "00");
  useEffect(() => {
    if (parseInt(data)) {
      setValue(parseInt(data));
    }
  }, [data]);

  const incrementData = () => {
    let parsedData = parseInt(data);
    if (type === "hrs") {
      if (parsedData < 99) {
        setData(("00" + (parsedData + 1)).slice(-2));
      }
    } else if (type === "mins") {
      if (parsedData < 40) {
        setData(("00" + (parsedData + 20)).slice(-2));
      } else if (parsedData >= 40) {
        setData("00");
      }
    }
  };
  const decrementData = () => {
    if (type === "hrs") {
      if (parseInt(data) > 0) {
        setData(("00" + (parseInt(data) - 1)).slice(-2));
      }
    } else if (type === "mins") {
      if (parseInt(data) > 0) {
        setData(("00" + (parseInt(data) - 20)).slice(-2));
      }
    }
  };
  return (
    <div className=" inline-block">
      <img
        src={Images.CounterupArrow}
        alt="Counter up"
        className={` transition-all duration-500  ${type === "hrs"
          ? data === "99"
            ? "opacity-70 cursor-not-allowed"
            : " cursor-pointer"
          : data === "59"
            ? "opacity-70 cursor-not-allowed"
            : " cursor-pointer"
          }`}
        onClick={incrementData}
      />
      <div className="h3 py-2">
        {data}{" "}
        <span className="h7 text-liteBlack2">{`${type === "hrs" ? "h" : type === "mins" ? "m" : ""
          }`}</span>
      </div>
      <img
        src={Images.CounterupArrow}
        alt="Counter up"
        className={` transition-all duration-500 transform rotate-180 cursor-pointer ${type === "hrs"
          ? data === "00"
            ? "opacity-70 cursor-not-allowed"
            : " cursor-pointer"
          : data === "00"
            ? "opacity-70 cursor-not-allowed"
            : " cursor-pointer"
          }`}
        onClick={decrementData}
      />
    </div>
  );
};

export default function Learn(props) {
  const [isStartLearning, setIsStartLearning] = useState(false);
  const [checkMaterials, setCheckMaterials] = useState(false);
  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedMins, setSelectedMins] = useState(20);
  const [showPrerequisite, setShowPrerequisite] = useState(false);

  return (
    <div>
      <Modal
        width={500}
        title={null}
        visible={isStartLearning}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setIsStartLearning(false);
        }}
      >
        <div className="mt-2 text-center nunito-bold-lg text-textDark">
          Today I would like to spend
        </div>
        <div className="mt-4 text-center text-lightGray">
          {`This will approximately take - ${props.estimate}`}
        </div>
        <div className="mt-8 text-center">
          <span className="mr-5">
            <Counter type="hrs" setValue={setSelectedHours} />
          </span>
          <Counter type="mins" defaultData={20} setValue={setSelectedMins} />
        </div>
        <div className="mt-8">
          <div className=" w-182 mx-auto mb-2 ">
            <Button
              secondary
              className="font-Nunito text-base px-6  py-2 flex font-bold justify-center"
              onClick={() => {
                if (props.onStartLearning) {
                  let hoursInMinutes = selectedHours * 60;
                  let totalMins = hoursInMinutes + selectedMins;
                  //let timeInSeconds = totalMins * 60;
                  if (totalMins > 0) {
                    props.onStartLearning(totalMins);
                  } else {
                    notification.warning({
                      message: "Minimum 20 mins need to be selected",
                    });
                  }
                }
              }}
            >
              Start Learning
            </Button>
          </div>
        </div>
      </Modal>
      <MaterialModal
        data={props.materials}
        visible={checkMaterials}
        onContinue={() => {
          setCheckMaterials(false);
          let dataSet = (props?.prerequisite_details || []).filter(
            (prerequisite) => {
              if (prerequisite.status !== "Completed") {
                return prerequisite;
              }
            }
          );
          if (dataSet.length) {
            setShowPrerequisite(true);
          } else {
            setIsStartLearning(true);
          }
          // if (props?.prerequisite_details.length) {
          //   setShowPrerequisite(true);
          // } else {
          //   setIsStartLearning(true);
          // }
        }}
        onCancel={() => {
          setCheckMaterials(false);
        }}
      />
      <div className=" w-182">
        <Button
          primary
          className="font-Nunito text-base px-6  py-2 flex font-bold justify-center"
          onClick={() => {
            // if (props.type === "watch") {
            //   setIsStartLearning(true);
            // } else if (props.type === "do") {
            // }
            if (props.materials?.length) {
              setCheckMaterials(true);
            } else if (props?.prerequisite_details.length) {
              let dataSet = (props?.prerequisite_details || []).filter(
                (prerequisite) => {
                  if (prerequisite.status !== "Completed") {
                    return prerequisite;
                  }
                }
              );
              if (dataSet.length) {
                setShowPrerequisite(true);
              } else {
                setIsStartLearning(true);
              }
            } else {
              setIsStartLearning(true);
            }
          }}
        >
          <img src={Images.LearnArrowIcon} alt="Arrow Icon" className="mr-3" />
          {`Learn`}
        </Button>
      </div>
      <Prerequisite
        showPrerequisite={showPrerequisite}
        setShowPrerequisite={setShowPrerequisite}
        data={props?.prerequisite_details}
        onSkip={() => {
          setIsStartLearning(true);
        }}
        onSchedule={props.onSchedule}
      />
    </div>
  );
}
