import { useMutation } from "@apollo/client";
import { Modal, notification, Upload } from "antd";
import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ADD_JOURNAL,
  ADD_NOTES_IMAGES,
  WORKSHOP_NOTES
} from "../../../client/graphql";
import { UploadDualPhoto } from "../../../themes/dualImages";
import { getS3UploadUrl, uploadFiles } from "../../../utils";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import Loader from "../Loader";

const NotesContainer = styled.div`
  max-width: calc(900px - 308px);
  textarea.ant-input {
    line-height: 1.65;
    padding: 9px 13px;
  }
`;
export default function NotesPhotoUpload(props) {
  const [file, setFile] = React.useState({});
  const [filePreview, setfilePreview] = React.useState("");
  const [showUpload, setshowUpload] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const { activityId, id } = useParams();
  const [cropProps, setCropProps] = React.useState({
    x: 0,
    y: 0,
    aspect: 16 / 9,
    unit: "px",
    width: 812.44,
    height: 457,
  });
  const [imageType, setImageType] = React.useState("jpeg")
  const [croppedImageBlob, setCroppedImageBlob] = React.useState(null)
  const [cropImageURL, setCropImageURL] = React.useState(null)
  const [isCropped, setIsCropped] = React.useState(false)
  const imageRef = React.useRef();

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const [addImage, { loading: addingImage }] = useMutation(ADD_NOTES_IMAGES, {
    onCompleted: (data) => {
      if (data.ipadAddNotesImagesV2) {
        props.onSuccess && props.onSuccess();
        notification.success({ message: "Image uploaded" });
        setNotes("");
        setshowUpload(false);
        let elementId = document.getElementById("notesSection");
        if (elementId) {
          elementId.scrollIntoView();
        }
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addJournal, { loading: addingJournal }] = useMutation(ADD_JOURNAL, {
    onCompleted: (data) => {
      if (data.AddJournalNoteImageV2?.is_added) {
        props.onSuccess && props.onSuccess();
        notification.success({ message: "Image uploaded" });
        setNotes("");
        setshowUpload(false);
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addWorkshopNotes, { loading: addingNotes }] = useMutation(
    WORKSHOP_NOTES,
    {
      onCompleted: (data) => {
        if (data.ipadAddNotesImagesV2?.is_added) {
          props.onSuccess && props.onSuccess();
          notification.success({ message: "Image uploaded" });
          setNotes("");
          setshowUpload(false);
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );


  const onImageLoaded = image => {
    // imageRef = image;
    onCropComplete(cropProps);
  };

  const onCropChange = (crop, percentCrop) => {
    if (crop.height !== 0 && crop.width !== 0) {
      setCropProps(crop);
    }
  };
  const onCropComplete = crop => {
    makeClientCrop(crop);
  };
  const makeClientCrop = async crop => {
    if (imageRef && crop.width && crop.height) {
      // console.log(imageRef)

      const tempCroppedImageBlob = await getCroppedImg(imageRef.current, crop);
      console.log(URL.createObjectURL(tempCroppedImageBlob));
      setCropImageURL(URL.createObjectURL(tempCroppedImageBlob))
      console.log(tempCroppedImageBlob)
      setCroppedImageBlob(tempCroppedImageBlob)
    }
  };
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    // console.log("imageType ", this.state.imageType)
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          resolve(blob);
        },
        `image/${imageType ? imageType : 'jpeg'}`,
        1
      );
    });
  }

  return (
    <div>
      <Modal
        width={900}
        title={null}
        visible={showUpload}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setshowUpload(false);
        }}
      >
        <Loader loading={addingJournal || addingImage || addingNotes} />

        <div className="nunito-bold-xl">My pictures</div>
        <div className="flex mt-5">
          {filePreview && (
            <div className={` ${props.enableCrop && !isCropped ? "" : "w-60 h-44 mr-5 rounded-md"} `}>
              {props.enableCrop ? (<>
                {isCropped && <img
                  alt="uploaded Img"
                  src={cropImageURL}
                  className="mx-auto rounded-md"
                />}
                {!isCropped &&
                  <ReactCrop
                    src={filePreview}
                    crop={cropProps}
                    minWidth= {406}
                    minHeight= {228}
                    aspect={16 / 9}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  >
                    <img
                      alt="uploaded Img"
                      src={filePreview}
                      ref={imageRef}
                      className="w-full h-full object-cover rounded-md"
                      onLoad={onImageLoaded}
                    />
                  </ReactCrop>}
              </>)
                : (
                  <img
                    src={filePreview}
                    alt="uploaded Img"
                    className="w-full h-full object-scale-down rounded-md"
                  />
                )}
            </div>
          )}
          {((props.enableCrop && isCropped) || !props.enableCrop) &&
            <NotesContainer className="w-full">
              <Textarea notes={notes} setNotes={setNotes} />
            </NotesContainer>}
        </div>
        <div
          className={` ${props.enableCrop ? `mt-12` : `mt-8`
            } flex items-center justify-center`}
        >
          <div className=" w-40 mr-4">
            <Button
              className="font-Nunito text-darkGray border border-darkGray pl-3 pr-6  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                setshowUpload(false);
                // AddToWishlist();
              }}
            >
              Cancel
            </Button>
          </div>
          <div className=" w-36 mr-4">
            <Button
              secondaryGhost
              className="font-Nunito text-base px-3  py-2 text-center flex font-bold justify-center"
              onClick={() => {
                document.getElementById("photoUpload").click();
              }}
            >
              {`Change picture`}
            </Button>
          </div>
          <div className=" w-40">
            <Button
              secondary
              disabled={
                !filePreview || addingJournal || addingImage || addingNotes
              }
              className="font-Nunito text-base text-white px-6  py-2 flex font-bold text-center justify-center"
              onClick={async () => {
                if (props.enableCrop && !isCropped) {
                  setIsCropped(true)
                  return;
                }
                let signedURL = await getS3UploadUrl(file.name, "image");
                if (signedURL) {
                  let imageURLs = await uploadFiles(
                    [signedURL],
                    [croppedImageBlob]
                  );
                  //https://altius-testing-images.s3.ap-south-1.amazonaws.com/Bl9mv7Nsrv149071.png
                  if (imageURLs && imageURLs[0]) {
                    if (props?.title === "Journal Entry") {
                      addJournal({
                        variables: {
                          lcId: parseInt(localStorage.getItem("lc_id")) || 64,
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: imageURLs[0],
                          type: "upload",
                        },
                      });
                    } else if (props?.title === "Workshop notes") {
                      await addWorkshopNotes({
                        variables: {
                          workshop_id: parseInt(id),
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: imageURLs,
                          type: "upload",
                        },
                      });
                    } else {
                      await addImage({
                        variables: {
                          activityId: parseInt(activityId),
                          notes: window.btoa(
                            unescape(encodeURIComponent(notes))
                          ),
                          imageURL: imageURLs,
                          type: "upload",
                        },
                      });
                    }
                  }
                }
              }}
            >
              {`Save`}
            </Button>
          </div>
        </div>
      </Modal>
      <Upload
        // onChange={onChangeFile}
        accept={".png,.jpg,.jpeg"}
        listType="picture"
        showUploadList={false}
        multiple={false}
        beforeUpload={async (file, fileList) => {
          // fileList.map(async (file) => {
          setIsCropped(false)
          setFile(file);
          setfilePreview(await getBase64(file));
          setshowUpload(true);

          // });
          return false;
        }}
      >
        <div className="flex items-center cursor-pointer mb-5" id="photoUpload">
          {/* <img src={Images.UploadPhotoIcon} alt="Voice img" /> */}
          <UploadDualPhoto />
          <div className="ml-3 text-charcoal">Upload a photo</div>
        </div>
      </Upload>
    </div>
  );
}
