import { ReactComponent as NotesIcon } from "../static/images/icons/addBlue.svg";
import { ReactComponent as BackBlueIcon } from "../static/images/icons/chevron-left-colored.svg";
import { ReactComponent as NotificationIcon } from "../static/images/icons/Navbar/notifications.svg";
import { ReactComponent as NotesAddIcon } from "../static/images/icons/notes.svg";
import { ReactComponent as TimerIcon } from "../static/images/icons/timer.svg";
import { ReactComponent as UploadPhoto } from "../static/images/icons/uploadPhoto.svg";
import { ReactComponent as VoiceIcon } from "../static/images/icons/voice.svg";
import { ReactComponent as WishlistBlueIcon } from "../static/images/icons/wishlistBlue.svg";
let blackWhite = `rgba(var(--black),1)`
let primaryCombo = `rgba(var(--blue))`;
let grayCombo = `rgba(var(--darkGray),1)`
let secondaryCombo = `rgba(var(--secondaryGhost))`;

export const TimerDualIcon = () => {
  return <TimerIcon style={{ fill: blackWhite }} />
}
export const NotesDualIcon = () => {
  return <NotesIcon style={{ stroke: secondaryCombo }} />
}
export const NotesDualAddIcon = () => {
  return <NotesAddIcon style={{ stroke: primaryCombo }} />
}
export const VoiceDualIcon = () => {
  return <VoiceIcon style={{ stroke: primaryCombo }} />
}
export const UploadDualPhoto = () => {
  return <UploadPhoto style={{ stroke: primaryCombo }} />
}
export const BackBlueDualIcon = () => {
  return <BackBlueIcon style={{ fill: primaryCombo }} />
}
export const WishlistBlueDualIcon = () => {
  return <WishlistBlueIcon style={{ stroke: primaryCombo }} />
}
export const NotificationDualIcon = () => {
  return <NotificationIcon style={{ stroke: grayCombo }} className="cursor-pointer" />
}