import React, { Component } from "react";
import "./radar.css";
// import RadarChart from 'react-svg-radar-chart';
import RadarChart from "./RadarChart";

export default class Radar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        axes: true, // show axes?
        scales: 1, // how many circles?
        captions: true, // show captions?
        captionMargin: 80,
        dots: true,

        colors: ["#42c0ef", "#EA1F59"],
        dotProps: () => ({
          className: "dot",
          // mouseEnter: this.handleToolTip,
          // mouseLeave: this.handleToolTip
        }),
        // zoomDistance: .1,
      },
    };
  }
  onSelect() { }

  onOpen() { }

  onClose() { }

  signOut() {
    localStorage.clear();
    /* TODO: Change this to route push */
    window.location.href = "/";
  }

  render() {
    return (
      <div className="radarchart text-center">
        <RadarChart
          captions={this.props.captions}
          data={this.props.data}
          options={this.state.options}
          size={400}
        />
      </div>
    );
  }
}

/**
 * TODO: Incase of Text alignment issue refer radar.js search caption function
 */
