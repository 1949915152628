import { useLazyQuery } from "@apollo/client";
import { notification } from "antd";
import moment from "moment";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { VERIFY_PAYMENT, VERIFY_SUBSCRIPTION } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";

const PaymentStyle = styled.div`
  .paymentHeading {
    position: absolute;
    left: 55px;
    right: 55px;
    text-align: center;
  }
  .paymentContainer {
    background: rgba(248, 201, 71, 0.1);
    /* Brand colors/Brand yellow */

    border: 1px solid #f8c947;
  }
  .bg-ghostWhite {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .activeText {
    color: #3ec1f0;
  }
  .validSubscription {
    color: #bdbdbd;
  }
`;
export default function PaymentSuccess() {
  const history = useHistory();
  const location = useLocation();
  const { orderId, paymentId } = useParams();
  const [paymentStatus, setPaymentStatus] = React.useState("")
  
  const [verifyPayment, { data, loading: verifyingPayment }] = useLazyQuery(
    VERIFY_PAYMENT,
    {
      onCompleted: (data) => {
        if (
          data.paymentVerification &&
          data.paymentVerification.msg === "payment success"
        ) {
          setPaymentStatus(true);
        } else {
          setPaymentStatus(false);
        }
      },
      onError: (error) => {
        //notification.error({ message: "Payment failed" });
        setPaymentStatus(false);
      },
    }
  );
  React.useEffect(() => {
    if (orderId && paymentId) {
      verifyPayment({
        variables: {
          payment_request_id: paymentId,
          order_id: orderId,
          purchase_date: moment().format("yyyy-MM-DD HH:mm:ss")
        },
      });
    }
  }, []);

  return (
    <div>
      <Loader loading={verifyingPayment} />
      {paymentStatus === false && (
        <PaymentStyle className=" mt-20 mb-24 max-w-xl mx-auto relative">
          <div className="">
            <div className="nunito-bold-lg text-pureWhite text-center whitespace-nowrap">
              Payment was unsuccessful!
            </div>
          </div>
          <div className="mt-3 bg-ghostWhite rounded-xl p-8 text-pureWhite text-center ">
            <img
              src={Images.paymentFailureEmptyState}
              alt="Payment Success"
              className="mx-auto"
            />
            <div className="mt-6 h5 ">
              {" "}
              Oh no! That's unfortunate. We could not process your payment.
              Please try again?
              {/* <span className="activeText">for you</span> */}
            </div>

            <div className="mt-8 w-64 mx-auto">
              <Button
                secondary
                className="h6 font-bold bg-violet border-violet px-8  py-3 text-center flex justify-center items-center"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Explore Learning Content
              </Button>
            </div>
          </div>
        </PaymentStyle>
      )}
      {paymentStatus === true && (
        <PaymentStyle className=" mt-20 mb-24 max-w-xl mx-auto relative">
          <div className="">
            <div className="nunito-bold-lg text-pureWhite text-center whitespace-nowrap">
              Your payment was successful!
            </div>
          </div>
          <div className="mt-3 bg-ghostWhite rounded-xl p-8 text-pureWhite text-center ">
            <img
              src={Images.paymentSuccessEmptyState}
              alt="Payment Success"
              className="mx-auto"
            />
            <div className="mt-6 h5 ">
              {" "}
              The subscription is now active{" "}
              {/* <span className="activeText">for you</span> */}
            </div>
            <div className="mt-1 nunito-bold-sm validSubscription">
              Subscription is valid till{" "}
              {moment().add(1, "year").format("DD MMM yyyy")}
            </div>
            <div className="mt-8 nunito-bold-sm">
              A confirmation email has been sent to
              <br />
              <span className="text-yellow">
                {data?.paymentVerification?.email}{" "}
              </span>
            </div>
            <div className="mt-8 w-64 mx-auto">
              <Button
                secondary
                className="h6 font-bold bg-violet border-violet px-8  py-3 text-center flex justify-center items-center"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Explore Learning Content
              </Button>
            </div>
          </div>
        </PaymentStyle>
      )}
    </div>
  );
}
