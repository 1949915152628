import { useMutation, useQuery } from "@apollo/client";
import { Modal, notification } from "antd";
import React from "react";
import {
  ADD_PROFILE_INTERESTS,
  GET_INTEREST_CATEGORY
} from "../../../client/graphql";
import InterestCard from "../../../components/Cards/InterestCard";
import Button from "../../../components/common/Form/Button";
import Images from "../../../themes/Images";

const FetchAllData = ({ setAllInterest, existing = [], setLoading }) => {
  /** useQuery for fetch all interest */
  const { loading } = useQuery(GET_INTEREST_CATEGORY, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      let profileInterests = existing.map((i) => `${i.id}`);
      if (response.iPadgetInterestCategory.intrest_cat) {
        let allInterestMap = response.iPadgetInterestCategory.intrest_cat.map(
          (interest) => {
            return {
              ...interest,
              isActive: profileInterests.includes(interest.id),
            };
          }
        );
        setAllInterest(
          allInterestMap.sort((a, b) => a.name.localeCompare(b.name))
        );
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return loading ? (
    <>
      {[{}, {}, {}, {}].map((interest, interestIndex) => {
        return (
          <InterestCard
            key={`Interest-loading-${interestIndex}`}
            loading={true}
            interestIndex={interestIndex}
            isMargin={false}
          />
        );
      })}
    </>
  ) : (
    <></>
  );
};

export default function MyInterest({ interestList = [], onSave, ...props }) {
  const [showInterest, setShowInterest] = React.useState(false);
  const [allInterest, setAllInterest] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const AllInterestClick = (index) => {
    let tempInterest = [...allInterest];
    tempInterest = tempInterest.map((interest, interestIndex) => {
      let status = interest.isActive;
      if (interestIndex === index) {
        status = !interest.isActive;
      }
      return {
        ...interest,
        isActive: status,
      };
    });
    setAllInterest(tempInterest);
  };

  const [addInterests] = useMutation(ADD_PROFILE_INTERESTS, {
    onCompleted: (data) => {
      if (data.studentAddInterestCategory?.is_added) {
        notification.success({ message: "Interests Updated" });
        setShowInterest(false);
        if (onSave) onSave();
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  return (
    <div>
      <div className=" mt-6 flex items-center nunito-bold-2xl">
        My interests
        {interestList.length !== 0 && (
          <div className=" ml-6">
            <Button
              secondaryGhost
              className="h8  px-6  py-2 text-center flex font-normal justify-center items-center"
              onClick={() => {
                setShowInterest(true);
              }}
            >
              Add more
            </Button>
          </div>
        )}
      </div>
      {interestList.length === 0 && !props.loading && (
        <div className=" bg-gray-300 rounded-md mt-5">
          <div className=" w-11/12 mx-auto nunito-semibold-lg text-center px-12 py-6">
            Select Interest here so you can find things you'd love watching and
            doing in the Explore section
            <Button
              secondaryGhost
              className="h8  px-6 mt-5 w-40 mx-auto py-2 text-center flex font-normal justify-center items-center"
              onClick={() => {
                setShowInterest(true);
              }}
            >
              Add more
            </Button>
          </div>
        </div>
      )}
      <div className="relative">
        {interestList.length > 6 && (
          <>
            {
              <div className="absolute top-0 bottom-0 flex items-center justify-center -left-4 ">
                <img
                  src={Images.CarouselLeft}
                  alt="imageRight"
                  className={`rotate-180 transform shadow-3xl rounded-full w-8 h-8 cursor-pointer z-10`}
                  onClick={() => {
                    document.getElementById(`interest-details-thumb`).scrollTo({
                      left:
                        document.getElementById(`interest-details-thumb`)
                          .scrollLeft - 250,
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
            }
            <div className="absolute top-0 bottom-0 flex items-center justify-center -right-4 ">
              <img
                src={Images.CarouselLeft}
                alt="imageRight"
                className={`transform shadow-3xl rounded-full w-8 h-8 cursor-pointer z-10`}
                onClick={() => {
                  document.getElementById(`interest-details-thumb`).scrollTo({
                    left:
                      document.getElementById(`interest-details-thumb`)
                        .scrollLeft + 250,
                    behavior: "smooth",
                  });
                }}
              />
            </div>
          </>
        )}
        <div
          className=" mt-8 flex items-center overflow-auto "
          id="interest-details-thumb"
        >
          {props.loading && (
            <>
              {[{}, {}, {}, {}, {}, {}].map((interest, interestIndex) => {
                return (
                  <InterestCard
                    fixed
                    key={`Interest-loading-${interestIndex}`}
                    loading={true}
                    interestIndex={interestIndex}
                  />
                );
              })}
            </>
          )}
          {!props.loading && (
            <>
              {interestList.map((interest, interestIndex) => {
                return (
                  <InterestCard
                    fixed
                    key={`Interest-details-${interestIndex}`}
                    image={interest.background_image}
                    title={interest.name}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>

      <Modal
        width={1000}
        title={null}
        visible={showInterest}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowInterest(false);
        }}
      >
        <div className="mt-2 text-left nunito-bold-lg text-textDark">
          Add interest to explore
        </div>

        <div className=" mt-8 text-center grid grid-cols-4 gap-5">
          {showInterest && (
            <FetchAllData
              setAllInterest={setAllInterest}
              existing={interestList}
              setLoading={setLoading}
            />
          )}
          {/* {loading &&
            [{}, {}, {}, {}, {}, {}, {}, {}].map((interest, interestIndex) => {
              return (
                <InterestCard
                  key={`Interest-loading-${interestIndex}`}
                  loading={loading}
                  interestIndex={interestIndex}
                  isMargin={false}
                />
              );
            })} */}
          {allInterest.map((interest, interestIndex) => {
            return (
              <div
                onClick={() => AllInterestClick(interestIndex)}
                className=" cursor-pointer"
                key={`Interest-${interestIndex}`}
              >
                <InterestCard
                  key={`Interest-${interestIndex}`}
                  image={interest.background_image}
                  title={interest.name}
                  isMargin={false}
                  isSelected={interest.isActive}
                />
              </div>
            );
          })}
        </div>
        <div className="mt-8 mb-2 flex items-center justify-end">
          <div className=" w-40 mr-4 ">
            <Button
              className="font-Nunito text-lightGray border border-lightGray px-3  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                setShowInterest(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className=" w-40">
            <Button
              secondary
              className="font-Nunito text-base text-white px-6  py-2 flex font-bold justify-center"
              onClick={() => {
                let selected = allInterest.filter(
                  (interest) => interest.isActive
                );
                addInterests({
                  variables: {
                    categories: selected.map((interest) => interest.id),
                  },
                });
              }}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
