import { Carousel as CarouselAntd } from "antd";
import React from "react";
import Images from "../../themes/Images";
const LeftArrow = ({
  customClassName,
  currentSlide,
  className,
  slideCount,
  ...rest
}) => {
  return (
    <>
      <img
        src={Images.CarouselLeft}
        alt="imageLeft"
        {...rest}
        className={`${
          customClassName ? customClassName : "-right-3"
        } w-11 h-11 -mt-6  shadow-3xl rounded-full ${className || ""} ${
          currentSlide === slideCount ? "opacity-0" : "opacity-1"
        } transition-all duration-500`}
      />
    </>
  );
};
const RightArrow = ({ customClassName, currentSlide, className, ...rest }) => {
  return (
    <>
      <div
        className={`z-10  shadow-3xl rounded-full -mt-6 ${
          className || ""
        } w-11 h-11 ${
          currentSlide === 0 ? "opacity-0" : "opacity-1"
        } transition-all duration-500   ${
          customClassName ? customClassName : " left-3"
        }`}
      >
        <img
          src={Images.CarouselLeft}
          alt="imageRight"
          {...rest}
          className={`rotate-180 transform`}
        />
      </div>
    </>
  );
};
export default function Carousel({ slidesToShow = 3, ...props }) {
  return (
    <div>
      <CarouselAntd
        dots={false}
        className={`w-full max-w-full ${props.className || ""}`}
        slidesToScroll={1}
        slidesToShow={slidesToShow}
        arrows={true}
        infinite={false}
        variableWidth={props.variableWidth || false}
        nextArrow={<LeftArrow customClassName={props.RightArrowClassName} />}
        prevArrow={<RightArrow customClassName={props.LeftArrowClassName} />}
      >
        {props.children}
      </CarouselAntd>
    </div>
  );
}
