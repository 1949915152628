import React from "react";
import styled from "styled-components";

const StickyContainer = styled.div`
  box-shadow: var(--stickyBottomShadow)
`

export default function StickyBottom({ children, className = "" }) {
  return (
    <StickyContainer className={`sticky bottom-0 w-full z-30 ${className}`}>
      <div className="absolute top-0 left-0 right-0 h-1 -z-1 shadow-6xl"></div>
      {children}
    </StickyContainer>
  );
}
