import { useLazyQuery } from "@apollo/client";
import { notification } from "antd";
import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { FORGOT_PASSCODE_REQUEST } from "../../client/graphql";
import Button from "../../components/common/Form/Button";
import FormItem from "../../components/common/Form/FormItem";
import ReactForm from "../../components/common/Form/ReactForm";
import ReactInput from "../../components/common/Form/ReactInput";
import Images from "../../themes/Images";
import { BackButtonHandler } from "../../utils";
import BackButton from "./components/BackButton";
import OnboardingLayout from "./layout/OnboardingLayout";

const validationSchema = Yup.object().shape({
  username: Yup.string().max(35).required("Please enter username"),
});
const PasscodeContainer = styled.div`
  min-height: 500px;
  height: 100%;
`;
export default function ForgotPasscode() {
  const formRef = useRef();
  const [userName, setUserName] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    let user_details = JSON.parse(localStorage.getItem("user_details"));
    setUserName(user_details.username);
  }, []);

  const [MakeForgotPasscodeRequest, { loading: forgotPwdLoading }] =
    useLazyQuery(FORGOT_PASSCODE_REQUEST, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.forgotRequest) {
          notification.info({
            message: data.forgotRequest.success_message,
          });
          history.push("/");
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    });

  return (
    <OnboardingLayout loading={forgotPwdLoading}>
      <PasscodeContainer className="flex justify-between flex-col">
        <div>
          <div className="flex justify-between h-9 items-center">
            <div
              className=" cursor-pointer"
              onClick={() => {
                BackButtonHandler(history);
                // history.push("/");
              }}
            >
              <BackButton />
            </div>
            <div className=" absolute left-0 right-0 text-center pointer-events-none">
              <img
                src={Images.Logo}
                alt="logo"
                className="mx-auto w-44 lg:w-52 "
              />
            </div>
          </div>
          <div className="mt-8">
            <div className="authHeading">Forgot Passcode?</div>
            <div className="font-Nunito text-base lg:text-xl text-center mt-8 select-none">
              {`My username is`}
            </div>
          </div>
        </div>
        <div>
          <div className="px-0 md:px-10 lg:px-16 pt-9">
            <Formik
              innerRef={formRef}
              initialValues={{
                username: userName,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values.username);
                MakeForgotPasscodeRequest({
                  variables: {
                    username: values.username,
                  },
                });
              }}
              enableReinitialize
            >
              <ReactForm layout="vertical">
                <FormItem name="username">
                  <ReactInput
                    name="username"
                    placeholder={`Enter your username`}
                    small
                    className={"w-full py-4 px-5"}
                    onChange={(evt) => {
                      setUserName(evt.target.value);
                    }}
                  ></ReactInput>
                </FormItem>
              </ReactForm>
            </Formik>
          </div>
        </div>
        <div>
          <div
            className={`block text-center mt-6 mb-12 w-64 lg:w-56 mx-auto ${
              userName !== "" ? `` : `opacity-50 pointer-events-none`
            }`}
          >
            <Button
              onClick={() => {
                if (userName !== "") {
                  formRef.current.handleSubmit();
                }
              }}
              primary
            >
              Request passcode reset
            </Button>
          </div>
        </div>
      </PasscodeContainer>
    </OnboardingLayout>
  );
}
