import React from "react";
import Images from "../../../themes/Images";

const CartEmpty = () => {
  return (
    <img src={Images.CartEmpty} className="ml-auto mr-auto mt-6" alt="cart empty" />
  );
};

export default CartEmpty;
