import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { WORKSHOP_LIST } from "../../client/graphql";
import WorkshopCard from "../../components/Cards/WorkshopCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import moment from "moment";

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
    ? 48
    : 64;

export default function EventsWorkshop() {
  const location = useLocation();
  const history = useHistory();
  const { data, loading: fetchinghWorkshops } = useQuery(WORKSHOP_LIST, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(localStorage.getItem("lc_id")) || lc_id,
    },
  });
  const [upcomingWorkshop, setUpcomingWorkshop] = React.useState([{}]);
  React.useEffect(() => {
    if (data) {
      let title = location?.pathname?.includes("past") ? "Past" : "Upcoming";
      if (title === "Past") {
        setUpcomingWorkshop(data?.ipadWorkshopList?.past_workshop);
      } else if (title === "Upcoming") {
        setUpcomingWorkshop(data?.ipadWorkshopList?.workshop_details);
      }
    }
  }, [data]);

  const getDateString = (workshop) => {
    let dateString = "";
    const workshopDuration = workshop?.Dates?.length > 0 ? workshop?.Dates[0]?.duration : "1h";
    const startDate =
      workshop?.Dates?.length > 0
        ? moment
            .unix(workshop?.Dates[0]?.workshop_Date / 1000)
            .format("DD MMM 'YY")
        : moment().format("DD MMM 'YY");
    const startDay =
      workshop?.Dates?.length > 0
        ? moment.unix(workshop?.Dates[0]?.workshop_Date / 1000).format("ddd")
        : moment().format("ddd");
    const endDate =
      workshop?.Dates?.length > 0
        ? moment
            .unix(
              workshop?.Dates[workshop?.Dates?.length - 1]?.workshop_Date / 1000
            )
            .format("DD MMM 'YY")
        : moment().format("DD MMM 'YY");
    const endDay =
      workshop?.Dates?.length > 0
        ? moment
            .unix(
              workshop?.Dates[workshop?.Dates?.length - 1]?.workshop_Date / 1000
            )
            .format("ddd")
        : moment().format("ddd");
    if (workshop?.Dates?.length === 1) {
      dateString = `${startDay} | ${startDate} - ${workshopDuration}`;
    } else {
      if (workshop?.repeatDays) {
        dateString = `${workshop?.repeatDays} | ${startDate} - ${endDate} - ${workshopDuration}`;
      } else {
        dateString = `${startDay} - ${endDay} | ${startDate} - ${endDate} - ${workshopDuration}`;
      }
    }
    return dateString;
  };

  return (
    <div>
      <ExploreHeading
        title={
          location?.pathname?.includes("past")
            ? "Past Popular Events"
            : "Upcoming Events"
        }
        onClick={() => {
          history.push(`/workshops`);
        }}
        isBack
      ></ExploreHeading>
      <div
        className="container mx-auto p-6 bg-white"
        style={{
          boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`,
          borderRadius: "3px",
        }}
      >
        <div className=" mt-6 grid grid-cols-3 gap-7">
          {fetchinghWorkshops &&
            [{}, {}].map((workshop, workshopIndex) => {
              return (
                <div className="mr-5">
                  <WorkshopCard loading={fetchinghWorkshops} />
                </div>
              );
            })}
          {!fetchinghWorkshops &&
            upcomingWorkshop.map((workshop, workshopIndex) => {
              return (
                <div
                  key={`pastWorkshop-${workshopIndex}`}
                  className="mr-5"
                  onClick={() => {
                    history.push(`/workshops/${workshop?.id}`);
                  }}
                >
                  <WorkshopCard
                    thumbnail={
                      workshop?.featured_image?.length > 0
                        ? workshop?.featured_image[0]?.image_url
                        : null
                    }
                    author={workshop?.content_creator?.profile_image}
                    time={getDateString(workshop)}
                    title={workshop?.workshop_name}
                    tags={Array.from(workshop?.interest_details || [])
                      ?.slice(0, 2)
                      .map((interest) => interest?.name)}
                    list={
                      location?.pathname?.includes("past") ? "past" : "upcoming"
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
