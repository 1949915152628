import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "../../../../components/common/Carousel";
import Button from "../../../../components/common/Form/Button";
const StepContainer = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 370px;
  .cardImg {
    width: 370px;
    height: 200px;
  }
  .ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Step = ({ data, index, onReadmore }) => {
  return (
    <StepContainer className="rounded-lg bg-white relative mr-4 overflow-hidden mb-4">
      <div className=" py-2 px-3 bg-white rounded-lg nunito-bold-sm absolute top-2.5 left-2.5">
        {index}
      </div>
      <div className="cardImg">
        <img
          src={data.image}
          className="w-full h-full object-cover"
          alt="Card Img"
        />
      </div>
      <div className="px-6 pt-6 h7 ellipsis">{data.desc}</div>
      <div className="text-right">
        <div
          className="pt-3 pb-4 pr-4 text-blue h9 cursor-pointer inline-block"
          onClick={() => {
            onReadmore(index - 1);
          }}
        >
          Read more
        </div>
      </div>
    </StepContainer>
  );
};

export default function Steps({ data = [{}, {}, {}, {}, {}] }) {
  const [stepVisible, setStepVisible] = React.useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  return (
    <div>
      <Modal
        width={630}
        title={null}
        visible={stepVisible}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        onCancel={() => {
          setStepVisible(false);
        }}
      >
        <div className="flex justify-between h6 text-blue relative">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              // setSelectedStep((selectedStep - 1) % data.length);

              setSelectedStep(
                selectedStep - 1 >= 0 ? selectedStep - 1 : data.length - 1
              );
            }}
          >
            <LeftOutlined className="mr-3" /> Previous
          </div>
          <div className="absolute top-0 left-0 right-0 nunito-bold-xl text-center text-textDark pointer-events-none">
            {`Step ${selectedStep + 1} of ${data.length}`}
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setSelectedStep((selectedStep + 1) % data.length);
            }}
          >
            Next <RightOutlined className="ml-3" />
          </div>
        </div>
        <div className="mt-6">
          <img
            src={data[selectedStep]?.image}
            alt="Step img"
            className=" w-full h-80 object-cover"
          />
          <div className="pt-4 h7 ">{data[selectedStep]?.desc}</div>
        </div>
        <div className="mt-4">
          <div className=" w-32 mx-auto">
            <Button
              secondary
              onClick={() => {
                setStepVisible(false);
              }}
            >
              {`Close`}
            </Button>
          </div>
        </div>
      </Modal>
      <Carousel
        variableWidth
        LeftArrowClassName="-left-6"
        RightArrowClassName="-right-6"
      >
        {data.map((step, stepIndex) => {
          return (
            <Step
              index={stepIndex + 1}
              key={`Step-${stepIndex}`}
              onReadmore={(index) => {
                setSelectedStep(index);
                setStepVisible(true);
              }}
              data={step}
            ></Step>
          );
        })}
      </Carousel>
    </div>
  );
}
