import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Images from "../../themes/Images";

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
  &:hover {
    background: #ddd;
  }
`;
const Wave = styled.div`
  width: 100%;
  height: 90px;
`;
const SliderContainer = styled.div`
  input[type="range"] {
    /* box-sizing: border-box;
    font-size: 16px;
    line-height: 1;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: none;

    height: 8px;
    outline: none;
    appearance: none;
    border: none; */
    -webkit-appearance: none;
    width: 100%;
    cursor: pointer;
    height: 5px;
    outline: none !important;
    appearance: none;
    border: none;
    background: transparent;

    border-radius: 30px;
    background-color: #f2f2f2;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: rgb(var(--blue));
    cursor: pointer;
    border-radius: 50%;
    outline: none;

    margin-top: 0px;
  }
  input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: rgb(var(--blue));
    cursor: pointer;
    border-radius: 50%;
    border: none;
    outline: none;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: #ddd;
    color: transparent;
    height: 40px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
  /* input[type="range"]::-ms-thumb:active,
  input[type="range"]::-webkit-slider-thumb:active,
  input[type="range"]:active::-webkit-slider-runnable-track,
  input[type="range"]::-moz-range-thumb:active {
    background-color: #2f98f9;
  } */
`;

const WaveformContianer = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;

  border-bottom: 0;
  box-sizing: border-box;
  backdrop-filter: blur(8px); */
`;
const AudioContainer = styled.div``;
let setData = false;
export default function Audio(props) {
  const url = props.url; //"https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3";

  const [duration, setDuration] = useState("0:00");
  const [sliderValue, setsliderValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  let audio = document.querySelector("audio");
  let durationContainer = document.getElementById("duration");
  let seekSlider = document.getElementById("seek-slider");
  let currentTimeContainer = document.getElementById("current-time");
  let audioPlayerContainer = document.getElementById("audio-player-container");
  let raf = null;
  useEffect(() => {
    audio = document.querySelector("audio");
    durationContainer = document.getElementById("duration");
    seekSlider = document.getElementById("seek-slider");
    currentTimeContainer = document.getElementById("current-time");
    audioPlayerContainer = document.getElementById("audio-player-container");
  });
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  };
  const displayDuration = () => {
    let time = calculateTime(audio.duration);

    setDuration(time);
  };

  const setSliderMax = () => {
    seekSlider.max = Math.floor(audio.duration);
  };

  useEffect(() => {
    const track = document.querySelector("#track");
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (audio && seekSlider) {
        if (audio.readyState > 0) {
          displayDuration();
          setSliderMax();
          displayBufferedAmount();
        } else {
          audio.addEventListener("loadedmetadata", () => {
            displayDuration();
            setSliderMax();
            displayBufferedAmount();
          });
        }

        audio.addEventListener("progress", displayBufferedAmount);
        seekSlider.addEventListener("input", (e) => {
          showRangeProgress(e.target);
        });
        seekSlider.addEventListener("input", onSeekSlider);

        seekSlider.addEventListener("change", () => {
          audio.currentTime = seekSlider.value;
          if (!audio.paused) {
            requestAnimationFrame(whilePlaying);
          }
        });
      }
    }, 1000);
  }, [audio, seekSlider, audio?.readyState]);
  const onSeekSlider = () => {
    setsliderValue(seekSlider.value);
    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    if (!audio.paused) {
      cancelAnimationFrame(raf);
    }
  };
  const handlePlay = () => {
    if (audio) {
      setIsPlaying(!isPlaying);
      if (!isPlaying) {
        // audio.play();
        audio.play();
        whilePlaying();
        // requestAnimationFrame(whilePlaying);
      } else {
        audio.pause();

        cancelAnimationFrame(raf);
      }
    }
  };
  const showRangeProgress = (rangeInput) => {
    if (rangeInput === seekSlider)
      audioPlayerContainer.style.setProperty(
        "--seek-before-width",
        (rangeInput.value / rangeInput.max) * 100 + "%"
      );
    else
      audioPlayerContainer.style.setProperty(
        "--volume-before-width",
        (rangeInput.value / rangeInput.max) * 100 + "%"
      );
  };
  const displayBufferedAmount = () => {
    try {
      const bufferedAmount = Math.floor(
        audio.buffered.end(audio.buffered.length - 1)
      );
      audioPlayerContainer.style.setProperty(
        "--buffered-width",
        `${(bufferedAmount / seekSlider.max) * 100}%`
      );
    } catch (error) { }
  };

  const whilePlaying = () => {
    seekSlider.value = Math.floor(audio.currentTime);
    setsliderValue(seekSlider.value);

    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    audioPlayerContainer.style.setProperty(
      "--seek-before-width",
      `${(seekSlider.value / seekSlider.max) * 100}%`
    );
    if (!raf) raf = requestAnimationFrame(whilePlaying);
  };
  const onTimeUpdate = () => {
    seekSlider.value = Math.floor(audio.currentTime);
    setsliderValue(seekSlider.value);
    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    audioPlayerContainer.style.setProperty(
      "--seek-before-width",
      `${(seekSlider.value / seekSlider.max) * 100}%`
    );
  };

  return (
    <AudioContainer>
      <WaveformContianer id="audio-player-container" className="pb-9 relative">
        <div className="p-3" onClick={handlePlay}>
          <img
            src={Images.PlayIcon}
            alt="Play icon"
            className="mx-auto cursor-pointer"
          />
        </div>

        <audio
          preload
          id="track"
          src={url}
          onTimeUpdate={() => {
            onTimeUpdate();
          }}
          onEnded={(e) => {
            setIsPlaying(false);
            cancelAnimationFrame(raf);
          }}
        />
        <SliderContainer className="absolute -bottom-2 left-0 right-0 w-10/12 mx-auto">
          <div className="relative">
            <div
              className="absolute left-0 bg-blue pointer-events-none"
              style={{
                borderRadius: 30,
                bottom: 7,
                height: 5,
                width: `${(sliderValue / seekSlider?.max) * 100}%`,
              }}
            ></div>

            <input
              type="range"
              id="seek-slider"
              max="100"
              defaultValue={0}

            //value="0:12"
            />
            <span
              id="current-time"
              className="text-black absolute left-2 top-1 mt-6 font-Nunito font-normal text-2xs tracking-wide "
            >
              0:00
            </span>
            <span
              id="duration"
              className="text-black absolute right-1 top-1 mt-6 font-Nunito font-normal text-2xs tracking-wide "
            >
              {duration}
            </span>
          </div>
        </SliderContainer>
      </WaveformContianer>
    </AudioContainer>
  );
}

// export default Waveform;
