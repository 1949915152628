import { Input } from "antd";
import React from "react";

export default function Textarea({ notes, setNotes, limit=320 }) {
  return (
    <Input.TextArea
      showCount={true}
      maxLength={limit}
      value={notes}
      rows={6}
      size="large"
      className="rounded-md leading-1.7 w-full"
      onChange={(e) => {
        setNotes(e.target.value);
      }}
    />
  );
}
