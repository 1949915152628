import { useLazyQuery } from "@apollo/client";
import { notification, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ADD_TO_WISHLIST_ACTIVITY, GET_INTEREST_TAG_CARD, GET_SEARCH_DATA
} from "../../client/graphql";
import ImageCard, { CardContainer } from "../../components/Cards/ImageCard";
import Carousel from "../../components/common/Carousel";
import Loader from "../../components/common/Loader";
import { TabContainer } from "../../components/Style/TabContainer";
import Images from "../../themes/Images";
import ExploreCardSearch from "./ExploreCardSearch";
import WorkshopSearch from "./WorkshopSearch";
const { TabPane } = Tabs;

const CarouselContainer = ({
  cards = [],
  loading,
  type,
  name,
  category,
  onClick,
  onWishlistAdded,
}) => {
  const history = useHistory();
  const [addToWishList, { loading: addingWishList }] = useLazyQuery(
    ADD_TO_WISHLIST_ACTIVITY,
    {
      fetchPolicy: "network-only",
      onCompleted: (wishlistData) => {
        if (wishlistData.addToWishListV2) {
          let wishlistCard = cards?.map((card, cardIndex) => {
            let cardList = { ...card };
            if (card.id === wishlistData.addToWishListV2?.id) {
              cardList.is_wishlist = true;
            }
            return cardList;
          });
          onWishlistAdded(wishlistCard);
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );
  return (
    <Carousel className={""}>
      {cards.slice(0, 5).map((card, cardIndex) => {
        return (
          <div
            className={` cursor-pointer px-2`}
            key={`Cards-${cardIndex}`}
            onClick={() => {
              if (onClick) onClick(card);
            }}
          >
            <ImageCard
              title={card.name || ``}
              coverImage={
                card.thumb_images && card.thumb_images.length
                  ? card.thumb_images[0].image_url
                  : undefined
              }
              status={
                card.activity_status ? card.activity_status : card.card_status
              }
              cardType={
                card.default_activities_id === 2 ||
                  card.default_activities_id === 3
                  ? "watch"
                  : card.default_activities_id === 4
                    ? "do"
                    : "watch"
              }
              isWishlist={card.is_wishlist}
              difficultyLevel={card.difficulty}
              timeLevel={card.time ? `${card.time} mins` : ""}
              videoRefIcon={card.video_link || card.url}
              stepsRefIcon={card.steps}
              imgRefIcon={card.activity_reference_images}
              pdfRefIcon={card.pdf_url}
              loading={loading}
              showWishlist={!card.is_wishlist}
              onWishlist={() => {
                //for collections
                addToWishList({
                  variables: {
                    activityId: card.id,
                  },
                });
              }}
            />
          </div>
        );
      })}
      {!loading && cards.length > 5 && (
        <div className="px-2">
          <CardContainer>
            <div className="viewAll pt-8 px-9 flex items-center justify-center">
              <div className="">
                <div className=" w-32 mx-auto">
                  <div
                    className=" font-Nunito text-2xl font-semibold text-center cursor-pointer"
                    onClick={() => {
                      history.push(`/search/${category}/${type}/${name}`);
                    }}
                  >
                    View all
                  </div>
                </div>
              </div>
            </div>
          </CardContainer>
        </div>
      )}
    </Carousel>
  );
};
let loading = false;

const EmptyState = ({ data }) => {
  return (
    <div className="h-screen text-center mt-10">
      <img src={Images.SearchNotFound} alt="Empty State" className="mx-auto" />
      <div className=" mt-10 nunito-normal-lg">{data}</div>
    </div>
  );
};

var lc_id =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? 43
    : process.env.REACT_APP_BUILD_ENV === "uat"
      ? 48
      : 64;
export default function Search() {
  // const { type, name, id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [showTabs, setShowTabs] = useState(false);
  const [selectedTab, setselectedTab] = useState(undefined);
  const [cards, setCards] = useState([]);
  const [expertCards, setExpertCards] = useState([]);
  const [onTheGo, setOnTheGo] = useState([]);
  const [oneByOne, setOneByOne] = useState([]);
  const [oneByOneExpertCards, setOneByOneExpertCards] = useState([]);
  const [onTheGoExpertCards, setOnTheGoExpertCards] = useState([]);
  const [workshops, setWorkshops] = useState(undefined);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [totals, setTotals] = useState({ cards: 0 });

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (location.pathname.includes("/search")) {
      setType(params.get("type"));
      setName(params.get("name"));
      setShowTabs(false);
      setselectedTab(undefined);
      if (params.get("type") === 'search') {
        getSearchData({
          variables: {
            search: params.get("name"),
            lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
          },
        });
      } else {
        getInterestTag({
          variables: {
            search: params.get("name"),
            lc_id: localStorage.lc_id ? parseInt(localStorage.lc_id) : lc_id,
            is_tag: params.get("type") === "tag",
            is_interest: params.get("type") === "interest",
          },
        })
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (cards.length || expertCards.length) {
      setselectedTab("1");
    } else if (onTheGo.length || onTheGoExpertCards.length) {
      setselectedTab("2");
    } else if (oneByOne.length || oneByOneExpertCards.length) {
      setselectedTab("3");
    } else if (
      workshops &&
      (workshops.featured?.length ||
        workshops.past?.length ||
        workshops.upcoming?.length)
    ) {
      setselectedTab("4");
    } else {
      setselectedTab(undefined);
    }
  }, [cards, expertCards, onTheGo, oneByOne, workshops]);

  const [getSearchData, { loading: fetchingData }] = useLazyQuery(
    GET_SEARCH_DATA,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.getExploreSearch) {
          setCards(data.getExploreSearch.cards || []);
          setExpertCards(data.getExploreSearch.exports_cards || []);
          setOnTheGo(data.getExploreSearch.collection || []);
          setOneByOne(data.getExploreSearch.playlist || []);
          setOneByOneExpertCards(data.getExploreSearch.expert_collection || []);
          setOnTheGoExpertCards(data.getExploreSearch.expert_playlist || []);
          setWorkshops({
            featured: data.getExploreSearch.featured_workshops
              ? data.getExploreSearch.featured_workshops.workshop_details
              : [],
            past: data.getExploreSearch.past_workshops || [],
            upcoming: data.getExploreSearch.workshops || [],
          });
          setTotals({
            cards:
              data.getExploreSearch.total_card_count +
              data.getExploreSearch.tot_expert_card_count,
          });
          setShowTabs(true);
        }
      },
    }
  );

  const [getInterestTag, { loading: fetchingInterestTagData }] = useLazyQuery(GET_INTEREST_TAG_CARD, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.getInterestTagCard) {
        setCards(data.getInterestTagCard.cards || []);
        setExpertCards(data.getInterestTagCard.exports_cards || []);
        setOnTheGo(data.getInterestTagCard.collection || []);
        setOneByOne(data.getInterestTagCard.playlist || []);
        setOneByOneExpertCards(data.getInterestTagCard.expert_collection || []);
        setOnTheGoExpertCards(data.getInterestTagCard.expert_playlist || []);
        setWorkshops({
          featured: data.getInterestTagCard.featured_workshops
            ? data.getInterestTagCard.featured_workshops.workshop_details
            : [],
          past: data.getInterestTagCard.past_workshops || [],
          upcoming: data.getInterestTagCard.workshops || [],
        });
        setTotals({
          cards:
            data.getInterestTagCard.total_card_count +
            data.getInterestTagCard.tot_expert_card_count,
        });
        setShowTabs(true);
      }
    },
  })

  return (
    <div>
      <Loader loading={fetchingData || fetchingInterestTagData} />
      <TabContainer>
        {showTabs && (
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => {
              console.log(key);
              setselectedTab(key);
            }}
            className=" -ml-5 px-20"
          >
            {((expertCards || []).length !== 0 ||
              (cards || []).length !== 0) && (
                <TabPane tab={`Cards (${totals["cards"] || 0})`} key="1"></TabPane>
              )}
            {(onTheGo.length !== 0 || onTheGoExpertCards.length !== 0) && (
              <TabPane
                tab={`On The Go (${(onTheGo.length || 0) + (onTheGoExpertCards.length || 0)
                  })`}
                key="2"
                className={``}
              ></TabPane>
            )}
            {(oneByOne.length !== 0 || oneByOneExpertCards.length !== 0) && (
              <TabPane
                tab={`One By One (${(oneByOne.length || 0) + (oneByOneExpertCards.length || 0)
                  })`}
                key="3"
              ></TabPane>
            )}
            {(workshops.featured?.length !== 0 ||
              workshops.upcoming?.length !== 0 ||
              workshops.past?.length !== 0) && (
                <TabPane
                  tab={`Workshops (${(workshops.featured?.length || 0) +
                    (workshops.upcoming?.length || 0) +
                    (workshops.past?.length || 0)
                    })`}
                  key="4"
                ></TabPane>
              )}
          </Tabs>
        )}
        {!selectedTab && !fetchingData && (
          <EmptyState
            data={
              "No learning contents have been tagged to this interest or skills"
            }
          />
        )}
        {selectedTab === "1" && (
          <div className=" mt-8 px-20 -ml-5">
            {cards.length !== 0 && (
              <CarouselContainer
                cards={cards}
                loading={fetchingData}
                type={type}
                name={name}
                category={"cards"}
                onClick={(card) => {
                  history.push(`/collections/cards/${card.id}`);
                }}
                onWishlistAdded={(data) => {
                  setCards(data);
                }}
              />
            )}
            {expertCards.length !== 0 && (
              <>
                <div className=" mt-6 mb-5 nunito-bold-2xl">
                  Expert and curator cards
                </div>
                <CarouselContainer
                  cards={expertCards}
                  loading={fetchingData}
                  type={type}
                  name={name}
                  category={"experts"}
                  onClick={(card) => {
                    history.push(`/collections/expert/cards/${card.id}`);
                  }}
                  onWishlistAdded={(data) => {
                    setExpertCards(data);
                  }}
                />
              </>
            )}
          </div>
        )}
        {selectedTab === "2" && (
          <div>
            <ExploreCardSearch
              collections={onTheGo}
              isCollection
              expertCards={onTheGoExpertCards}
              onWishlistAdded={() => {
                setShowTabs(false);
                const params = new URLSearchParams(location.search);
                if (params.get("type") === "search") {
                  getSearchData({
                    variables: {
                      search: params.get("name"),
                      lc_id: localStorage.lc_id
                        ? parseInt(localStorage.lc_id)
                        : lc_id,
                    },
                  });
                } else {
                  getInterestTag({
                    variables: {
                      search: params.get("name"),
                      lc_id: localStorage.lc_id
                        ? parseInt(localStorage.lc_id)
                        : lc_id,
                      is_tag: params.get("type") === "tag",
                      is_interest: params.get("type") === "interest",
                    },
                  });
                }
              }}
            />
          </div>
        )}
        {selectedTab === "3" && (
          <div>
            <ExploreCardSearch
              collections={oneByOne}
              expertCards={oneByOneExpertCards}
              isPlaylist
              onWishlistAdded={(data) => {
                const params = new URLSearchParams(location.search);
                if (params.get("type") === "search") {
                  getSearchData({
                    variables: {
                      search: params.get("name"),
                      lc_id: localStorage.lc_id
                        ? parseInt(localStorage.lc_id)
                        : lc_id,
                    },
                  });
                } else {
                  getInterestTag({
                    variables: {
                      search: params.get("name"),
                      lc_id: localStorage.lc_id
                        ? parseInt(localStorage.lc_id)
                        : lc_id,
                      is_tag: params.get("type") === "tag",
                      is_interest: params.get("type") === "interest",
                    },
                  });
                }
              }}
            />
          </div>
        )}
        {selectedTab === "4" && (
          <div className=" mt-8 px-20 -ml-5">
            <WorkshopSearch
              featuredWorkshop={workshops.featured || []}
              upcomingWorkshop={workshops.upcoming || []}
              pastWorkshop={workshops.past || []}
            />
          </div>
        )}
      </TabContainer>
    </div>
  );
}
