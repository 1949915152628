import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Button as AntdButton,
  Checkbox,
  notification,
  Popover,
  Slider
} from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { GET_COLLECTION_DETAILS, GET_FILTER_DATA } from "../../client/graphql";
import ImageCard from "../../components/Cards/ImageCard";
import ExploreHeading from "../../components/common/ExploreHeading";
import Button from "../../components/common/Form/Button";
import Loader from "../../components/common/Loader";
import Images from "../../themes/Images";
import { BackButtonHandler } from "../../utils";

const FilterStyle = styled.div`
  .ant-checkbox-inner {
    border-color: rgb(var(--blue));
  }
`;
const FilterContainer = (props) => {
  const [filterData, setFilterData] = useState({});
  const { collectionId } = useParams();
  const [timeSliderVal, setTimeSliderVal] = useState([0, 0]);
  const [sliderMarks, setSliderMarks] = useState({
    0: "0 mins",
    100: "100 mins",
  });

  useQuery(GET_FILTER_DATA, {
    skip: !collectionId,
    fetchPolicy: "network-only",
    variables: {
      cardCollectonId: collectionId,
    },
    onCompleted: (data) => {
      if (data.getFilterData) {
        setFilterData(data.getFilterData);
        if (data.getFilterData.time) {
          setTimeSliderVal([
            parseInt(data.getFilterData.time.min),
            parseInt(data.getFilterData.time.max),
          ]);
          setSliderMarks({
            [data.getFilterData.time.min]: {
              label: (
                <span className="whitespace-nowrap">{`${data.getFilterData.time.min} mins`}</span>
              ),
            },
            [data.getFilterData.time.max]: {
              label: (
                <span className="whitespace-nowrap">{`${data.getFilterData.time.max} mins`}</span>
              ),
            },
          });
        }
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  return (
    <FilterStyle className="z-10">
      <div className="nunito-semibold-xs text-lightGray">Filter by</div>
      <div className="nunito-semibold-xs text-lightGray mt-2 mb-3">
        Difficulty
      </div>
      <Checkbox.Group
        className="flex flex-wrap"
        onChange={(checkedValue) => {
          if (props.onFilterChange)
            props.onFilterChange(
              "difficulty",
              checkedValue.map((id) => parseInt(id))
            );
        }}
      >
        {(filterData?.difficulty_details?.age_dificulty || []).map(
          (Opt, optIndex) => {
            return (
              <Checkbox
                key={`difficulty-${optIndex}`}
                value={Opt.id}
                className="ml-0 mr-8 h9 text-textDark"
              >
                {Opt.dificulty}
              </Checkbox>
            );
          }
        )}
      </Checkbox.Group>
      <div className="nunito-semibold-xs text-lightGray mt-3 mb-3">
        Content type
      </div>
      <Checkbox.Group
        className="flex flex-wrap"
        onChange={(checkedValue) => {
          if (props.onFilterChange)
            props.onFilterChange(
              "contentType",
              checkedValue.map((id) => parseInt(id))
            );
        }}
      >
        {(filterData?.contentType || []).map((Opt, optIndex) => {
          return (
            <Checkbox
              key={`difficulty-${optIndex}`}
              value={Opt.id}
              className="ml-0 mr-8 h9 text-textDark"
            >
              {Opt.name}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      {filterData?.time?.min && filterData?.time?.max && (
        <>
          <div className="nunito-semibold-xs text-lightGray mt-3 mb-3">
            Time taken
          </div>
          <div className="px-5">
            <Slider
              className="w-full pr-5"
              range
              marks={sliderMarks}
              onChange={(value) => {
                setTimeSliderVal(value);
                setSliderMarks({
                  [value[0]]: {
                    label: (
                      <span className="whitespace-nowrap">{`${value[0]} mins`}</span>
                    ),
                  },
                  [value[1]]: {
                    label: (
                      <span className="whitespace-nowrap">{`${value[1]} mins`}</span>
                    ),
                  },
                });
              }}
              onAfterChange={(value) => {
                if (props.onFilterChange) props.onFilterChange("time", value);
              }}
              value={timeSliderVal}
              min={filterData?.time ? filterData?.time.min : 0}
              max={filterData?.time ? filterData?.time.max : 100}
            />
          </div>
        </>
      )}
      {(filterData?.interest_details || []).length !== 0 && (
        <>
          <div className="nunito-semibold-xs text-lightGray mt-2">
            Interests
          </div>
          <Checkbox.Group
            className="flex flex-col"
            onChange={(checkedValue) => {
              if (props.onFilterChange)
                props.onFilterChange(
                  "interest",
                  checkedValue.map((id) => parseInt(id))
                );
            }}
          >
            {(filterData?.interest_details || []).map((Opt, optIndex) => {
              return (
                <Checkbox
                  key={`difficulty-${optIndex}`}
                  value={Opt.id}
                  className="ml-0 mr-8 h9 mt-3 text-textDark"
                >
                  {Opt.name}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </>
      )}
    </FilterStyle>
  );
};

export default function Listing() {
  // Store only the incompleted cards
  const history = useHistory();
  const location = useLocation();
  const { collectionId } = useParams();
  const [collectionInfo, setCollectionInfo] = React.useState(null);
  const [cardsList, setCardsList] = React.useState([]);
  const [showAllCards, setshowAllCards] = React.useState(false);
  const [filter, setFilter] = useState({});
  const [filtering, setFiltering] = useState(false);
  const [filterResultLength, setFilterResultLength] = useState(null);
  // Store only the completed cards
  const [completedCardsList, setcompletedCardsList] = React.useState([]);
  const [showContent, setShowContent] = React.useState({});

  React.useEffect(() => {
    if (location.pathname.includes("/collections/expert")) {
      setShowContent({
        isExpertCollection: true,
      });
    } else if (location.pathname.includes("/collections")) {
      setShowContent({
        isCollection: true,
      });
    }
  }, []);

  useEffect(() => {
    if (collectionId) {
      fetchCollectionInfo({
        variables: {
          collections_id: collectionId,
        },
      });
    }
  }, [collectionId]);

  const [
    fetchCollectionInfo,
    { data: CollectionData, loading: collectionLoading },
  ] = useLazyQuery(GET_COLLECTION_DETAILS, {
    skip: !collectionId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.ipadActivityList) {
        console.log(data.ipadActivityList);
        setCollectionInfo(data.ipadActivityList.collection_details);
        if (data.ipadActivityList.activity_details) {
          let completedList = [];
          let cardList = [];
          for (let activity of data.ipadActivityList.activity_details) {
            let status =
              activity.activity_status || activity.status || "Not Start";
            if (status === "Completed") {
              completedList.push(activity);
            } else {
              cardList.push(activity);
            }
          }
          setcompletedCardsList(completedList);
          setCardsList(cardList);
          setFilterResultLength(cardList.length);
        } else {
          setFilterResultLength(0);
        }
      }
      setFiltering(false);
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const onFilterChange = (name, value) => {
    setFiltering(true);
    let newFilter = {
      ...filter,
      [name]: value,
    };
    setFilter(newFilter);
    debounceCollection(newFilter);
  };

  const onCardOpen = useCallback(
    (cardId) => {
      if (showContent.isExpertCollection) {
        history.push(`/collections/expert/${collectionId}/${cardId}`);
      } else {
        history.push(`/collections/${collectionId}/${cardId}`);
      }
    },
    [collectionId, showContent]
  );

  const fetchNewCollections = (newFilter) => {
    fetchCollectionInfo({
      variables: {
        collections_id: collectionId,
        filter: [newFilter],
      },
    });
  };

  const debounceCollection = useCallback(
    _.debounce(fetchNewCollections, 2000),
    []
  );

  return (
    <div>
      <Loader loading={collectionLoading || filtering} />
      <div className="bg-white">
        <ExploreHeading
          title={collectionInfo?.name}
          onClick={() => {
            console.log(document.referrer);
            console.log(history);
            // history.push(`/dashboard`);
            BackButtonHandler(history);
          }}
        ></ExploreHeading>
        <div className="px-12">
          {showContent.isCollection && collectionInfo?.description && (
            <div className="mt-6 rounded-md pt-3 pb-4 pl-4 pr-6 bg-ghostWhite">
              <div className="font-Nunito font-bold text-textDark">
                About {collectionInfo?.name}
              </div>
              <div className="font-Nunito font-normal text-sm text-textDark mt-2 ">
                {collectionInfo?.description}
              </div>
            </div>
          )}
          {showContent.isExpertCollection && (
            <div className="mt-6 rounded-md pt-3 pb-4 pl-4 pr-6 bg-ghostWhite">
              <div className="font-Nunito font-bold text-textDark">
                About {collectionInfo?.name}
              </div>
              <div>
                <div className="mt-2 font-semibold font-Nunito text-pureWhite flex">
                  <AntdButton
                    type="link"
                    className="px-0"
                    onClick={() => {
                      history.push(
                        `/collections/expert/contents/${collectionInfo?.user?.id}`
                      );
                    }}
                  >
                    {`By ${collectionInfo?.user?.name || ""}`}{" "}
                  </AntdButton>
                  {collectionInfo?.user?.role_id === 4 && (
                    <img
                      src={Images.VerifiedIcon}
                      alt="verified Icon"
                      className="ml-2"
                    />
                  )}
                </div>
                <div className="mt-2 font-Nunito font-normal text-sm text-textDark">
                  {collectionInfo?.description}
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <div className="font-Nunito font-bold text-textDark text-xl flex justify-between items-center">
              {`Cards - ${cardsList.length || 0}`}
              <div className="inline-block">
                <Popover
                  content={<FilterContainer onFilterChange={onFilterChange} />}
                  trigger="hover"
                  placement="bottomRight"
                >
                  <button type="button">
                    <Button secondaryGhost className="py-2 px-6">
                      Filter
                    </Button>
                  </button>
                </Popover>
              </div>
            </div>
            {!collectionLoading &&
              cardsList.length === 0 &&
              Object.keys(filter).length === 0 &&
              (CollectionData?.ipadActivityList.total_count ===
                completedCardsList.length ? (
                <div className="mt-8 text-center">
                  <img
                    src={Images.CollectionEmptyState}
                    alt="Empty State"
                    className="w-96 mx-auto"
                  />
                  <div className="mt-8 font-Nunito text-textDark text-lg">
                    {collectionInfo?.completion_msg}
                    {/* Seems like you have already completed the contents
                    separately */}
                  </div>
                </div>
              ) : (
                <div className="mt-8 text-center">
                  <img
                    src={Images.NoFilterResults}
                    alt="Empty State"
                    className="w-96 mx-auto"
                  />
                  <div className="mt-8 font-Nunito text-textDark text-lg">
                    No Cards found.
                  </div>
                </div>
              ))}
            {cardsList.length === 0 && Object.keys(filter).length !== 0 && (
              <div className="mt-8 text-center">
                <img
                  src={Images.NoFilterResults}
                  alt="Empty State"
                  className="w-96 mx-auto"
                />
                <div className="mt-8 font-Nunito text-textDark text-lg">
                  No filter results found. Please try again with different
                  parameters.
                </div>
              </div>
            )}
            <div className="grid grid-cols-4 gap-x-5 gap-y-1 mt-5">
              {collectionLoading &&
                filtering &&
                [{}, {}, {}, {}].map((card, cardIndex) => {
                  return (
                    <div>
                      <ImageCard
                        title={``}
                        coverImage={
                          card.thumb_images && card.thumb_images.length
                            ? card.thumb_images[0].image_url
                            : undefined
                        }
                        status={
                          card.activity_status
                            ? card.activity_status
                            : card.card_status
                        }
                        cardType={
                          card.default_activities_id === 2 ||
                            card.default_activities_id === 3
                            ? "watch"
                            : card.default_activities_id === 4
                              ? "do"
                              : "watch"
                        }
                        isWishlist={card.is_wishlist}
                        difficultyLevel={card.difficulty}
                        timeLevel={``}
                        videoRefIcon={card.video_link || card.url}
                        stepsRefIcon={card.steps}
                        imgRefIcon={card.activity_reference_images}
                        pdfRefIcon={card.pdf_url}
                        loading
                      />
                    </div>
                  );
                })}
              {cardsList.map((card, cardIndex) => {
                if (cardIndex < 4 || showAllCards)
                  return (
                    <div
                      className={`cursor-pointer`}
                      onClick={() => {
                        onCardOpen(card.id);
                      }}
                    >
                      <ImageCard
                        title={card.name || `Card ${cardIndex + 1}`}
                        orderNumber={cardIndex + 1}
                        coverImage={
                          card.thumb_images && card.thumb_images.length
                            ? card.thumb_images[0].image_url
                            : undefined
                        }
                        status={
                          card.activity_status
                            ? card.activity_status
                            : card.card_status
                        }
                        cardType={
                          card.default_activities_id === 2 ||
                            card.default_activities_id === 3
                            ? "watch"
                            : card.default_activities_id === 4
                              ? "do"
                              : "watch"
                        }
                        isWishlist={card.is_wishlist}
                        difficultyLevel={card.difficulty}
                        timeLevel={card.time ? `${card.time} mins` : ""}
                        videoRefIcon={card.video_link || card.url}
                        stepsRefIcon={card.steps}
                        imgRefIcon={card.activity_reference_images}
                        pdfRefIcon={card.pdf_url}
                      />
                    </div>
                  );
              })}
            </div>
            {cardsList.length > 4 && !showAllCards && (
              <div className="mt-5 pb-10">
                <div className=" w-32 mx-auto  cursor-pointer">
                  <Button
                    secondaryGhost
                    onClick={() => {
                      setshowAllCards(true);
                    }}
                  >
                    {`+ ${cardsList.length - 4} more`}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {completedCardsList.length !== 0 && (
            <div className="mt-4">
              <div className="font-Nunito font-bold text-textDark text-xl">
                {`Completed - ${completedCardsList.length || 0}`}
              </div>
              <div className="grid grid-cols-4 gap-x-5 gap-y-1 mt-5">
                {collectionLoading &&
                  filtering &&
                  [{}, {}, {}, {}].map((card, cardIndex) => {
                    return (
                      <div>
                        <ImageCard
                          title={``}
                          coverImage={
                            card.thumb_images && card.thumb_images.length
                              ? card.thumb_images[0].image_url
                              : undefined
                          }
                          status={
                            card.activity_status
                              ? card.activity_status
                              : card.card_status
                          }
                          cardType={
                            card.default_activities_id === 2 ||
                              card.default_activities_id === 3
                              ? "watch"
                              : card.default_activities_id === 4
                                ? "do"
                                : "watch"
                          }
                          isWishlist={card.is_wishlist}
                          difficultyLevel={card.difficulty}
                          timeLevel={``}
                          videoRefIcon={card.video_link || card.url}
                          stepsRefIcon={card.steps}
                          imgRefIcon={card.activity_reference_images}
                          pdfRefIcon={card.pdf_url}
                          loading
                        />
                      </div>
                    );
                  })}
                {completedCardsList.map((card, cardIndex) => {
                  if (cardIndex < 4 || showAllCards)
                    return (
                      <div
                        className={`cursor-pointer`}
                        onClick={() => {
                          onCardOpen(card.id);
                        }}
                      >
                        <ImageCard
                          title={card.name || `Card ${cardIndex + 1}`}
                          coverImage={
                            card.thumb_images && card.thumb_images.length
                              ? card.thumb_images[0].image_url
                              : undefined
                          }
                          status={
                            card.activity_status
                              ? card.activity_status
                              : card.card_status
                          }
                          cardType={
                            card.default_activities_id === 2 ||
                              card.default_activities_id === 3
                              ? "watch"
                              : card.default_activities_id === 4
                                ? "do"
                                : "do"
                          }
                          isWishlist={card.is_wishlist}
                          difficultyLevel={card.difficulty}
                          timeLevel={`${card.time} mins`}
                          videoRefIcon={card.video_link || card.url}
                          stepsRefIcon={card.steps}
                          imgRefIcon={card.activity_reference_images}
                          pdfRefIcon={card.pdf_url}
                        />
                      </div>
                    );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
