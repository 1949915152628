import { Tooltip } from "antd";
import React from "react";
import { Img } from 'react-image';
import styled from "styled-components";
import Images from "../../themes/Images";

export const CardContainer = styled.div`
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  background: rgb(var(--eerieBlack));
  .viewAll {
    min-height: 284px;
  }
  .cardImg {
    /* width: 320px; */
    width: 100%;
    height: 160px;
  }
  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cardOrder {
    background: #4c39b3;
  }
  .floatingContainer {
    img {
      border-radius: 2px;
      padding: 5px 6px;
      background: #fff;
      margin-right: 5px;
    }
  }
  .tag {
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    &.blue {
      background: #c8edff;
      color: rgba(var(--scheduleTagTextColor),1);
    }
    &.yellow {
      background: #fffbc3;
      color: rgba(var(--progressTagTextColor),1);
    }
    &.green {
      color: #68b415;
      background: #d8ffad;
    }
    &.gray {
      background: #f0f0f0;
      color: #4f4f4f;
    }
  }
`;
export default function ImageCard({
  title,
  coverImage = "https://via.placeholder.com/400x400.png",
  status,
  cardType,
  isWishlist,
  showWishlist,
  difficultyLevel,
  timeLevel,
  videoRefIcon,
  stepsRefIcon,
  imgRefIcon,
  pdfRefIcon,
  isLoading,
  loading,
  orderNumber = "",
  onWishlist,
}) {
  return (
    <div>
      <CardContainer className="mb-5">
        <div className="cardImg relative">
          {orderNumber !== "" && (
            <div className="cardOrder px-3 rounded-xl h6 absolute left-2 top-2 text-pureWhite">
              {orderNumber || "1"}
            </div>
          )}
          {showWishlist && !loading &&
            status !== "In - Progress" &&
            status !== "Completed" && (
              <Tooltip placement="top" title={"Add to wishlist"} overlayClassName="cardTooltip font-Nunito">
                <img
                  src={Images.WishlistIcon}
                  className="absolute img-fluid right-2 top-2 z-1000 shadow-2xl rounded-2xl scale-105 transform"
                  alt="wishlist"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    if (onWishlist) {
                      onWishlist();
                    }
                  }}
                />
              </Tooltip>
            )}
          {!loading ? (
            <Img
              className="w-full h-full object-cover"
              src={coverImage}
              alt="Explore card"
              loader={<div className="w-full h-full loader"></div>}
            />
          ) : (
            <div className="w-full h-full loader "></div>
          )}
          {(videoRefIcon || stepsRefIcon || imgRefIcon || pdfRefIcon) && (
            <React.Fragment>
              {!isLoading && (
                <div
                  className={`floatingContainer absolute bottom-0 pb-2 pl-2 pr-1 flex justify-center items-center cursor-default`}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  {videoRefIcon && (
                    <img src={Images.VideoIcon} className="img-fluid" alt="" />
                  )}
                  {imgRefIcon && (
                    <img
                      src={Images.PictureIcon}
                      className="img-fluid "
                      alt=""
                    />
                  )}
                  {stepsRefIcon && (
                    <img
                      src={Images.ProgressChartIcon}
                      className="img-fluid"
                      alt=""
                    />
                  )}
                  {pdfRefIcon && (
                    <img src={Images.PdfIcon} className="img-fluid " alt="" />
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="mt-2 mx-3 mb-3">
          <div
            className={`font-Nunito font-bold text-textDark h-12 ellipsis pb-3 `}
          >
            {loading && (
              <div
                className={`${loading ? "loader rounded w-full h-4 " : ""}`}
              ></div>
            )}
            {title || ""}
          </div>
          <div className="flex justify-between items-center cursor-default" onClick={(e) => {
            e.stopPropagation()
          }}>
            <div>
              {status === "Scheduled" && (
                <div className="font-Nunito font-semibold text-xs tag blue">
                  Scheduled
                </div>
              )}
              {status === "Completed" && (
                <div className="font-Nunito font-semibold text-xs tag green">
                  Completed
                </div>
              )}
              {status === "In - Progress" && (
                <div className="font-Nunito font-semibold text-xs tag yellow">
                  In - Progress
                </div>
              )}
              {status === "Not Start" && isWishlist && (
                <div className="font-Nunito font-semibold text-xs tag gray">
                  In wishlist
                </div>
              )}
            </div>
            <div className="cursor-default" onClick={(e) => {
              e.stopPropagation()
            }}>
              {loading && <div className=" loader w-5 rounded h-5"></div>}
              {!loading && (
                <>
                  {cardType === "do" && (
                    <img src={Images.DoCardIcon} alt="Do Card" />
                  )}
                  {cardType === "watch" && (
                    <img src={Images.WatchCardIcon} alt="Watch Card" />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-3 flex justify-between items-center">
            {cardType === "do" && (
              <div>
                {(difficultyLevel || loading) && (
                  <span className="font-Nunito font-normal text-lightGray text-sm" >
                    Difficulty:{" "}
                    <span className="font-Nunito font-semibold text-textDark">
                      {loading && (
                        <span className="h-5 loader rounded w-14 inline-block"></span>
                      )}
                      {difficultyLevel || ""}
                    </span>
                  </span>
                )}
              </div>
            )}
            <div>
              {(timeLevel || loading) && (
                <span className="font-Nunito font-normal text-lightGray text-sm">
                  Time:{" "}
                  <span className="font-Nunito font-semibold text-textDark">
                    {loading && (
                      <span className="h-5 loader rounded w-14 inline-block"></span>
                    )}
                    {timeLevel || ""}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
}
