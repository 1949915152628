import { useMutation } from "@apollo/client";
import { Modal, notification } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import {
  ADD_JOURNAL,
  ADD_NOTES_IMAGES,
  WORKSHOP_NOTES
} from "../../../client/graphql";
import { NotesDualAddIcon } from "../../../themes/dualImages";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import Loader from "../Loader";

export default function Notes(props) {
  const [showNotes, setShowNotes] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const { activityId, playListId, id } = useParams();

  const [addNotes, { loading: addingNotes }] = useMutation(ADD_NOTES_IMAGES, {
    onCompleted: (data) => {
      if (data.ipadAddNotesImagesV2) {
        props.onSuccess && props.onSuccess();
        notification.success({ message: "Notes updated" });
        setNotes("");
        setShowNotes(false);
        let elementId = document.getElementById("notesSection");
        if (elementId) {
          elementId.scrollIntoView();
        }
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addJournal, { loading: addingJournal }] = useMutation(ADD_JOURNAL, {
    onCompleted: (data) => {
      if (data.AddJournalNoteImageV2?.is_added) {
        props.onSuccess && props.onSuccess();
        notification.success({ message: "Notes updated" });
        setNotes("");
        setShowNotes(false);
        let elementId = document.getElementById("notesSection");
        if (elementId) {
          elementId.scrollIntoView();
        }
      }
    },
    onError: (err) => {
      notification.error({ message: err.message });
    },
  });

  const [addWorkshopNotes, { loading: addingNotesWorkshop }] = useMutation(
    WORKSHOP_NOTES,
    {
      onCompleted: (data) => {
        if (data.ipadAddNotesImagesV2?.is_added) {
          props.onSuccess && props.onSuccess();
          notification.success({ message: "Notes updated" });
          setNotes("");
          setShowNotes(false);
          let elementId = document.getElementById("notesSection");
          if (elementId) {
            elementId.scrollIntoView();
          }
        }
      },
      onError: (err) => {
        notification.error({ message: err.message });
      },
    }
  );

  return (
    <div>
      <Modal
        width={550}
        title={null}
        visible={showNotes}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={true}
        className="rounded-lg"
        onCancel={() => {
          setShowNotes(false);
        }}
      >
        <Loader loading={addingNotes || addingJournal || addingNotesWorkshop} />
        <div className="nunito-bold-xl mt-2 text-center">Notes</div>

        <div className=" mt-6">
          <Textarea notes={notes} setNotes={setNotes} limit={1000} />
        </div>
        <div className="mt-8 flex items-center justify-center">
          <div className=" w-40 mr-4">
            <Button
              className="font-Nunito text-lightGray border border-lightGray px-3  py-2 flex text-base font-bold justify-center"
              onClick={() => {
                setNotes("");
                setShowNotes(false);
                // AddToWishlist();
              }}
            >
              Cancel
            </Button>
          </div>

          <div className=" w-40 ">
            <Button
              secondary
              className="font-Nunito text-base text-pureWhite px-6  py-2 flex font-bold text-center justify-center"
              disabled={!notes.length}
              onClick={() => {
                if (props?.title === "Journal Entry") {
                  if (notes?.trim().length > 0) {
                    addJournal({
                      variables: {
                        lcId: parseInt(localStorage.getItem("lc_id")) || 64,
                        notes: window.btoa(unescape(encodeURIComponent(notes))),
                        type: "notes",
                      },
                    });
                  }
                } else if (props?.title === "Workshop notes") {
                  if (notes?.trim().length > 0) {
                    addWorkshopNotes({
                      variables: {
                        workshop_id: parseInt(id),
                        notes: window.btoa(unescape(encodeURIComponent(notes))),
                        type: "notes",
                      },
                    });
                  }
                } else {
                  if (notes?.trim().length > 0) {
                    addNotes({
                      variables: {
                        activityId: activityId,
                        library_id: playListId,
                        notes: window.btoa(unescape(encodeURIComponent(notes))),
                        type: "notes",
                      },
                    });
                  }
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          setShowNotes(true);
        }}
      >
        {/* <img src={Images.NotesIcon} alt="Voice img" /> */}
        <NotesDualAddIcon />
        <div className="ml-3">Make notes</div>
      </div>
    </div>
  );
}
