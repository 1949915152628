import { Popover } from "antd";
import React from "react";
import Button from "../../../../components/common/Form/Button";
import { TimerDualIcon } from "../../../../themes/dualImages";
// const { ReactComponent } = TimerIcon;
export default function ScheduleTimer(props) {
  // const [timeRemaining, setTimeRemaining] = useState(props.estimated);
  // const [timeElapsed, setTimeElapsed] = useState(0);

  // useEffect(() => {
  //   setInterval(() => {
  //     // setTimeRemaining((remaining) => {
  //     //   return remaining - 1;
  //     // });
  //     setTimeElapsed((elapsed) => {
  //       return elapsed + 1;
  //     });
  //   }, 1000);
  // }, []);
  React.useEffect(() => {
    let elementId =
      document.getElementById("watchHowTo") ||
      document.getElementById("watchtrailer") ||
      document.getElementById("textLongDesc");
    if (elementId) {
      elementId.scrollIntoView();
    }
  }, []);
  return (
    <div className="flex">
      <Popover
        placement="topLeft"
        title={null}
        overlayClassName="rounded-lg pb-0 mb-3 shadow-5xl "
        content={
          <div className="h9 w-24 text-liteBlack2">
            {`Time ${props.timeLeft ? "left" : "elapsed"}`}
            <div className="mt-2 font-bold">{props.time} mins</div>
          </div>
        }
        trigger="hover"
      >
        <div className="relative mr-10">
          <TimerDualIcon />
          {/* <img src={TimerIcon} alt="timerIcon" /> */}
          <div
            className={`absolute top-0 -right-2.5 w-2 h-2 rounded-full ${props.timeLeft ? "bg-green" : "bg-orchid"
              }`}
          ></div>
        </div>
      </Popover>
      {props.isPaused ? (
        <Button
          className="font-Nunito border border-lightGray px-11  py-2 flex text-base font-bold text-lightGray"
          onClick={() => {
            // AddToWishlist();
            if (props.onContinue) props.onContinue();
          }}
        >
          Continue
        </Button>
      ) : (
        <Button
          className="font-Nunito border border-lightGray px-11  py-2 flex text-base font-bold text-lightGray"
          onClick={() => {
            // AddToWishlist();
            if (props.onPause) props.onPause();
          }}
        >
          Pause
        </Button>
      )}
    </div>
  );
}
