import React from "react";
import { Modal } from "antd";
import Button from "../../../components/common/Form/Button";
import Audio from "../../../components/common/Audio";

const AudioFeedbackModal = ({ data, isModalVisible, handleClose }) => {
  return (
    <Modal
      visible={isModalVisible}
      centered
      width="60%"
      footer={null}
      className="overflow-auto rounded"
      closable={false}
    >
      <div className="block">
        <Button
          secondaryGhost
          className="font-Nunito text-base px-6 py-2 flex font-bold w-24 ml-auto"
          onClick={() => {
            handleClose();
          }}
        >
          {`Close`}
        </Button>
      </div>
      <div
        className="mt-2"
        style={{ height: "40vh" }}
      >
        <Audio url={data} />
      </div>
    </Modal>
  );
};

export default AudioFeedbackModal;
